/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'

export default class EngagementActivatedEvent implements EventInterface {
  public static readonly NAME = 'ENGAGEMENT_ACTIVATED'

  public constructor(public readonly engagement: EngagementIri, public readonly phase: PhaseIri) {}
}
