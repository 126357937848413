/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'
import type {
  useRepositoryReturnType,
} from '~/src/UserInterface/App/composables/Repository/useRepository'

type ApiValueFilterValueType = string[]

export default class ApiValueFilter<T extends keyof useRepositoryReturnType> extends AbstractFilter<ApiValueFilterValueType> {
  public constructor(
    public readonly repository: T,
    public readonly propertyName: string | undefined = undefined,
  ) {
    super(Operator.EQUALS, FilterType.SELECT, FilterClass.API_VALUE_FILTER)
  }

  public override apply(value: string | string[]) {
    super.apply(Array.isArray(value) ? value : [value])
  }

  protected toUrlParameters(key: string, value: ApiValueFilterValueType): string[] {
    return value.map((v) => `${key}[]=${encodeURIComponent(v)}`)
  }
}
