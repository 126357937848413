/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ApplicationInterface } from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import type File from '~/src/Domain/DataRequest/File/File'
import type DataCorruptionFinding from '~/src/Domain/DataRequest/Validation/DataCorruptionFinding'
import type DataEnrichmentFinding from '~/src/Domain/DataRequest/Validation/DataEnrichmentFinding'
import type DataIntegrationFinding from '~/src/Domain/DataRequest/Validation/DataIntegrationFinding'
import type DataQualityFinding from '~/src/Domain/DataRequest/Validation/DataQualityFinding'
import { Severity } from '~/src/Domain/DataRequest/Validation/Severity'

type DataIntegrityFindings = (
  | DataEnrichmentFinding
  | DataIntegrationFinding
  | DataQualityFinding
  | DataCorruptionFinding
)[]

export default class FileGroup {
  public readonly isValidated: boolean
  public readonly haveAllFilesBeenTransformed: boolean
  public readonly dataIntegrityFindings: DataIntegrityFindings
  public readonly numDataIntegrityFindings: number
  public readonly hasDataIntegrityErrors: boolean
  public readonly hasDataIntegrityWarnings: boolean

  public constructor(
    public readonly file: File,
    public readonly convertedFiles: File[],
    public readonly transformedFiles: File[],
    application: ApplicationInterface,
  ) {
    this.haveAllFilesBeenTransformed
      = this.convertedFiles.length > 0
      && this.convertedFiles.length === this.transformedFiles.length
      && this.convertedFiles.every((c) => this.transformedFiles.some((t) => t.parentFile?.equalsTo(c['@id'])))

    this.dataIntegrityFindings = [this.file, ...convertedFiles, ...transformedFiles].flatMap(
      (f) => f.dataIntegrityFindings,
    )

    this.numDataIntegrityFindings = this.dataIntegrityFindings.length

    this.hasDataIntegrityErrors
      = this.numDataIntegrityFindings > 0
      && this.dataIntegrityFindings.some((f) => f.severity === Severity.ERROR)

    this.hasDataIntegrityWarnings
      = this.numDataIntegrityFindings > 0
      && this.dataIntegrityFindings.some((f) => f.severity === Severity.WARNING)

    this.isValidated
      = (!application.isProcessable && this.file.dataIntegrityStatus !== null)
      || this.hasDataIntegrityErrors
      || this.hasDataIntegrityWarnings
      || (this.convertedFiles.length > 0
        && this.convertedFiles.length === this.transformedFiles.length)
  }
}
