/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataCube from '~/src/Domain/DataCube/DataCube'
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'

export default class DataCubeCollection {
  public constructor(public readonly dataCubes: DataCube[]) {}

  public hasDataCubeWithName(definitionName: string, phase: PhaseIri): boolean {
    return this.dataCubes.some((d) => d.definitionName === definitionName && d.phase.equalsTo(phase))
  }

  public hasPopulatedDataCubeWithName(definitionName: string, phase: PhaseIri): boolean {
    return this.dataCubes.some((d) => d.definitionName === definitionName && d.populated && d.phase.equalsTo(phase))
  }

  public haveAllDataCubesBeenPopulatedFor(analysisType: AnalysisType, phase: PhaseIri): boolean {
    const cubes = this.dataCubes.filter((d) => d.analysisType === analysisType && d.phase.equalsTo(phase))

    return cubes.length > 0 && cubes.every((d) => d.populated)
  }
}
