/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import GreaterThanAbsoluteFilter from '~/src/Domain/Shared/Filter/Filter/GreaterThanAbsoluteFilter'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import type ParameterDefinitionInterface from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionInterface'
import ParameterName from '~/src/Domain/WorkProgram/ParameterName'
import t from '~/src/Infrastructure/Shared/Translation/t'

export default class PerformanceMaterialityThresholdParameterDefinition
implements ParameterDefinitionInterface {
  public getName(): ParameterName {
    return new ParameterName('performanceMaterialityThreshold')
  }

  public getTitle(): string {
    return t('work_program.parameters.performance_materiality_threshold.title')
  }

  public getDescription(): string {
    return t('work_program.parameters.performance_materiality_threshold.description')
  }

  public getFilter(): AbstractFilter<any> {
    return new GreaterThanAbsoluteFilter(FilterType.MONEY)
  }

  public getIcon(): string {
    return 'bell'
  }
}
