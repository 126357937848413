/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  EngagementExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'

export interface TransactionalDataPivotExportDetailsProps extends EngagementExportDetailsProps {
  engagementId: string
  extension: string
  locale: string
  iri: string
  rows: string[]
  columns: string[]
  values: { field: string, valueType: 'count' | 'sum' }[]
  url?: string
}
export default class TransactionalDataPivotExportDetails extends EngagementExportDetailsInterface<
  TransactionalDataPivotExportDetails,
  TransactionalDataPivotExportDetailsProps
> {
  private constructor(
    public readonly engagementId: string,
    public readonly extension: string,
    public readonly locale: string,
    public readonly iri: GenericIri,
    public readonly rows: string[],
    public readonly columns: string[],
    public readonly values: { field: string, valueType: 'count' | 'sum' }[],
    public readonly url: string | undefined,
  ) {
    super()
  }

  public fromJSON(
    values: TransactionalDataPivotExportDetailsProps,
  ): TransactionalDataPivotExportDetails {
    return new TransactionalDataPivotExportDetails(
      values.engagementId,
      values.extension,
      values.locale,
      new GenericIri(values.iri),
      values.rows,
      values.columns,
      values.values,
      values.url,
    )
  }

  public toJSON(): TransactionalDataPivotExportDetailsProps {
    return {
      engagementId: this.engagementId,
      extension: this.extension,
      locale: this.locale,
      iri: this.iri.toString(),
      rows: this.rows,
      columns: this.columns,
      values: this.values,
      url: this.url,
    }
  }
}
