/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'

export default class DeleteDataRequestCommand implements CommandInterface {
  public static readonly NAME = 'DELETE_DATA_REQUEST'

  public constructor(public readonly dataRequest: DataRequestIri) {}
}
