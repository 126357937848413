/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { FormKitNode, FormKitPlugin } from '@formkit/core'

export function createValidationInputClassPlugin(): FormKitPlugin {
  return (node: FormKitNode) => {
    node.on('mounted', () => {
      node.on(
        'message-added',
        ({ payload }: { payload: { key: string, type: string, visible: boolean } }) => {
          if (
            payload.key === 'rule_required'
            && payload.type === 'validation'
            && payload.visible === true
          ) {
            document.querySelector(`#${node.props.id}`)?.classList.add('is-invalid')
          }
        },
      )

      node.on(
        'message-removed',
        ({ payload }: { payload: { key: string, type: string, visible: boolean } }) => {
          if (
            payload.key === 'rule_required'
            && payload.type === 'validation'
            && payload.visible === true
          ) {
            document.querySelector(`#${node.props.id}`)?.classList.remove('is-invalid')
          }
        },
      )
    })
  }
}
