/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type CoaMappingApiRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingApiRepositoryInterface'
import type CoaMappingStoreRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingStoreRepositoryInterface'
import EngagementActivatedEvent from '~/src/Domain/Engagement/Event/EngagementActivatedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(EngagementActivatedEvent.NAME, 'coaMappingEngagementActivatedEventHandler')
export default class CoaMappingEngagementActivatedEventHandler extends EventHandlerInterface<EngagementActivatedEvent> {
  private readonly coaMappingApiRepository: CoaMappingApiRepositoryInterface
  private readonly coaMappingStoreRepository: CoaMappingStoreRepositoryInterface

  public constructor({ coaMappingApiRepository, coaMappingStoreRepository }: Services) {
    super()
    this.coaMappingApiRepository = coaMappingApiRepository
    this.coaMappingStoreRepository = coaMappingStoreRepository
  }

  public async handle(event: EngagementActivatedEvent): Promise<void> {
    this.coaMappingStoreRepository.resetState()

    const coaMappings = await this.coaMappingApiRepository.findAll(event.engagement)
    this.coaMappingStoreRepository.markState(coaMappings)
  }
}
