/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import IntroductionStep from '~/src/Domain/DataRequest/Step/IntroductionStep'
import { Status } from '~/src/Domain/DataRequest/Step/Status/Status'
import type StatusBuilderInterface from '~/src/Domain/DataRequest/Step/Status/StatusBuilderInterface'
import type StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class IntroductionStepStatusBuilder implements StatusBuilderInterface {
  public supports(step: StepInterface): boolean {
    return step instanceof IntroductionStep
  }

  public build(_step: StepInterface, _dataRequest: DataRequestProjection): Status {
    return Status.INITIAL
  }
}
