/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'
import type {
  useRepositoryReturnType,
} from '~/src/UserInterface/App/composables/Repository/useRepository'

type MultiApiValueFilterType = string[]

export default class MultiApiValueFilter<T extends keyof useRepositoryReturnType> extends AbstractFilter<MultiApiValueFilterType> {
  public constructor(
    public readonly repository: T,
    public readonly propertyNames: string[],
  ) {
    super(Operator.IN, FilterType.SELECT, FilterClass.MULTI_API_VALUE_FILTER)
  }

  protected toUrlParameters(key: string, value: MultiApiValueFilterType): string[] {
    return value.map((v) => `${key}[]=${encodeURIComponent(v)}`)
  }
}
