/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Image from '~/src/Domain/DataRequest/Fixtures/Content/Image/Image'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class VanLanschotDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.VAN_LANSCHOT === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.VAN_LANSCHOT),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.van_lanschot.pages.xml_file.title'),
          '',
          [
            new Alert(
              t('components.data_request.van_lanschot.pages.intro.alert_background.message'),
              t('components.data_request.van_lanschot.pages.intro.alert_background.heading'),
              'bell',
            ),
            new Paragraph([
              t('components.data_request.van_lanschot.pages.xml_file.open_bank_enviroment'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.download_camt_file.open_export'),
                  new Badge(45, undefined, 10, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/download_camt_file.png',
            ),
            new Paragraph([
              t(
                'components.data_request.van_lanschot.pages.xml_file.following_page_is_being_shown',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.van_lanschot.checklist.select_account_holders.select_all_accounts_holders',
                  ),
                  new Badge(30, undefined, 44, undefined),
                ),
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.select_account_holders.click_next'),
                  new Badge(43, undefined, 82, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/select_account_holders.png',
            ),
            new Paragraph([
              t('components.data_request.van_lanschot.pages.xml_file.select_relevant_accounts'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.select_bank_accounts.select_all_accounts'),
                  new Badge(46, undefined, 44, undefined),
                ),
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.select_bank_accounts.click_next'),
                  new Badge(62, undefined, 82, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/select_bank_accounts.png',
            ),
            new Paragraph([
              t('components.data_request.van_lanschot.pages.xml_file.export_details'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.van_lanschot.checklist.select_export_details.select_transactions',
                  ),
                  new Badge(40, undefined, 29, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.van_lanschot.checklist.select_export_details.select_adjusted_period',
                  ),
                  new Badge(64, undefined, 46, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/select_export_details.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.select_date_range.fill_in_dates'),
                  new Badge(47, undefined, 63, undefined),
                ),
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.select_date_range.select_camt'),
                  new Badge(68, undefined, 42, undefined),
                ),
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.select_date_range.click_next'),
                  new Badge(81, undefined, 82, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/select_date_range.png',
            ),
            new Paragraph([
              t('components.data_request.van_lanschot.pages.xml_file.optional_filename'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.enter_filename.click_export'),
                  new Badge(48, undefined, 82, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/enter_filename.png',
            ),
            new Paragraph([
              t(
                'components.data_request.van_lanschot.pages.xml_file.status_update_on_bottom_of_page',
              ),
            ]),
            new Image(
              '/img/data-request/van_lanschot/wait_for_status_update.png',
              t(
                'components.data_request.van_lanschot.pages.xml_file.status_update_on_bottom_of_page',
              ),
            ),
            new Paragraph([
              t('components.data_request.van_lanschot.pages.xml_file.download_banking_file'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.van_lanschot.checklist.download_file.click_download'),
                  new Badge(77, undefined, 71, undefined),
                ),
              ],
              '/img/data-request/van_lanschot/download_file.png',
            ),
            new FileUploader(new GenericFileType(), ['application/xml', 'text/xml']),
          ],
        ),
      ]),
    )
  }
}
