/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type EnumFilterValueType = string[] | string

export default class EnumFilter extends AbstractFilter<EnumFilterValueType> {
  public constructor(public enumObject: Record<string, string>) {
    super(Operator.IN, FilterType.SELECT, FilterClass.ENUM_FILTER)
  }

  protected toUrlParameters(key: string, value: EnumFilterValueType): string[] {
    return (Array.isArray(value) ? value : [value]).map((v) => `${key}[]=${encodeURIComponent(v)}`)
  }
}
