/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ComputedRef, Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import clone from '~/src/Domain/Shared/Utils/Clone'
import WorkProgramNotActivatedException from '~/src/Domain/WorkProgram/Exception/WorkProgramNotActivatedException'
import WorkProgramStepNotActivatedException from '~/src/Domain/WorkProgram/Exception/WorkProgramStepNotActivatedException'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import WorkProgram from '~/src/Domain/WorkProgram/WorkProgram'
import type { WorkProgramProps } from '~/src/Domain/WorkProgram/WorkProgram'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

const fixtures = ref<Readonly<WorkProgramProjection[]>>([])
const workPrograms = ref<WorkProgramProjection[]>([])
const currentWorkProgram = ref<WorkProgramProjection | undefined>(undefined)
const currentWorkProgramStep = ref<StepInterface | undefined>(undefined)

const workProgramStore = defineStore('work-program', () => {
  const startedWorkPrograms = ref<WorkProgramProps[]>([])

  const activateWorkProgram = (workProgramSlug: Slug, iri: WorkProgramIri | undefined) => {
    currentWorkProgram.value = workPrograms.value.find(
      (w) => w.slug.equals(workProgramSlug) && w.iri?.toString() === iri?.toString(),
    )
  }

  const activateWorkProgramStep = (workProgramStepSlug: Slug) => {
    if (currentWorkProgram.value === undefined) {
      throw new WorkProgramNotActivatedException(
        'Cannot activate workProgram step, as a workProgram is not activated.',
      )
    }

    currentWorkProgramStep.value
      = currentWorkProgram.value.stepSectionCollection.getStep(workProgramStepSlug)
  }

  const hasCurrentWorkProgram = computed(() => currentWorkProgram.value !== undefined)

  const getCurrentWorkProgram = (): ComputedRef<WorkProgramProjection> => {
    if (currentWorkProgram.value === undefined) {
      throw new WorkProgramNotActivatedException(
        'Cannot get the current workProgram, as it is not activated.',
      )
    }

    return computed(() => currentWorkProgram.value as WorkProgramProjection)
  }

  const getCurrentWorkProgramStep = (): ComputedRef<StepInterface> => {
    if (currentWorkProgramStep.value === undefined) {
      throw new WorkProgramStepNotActivatedException(
        'Cannot get the current workProgram step, as it is not activated.',
      )
    }

    return computed(() => currentWorkProgramStep.value as StepInterface)
  }

  const updateStartedWorkProgram = (workProgram: WorkProgram) => {
    const index = startedWorkPrograms.value.findIndex(
      (w) => new Slug(w.slug).equals(workProgram.slug) && workProgram['@id'].toString() === w['@id'],
    )

    if (index === -1) {
      if (
        currentWorkProgram.value !== undefined
        && currentWorkProgram.value.iri === undefined
        && currentWorkProgram.value.slug.equals(workProgram.slug)
      ) {
        currentWorkProgram.value.iri = workProgram['@id']
      }

      startedWorkPrograms.value.push(workProgram.toJSON())
    } else {
      startedWorkPrograms.value[index] = workProgram.toJSON()
    }

    const fixture = workPrograms.value.find((w) => w.phase?.toString() === workProgram.phase.toString() && w.slug.equals(workProgram.slug))
    fixture?.populateFromDomainModel(workProgram)
  }

  const deleteStartedWorkProgram = (workProgram: WorkProgramIri) => {
    const index = startedWorkPrograms.value.findIndex((w) => w['@id'] === workProgram.toString())
    if (index > -1) {
      startedWorkPrograms.value.splice(index, 1)

      if (currentWorkProgram.value !== undefined && currentWorkProgram.value.iri === workProgram) {
        currentWorkProgram.value = undefined
        currentWorkProgramStep.value = undefined
      }
    }
  }

  const markState = (w: WorkProgram[], f: WorkProgramProjection[]) => {
    startedWorkPrograms.value = w.map((w) => w.toJSON())
    fixtures.value = f
  }

  const markWorkProgramFixtures = (f: WorkProgramProjection[]) => {
    fixtures.value = f
  }

  const resetState = (): void => {
    fixtures.value = []
    workPrograms.value = []
    startedWorkPrograms.value = []
  }

  const calculateWorkProgramState = () => {
    const allWorkPrograms: WorkProgramProjection[] = clone(
      fixtures.value,
    ) as WorkProgramProjection[]
    for (const startedWorkProgram of startedWorkPrograms.value) {
      const fixture = clone(
        fixtures.value.find((f) => f.slug.equals(new Slug(startedWorkProgram.slug))),
      ) as WorkProgramProjection | undefined

      if (fixture === undefined) {
        continue
      }

      fixture.populateFromDomainModel(WorkProgram.prototype.fromJSON(startedWorkProgram))
      allWorkPrograms.push(fixture)
    }

    workPrograms.value = allWorkPrograms

    if (currentWorkProgram.value !== undefined) {
      currentWorkProgram.value = workPrograms.value.find(
        (w) => w.iri?.toString() === currentWorkProgram.value?.iri?.toString(),
      )
      currentWorkProgramStep.value = currentWorkProgram.value?.stepSectionCollection.getStep(
        currentWorkProgramStep.value?.slug as Slug,
      )
    }
  }

  watch([() => startedWorkPrograms, () => fixtures], () => {
    calculateWorkProgramState()
  }, { deep: true })

  return {
    startedWorkPrograms: startedWorkPrograms as Readonly<Ref<WorkProgramProps[]>>,
    fixtures,
    workPrograms,
    currentWorkProgram,
    currentWorkProgramStep,
    hasCurrentWorkProgram,
    getCurrentWorkProgram,
    getCurrentWorkProgramStep,
    updateStartedWorkProgram,
    deleteStartedWorkProgram,
    activateWorkProgram,
    activateWorkProgramStep,
    markState,
    markWorkProgramFixtures,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(workProgramStore, import.meta.hot))
}

export default workProgramStore
