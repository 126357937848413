/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import Applications from '~/src/Domain/DataRequest/Application/Applications'
import ApplicationNotFoundException from '~/src/Domain/DataRequest/Application/Exception/ApplicationNotFoundException'
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'

export interface ApplicationInterface {
  id: ApplicationId
  name: () => string
  icon: string | undefined
  logo: string | undefined
  analysisType: AnalysisType
  isProcessable: boolean
}

export default class ApplicationFactory {
  public static create(applicationId: ApplicationId): ApplicationInterface {
    for (const [_, applications] of Object.entries(Applications)) {
      for (const application of applications) {
        if (!(applicationId === application.id)) {
          continue
        }

        return application
      }
    }

    throw new ApplicationNotFoundException(applicationId)
  }

  public static createForAnalysisType(analysisType: AnalysisType): ApplicationInterface[] {
    return Applications[analysisType]
  }
}
