/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type FileGroup from '~/src/Domain/DataRequest/File/FileGroup/FileGroup'
import IntroductionStep from '~/src/Domain/DataRequest/Step/IntroductionStep'
import { Status } from '~/src/Domain/DataRequest/Step/Status/Status'
import type StatusBuilderInterface from '~/src/Domain/DataRequest/Step/Status/StatusBuilderInterface'
import type StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import SummaryStep from '~/src/Domain/DataRequest/Step/SummaryStep'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class StepStatusBuilder implements StatusBuilderInterface {
  public supports(step: StepInterface): boolean {
    return !(step instanceof IntroductionStep) && !(step instanceof SummaryStep)
  }

  public build(step: StepInterface, dataRequest: DataRequestProjection): Status {
    const fileGroups: FileGroup[] = step.fileTypes.flatMap((fileType) =>
      dataRequest.getFileGroups(step.slug, fileType),
    )

    // When no files have been uploaded, set the status to initial
    if (fileGroups.length === 0) {
      return Status.INITIAL
    }

    // For GenericApplication, it is sufficient to have files uploaded to be able to fulfill the data request
    if (!dataRequest.application.isProcessable && fileGroups.length > 0) {
      return Status.COMPLETED
    }

    if (fileGroups.some((g) => g.hasDataIntegrityErrors || g.hasDataIntegrityWarnings)) {
      return Status.HAS_ERRORS
    }

    if (fileGroups.every((g) => g.haveAllFilesBeenTransformed)) {
      return Status.COMPLETED
    }

    return Status.PENDING
  }
}
