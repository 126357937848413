/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import FileIri from '~/src/Domain/DataRequest/File/FileIri'
import type { FileStatus } from '~/src/Domain/DataRequest/File/FileStatus'
import DataCorruptionFinding from '~/src/Domain/DataRequest/Validation/DataCorruptionFinding'
import type { DataCorruptionFindingProps } from '~/src/Domain/DataRequest/Validation/DataCorruptionFinding'
import DataEnrichmentFinding from '~/src/Domain/DataRequest/Validation/DataEnrichmentFinding'
import type { DataEnrichmentFindingProps } from '~/src/Domain/DataRequest/Validation/DataEnrichmentFinding'
import DataIntegrationFinding from '~/src/Domain/DataRequest/Validation/DataIntegrationFinding'
import type { DataIntegrationFindingProps } from '~/src/Domain/DataRequest/Validation/DataIntegrationFinding'
import { DataIntegrityPillar } from '~/src/Domain/DataRequest/Validation/DataIntegrityPillar'
import type { DataIntegrityStatus } from '~/src/Domain/DataRequest/Validation/DataIntegrityStatus'
import DataQualityFinding from '~/src/Domain/DataRequest/Validation/DataQualityFinding'
import type { DataQualityFindingProps } from '~/src/Domain/DataRequest/Validation/DataQualityFinding'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import Slug from '~/src/Domain/Shared/Identifier/Slug'

export interface FileProps extends DomainModelProps {
  '@id': string
  '@type': string
  originalFileName: string | undefined
  size: number
  extension: string
  fileType: string
  mimeTypes: Record<string, string[]>
  status: FileStatus
  dataIntegrityStatus: DataIntegrityStatus | undefined
  dataIntegrityFindings: (
    | DataEnrichmentFindingProps
    | DataIntegrationFindingProps
    | DataQualityFindingProps
    | DataCorruptionFindingProps
  )[]
  parentFile: string | undefined
  hash: string
  stepSlug: string | undefined
}

export default class File implements DomainModelInterface<File, FileProps> {
  public readonly '@id': FileIri
  public readonly '@type': string

  private constructor(
    id: FileIri,
    type: string,
    public readonly originalFileName: string | undefined,
    public readonly size: number,
    public readonly extension: string,
    public readonly fileType: string,
    public readonly mimeTypes: Record<string, string[]>,
    public readonly status: FileStatus,
    public readonly dataIntegrityStatus: DataIntegrityStatus | undefined,
    public readonly dataIntegrityFindings: (
      | DataEnrichmentFinding
      | DataIntegrationFinding
      | DataQualityFinding
      | DataCorruptionFinding
    )[],
    public readonly parentFile: FileIri | undefined,
    public readonly hash: string,
    public readonly stepSlug: Slug | undefined,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'File'
  }

  public fromJSON(values: FileProps): File {
    return new File(
      new FileIri(values['@id']),
      values['@type'],
      values.originalFileName,
      values.size,
      values.extension,
      values.fileType,
      values.mimeTypes,
      values.status,
      values.dataIntegrityStatus,
      values.dataIntegrityFindings.map((f) => {
        const pillar = f.pillar as DataIntegrityPillar
        switch (f.pillar) {
          case DataIntegrityPillar.DATA_ENRICHMENT:
            return DataEnrichmentFinding.prototype.fromJSON(f)
          case DataIntegrityPillar.DATA_INTEGRATION:
            return DataIntegrationFinding.prototype.fromJSON(f)
          case DataIntegrityPillar.DATA_QUALITY:
            return DataQualityFinding.prototype.fromJSON(f)
          case DataIntegrityPillar.DATA_CORRUPTION:
            return DataCorruptionFinding.prototype.fromJSON(f)
          default:
            throw new Error(`Unknown pillar: "${String(pillar)}"`)
        }
      }),
      values.parentFile === undefined ? undefined : new FileIri(values.parentFile),
      values.hash,
      values.stepSlug === undefined ? undefined : Slug.fromString(values.stepSlug),
    )
  }

  public toJSON(): FileProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      originalFileName: this.originalFileName,
      size: this.size,
      extension: this.extension,
      fileType: this.fileType,
      mimeTypes: this.mimeTypes,
      status: this.status,
      dataIntegrityStatus: this.dataIntegrityStatus,
      dataIntegrityFindings: this.dataIntegrityFindings.map((f) => f.toJSON()),
      parentFile: this.parentFile?.toString(),
      hash: this.hash,
      stepSlug: this.stepSlug?.toString(),
    }
  }
}
