/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import WorkProgramUpdatedEvent from '~/src/Domain/WorkProgram/Event/WorkProgramUpdatedEvent'
import type WorkProgramStoreRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(WorkProgramUpdatedEvent.NAME, 'workProgramUpdatedEventHandler')
export default class WorkProgramUpdatedEventHandler extends EventHandlerInterface<WorkProgramUpdatedEvent> {
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface
  private readonly workProgramStoreRepository: WorkProgramStoreRepositoryInterface

  public constructor({ engagementStoreRepository, workProgramStoreRepository }: Services) {
    super()
    this.engagementStoreRepository = engagementStoreRepository
    this.workProgramStoreRepository = workProgramStoreRepository
  }

  public handle(event: WorkProgramUpdatedEvent): void {
    if (
      this.engagementStoreRepository.hasCurrentEngagement()
      && event.workProgram['@id'].toString().startsWith(
        this.engagementStoreRepository.getCurrentEngagement()['@id'].toString(),
      )
    ) {
      this.workProgramStoreRepository.updateStartedWorkProgram(event.workProgram)
    }
  }
}
