/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Image from '~/src/Domain/DataRequest/Fixtures/Content/Image/Image'
import Header from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Header'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class ExactOnlineDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.EXACT_ONLINE === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.EXACT_ONLINE),
      new StepCollection([
        new Step(
          new Slug('profit-and-loss'),
          t('components.data_request.exact_online.pages.profit_and_loss.title'),
          '',
          [
            new Alert(
              t(
                'data_request.exact_online.pages.profit_and_loss.alert_export_trial_balance_first.text',
              ),
              t(
                'data_request.exact_online.pages.profit_and_loss.alert_export_trial_balance_first.title',
              ),
              'exclamation-triangle',
            ),
            new Alert(
              t('data_request.exact_online.pages.profit_and_loss.alert_repeat_process.text'),
              t('data_request.exact_online.pages.profit_and_loss.alert_repeat_process.title'),
              'exclamation-square',
            ),
            new Paragraph([
              t(
                'data_request.exact_online.pages.profit_and_loss.title_select_relevant_administration',
                {
                  chapter: 1,
                },
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_administration.click_to_switch_administration',
                  ),
                  new Badge(12, undefined, 72, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-relevant-administration.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_entity.select_correct_administration',
                  ),
                  new Badge(36, undefined, 66, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-correct-entity.png',
            ),
            new Header(
              t('data_request.exact_online.pages.profit_and_loss.title_export_trial_balance', {
                chapter: 2,
              }),
            ),
            new Paragraph([
              t(
                'data_request.exact_online.pages.profit_and_loss.paragraph_show_and_export_trial_balance',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_navigate_to_profit_and_loss_accounts.click_financial',
                  ),
                  new Badge(10, undefined, 35, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_navigate_to_profit_and_loss_accounts.select_analyse_and_results',
                  ),
                  new Badge(47, undefined, 44, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_navigate_to_profit_and_loss_accounts.select_balance_profit_loss',
                  ),
                  new Badge(28, undefined, 63, undefined),
                ),
              ],
              '/img/data-request/exact_online/navigate-to-profit-loss-accounts.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.select_period_balance',
                  ),
                  new Badge(58, undefined, 26, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.select_both',
                  ),
                  new Badge(58, undefined, 59, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.select_ledger_account',
                  ),
                  new Badge(58, undefined, 95, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.select_financial_year',
                  ),
                  new Badge(68, undefined, 18, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.select_compare_with',
                  ),
                  new Badge(78, undefined, 23, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.select_chart_of_accounts',
                  ),
                  new Badge(78, undefined, 75, undefined),
                ),

                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_settings_instructions.click_refresh',
                  ),
                  new Badge(38, undefined, 9, undefined),
                ),
              ],
              '/img/data-request/exact_online/set-export-settings.png',
            ),
            new Paragraph([
              t(
                'data_request.exact_online.pages.profit_and_loss.paragraph_assert_beginning_ending_balance',
              ),
              t('data_request.exact_online.pages.profit_and_loss.paragraph_export_trial_balance'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_profit_and_loss_as_excel.click_icon_dropdown',
                  ),
                  new Badge(5, undefined, 91, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_export_profit_and_loss_as_excel.select_export_excel',
                  ),
                  new Badge(26, undefined, 65, undefined),
                ),
              ],
              '/img/data-request/exact_online/export-as-excel.png',
            ),
            new Alert(
              t('data_request.exact_online.pages.profit_and_loss.alert_rename_file.text'),
              t('data_request.exact_online.pages.profit_and_loss.alert_rename_file.title'),
              'exclamation-square',
            ),
            new Alert(
              t('data_request.exact_online.pages.profit_and_loss.alert_repeat_steps.text'),
              t('data_request.exact_online.pages.profit_and_loss.alert_repeat_process.title'),
              'exclamation-square',
            ),
            new FileUploader(new GenericFileType(), ['.xlsx']),
          ],
        ),
        new Step(
          new Slug('backup-file'),
          t('components.data_request.exact_online.pages.backup_file.title'),
          '',
          [
            new Alert(
              t(
                'data_request.exact_online.pages.backup_file.alert_administration_not_available.text',
              ),
              t(
                'data_request.exact_online.pages.backup_file.alert_administration_not_available.title',
              ),
              'exclamation-triangle',
            ),
            new Alert(
              t(
                'data_request.exact_online.pages.backup_file.alert_repeat_steps_per_administration.text',
              ),
              t(
                'data_request.exact_online.pages.backup_file.alert_repeat_steps_per_administration.title',
              ),
              'exclamation-square',
            ),
            new Header(
              t(
                'data_request.exact_online.pages.profit_and_loss.title_select_relevant_administration',
                {
                  chapter: 1,
                },
              ),
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_administration.click_to_switch_administration',
                  ),
                  new Badge(12, undefined, 72, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-relevant-administration.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_entity.select_correct_administration',
                  ),
                  new Badge(36, undefined, 66, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-correct-entity.png',
            ),
            new Header(
              t('data_request.exact_online.pages.backup_file.title_navigate_to_backup_page', {
                chapter: 2,
              }),
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_open_administration_dropdown.select_administration_open_menu',
                  ),
                  new Badge(13, undefined, 70, undefined),
                ),
              ],
              '/img/data-request/exact_online/open-administration-dropdown.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_backup_option.select_option_backup',
                  ),
                  new Badge(81, undefined, 24, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-backup-option.png',
            ),
            new Header(
              t('data_request.exact_online.pages.backup_file.title_backup_overview', {
                chapter: 3,
              }),
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_backup_overview'),
            ]),
            new Image(
              '/img/data-request/exact_online/backup-list-example.png',
              'Backup list example',
            ),
            new Header(
              t('data_request.exact_online.pages.backup_file.title_check_user_activity', {
                chapter: 4,
              }),
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_user_activity'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_check_active_users.minutes_to_verify',
                  ),
                  new Badge(49, undefined, 79, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_check_active_users.click_test',
                  ),
                  new Badge(42, undefined, 96, undefined),
                ),
              ],
              '/img/data-request/exact_online/check-active-users.png',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_user_activity_popup'),
            ]),
            new Image(
              '/img/data-request/exact_online/active-users-popup.png',
              'Active users popup',
            ),
            new Alert(
              t('data_request.exact_online.pages.backup_file.alert_inform_active_users.text'),
              t('data_request.exact_online.pages.backup_file.alert_inform_active_users.title'),
              'exclamation-square',
            ),
            new Header(
              t('data_request.exact_online.pages.backup_file.title_generate_backup', {
                chapter: 5,
              }),
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_create_empty_backup_line'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_backup.click_btn_backup',
                  ),
                  new Badge(72, undefined, 81, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-backup-on-empty-line.png',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_create_backup_popup'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_create_backup_popup.click_btn_yes',
                  ),
                  new Badge(79, undefined, 81, undefined),
                ),
              ],
              '/img/data-request/exact_online/create-backup-popup.png',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_backup_status'),
            ]),
            new Image('/img/data-request/exact_online/backup-status.png', 'Backup status'),
            new Header(
              t('data_request.exact_online.pages.backup_file.title_export_backup', {
                chapter: 6,
              }),
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_backup_generated'),
            ]),
            new Image(
              '/img/data-request/exact_online/backup-created-overview.png',
              'Backup created overview',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.backup_file.paragraph_click_refresh'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_backup_refresh.click_btn_refresh',
                  ),
                  new Badge(37, undefined, 35, undefined),
                ),
              ],
              '/img/data-request/exact_online/backup-refresh.png',
            ),
            new Paragraph([
              t(
                'data_request.exact_online.pages.backup_file.paragraph_page_refreshed_show_backups',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_download_backup.click_btn_download',
                  ),
                  new Badge(76, undefined, 89, undefined),
                ),
              ],
              '/img/data-request/exact_online/download-backup.png',
            ),
            new Alert(
              t('data_request.exact_online.pages.backup_file.alert_rename_file.text'),
              t('data_request.exact_online.pages.backup_file.alert_rename_file.title'),
              'exclamation-square',
            ),
            new Alert(
              t('data_request.exact_online.pages.backup_file.alert_repeat_steps.text'),
              t('data_request.exact_online.pages.backup_file.alert_repeat_steps.title'),
              'exclamation-square',
            ),
            new FileUploader(new GenericFileType(), ['.zip']),
          ],
        ),
        new Step(
          new Slug('business-users'),
          t('components.data_request.exact_online.pages.business_users.title'),
          '',
          [
            new Alert(
              t(
                'data_request.exact_online.pages.business_users.alert_repeat_steps_per_administration.text',
              ),
              t(
                'data_request.exact_online.pages.business_users.alert_repeat_steps_per_administration.title',
              ),
              'exclamation-square',
            ),
            new Header(
              t(
                'data_request.exact_online.pages.profit_and_loss.title_select_relevant_administration',
                {
                  chapter: 1,
                },
              ),
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_administration.click_to_switch_administration',
                  ),
                  new Badge(12, undefined, 72, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-relevant-administration.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_select_entity.select_correct_administration',
                  ),
                  new Badge(36, undefined, 66, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-correct-entity.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.profit_and_loss.checklist_open_administration_dropdown.select_administration_open_menu',
                  ),
                  new Badge(13, undefined, 70, undefined),
                ),
              ],
              '/img/data-request/exact_online/open-administration-dropdown.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_select_option.click_btn_import_export',
                  ),
                  new Badge(74, undefined, 33, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-option-import-export.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_select_method_xml.select_option_xml',
                  ),
                  new Badge(72, undefined, 28, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-method-xml.png',
            ),
            new Header(
              t('data_request.exact_online.pages.business_users.title_export_business_users', {
                chapter: 2,
              }),
            ),
            new Paragraph([
              t('data_request.exact_online.pages.business_users.paragraph_page_is_displayed'),
            ]),
            new Alert(
              t(
                'data_request.exact_online.pages.business_users.alert_only_visible_with_permissions.text',
              ),
              t(
                'data_request.exact_online.pages.business_users.alert_only_visible_with_permissions.title',
              ),
              'exclamation-square',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_export_users.select_option_export',
                  ),
                  new Badge(90, undefined, 65, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-export-users.png',
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_select_export_users_settings.select_option_users',
                  ),
                  new Badge(52, undefined, 90, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_select_export_users_settings.select_options_format',
                  ),
                  new Badge(68, undefined, 94, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_select_export_users_settings.empty_date_fields',
                  ),
                  new Badge(91, undefined, 99, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_select_export_users_settings.click_export',
                  ),
                  new Badge(36, undefined, 26, undefined),
                ),
              ],
              '/img/data-request/exact_online/select-export-users-settings.png',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.business_users.paragraph_popup_displayed'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_online.pages.business_users.checklist_export_users_popup.click_next',
                  ),
                  new Badge(24, undefined, 14, undefined),
                ),
              ],
              '/img/data-request/exact_online/export-users-popup.png',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.business_users.paragraph_confirmation_download'),
            ]),
            new Image(
              '/img/data-request/exact_online/export-users-success.png',
              'Export users success',
            ),
            new Paragraph([
              t('data_request.exact_online.pages.business_users.paragraph_file_downloaded'),
            ]),
            new Alert(
              t('data_request.exact_online.pages.business_users.alert_rename_file.text'),
              t('data_request.exact_online.pages.business_users.alert_rename_file.title'),
              'exclamation-square',
            ),
            new Alert(
              t('data_request.exact_online.pages.business_users.alert_repeat_steps.text'),
              t('data_request.exact_online.pages.business_users.alert_repeat_steps.title'),
              'exclamation-square',
            ),
            new FileUploader(new GenericFileType(), ['.xlsx']),
          ],
        ),
      ]),
    )
  }
}
