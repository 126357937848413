/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type FileTypeInterface from '~/src/Domain/DataRequest/File/FileType/FileTypeInterface'
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'

export default class FileTypeFactory {
  public constructor(private readonly fileTypes: FileTypeInterface[]) {}

  public createForAnalysisType(analysisType: AnalysisType): FileTypeInterface[] {
    const fileTypes = []

    for (const fileType of this.fileTypes) {
      if (!fileType.supports(analysisType)) {
        continue
      }

      fileTypes.push(fileType)
    }

    if (fileTypes.length === 0) {
      throw new Error(`No FileTypes found for analysisType ${analysisType}.`)
    }

    return fileTypes
  }

  public createForAnalysisTypeAndName(analysisType: AnalysisType, name: string): FileTypeInterface {
    for (const fileType of this.fileTypes) {
      if (!fileType.supports(analysisType) || fileType.name !== name) {
        continue
      }

      return fileType
    }

    throw new Error(`No FileType found for analysisType ${analysisType} and name ${name}.`)
  }
}
