/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type EventHandlerInterface
  from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type { Container, Services } from '~/src/Infrastructure/Shared/Container/Container'

const eventHandlers: { eventName: string, eventHandlerName: keyof Services }[] = []

export function AsEventHandler<T extends keyof Services>(eventName: string, eventHandlerName: T) {
  return function (_target: any) {
    eventHandlers.push({ eventName, eventHandlerName })
  }
}

export function initializeEventHandlers(container: Container, eventBus: EventBusInterface): void {
  for (const { eventName, eventHandlerName } of eventHandlers) {
    eventBus.registerHandler(eventName, {
      handle: async (event: EventInterface) => {
        const handler = container.resolve<EventHandlerInterface<EventInterface>>(eventHandlerName)
        await handler.handle(event)
      },
    })
  }
}
