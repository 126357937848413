/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { FilterSorterValuesFromHistory } from '~/src/Domain/Shared/Filter/FilterCollection'
import type FilterSorterStoreRepositoryInterface from '~/src/Domain/Shared/Filter/FilterSorterStoreRepositoryInterface'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import FilterCollectionStore from '~/src/UserInterface/Shared/Filter/store/FilterCollectionStore'

export default class FilterSorterStoreRepository implements FilterSorterStoreRepositoryInterface {
  private readonly store: ReturnType<typeof FilterCollectionStore>

  public constructor() {
    this.store = FilterCollectionStore(pinia)
  }

  public getFilterSorterValues(phase: PhaseIri): FilterSorterValuesFromHistory[] {
    return this.store.filterSorterValues[phase.toString()] ?? []
  }

  public getFilterSorterValuesById(
    phase: PhaseIri,
    id: string,
  ): FilterSorterValuesFromHistory | undefined {
    return this.getFilterSorterValues(phase).find((i) => i.id === id)
  }

  public update(phase: PhaseIri, filterSorterValues: FilterSorterValuesFromHistory): void {
    this.store.update(phase, filterSorterValues)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
