/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum FieldsResultReference {
  JOURNAL_ENTRY,
  TRIAL_BALANCE_ROLL_FORWARD,
  UNBALANCED_JOURNAL_ENTRY,
  BANK,
  BANK_STATEMENT_ENTRY_MULTIPLE_CUSTOMER_NAMES,
  BANK_STATEMENT_ENTRY_MULTIPLE_BANK_ACCOUNT,
  ACCOUNTS_PAYABLE,
  ACCOUNTS_PAYABLE_AGING,
  ACCOUNTS_PAYABLE_SPECIAL_RELATION,
  ACCOUNTS_PAYABLE_MULTIPLE_BANK_ACCOUNTS_SAME_SUPPLIER_NAME,
  ACCOUNTS_PAYABLE_MULTIPLE_SUPPLIER_NAMES_SAME_BANK_ACCOUNT,
  ACCOUNTS_RECEIVABLE,
  ACCOUNTS_RECEIVABLE_AGING,
  ACCOUNTS_RECEIVABLE_CUTOFF,
}
