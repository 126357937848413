/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum FilterState {
  AVAILABLE = 'available',
  ACTIVE = 'active',

  /**
   * When a filter has the state applied, that means the filter values are valid.
   */
  APPLIED = 'applied',
}
