/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum AnalysisType {
  GENERIC = 'generic',
  GENERAL_LEDGER = 'general_ledger',
  BANK = 'bank',
  BANK_GENERAL_LEDGER = 'bank_general_ledger',
  ACCOUNTS_RECEIVABLE = 'accounts_receivable',
  ACCOUNTS_PAYABLE = 'accounts_payable',
  PERSONNEL = 'personnel',
}
