/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

export default class Publisher {
  private readonly publishers: PublisherInterface[] = []

  public async publish(message: Message): Promise<void> {
    await Promise.all(this.publishers.map(async (p) => p.publish(message)))
  }

  public registerPublisher<T extends PublisherInterface>(publisher: T): void {
    this.publishers.push(publisher)
  }
}
