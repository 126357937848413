/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ExportDetailsFactory from '~/src/Domain/Export/ExportDetails/ExportDetailsFactory'
/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ExportDetailsProps } from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import type ExportDetailsInterface from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import ExportIri from '~/src/Domain/Export/ExportIri'
import type { ExportType } from '~/src/Domain/Export/ExportType'
import type { FileNameDetailsProps } from '~/src/Domain/Export/FileNameDetails'
import FileNameDetails from '~/src/Domain/Export/FileNameDetails'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface ExportProps extends DomainModelProps {
  '@id': string
  '@type': string
  type: ExportType
  prepared: boolean
  fileNameDetails: FileNameDetailsProps
  exportDetails: ExportDetailsProps
  created: string
  validUntil: string
}

export default class Export implements DomainModelInterface<Export, ExportProps> {
  public readonly '@id': ExportIri
  public readonly '@type': string
  public readonly type: ExportType

  private constructor(
    id: ExportIri,
    type: string,
    exportType: ExportType,
    public readonly prepared: boolean,
    public readonly fileNameDetails: FileNameDetails,
    public readonly exportDetails: ExportDetailsInterface<any, ExportDetailsProps>,
    public readonly created: BetterDateTime,
    public readonly validUntil: BetterDateTime,
  ) {
    this['@id'] = id
    this['@type'] = type
    this.type = exportType
  }

  public getClassName(): string {
    return 'Export'
  }

  public isOutdated(): boolean {
    return !this.prepared && new BetterDateTime().diffInHours(this.created) > 2
  }

  public fromJSON(values: ExportProps): Export {
    return new Export(
      new ExportIri(values['@id']),
      values['@type'],
      values.type,
      values.prepared,
      FileNameDetails.prototype.fromJSON(values.fileNameDetails),
      ExportDetailsFactory.create(values.type, values.exportDetails),
      new BetterDateTime(values.created),
      new BetterDateTime(values.validUntil),
    )
  }

  public toJSON(): ExportProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      type: this.type,
      prepared: this.prepared,
      fileNameDetails: this.fileNameDetails.toJSON(),
      exportDetails: this.exportDetails.toJSON(),
      created: this.created.toString(),
      validUntil: this.validUntil.toString(),
    }
  }
}
