/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DataSourceSettingsModel } from '@syncfusion/ej2-pivotview/src/model/datasourcesettings-model'
import PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'

export default class SyncfusionPivotToPivotTableTransformer {
  public transform(
    basePivotTable: PivotTableDefinition,
    dataSourceSettings: DataSourceSettingsModel,
  ): PivotTableDefinition {
    return new PivotTableDefinition(
      basePivotTable.id,
      basePivotTable.title,
      dataSourceSettings.rows?.map((item) => ({
        key: item.name ?? '',
        value: dataSourceSettings.filterSettings?.find((f) => f.name === item.name)?.items,
      })) ?? [],
      dataSourceSettings.columns?.map((item) => ({
        key: item.name ?? '',
        value: dataSourceSettings.filterSettings?.find((f) => f.name === item.name)?.items,
      })) ?? [],
      dataSourceSettings.values?.map((item) => ({
        key: item.name ?? '',
        value: dataSourceSettings.filterSettings?.find((f) => f.name === item.name)?.items,
      })) ?? [],
      dataSourceSettings.filters?.map((item) => ({
        key: item.name ?? '',
        value: dataSourceSettings.filterSettings?.find((f) => f.name === item.name)?.items,
      })) ?? [],
      basePivotTable.fieldMappings,
      dataSourceSettings.formatSettings?.map((item) => ({
        key: item.name ?? '',
        format: item.format ?? '',
        type: item.type as 'date' | 'dateTime' | 'time' | undefined,
      })) ?? [],
      dataSourceSettings.showColumnGrandTotals,
      dataSourceSettings.showRowGrandTotals,
    )
  }
}
