/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CryptoJS from 'crypto-js'

export const encrypt = (payload: string): string => {
  return CryptoJS.AES.encrypt(payload, import.meta.env.VITE_ENCRYPTED_STORAGE_KEY).toString()
}

export const decrypt = <T>(payload: string): T => {
  return CryptoJS.AES.decrypt(payload, import.meta.env.VITE_ENCRYPTED_STORAGE_KEY).toString(
    CryptoJS.enc.Utf8,
  ) as T
}

const getItem = (key: string): string | null => {
  const item = localStorage.getItem(key)
  if (item === null) {
    return null
  }

  return decrypt(item)
}

const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, encrypt(value))
}

const removeItem = (key: string): void => {
  localStorage.removeItem(key)
}

export const useEncryptStorage = (): {
  encrypt: (payload: string) => string
  decrypt: (payload: string) => string
  getItem: (key: string) => string | null
  setItem: (key: string, value: string) => void
  removeItem: (key: string) => void
} => {
  return {
    encrypt,
    decrypt,
    getItem,
    setItem,
    removeItem,
  }
}
