/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { NuxtApp, navigateTo as NuxtNavigateTo } from '#app'
import type { Router as VueRouter } from 'vue-router'
import type RouterInterface from '~/src/Application/Shared/Router/RouterInterface'
import type { ResolveReturn, Route } from '~/src/Application/Shared/Router/RouterInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class Router implements RouterInterface {
  private readonly nuxtLocalePath: NuxtApp['$localePath']
  private readonly nuxtNavigateTo: typeof NuxtNavigateTo
  private readonly nuxtRouter: VueRouter
  private readonly hostname: string

  public constructor({ nuxtLocalePath, nuxtNavigateTo, nuxtRouter, hostname }: Services) {
    this.nuxtLocalePath = nuxtLocalePath
    this.nuxtNavigateTo = nuxtNavigateTo
    this.nuxtRouter = nuxtRouter
    this.hostname = hostname
  }

  public getHostname(): string {
    return this.hostname
  }

  public refreshPage(): void {
    this.nuxtRouter.go(0)
  }

  public async replace(route: Route): Promise<void> {
    await this.nuxtRouter.replace(route)
  }

  public resolve(to: Route): ResolveReturn {
    return this.nuxtRouter.resolve(to)
  }

  public async navigateTo(route: Route): Promise<void> {
    await this.nuxtNavigateTo(route)
  }

  public async navigateToLocalePath(
    route: Route,
    locale: string | undefined = undefined,
  ): Promise<void> {
    await this.nuxtNavigateTo(this.getLocalePath(route, locale))
  }

  public getLocalePath(route: Route, locale: string | undefined = undefined): string {
    return this.nuxtLocalePath(route, locale)
  }

  public getCurrentUrl(): string {
    return `https://${this.getHostname()}${this.nuxtRouter.currentRoute.value.fullPath}`
  }
}
