/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DataRequestProps } from '~/src/Domain/DataRequest/DataRequest'
import DataRequest from '~/src/Domain/DataRequest/DataRequest'
import type DataRequestorApiRepositoryInterface from '~/src/Domain/DataRequest/DataRequestorApiRepositoryInterface'
import FileIri from '~/src/Domain/DataRequest/File/FileIri'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class DataRequestorApiRepository implements DataRequestorApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async get(dataRequestRecipientId: string, dataRequestId: string): Promise<DataRequest> {
    const dataRequestProps = await this.httpClient.get<DataRequestProps>(
      `/v1/data_request_recipients/${dataRequestRecipientId}/data_requests/${dataRequestId}`,
    )

    for (const fileProps of dataRequestProps.files) {
      const fileId = new FileIri(fileProps['@id']).toId()
      fileProps['@id']
        = `/v1/data_request_recipients/${dataRequestRecipientId}/data_requests/${dataRequestId}/files/${fileId}`

      if (fileProps.parentFile !== undefined) {
        const parentFileId = new FileIri(fileProps.parentFile).toId()
        fileProps.parentFile = `/v1/data_request_recipients/${dataRequestRecipientId}/data_requests/${dataRequestId}/files/${parentFileId}`
      }
    }

    return DataRequest.prototype.fromJSON(dataRequestProps)
  }
}
