/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type CoaMappingStoreRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingStoreRepositoryInterface'
import CoaMappingUpdatedEvent from '~/src/Domain/CoaMapping/Event/CoaMappingUpdatedEvent'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(CoaMappingUpdatedEvent.NAME, 'coaMappingUpdatedEventHandler')
export default class CoaMappingUpdatedEventHandler extends EventHandlerInterface<CoaMappingUpdatedEvent> {
  private readonly coaMappingStoreRepository: CoaMappingStoreRepositoryInterface
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface

  public constructor({ coaMappingStoreRepository, engagementStoreRepository }: Services) {
    super()
    this.coaMappingStoreRepository = coaMappingStoreRepository
    this.engagementStoreRepository = engagementStoreRepository
  }

  public handle(event: CoaMappingUpdatedEvent): void {
    if (
      this.engagementStoreRepository.hasCurrentEngagement()
      && event.coaMapping['@id'].toString().startsWith(
        this.engagementStoreRepository.getCurrentEngagement()['@id'].toString(),
      )
    ) {
      this.coaMappingStoreRepository.updateCoaMapping(event.coaMapping)
    }
  }
}
