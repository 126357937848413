/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useIdentityNavigation() {
  const { navigateToLocalePath, getLocalePath, route } = useNavigation()

  const identityLoginPageLocalePath = getLocalePath('identity-Login')
  const identityLogoutPageLocalePath = getLocalePath('identity-Logout')
  const identityRegisterLocalePath = getLocalePath('identity-Register')
  const identityRequestResetPasswordLocalePath = getLocalePath('identity-RequestResetPassword')

  const navigateToIdentityLoginPage = async (): Promise<void> => {
    await navigateToLocalePath('identity-Login')
  }

  const navigateToIdentity2faPage = async (): Promise<void> => {
    await navigateToLocalePath('identity-Login2fa')
  }

  const navigateToIdentityRegisteredPage = async (email: string): Promise<void> => {
    await navigateToLocalePath({
      name: 'identity-Registered',
      params: {
        email,
      },
    })
  }

  return {
    route,
    identityLoginPageLocalePath,
    identityLogoutPageLocalePath,
    identityRegisterLocalePath,
    identityRequestResetPasswordLocalePath,
    navigateToIdentityLoginPage,
    navigateToIdentity2faPage,
    navigateToIdentityRegisteredPage,
  }
}
