/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'
import CoaMappingExportDetails from '~/src/Domain/CoaMapping/Export/CoaMappingExportDetails'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import DataRequestArchiveExportDetails from '~/src/Domain/DataRequest/Export/DataRequestArchiveExportDetails'
import DataRequestFileExportDetails from '~/src/Domain/DataRequest/Export/DataRequestFileExportDetails'
import type FileIri from '~/src/Domain/DataRequest/File/FileIri'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type Export from '~/src/Domain/Export/Export'
import TransactionalDataPivotExportDetails from '~/src/Domain/Export/ExportDetails/TransactionalDataPivotExportDetails'
import TransactionalDataTableExportDetails from '~/src/Domain/Export/ExportDetails/TransactionalDataTableExportDetails'
import OtherSideOfJournalEntriesExportDetails from '~/src/Domain/GeneralLedger/Export/OtherSideOfJournalEntriesExportDetails'
import TwoWayCorrelationsExportDetails from '~/src/Domain/GeneralLedger/Export/TwoWayCorrelationsExportDetails'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import WorkProgramExportDetails from '~/src/Domain/WorkProgram/Export/WorkProgramExportDetails'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import { isEqual } from '~/src/UserInterface/App/utils/object'

export default class ExportCollection {
  public constructor(private readonly exports: Export[]) {}

  public getExports(): Export[] {
    return this.exports
  }

  public hasExports(): boolean {
    return this.exports.length > 0
  }

  public findCoaMappingExport(
    coaMapping: CoaMappingIri,
    locale: string,
    lastUpdated: BetterDateTime,
  ): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof CoaMappingExportDetails
        && e.exportDetails.coaMappingId === coaMapping.toId()
        && e.exportDetails.locale === locale
        && e.created.getTime() >= lastUpdated.getTime(),
    )
  }

  public findDataRequestArchiveExport(dataRequest: DataRequestIri): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof DataRequestArchiveExportDetails
        && e.exportDetails.dataRequestId === dataRequest.toId(),
    )
  }

  public findDataRequestFileExport(dataRequest: DataRequestIri, file: FileIri): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof DataRequestFileExportDetails
        && e.exportDetails.dataRequestId === dataRequest.toId()
        && e.exportDetails.fileId === file.toId(),
    )
  }

  public findTransactionalDataTableExport(
    iri: GenericIri,
    extension: string,
    fields: string[],
    locale: string,
  ): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof TransactionalDataTableExportDetails
        && e.exportDetails.extension === extension
        && e.exportDetails.iri.equalsTo(iri)
        && isEqual(e.exportDetails.fields, fields)
        && e.exportDetails.locale === locale,
    )
  }

  public findTransactionalDataPivotExport(
    iri: GenericIri,
    extension: string,
    locale: string,
    rows: string[],
    columns: string[],
    values: { field: string, valueType: 'count' | 'sum' }[],
  ): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof TransactionalDataPivotExportDetails
        && e.exportDetails.extension === extension
        && e.exportDetails.iri.equalsTo(iri)
        && e.exportDetails.locale === locale
        && isEqual(e.exportDetails.rows, rows)
        && isEqual(e.exportDetails.columns, columns)
        && isEqual(e.exportDetails.values, values),
    )
  }

  public findWorkProgramExport(
    workProgram: WorkProgramIri,
    title: string,
    description: string,
    steps: { stepSlug: string, title: string, description: string }[],
    locale: string,
  ): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof WorkProgramExportDetails
        && e.exportDetails.workProgramId === workProgram.toId()
        && e.exportDetails.title === title
        && e.exportDetails.description === description
        && e.exportDetails.steps.every((s) =>
          steps.some(
            (step) =>
              step.stepSlug === s.stepSlug
              && step.title === s.title
              && step.description === s.description,
          ),
        )
        && e.exportDetails.locale === locale,
    )
  }

  public findTwoWayCorrelationsExport(
    phase: PhaseIri,
    fiscalYears: string[],
    businessUnitNames: string[],
    sourceNames: string[],
    primaryGlAccounts: string[],
    primaryAccountTypes: string[],
    primaryAccountSubtypes: string[],
    primaryDebitCreditIndicators: string[],
    secondaryGlAccounts: string[],
    secondaryAccountTypes: string[],
    secondaryAccountSubtypes: string[],
    secondaryDebitCreditIndicators: string[],
    extension: string,
    locale: string,
  ): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof TwoWayCorrelationsExportDetails
        && e.exportDetails.iri.equalsTo(new GenericIri(`${phase.toString()}/two_way_correlations`))
        && isEqual(e.exportDetails.fiscalYears, fiscalYears)
        && isEqual(e.exportDetails.businessUnitNames, businessUnitNames)
        && isEqual(e.exportDetails.sourceNames, sourceNames)
        && isEqual(e.exportDetails.primaryGlAccounts, primaryGlAccounts)
        && isEqual(e.exportDetails.primaryAccountTypes, primaryAccountTypes)
        && isEqual(e.exportDetails.primaryAccountSubtypes, primaryAccountSubtypes)
        && isEqual(e.exportDetails.primaryDebitCreditIndicators, primaryDebitCreditIndicators)
        && isEqual(e.exportDetails.secondaryGlAccounts, secondaryGlAccounts)
        && isEqual(e.exportDetails.secondaryAccountTypes, secondaryAccountTypes)
        && isEqual(e.exportDetails.secondaryAccountSubtypes, secondaryAccountSubtypes)
        && isEqual(e.exportDetails.secondaryDebitCreditIndicators, secondaryDebitCreditIndicators)
        && e.exportDetails.extension === extension
        && e.exportDetails.locale === locale,
    )
  }

  public findOtherSideOfJournalEntriesExport(
    phase: PhaseIri,
    fiscalYears: string[],
    businessUnitNames: string[],
    sourceNames: string[],
    primaryGlAccounts: string[],
    primaryAccountTypes: string[],
    primaryAccountSubtypes: string[],
    primaryDebitCreditIndicators: string[],
    extension: string,
    locale: string,
  ): Export | undefined {
    return this.exports.find(
      (e) =>
        e.exportDetails instanceof OtherSideOfJournalEntriesExportDetails
        && e.exportDetails.iri.equalsTo(new GenericIri(`${phase.toString()}/other_side_of_journal_entries`))
        && isEqual(e.exportDetails.fiscalYears, fiscalYears)
        && isEqual(e.exportDetails.businessUnitNames, businessUnitNames)
        && isEqual(e.exportDetails.sourceNames, sourceNames)
        && isEqual(e.exportDetails.primaryGlAccounts, primaryGlAccounts)
        && isEqual(e.exportDetails.primaryAccountTypes, primaryAccountTypes)
        && isEqual(e.exportDetails.primaryAccountSubtypes, primaryAccountSubtypes)
        && isEqual(e.exportDetails.primaryDebitCreditIndicators, primaryDebitCreditIndicators)
        && e.exportDetails.extension === extension
        && e.exportDetails.locale === locale,
    )
  }

  public getNumberOfExports(): number {
    return this.exports.length
  }
}
