/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Container, Services } from '~/src/Infrastructure/Shared/Container/Container'
import type Publisher from '~/src/Infrastructure/Shared/PubSub/Publisher'
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

const publishers: { publisherName: keyof Services }[] = []

export function AsPublisher<T extends keyof Services>(publisherName: T) {
  return function (_target: any) {
    publishers.push({ publisherName })
  }
}

export function initializePublishers(container: Container, publisher: Publisher): void {
  for (const { publisherName } of publishers) {
    publisher.registerPublisher({
      publish: async (message) => {
        const publisher = container.resolve<PublisherInterface>(publisherName)
        await publisher.publish(message)
      },
    })
  }
}
