/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import OrganisationUpdatedEvent from '~/src/Domain/Organisation/Event/OrganisationUpdatedEvent'
import type { OrganisationProps } from '~/src/Domain/Organisation/Organisation'
import Organisation from '~/src/Domain/Organisation/Organisation'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import { AsPublisher } from '~/src/Infrastructure/Shared/PubSub/AsPublisher'
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import { Type } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

@AsPublisher('organisationPublisher')
export default class OrganisationPublisher implements PublisherInterface {
  private REGEX_PATTERN
    = '^/v1/organisations/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'

  private readonly eventBus: EventBusInterface

  public constructor({ eventBus }: Services) {
    this.eventBus = eventBus
  }

  public async publish(message: Message): Promise<void> {
    if (!('@id' in message.data && message.data['@id'].match(this.REGEX_PATTERN) !== null)) {
      return
    }

    if (message.type === Type.UPDATE) {
      await this.eventBus.dispatch(OrganisationUpdatedEvent.NAME, new OrganisationUpdatedEvent(
        Organisation.prototype.fromJSON(message.data as OrganisationProps),
      ))
    }
  }
}
