/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DataRequestRecipientIri from '~/src/Domain/DataRequest/DataRequestRecipientIri'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface DataRequestRecipientProps extends DomainModelProps {
  '@id': string
  '@type': string
  email: string
  firstName: string
  lastName: string
  locale: string
}

export default class DataRequestRecipient
implements DomainModelInterface<DataRequestRecipient, DataRequestRecipientProps> {
  public readonly '@id': DataRequestRecipientIri
  public readonly '@type': string

  private constructor(
    id: DataRequestRecipientIri,
    type: string,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly locale: string,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'DataRequest'
  }

  public toString(): string {
    return `${this.firstName} ${this.lastName} <${this.email}>`
  }

  public fromJSON(values: DataRequestRecipientProps): DataRequestRecipient {
    return new DataRequestRecipient(
      new DataRequestRecipientIri(values['@id']),
      values['@type'],
      values.email,
      values.firstName,
      values.lastName,
      values.locale,
    )
  }

  public toJSON(): DataRequestRecipientProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      locale: this.locale,
    }
  }
}
