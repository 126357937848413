/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type Decimal from '~/src/Domain/Shared/Decimal/Decimal'
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type GreaterThanOrEqualFilterValueType = number | Decimal | BetterDate | BetterDateTime

export default class GreaterThanOrEqualFilter extends AbstractFilter<GreaterThanOrEqualFilterValueType> {
  public constructor(filterType: FilterType.NUMBER | FilterType.DATE | FilterType.MONEY) {
    super(Operator.GREATER_THAN_OR_EQUAL, filterType, FilterClass.GREATER_THAN_OR_EQUAL_FILTER)
  }

  protected toUrlParameters(key: string, value: GreaterThanOrEqualFilterValueType): string {
    return `${key}[${this.operator}]=${encodeURIComponent(value.toString())}`
  }
}
