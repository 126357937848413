/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DomainModelProps } from '~/src/Domain/Shared/DomainModelInterface'

/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum Type {
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface Message {
  type: Type
  data: DomainModelProps
}

export default abstract class PublisherInterface {
  public abstract publish(message: Message): Promise<void>
}
