/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import UserLogoutEvent from '~/src/Domain/Identity/Event/UserLogoutEvent'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import type TokenExpiryListener from '~/src/Infrastructure/Identity/TokenExpiryListener'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type Subscriber from '~/src/Infrastructure/Shared/PubSub/Subscriber'

@AsEventHandler(UserLogoutEvent.NAME, 'userLogoutEventHandler')
export default class UserLogoutEventHandler extends EventHandlerInterface<UserLogoutEvent> {
  private readonly cookies: CookiesInterface
  private readonly subscriber: Subscriber
  private readonly tokenExpiryListener: TokenExpiryListener
  private readonly userStoreRepository: UserStoreRepositoryInterface

  public constructor({ cookies, subscriber, tokenExpiryListener, userStoreRepository }: Services) {
    super()
    this.cookies = cookies
    this.subscriber = subscriber
    this.tokenExpiryListener = tokenExpiryListener
    this.userStoreRepository = userStoreRepository
  }

  public handle(_event: UserLogoutEvent): void {
    this.cookies.remove('authenticated')

    this.userStoreRepository.resetState()
    this.tokenExpiryListener.unlisten()
    this.subscriber.unsubscribe()
  }
}
