/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DomainException from '~/src/Domain/Shared/Exception/DomainException'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'

export default class StepSection {
  public constructor(
    public readonly slug: string,
    public readonly title: string,
    private _steps: StepInterface[],
  ) {
    if (this.getTotalStepCount() === 0) {
      throw new DomainException(
        `Sections without steps are not allowed. Please add steps to the section with slug '${this.slug}'.`,
      )
    }
  }

  public getAllSteps(): StepInterface[] {
    return this._steps
  }

  public getHiddenSteps(): StepInterface[] {
    return this._steps.filter((s) => s.hidden)
  }

  public getHiddenStepCount(): number {
    return this.getHiddenSteps().length
  }

  public getVisibleSteps(): StepInterface[] {
    return this._steps.filter((s) => !s.hidden)
  }

  public getResultStepCount(): number {
    return this._steps.filter((s) => s instanceof ResultStep).length
  }

  public getTotalStepCount(): number {
    return this._steps.length
  }

  public getFirstStep(): StepInterface {
    return this.getVisibleSteps()[0]
  }

  public hasStep(searchStep: StepInterface): boolean {
    return undefined !== this.getVisibleSteps().find((s) => s.equals(searchStep))
  }

  /**
   * Only get a section's steps that have results and thereby fields and possible parameter filters.
   */
  public getResultSteps(): ResultStep[] {
    return this.getAllSteps().filter((s) => s instanceof ResultStep)
  }

  public hasResultSteps(): boolean {
    return this.getResultSteps().length > 0
  }
}
