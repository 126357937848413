/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import ExportDeletedEvent from '~/src/Domain/Export/Event/ExportDeletedEvent'
import ExportUpdatedEvent from '~/src/Domain/Export/Event/ExportUpdatedEvent'
import type { ExportProps } from '~/src/Domain/Export/Export'
import Export from '~/src/Domain/Export/Export'
import ExportIri from '~/src/Domain/Export/ExportIri'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import { AsPublisher } from '~/src/Infrastructure/Shared/PubSub/AsPublisher'
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import { Type } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

@AsPublisher('exportPublisher')
export default class ExportPublisher implements PublisherInterface {
  private REGEX_PATTERN
    = '^/v1/users/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/exports/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'

  private readonly eventBus: EventBusInterface

  public constructor({ eventBus }: Services) {
    this.eventBus = eventBus
  }

  public async publish(message: Message): Promise<void> {
    if (!('@id' in message.data && message.data['@id'].match(this.REGEX_PATTERN) !== null)) {
      return
    }

    if (message.type === Type.UPDATE) {
      await this.eventBus.dispatch(ExportUpdatedEvent.NAME, new ExportUpdatedEvent(Export.prototype.fromJSON(message.data as ExportProps)))
    }

    if (message.type === Type.DELETE) {
      await this.eventBus.dispatch(ExportDeletedEvent.NAME, new ExportDeletedEvent(new ExportIri(message.data['@id'])))
    }
  }
}
