/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  EngagementExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'

export interface TransactionalDataTableExportDetailsProps extends EngagementExportDetailsProps {
  engagementId: string
  extension: string
  locale: string
  iri: string
  fields: string[]
  url?: string
}

export default class TransactionalDataTableExportDetails extends EngagementExportDetailsInterface<
  TransactionalDataTableExportDetails,
  TransactionalDataTableExportDetailsProps
> {
  private constructor(
    public readonly engagementId: string,
    public readonly extension: string,
    public readonly locale: string,
    public readonly iri: GenericIri,
    public readonly fields: string[],
    public readonly url: string | undefined,
  ) {
    super()
  }

  public fromJSON(
    values: TransactionalDataTableExportDetailsProps,
  ): TransactionalDataTableExportDetails {
    return new TransactionalDataTableExportDetails(
      values.engagementId,
      values.extension,
      values.locale,
      new GenericIri(values.iri),
      values.fields,
      values.url,
    )
  }

  public toJSON(): TransactionalDataTableExportDetailsProps {
    return {
      engagementId: this.engagementId,
      extension: this.extension,
      locale: this.locale,
      iri: this.iri.toString(),
      fields: this.fields,
      url: this.url,
    }
  }
}
