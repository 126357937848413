/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EngagementApiRepositoryInterface from '~/src/Domain/Engagement/EngagementApiRepositoryInterface'
import EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import type OrganisationActivatorInterface from '~/src/Domain/Organisation/OrganisationActivatorInterface'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class OrganisationActivator implements OrganisationActivatorInterface {
  private readonly cookies: CookiesInterface
  private readonly engagementApiRepository: EngagementApiRepositoryInterface
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface
  private readonly organisationStoreRepository: OrganisationStoreRepositoryInterface
  private readonly userStoreRepository: UserStoreRepositoryInterface

  public constructor({
    cookies,
engagementApiRepository,
engagementStoreRepository,
organisationStoreRepository,
userStoreRepository,
  }: Services,
  ) {
    this.cookies = cookies
    this.engagementApiRepository = engagementApiRepository
    this.engagementStoreRepository = engagementStoreRepository
    this.organisationStoreRepository = organisationStoreRepository
    this.userStoreRepository = userStoreRepository
  }

  public async activate(organisation: Organisation): Promise<void> {
    this.organisationStoreRepository.activate(organisation)

    const engagements = await this.engagementApiRepository.findForOrganisation(organisation['@id'])
    let currentEngagementId: EngagementIri | undefined
    let currentPhaseId: PhaseIri | undefined

    const phaseCookie = this.cookies.tryGet<string>('phase')
    const phaseIri = typeof phaseCookie === 'string' ? new PhaseIri(phaseCookie) : undefined

    if (
      phaseIri
      && undefined === engagements.find((e) => e['@id'].toString() === phaseIri.toEngagementId())?.phases.find((p) => p['@id'].equalsTo(phaseIri))
    ) {
      this.cookies.remove('phase')
    } else if (phaseIri) {
      currentEngagementId = EngagementIri.fromId(phaseIri.toEngagementId())
      currentPhaseId = phaseIri
    }

    if (currentEngagementId === undefined) {
      const activeOrganisationResponse = await this.engagementApiRepository.findActive(
        this.userStoreRepository.getUser()['@id'],
      )
      if (activeOrganisationResponse !== undefined) {
        currentEngagementId = EngagementIri.fromId(activeOrganisationResponse.toEngagementId())
        currentPhaseId = activeOrganisationResponse
      }
    }

    this.engagementStoreRepository.markState(engagements)
    if (currentEngagementId !== undefined && currentPhaseId !== undefined) {
      await this.engagementStoreRepository.activate(currentEngagementId, currentPhaseId)
    }
  }
}
