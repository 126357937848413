/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */

export default abstract class AbstractIri<T extends AbstractIri<T>> {
  public constructor(public readonly iri: string) {}

  public toString(): string {
    return this.iri
  }

  public toEngagementId(): string {
    return this.iri.toString().split('/')[3]
  }

  public equalsTo(id: T): boolean {
    return this.iri === id.toString()
  }
}
