/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export default function deepReplace<T>(o: T, replacer: (value: any) => any): T {
  if (Array.isArray(o)) {
    for (const [index, value] of o.entries()) {
      o[index] = deepReplace(value, replacer)
    }

    return o
  }

  if (typeof o === 'object' && o !== null) {
    for (const [key, value] of Object.entries(o)) {
      if (Array.isArray(o) || (typeof value === 'object' && value !== null)) {
        o[key as keyof T] = deepReplace(value, replacer)
      } else {
        o[key as keyof T] = replacer(value)
      }
    }

    return o
  }

  return o
}
