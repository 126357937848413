/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type ExportStoreRepositoryInterface from '~/src/Domain/Export/ExportStoreRepositoryInterface'
import UserAuthenticatedEvent from '~/src/Domain/Identity/Event/UserAuthenticatedEvent'
import type UserApiRepositoryInterface from '~/src/Domain/Identity/UserApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(UserAuthenticatedEvent.NAME, 'exportUserAuthenticatedEventHandler')
export default class ExportUserAuthenticatedEventHandler extends EventHandlerInterface<UserAuthenticatedEvent> {
  private readonly exportApiRepository: ExportApiRepositoryInterface
  private readonly exportStoreRepository: ExportStoreRepositoryInterface
  private readonly userApiRepository: UserApiRepositoryInterface

  public constructor({ exportApiRepository, exportStoreRepository, userApiRepository }: Services) {
    super()
    this.exportApiRepository = exportApiRepository
    this.exportStoreRepository = exportStoreRepository
    this.userApiRepository = userApiRepository
  }

  public async handle(_event: UserAuthenticatedEvent): Promise<void> {
    const user = await this.userApiRepository.findUser()

    const exports = await this.exportApiRepository.findAll(user['@id'])
    this.exportStoreRepository.markState(exports)
  }
}
