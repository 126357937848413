/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ApplicationInterface } from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import type DataRequestEngagement from '~/src/Domain/DataRequest/DataRequestEngagement'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import type DataRequestOrganisation from '~/src/Domain/DataRequest/DataRequestOrganisation'
import type DataRequestRecipient from '~/src/Domain/DataRequest/DataRequestRecipient'
import type { DataRequestStatus } from '~/src/Domain/DataRequest/DataRequestStatus'
import FileGroup from '~/src/Domain/DataRequest/File/FileGroup/FileGroup'
import type FileTypeInterface from '~/src/Domain/DataRequest/File/FileType/FileTypeInterface'
import type StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import type DataCorruptionFinding from '~/src/Domain/DataRequest/Validation/DataCorruptionFinding'
import type DataEnrichmentFinding from '~/src/Domain/DataRequest/Validation/DataEnrichmentFinding'
import type DataIntegrationFinding from '~/src/Domain/DataRequest/Validation/DataIntegrationFinding'
import type { DataIntegrityStatus } from '~/src/Domain/DataRequest/Validation/DataIntegrityStatus'
import type DataQualityFinding from '~/src/Domain/DataRequest/Validation/DataQualityFinding'
import { Severity } from '~/src/Domain/DataRequest/Validation/Severity'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'

export default class DataRequestProjection {
  public readonly '@id': DataRequestIri

  public constructor(
    private readonly dataRequest: DataRequest,
    public readonly application: ApplicationInterface,
    public readonly stepCollection: StepCollection,
  ) {
    this['@id'] = dataRequest['@id']
  }

  public get organisation(): DataRequestOrganisation {
    return this.dataRequest.organisation
  }

  public get engagement(): DataRequestEngagement {
    return this.dataRequest.engagement
  }

  public get businessUnits() {
    return this.dataRequest.businessUnits
  }

  public get phase() {
    return this.dataRequest.phase
  }

  public get period() {
    return this.dataRequest.period
  }

  public get analysisType() {
    return this.dataRequest.analysisType
  }

  public get files() {
    return this.dataRequest.files
  }

  public get recipient(): DataRequestRecipient {
    return this.dataRequest.recipient
  }

  public get status(): DataRequestStatus {
    return this.dataRequest.status
  }

  public get dataIntegrityStatus(): DataIntegrityStatus | undefined {
    return this.dataRequest.dataIntegrityStatus
  }

  public get hasDataIntegrityFindings(): boolean {
    return this.dataRequest.dataIntegrityFindings.length > 0
  }

  public get hasDataIntegrityErrors(): boolean {
    return this.dataRequest.dataIntegrityFindings.some((d) => d.severity === Severity.ERROR)
  }

  public get dataIntegrityErrors(): (
    | DataEnrichmentFinding
    | DataIntegrationFinding
    | DataQualityFinding
    | DataCorruptionFinding
  )[] {
    return this.dataRequest.dataIntegrityFindings.filter((d) => d.severity === Severity.ERROR)
  }

  public get hasDataIntegrityWarnings(): boolean {
    return this.dataRequest.dataIntegrityFindings.some((d) => d.severity === Severity.WARNING)
  }

  public get dataIntegrityWarnings(): (
    | DataEnrichmentFinding
    | DataIntegrationFinding
    | DataQualityFinding
    | DataCorruptionFinding
  )[] {
    return this.dataRequest.dataIntegrityFindings.filter((d) => d.severity === Severity.WARNING)
  }

  public get dataIntegrityFindings(): (
    | DataEnrichmentFinding
    | DataIntegrationFinding
    | DataQualityFinding
    | DataCorruptionFinding
  )[] {
    return this.dataRequest.dataIntegrityFindings
  }

  public get steps() {
    return this.stepCollection.steps
  }

  public get isFulfilled(): boolean {
    return this.dataRequest.isFulfilled()
  }

  public getFileGroups(stepSlug: Slug, fileType: FileTypeInterface): FileGroup[] {
    return this
      .dataRequest
      .files
      .filter((f) => stepSlug.equals(stepSlug) && f.fileType === fileType.name)
      .reduce((agg, file) => {
        if (file.parentFile !== undefined) {
          return agg
        }

        const convertedFiles = this.dataRequest.files.filter((f) => f.parentFile?.equalsTo(file['@id']))
        const transformedFiles = convertedFiles.flatMap((c) =>
          this.dataRequest.files.filter((f) => f.parentFile?.equalsTo(c['@id'])),
        )

        agg.push(new FileGroup(file, convertedFiles, transformedFiles, this.application))

        return agg
      }, [] as FileGroup[])
      .sort((a, b) => (a.file.originalFileName ?? '').localeCompare(b.file.originalFileName ?? ''))
  }
}
