/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import {
  CellValueType,
  type ICellData,
  type IObjectMatrixPrimitiveType,
  type IStyleData,
  type IWorkbookData,
} from '@univerjs/core'

export type WorkbookData = Partial<IWorkbookData>
export type CellData = IObjectMatrixPrimitiveType<ICellData>
export const CellType = CellValueType
export type CellStyle = IStyleData
