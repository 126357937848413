/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type ClockInterface from '~/src/Domain/Shared/Clock/ClockInterface'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import type LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default abstract class AbstractTokenExpiryListener {
  private timer: NodeJS.Timeout | undefined = undefined

  private readonly clock: ClockInterface
  private readonly cookies: CookiesInterface
  private readonly logger: LoggerInterface

  protected constructor({ clock, cookies, logger }: Services) {
    this.clock = clock
    this.cookies = cookies
    this.logger = logger
  }

  public isListening(): boolean {
    return this.timer !== undefined
  }

  public listen(expiryDate: BetterDateTime): void {
    this.logger.info(
      `[TOKEN_EXPIRY_LISTENER] Listen for expiry. Token will expire in ${
        expiryDate.getUnix() - new BetterDateTime().getUnix()
      } seconds.`,
    )

    this.clearTimerIfListening()

    this.timer = setTimeout(
      () => {
        this.refreshToken().catch((error) => {
          // handle the error here if necessary
          console.error('Failed to refresh token:', error)
        })
      },
      Math.max(this.getRefreshDelayInMilliseconds(expiryDate), 0),
    )
  }

  public tryListen(): void {
    const expiration = this.getExpirationFromCookie()
    if (expiration !== undefined) {
      this.listen(expiration)
    }
  }

  public unlisten(): void {
    this.logger.info('[TOKEN_EXPIRY_LISTENER] Unlisten for expiry')

    this.clearTimerIfListening()
  }

  public async refreshToken(): Promise<void> {
    this.logger.info('[TOKEN_EXPIRY_LISTENER] Refreshing token')

    // refresh token
    try {
      await this.refreshApiToken()
    } catch {
      await this.logout()
      return
    }

    // get new expiry date
    const expiration = this.getExpirationFromCookie()
    if (expiration === undefined) {
      this.clearTimerIfListening()
    } else {
      this.listen(expiration)
    }
  }

  public clearTimerIfListening(): void {
    if (this.isListening()) {
      clearTimeout(this.timer)
    }
  }

  public getExpirationFromCookie(): BetterDateTime | undefined {
    const expiration = this.cookies.tryGet<number | undefined>('auth_expiration')
    if (expiration === undefined) {
      return undefined
    }

    return BetterDateTime.fromUnixTimestamp(expiration)
  }

  public getRefreshDelayInMilliseconds(
    expiryDate: BetterDateTime,
    expireEarlyInSeconds = 60,
  ): number {
    const expiry = expiryDate.getUnix() - expireEarlyInSeconds - this.clock.now().getUnix()

    this.logger.info(
      `[TOKEN_EXPIRY_LISTENER] Token will be refreshed in ${Math.max(expiry, 0)} seconds.`,
    )

    return expiry * 1000
  }

  public abstract refreshApiToken(): Promise<void>

  public abstract logout(): Promise<void>
}
