/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import type Phase from '~/src/Domain/Engagement/Phase'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import EngagementStore from '~/src/UserInterface/Engagement/store/EngagementStore'

export default class EngagementStoreRepository implements EngagementStoreRepositoryInterface {
  private readonly store: ReturnType<typeof EngagementStore>

  public constructor() {
    this.store = EngagementStore(pinia)
  }

  public getEngagements(): Engagement[] {
    return this.store.engagements
  }

  public getCurrentEngagement(): Engagement {
    return this.store.getCurrentEngagement().value
  }

  public hasCurrentEngagement(): boolean {
    return this.store.hasCurrentEngagement
  }

  public getCurrentPhase(): Phase {
    return this.store.getCurrentPhase().value
  }

  public hasCurrentPhase(): boolean {
    return this.store.hasCurrentPhase
  }

  public markState(engagements: Engagement[]): void {
    this.store.markState(engagements)
  }

  public async activate(engagement: EngagementIri, phase: PhaseIri): Promise<void> {
    await this.store.activate(engagement, phase)
  }

  public update(engagement: Engagement): void {
    this.store.updateEngagement(engagement)
  }

  public delete(engagement: EngagementIri): void {
    this.store.deleteEngagement(engagement)
  }
}
