/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import OpenAccountsReceivableFileType from '~/src/Domain/AccountsReceivable/FileType/OpenAccountsReceivableFileType'
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import CustomerFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/CustomerFileType'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class GenericAccountsReceivableDataRequestFixture
implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.GENERIC_ACCOUNTS_RECEIVABLE === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.GENERIC_ACCOUNTS_RECEIVABLE),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.accounts_receivable.pages.accounts_receivable.title'),
          t('components.data_request.accounts_receivable.pages.accounts_receivable.description'),
          [
            new FileUploader(new OpenAccountsReceivableFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('customers'),
          t('components.data_request.accounts_receivable.pages.customers.title'),
          t('components.data_request.accounts_receivable.pages.customers.description'),
          [
            new FileUploader(new CustomerFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
      ]),
    )
  }
}
