/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ParameterNotFoundException from '~/src/Domain/Engagement/Exception/ParameterNotFoundException'
import type Parameter from '~/src/Domain/Engagement/Parameter'
import type ParameterName from '~/src/Domain/Engagement/Parameter/ParameterName'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'

export default class ParameterCollection {
  public constructor(public readonly parameters: Parameter[]) {}

  public getParameterByParameterName(phase: PhaseIri, name: ParameterName): Parameter {
    const parameter = this.parameters.find((p) => p.phase.equalsTo(phase) && p.name.equalsTo(name))
    if (parameter !== undefined) {
      return parameter
    }

    throw ParameterNotFoundException.withName(name)
  }

  public hasParameter(phase: PhaseIri, name: ParameterName): boolean {
    return this.parameters.some((p) => p.phase.equalsTo(phase) && p.name.toString() === name.toString())
  }
}
