/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import User2faInitiatedEvent from '~/src/Domain/Identity/Event/User2faInitiatedEvent'
import UserAuthenticatedEvent from '~/src/Domain/Identity/Event/UserAuthenticatedEvent'
import UserLogoutEvent from '~/src/Domain/Identity/Event/UserLogoutEvent'
import Invalid2faException from '~/src/Domain/Identity/Exception/Invalid2faException'
import UserIsNotIn2faProcessException from '~/src/Domain/Identity/Exception/UserIsNotIn2faProcessException'
import type SecurityInterface from '~/src/Domain/Identity/SecurityInterface'
import type {
  LoginResponse,
  RegisterPayload,
  RequestResetPasswordPayload,
} from '~/src/Domain/Identity/SecurityInterface'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class Security implements SecurityInterface {
  private readonly eventBus: EventBusInterface
  private readonly httpClient: HttpClient
  private readonly userStoreRepository: UserStoreRepositoryInterface

  public constructor({ eventBus, httpClient, userStoreRepository }: Services) {
    this.eventBus = eventBus
    this.httpClient = httpClient
    this.userStoreRepository = userStoreRepository
  }

  public async login(username: string, password: string): Promise<void> {
    const response = await this.httpClient.post<LoginResponse>('/v1/auth/login', {
      username,
      password,
    })

    if (response.login === 'success' && !response.two_factor_complete) {
      await this.eventBus.dispatch(User2faInitiatedEvent.NAME, new User2faInitiatedEvent(username))
    }
  }

  public async login2fa(token: string): Promise<void> {
    try {
      const response = await this.httpClient.post<LoginResponse | undefined>('/v1/auth/2fa', {
        token,
      })

      if (response?.error === '2fa_failed') {
        throw new Invalid2faException(response.error)
      }

      await this.eventBus.dispatch(UserAuthenticatedEvent.NAME, new UserAuthenticatedEvent())
    } catch (error) {
      if (!this.userStoreRepository.isAuthenticationProcessInitiated()) {
        throw new UserIsNotIn2faProcessException(
          'User is not in a two-factor authentication process.',
        )
      }

      throw error
    }
  }

  public async requestNewEmailAuthToken(): Promise<void> {
    await this.httpClient.post('/v1/auth/email-token')
  }

  public async logout(): Promise<void> {
    try {
      await this.httpClient.post('/v1/auth/logout')
    } finally {
      await this.eventBus.dispatch(UserLogoutEvent.NAME, new UserLogoutEvent())
    }
  }

  public async refreshJwtToken(): Promise<void> {
    await this.httpClient.post('/v1/auth/refresh')
  }

  public async register(payload: RegisterPayload): Promise<void> {
    await this.httpClient.post('/v1/register', payload)
  }

  public async requestResetPassword(payload: RequestResetPasswordPayload): Promise<void> {
    await this.httpClient.post('/v1/request-reset-password', payload)
  }
}
