/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type CoaMapping from '~/src/Domain/CoaMapping/CoaMapping'

export default class CoaMappingUpdatedEvent implements EventInterface {
  public static readonly NAME = 'COA_MAPPING_UPDATED'

  public constructor(public readonly coaMapping: CoaMapping) {}
}
