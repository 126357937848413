/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type QueryBusInterface from '~/src/Application/Shared/MessageBus/QueryBusInterface'
import type QueryHandlerInterface
  from '~/src/Application/Shared/MessageBus/QueryHandlerInterface'
import type QueryInterface from '~/src/Application/Shared/MessageBus/QueryInterface'
import type { Container, Services } from '~/src/Infrastructure/Shared/Container/Container'

const queryHandlers: { queryName: string, queryHandlerName: keyof Services }[] = []

export function AsQueryHandler<T extends keyof Services>(queryName: string, queryHandlerName: T) {
  return function (_target: any) {
    queryHandlers.push({ queryName, queryHandlerName })
  }
}

export function initializeQueryHandlers(container: Container, queryBus: QueryBusInterface): void {
  for (const { queryName, queryHandlerName } of queryHandlers) {
    queryBus.registerHandler(queryName, {
      handle: async (query: QueryInterface) => {
        const handler = container.resolve<QueryHandlerInterface<QueryInterface, any>>(queryHandlerName)
        await handler.handle(query)
      },
    })
  }
}
