/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type BooleanFilterValueType = boolean

export default class BooleanFilter extends AbstractFilter<BooleanFilterValueType> {
  public constructor() {
    super(Operator.EQUALS, FilterType.BOOLEAN, FilterClass.BOOLEAN_FILTER)
  }

  protected toUrlParameters(key: string, value: BooleanFilterValueType): string {
    return `${key}=${encodeURIComponent(value.toString())}`
  }
}
