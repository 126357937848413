/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { cloneDeep as lodashCloneDeep } from 'lodash-es'

export default function clone<T>(source: T): T {
  return lodashCloneDeep<T>(source)
}
