/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface DataRequestEngagementProps extends DomainModelProps {
  '@id': string
  '@type': string
  name: string
}

export default class DataRequestEngagement
implements DomainModelInterface<DataRequestEngagement, DataRequestEngagementProps> {
  public readonly '@id': EngagementIri
  public readonly '@type': string

  private constructor(
    id: EngagementIri,
    type: string,
    public readonly name: string,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'DataRequestEngagement'
  }

  public fromJSON(values: DataRequestEngagementProps): DataRequestEngagement {
    return new DataRequestEngagement(new EngagementIri(values['@id']), values['@type'], values.name)
  }

  public toJSON(): DataRequestEngagementProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      name: this.name,
    }
  }
}
