/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import Exception from '~/src/Domain/Shared/Exception/Exception'
import ParameterDefinitionNotFoundException from '~/src/Domain/WorkProgram/Exception/ParameterDefinitionNotFoundException'
import type ParameterDefinitionInterface from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionInterface'
import type ParameterName from '~/src/Domain/WorkProgram/ParameterName'

export default class ParameterDefinitionFactory {
  public constructor(private readonly parameterDefinitions: ParameterDefinitionInterface[]) {
    for (const definition of this.parameterDefinitions) {
      if (!this.parameterDefinitions.some((p) => p.getName().equalsTo(definition.getName()))) {
        continue
      }

      throw new Exception(
        `Parameter name must be unique. Got duplicated name "${definition.getName().toString()}".`,
      )
    }
  }

  public createForName(parameterName: ParameterName) {
    for (const definition of this.parameterDefinitions) {
      if (!definition.getName().equalsTo(parameterName)) {
        continue
      }

      return definition
    }

    throw new ParameterDefinitionNotFoundException(
      `No parameter definition found with name ${parameterName.toString()}`,
    )
  }
}
