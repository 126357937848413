/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type RouterInterface from '~/src/Application/Shared/Router/RouterInterface'
import type SecurityInterface from '~/src/Domain/Identity/SecurityInterface'
import AbstractTokenExpiryListener from '~/src/Infrastructure/Identity/AbstractTokenExpiryListener'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class TokenExpiryListener extends AbstractTokenExpiryListener {
  private readonly router: RouterInterface
  private readonly security: SecurityInterface

  public constructor({ clock, cookies, logger, router, security }: Services) {
    super({ clock, cookies, logger } as Services)
    this.router = router
    this.security = security
  }

  public async refreshApiToken(): Promise<void> {
    await this.security.refreshJwtToken()
  }

  public async logout(): Promise<void> {
    try {
      await this.security.logout()
    } finally {
      await this.router.replace('/')
    }
  }
}
