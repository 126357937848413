/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export default class ParameterName {
  public constructor(private readonly parameterName: string) {}

  public equalsTo(parameterName: ParameterName): boolean {
    return this.parameterName === parameterName.toString()
  }

  public toString(): string {
    return this.parameterName
  }
}
