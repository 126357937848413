/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type ParameterName from '~/src/Domain/Engagement/Parameter/ParameterName'
import DomainException from '~/src/Domain/Shared/Exception/DomainException'

export default class ParameterValueObjectNotFoundException extends DomainException {
  private constructor(message: string) {
    super(message)
    this.name = 'ParameterValueObjectNotFoundException'
  }

  public static withName(name: ParameterName): ParameterValueObjectNotFoundException {
    return new ParameterValueObjectNotFoundException(
      `Cannot find parameter with name "${name.toString()}"`,
    )
  }
}
