/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'

export default class CreateWorkProgramExportCommand implements CommandInterface {
  public static readonly NAME = 'CREATE_WORK_PROGRAM_EXPORT_COMMAND'

  public constructor(
    public readonly workProgram: WorkProgramIri,
    public readonly title: string,
    public readonly description: string,
    public readonly steps: { stepSlug: string, title: string, description: string }[],
    public readonly url: string,
  ) {}
}
