/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type CoaMappingStoreRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingStoreRepositoryInterface'
import CoaMappingDeletedEvent from '~/src/Domain/CoaMapping/Event/CoaMappingDeletedEvent'

import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(CoaMappingDeletedEvent.NAME, 'coaMappingDeletedEventHandler')
export default class CoaMappingDeletedEventHandler extends EventHandlerInterface<CoaMappingDeletedEvent> {
  private readonly coaMappingStoreRepository: CoaMappingStoreRepositoryInterface

  public constructor({ coaMappingStoreRepository }: Services) {
    super()
    this.coaMappingStoreRepository = coaMappingStoreRepository
  }

  public handle(event: CoaMappingDeletedEvent): void {
    this.coaMappingStoreRepository.deleteCoaMapping(event.coaMapping)
  }
}
