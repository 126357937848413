/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { NuxtApp } from '#app'
import { defineNuxtPlugin, navigateTo } from '#app'
import { buildContainer } from '~/src/Infrastructure/Shared/Container/Container'

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    buildContainer(nuxtApp.$config, nuxtApp as NuxtApp, $fetch, navigateTo)
  },
})
