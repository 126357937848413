/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import DataRequestEngagement, {
  type DataRequestEngagementProps,
} from '~/src/Domain/DataRequest/DataRequestEngagement'
import DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import type { DataRequestOrganisationProps } from '~/src/Domain/DataRequest/DataRequestOrganisation'
import DataRequestOrganisation from '~/src/Domain/DataRequest/DataRequestOrganisation'
import type { DataRequestRecipientProps } from '~/src/Domain/DataRequest/DataRequestRecipient'
import DataRequestRecipient from '~/src/Domain/DataRequest/DataRequestRecipient'
import { DataRequestStatus } from '~/src/Domain/DataRequest/DataRequestStatus'
import type { FileProps } from '~/src/Domain/DataRequest/File/File'
import File from '~/src/Domain/DataRequest/File/File'
import DataCorruptionFinding, {
  type DataCorruptionFindingProps,
} from '~/src/Domain/DataRequest/Validation/DataCorruptionFinding'
import DataEnrichmentFinding, {
  type DataEnrichmentFindingProps,
} from '~/src/Domain/DataRequest/Validation/DataEnrichmentFinding'
import DataIntegrationFinding, {
  type DataIntegrationFindingProps,
} from '~/src/Domain/DataRequest/Validation/DataIntegrationFinding'
import { DataIntegrityPillar } from '~/src/Domain/DataRequest/Validation/DataIntegrityPillar'
import type { DataIntegrityStatus } from '~/src/Domain/DataRequest/Validation/DataIntegrityStatus'
import DataQualityFinding, {
  type DataQualityFindingProps,
} from '~/src/Domain/DataRequest/Validation/DataQualityFinding'
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import type { AuditTeamMemberProps } from '~/src/Domain/Engagement/AuditTeamMember'
import AuditTeamMember from '~/src/Domain/Engagement/AuditTeamMember'
import type { PhaseProps } from '~/src/Domain/Engagement/Phase'
import Phase from '~/src/Domain/Engagement/Phase'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface DataRequestProps extends DomainModelProps {
  '@id': string
  '@type': string
  organisation: DataRequestOrganisationProps
  engagement: DataRequestEngagementProps
  recipient: DataRequestRecipientProps
  createdBy: AuditTeamMemberProps
  createdOn: string
  phase: PhaseProps
  applicationId: ApplicationId
  analysisType: AnalysisType
  period: { start: string, end: string }
  businessUnits: { code: string, description: string }[]
  message: string
  files: FileProps[]
  status: DataRequestStatus
  dataIntegrityStatus: DataIntegrityStatus | undefined
  dataIntegrityFindings: (
    | DataEnrichmentFindingProps
    | DataIntegrationFindingProps
    | DataQualityFindingProps
    | DataCorruptionFindingProps
  )[]
  fulfilled: boolean
  fulfilledDate: string | undefined
  archived: boolean
  archivalDate: string | undefined
}

export default class DataRequest implements DomainModelInterface<DataRequest, DataRequestProps> {
  public readonly '@id': DataRequestIri
  public readonly '@type': string

  private constructor(
    id: DataRequestIri,
    type: string,
    public readonly organisation: DataRequestOrganisation,
    public readonly engagement: DataRequestEngagement,
    public readonly recipient: DataRequestRecipient,
    public readonly createdBy: AuditTeamMember,
    public readonly createdOn: BetterDateTime,
    public readonly phase: Phase,
    public readonly applicationId: ApplicationId,
    public readonly analysisType: AnalysisType,
    public readonly period: { start: string, end: string },
    public readonly businessUnits: { code: string, description: string }[],
    public readonly message: string,
    public readonly files: File[],
    public readonly status: DataRequestStatus,
    public readonly dataIntegrityStatus: DataIntegrityStatus | undefined,
    public readonly dataIntegrityFindings: (
      | DataEnrichmentFinding
      | DataIntegrationFinding
      | DataQualityFinding
      | DataCorruptionFinding
    )[],
    public readonly fulfilled: boolean,
    public readonly fulfilledDate: BetterDateTime | undefined,
    public readonly archived: boolean,
    public readonly archivalDate: BetterDateTime | undefined,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'DataRequest'
  }

  public isGenericApplication(): boolean {
    return this.applicationId === ApplicationId.GENERIC_GENERAL_LEDGER
  }

  public hasFiles(): boolean {
    return this.files.length > 0
  }

  public isFulfilled(): boolean {
    return [DataRequestStatus.FULFILLED, DataRequestStatus.ARCHIVED].includes(this.status)
  }

  public isFinished(): boolean {
    return (
      [DataRequestStatus.ARCHIVED].includes(this.status)
      || (this.isFulfilled() && this.isGenericApplication())
    )
  }

  public fromJSON(values: DataRequestProps): DataRequest {
    return new DataRequest(
      new DataRequestIri(values['@id']),
      values['@type'],
      DataRequestOrganisation.prototype.fromJSON(values.organisation),
      DataRequestEngagement.prototype.fromJSON(values.engagement),
      DataRequestRecipient.prototype.fromJSON(values.recipient),
      AuditTeamMember.prototype.fromJSON(values.createdBy),
      new BetterDateTime(values.createdOn),
      Phase.prototype.fromJSON(values.phase),
      values.applicationId,
      values.analysisType,
      values.period,
      values.businessUnits,
      values.message,
      values.files.map((f) => File.prototype.fromJSON(f)),
      values.status,
      values.dataIntegrityStatus,
      values.dataIntegrityFindings.map((f) => {
        const pillar = f.pillar
        switch (pillar) {
          case DataIntegrityPillar.DATA_ENRICHMENT:
            return DataEnrichmentFinding.prototype.fromJSON(f)
          case DataIntegrityPillar.DATA_INTEGRATION:
            return DataIntegrationFinding.prototype.fromJSON(f)
          case DataIntegrityPillar.DATA_QUALITY:
            return DataQualityFinding.prototype.fromJSON(f)
          case DataIntegrityPillar.DATA_CORRUPTION:
            return DataCorruptionFinding.prototype.fromJSON(f)
          default:
            throw new Error(`Unknown pillar: "${String(pillar)}"`)
        }
      }),
      values.fulfilled,
      values.fulfilledDate === undefined ? undefined : new BetterDateTime(values.fulfilledDate),
      values.archived,
      values.archivalDate === undefined ? undefined : new BetterDateTime(values.archivalDate),
    )
  }

  public toJSON(): DataRequestProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      organisation: this.organisation.toJSON(),
      engagement: this.engagement.toJSON(),
      recipient: this.recipient.toJSON(),
      createdBy: this.createdBy.toJSON(),
      createdOn: this.createdOn.toString(),
      phase: this.phase.toJSON(),
      applicationId: this.applicationId,
      analysisType: this.analysisType,
      period: this.period,
      businessUnits: this.businessUnits,
      message: this.message,
      files: this.files.map((f) => f.toJSON()),
      status: this.status,
      dataIntegrityStatus: this.dataIntegrityStatus,
      dataIntegrityFindings: this.dataIntegrityFindings.map((f) => f.toJSON()),
      fulfilled: this.fulfilled,
      fulfilledDate: this.fulfilledDate?.toString(),
      archived: this.archived,
      archivalDate: this.archivalDate?.toString(),
    }
  }
}
