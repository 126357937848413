/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed } from 'vue'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default function useUserState() {
  const userStoreRepository = useService('userStoreRepository')

  const getUser = () => userStoreRepository.getUser()
  const getUserIri = () => userStoreRepository.getUser()['@id']

  const isAuthenticated = computed(() => userStoreRepository.isAuthenticated())
  const isAuthenticationProcessInitiated = computed(() =>
    userStoreRepository.isAuthenticationProcessInitiated(),
  )
  const authenticationProcessInitiatedFor = () =>
    userStoreRepository.authenticationProcessInitiatedFor()

  return {
    getUser,
    getUserIri,
    isAuthenticated,
    isAuthenticationProcessInitiated,
    authenticationProcessInitiatedFor,
  }
}
