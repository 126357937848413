/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import CodePreview from '~/src/Domain/DataRequest/Fixtures/Content/CodePreview/CodePreview'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Image from '~/src/Domain/DataRequest/Fixtures/Content/Image/Image'
import Header from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Header'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import sql from '~/src/Domain/GeneralLedger/Application/ExactGlobeNext/assets/journal_entries.sql?raw'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class ExactGlobeNextDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.EXACT_GLOBE_NEXT === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.EXACT_GLOBE_NEXT),
      new StepCollection([
        new Step(
          new Slug('system-settings'),
          t('components.data_request.exact_globe_next.pages.system_settings.title'),
          '',
          [
            new Header(
              t(
                'data_request.exact_globe_next.pages.system_settings.title_select_relevant_administration',
                {
                  chapter: 1,
                },
              ),
            ),
            new Paragraph([
              t(
                'data_request.exact_globe_next.pages.system_settings.paragraph_select_relevant_administration',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.select_administration.click_open_dropdown',
                  ),
                  new Badge(9, undefined, 18, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.select_administration.click_select_entity',
                  ),
                  new Badge(33, undefined, 35, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/select-relevant-administration.png',
            ),
            new Header(
              t(
                'data_request.exact_globe_next.pages.system_settings.title_document_system_settings',
                {
                  chapter: 2,
                },
              ),
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.document_system_settings.click_open_menu',
                  ),
                  new Badge(3, undefined, 0, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.document_system_settings.click_btn_about',
                  ),
                  new Badge(23, undefined, 6, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/document-system-settings.png',
            ),
            new FileUploader(new GenericFileType(), ['.png', '.jpg', '.jpeg', '.bmp']),
          ],
        ),
        new Step(
          new Slug('trial-balance'),
          t('components.data_request.exact_globe_next.pages.trial_balance.title'),
          '',
          [
            new Paragraph([
              t('data_request.exact_globe_next.pages.trial_balance.paragraph_follow_next_steps'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.download_trial_balance.click_financial',
                  ),
                  new Badge(14, undefined, 8, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.download_trial_balance.click_interactive_balance',
                  ),
                  new Badge(32, undefined, 76, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/select-interactive-balance.png',
            ),
            new Paragraph([
              t(
                'data_request.exact_globe_next.pages.trial_balance.paragraph_follow_next_steps_to_export',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.enter_dates',
                  ),
                  new Badge(17, undefined, 34, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_balance_pl',
                  ),
                  new Badge(20, undefined, 29, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_debit_credit_saldo',
                  ),
                  new Badge(22, undefined, 22, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_year',
                  ),
                  new Badge(25, undefined, 31, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_image',
                  ),
                  new Badge(28, undefined, 33, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_previous_year',
                  ),
                  new Badge(34, undefined, 34, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_type_mutation',
                  ),
                  new Badge(39, undefined, 23, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.do_not_select_additional',
                  ),
                  new Badge(50, undefined, 9, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_all_cost_centre_layouts',
                  ),
                  new Badge(63, undefined, 34, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_show_actual',
                  ),
                  new Badge(72, undefined, 15, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_precision',
                  ),
                  new Badge(79, undefined, 38, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.select_nul_saldi',
                  ),
                  new Badge(89, undefined, 14, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.config_export_trial_balance.click_start',
                  ),
                  new Badge(87, undefined, 89, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/configure-export-trial-balance.png',
            ),
            new Paragraph([
              t(
                'data_request.exact_globe_next.pages.trial_balance.paragraph_follow_next_steps_to_export_actual',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.export_trial_balance.click_export'),
                  new Badge(90, undefined, 53, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/export-trial-balance.png',
            ),
            new Paragraph([
              t(
                'data_request.exact_globe_next.pages.trial_balance.paragraph_trial_balance_being_exported',
              ),
            ]),
            new Image(
              '/img/data-request/exact_globe_next/popup-export-trial-balance.png',
              t(
                'data_request.exact_globe_next.pages.trial_balance.paragraph_trial_balance_being_exported',
              ),
              250,
            ),
            new FileUploader(new GenericFileType(), ['.xlsx']),
          ],
        ),
        new Step(
          new Slug('charts-of-accounts'),
          t('components.data_request.exact_globe_next.pages.charts_of_accounts.title'),
          '',
          [
            new Paragraph([
              t(
                'components.data_request.exact_globe_next.pages.charts_of_accounts.paragraph_follow_next_steps',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_layout',
                  ),
                  new Badge(16, undefined, 24, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_financial_year',
                  ),
                  new Badge(22, undefined, 25, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_date_settings',
                  ),
                  new Badge(25, undefined, 23, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_correct_date',
                  ),
                  new Badge(29, undefined, 25, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_unprocessed',
                  ),
                  new Badge(33, undefined, 12, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_correct_categories',
                  ),
                  new Badge(44, undefined, 26, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_precision',
                  ),
                  new Badge(57, undefined, 15, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_nul_saldi',
                  ),
                  new Badge(61, undefined, 12, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.export_charts_of_accounts.select_start',
                  ),
                  new Badge(91, undefined, 90, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/export-charts-of-accounts.png',
            ),
            new FileUploader(new GenericFileType(), ['.xlsx']),
          ],
        ),
        new Step(
          new Slug('journal-entries'),
          t('components.data_request.exact_globe_next.pages.journal_entries.title'),
          '',
          [
            new Paragraph([
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.paragraph_recommended_use_excel_add_in',
              ),
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.paragraph_from_excel_open',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.open_excel_add_in.click_add_in'),
                  new Badge(10, undefined, 83, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.open_excel_add_in.click_exact_globe_next',
                  ),
                  new Badge(38, undefined, 13, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/excel-ribbon-exact-globe-next.png',
            ),
            new Paragraph([
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.paragraph_context_menu_is_opened',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.open_reports_checklist.click_reports'),
                  new Badge(47, undefined, 80, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/excel-context-menu-open-reports.png',
            ),
            new Paragraph([
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.paragraph_popup_is_displayed',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.popup_reports.select_administration'),
                  new Badge(13, undefined, 25, undefined),
                ),
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.popup_reports.select_option_generate'),
                  new Badge(30, undefined, 35, undefined),
                ),
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.popup_reports.enter_query'),
                  new Badge(49, undefined, 8, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/popup-reports-query.png',
            ),
            new Alert(
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.alert_query_range.text',
              ),
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.alert_query_range.title',
              ),
              'exclamation-square',
            ),
            new Image(
              '/img/data-request/exact_globe_next/sql-query-export-range.png',
              'Export range',
            ),
            new CodePreview(sql, 'sql'),
            new Paragraph([
              t(
                'components.data_request.exact_globe_next.pages.journal_entries.paragraph_verify_popup_reports',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.exact_globe_next.checklist.verify_popup_reports.verify_administration',
                  ),
                  new Badge(6, undefined, 32, undefined),
                ),
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.verify_popup_reports.verify_filters'),
                  new Badge(31, undefined, 45, undefined),
                ),
                new ChecklistItem(
                  t('data_request.exact_globe_next.checklist.verify_popup_reports.click_ok'),
                  new Badge(88, undefined, 82, undefined),
                ),
              ],
              '/img/data-request/exact_globe_next/popup-verify-reports.png',
            ),
            new FileUploader(new GenericFileType(), ['.xlsx']),
          ],
        ),
      ]),
    )
  }
}
