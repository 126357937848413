/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import AccountsPayablesGlAccountsParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/AccountsPayablesGlAccountsParameterDefinition24342109'
import BusinessUnitParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/BusinessUnitParameterDefinition24342109'
import FiscalYearParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/FiscalYearParameterDefinition24342109'
import ManualBookingSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/ManualBookingSourcesParameterDefinition24342109'
import PurchasingSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/PurchasingSourcesParameterDefinition24342109'
import SpecialRelationsEmployeesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsEmployeesParameterDefinition24342109'
import SpecialRelationsInterCompanyParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsInterCompanyParameterDefinition24342109'
import SpecialRelationsPensionFundsParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsPensionFundsParameterDefinition24342109'
import SpecialRelationsTaxAuthoritiesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsTaxAuthoritiesParameterDefinition24342109'
import SubsequentYearFirstDayParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SubsequentYearFirstDayParameterDefinition24342109'
import SubsequentYearFirstPeriodParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SubsequentYearFirstPeriodParameterDefinition24342109'
import SubsequentYearParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SubsequentYearParameterDefinition24342109'
import t from '~/src/Infrastructure/Shared/Translation/t'

/// /////////////////
// General Ledger
/// /////////////////

export const fiscalYearFilterField = (): FilterField => {
  return new FilterField('fiscalYear', t('general.entity_fields.journal_entry.fiscal_year'), [
    new ParameterizedFilterFieldFilter(
      new FiscalYearParameterDefinition24342109(),
      FilterClass.API_VALUE_FILTER,
    ),
  ])
}

export const businessUnitFilterField = (): FilterField => {
  return new FilterField(
    'businessUnit.businessUnitName',
    t('general.entity_fields.business_unit.business_unit_name'),
    [
      new ParameterizedFilterFieldFilter(
        new BusinessUnitParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    false,
  )
}

export const headerDescriptionExistsFilterField = (): FilterField => {
  return new FilterField(
    'headerDescription',
    t('general.entity_fields.journal_entry.header_description'),
    [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
    true,
    true,
  )
}

export const headerDescriptionUnusualFilterField = (): FilterField => {
  return new FilterField(
    'headerDescription',
    t('general.entity_fields.journal_entry.header_description'),
    [
      new FilterFieldFilter(FilterClass.PARTIAL_TEXT_FILTER, [
        'dotatie',
        'vrijval',
        'reservering',
        'corr',
        'stelpost',
        'fout',
        'dubbel',
        'fraude',
        'afw',
        'versch',
        'afb',
        'overb',
        'aanp',
        'boekw',
        'nagek',
        'incid',
        'eenm',
      ]),
    ],
    true,
    true,
  )
}

export const lineDescriptionExistsFilterField = (): FilterField => {
  return new FilterField(
    'lineDescription',
    t('general.entity_fields.journal_entry.line_description'),
    [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
    true,
    true,
  )
}

export const lineDescriptionUnusualFilterField = (): FilterField => {
  return new FilterField(
    'lineDescription',
    t('general.entity_fields.journal_entry.line_description'),
    [
      new FilterFieldFilter(FilterClass.PARTIAL_TEXT_FILTER, [
        'dotatie',
        'vrijval',
        'reservering',
        'corr',
        'stelpost',
        'fout',
        'dubbel',
        'fraude',
        'afw',
        'versch',
        'afb',
        'overb',
        'aanp',
        'boekw',
        'nagek',
        'incid',
        'eenm',
      ]),
    ],
    true,
    true,
  )
}

export const manualSourceNameFilterField = (): FilterField => {
  return new FilterField(
    'source.sourceName',
    t('general.entity_fields.source.source_name'),
    [
      new ParameterizedFilterFieldFilter(
        new ManualBookingSourcesParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    false,
    t('pages.general_ledger.work_program.management_fraud.descriptions.source_code'),
  )
}

export const reportDateFilterField = (): FilterField => {
  return new FilterField(
    'reportDate',
    t('general.entity_fields.open_accounts_payable.report_date'),
    [new FilterFieldFilter(FilterClass.EQUALS_FILTER, undefined)],
    false,
    false,
  )
}

export const accountsPayablesGlAccountsFilterField = (): FilterField => {
  return new FilterField(
    'chartOfAccounts.glAccount',
    t('general.entity_fields.chart_of_accounts.gl_account'),
    [
      new ParameterizedFilterFieldFilter(
        new AccountsPayablesGlAccountsParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
  )
}

export const creditorSourceNameFilterField = (): FilterField => {
  return new FilterField(
    'source.sourceName',
    t('general.entity_fields.source.source_name'),
    [
      new ParameterizedFilterFieldFilter(
        new PurchasingSourcesParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    false,
    t('pages.general_ledger.work_program.management_fraud.descriptions.source_code'),
  )
}

export const subsequentYearFilterField = (): FilterField => {
  return new FilterField('fiscalYear', t('general.entity_fields.journal_entry.fiscal_year'), [
    new ParameterizedFilterFieldFilter(
      new SubsequentYearParameterDefinition24342109(),
      FilterClass.API_VALUE_FILTER,
    ),
  ])
}

export const subsequentYearFirstDayFilterField = (): FilterField => {
  return new FilterField('effectiveDate', t('general.entity_fields.journal_entry.effective_date'), [
    new ParameterizedFilterFieldFilter(
      new SubsequentYearFirstDayParameterDefinition24342109(),
      FilterClass.EQUALS_FILTER,
    ),
  ])
}

export const subsequentYearFirstPeriodFilterField = (): FilterField => {
  return new FilterField('period', t('general.entity_fields.journal_entry.period'), [
    new ParameterizedFilterFieldFilter(
      new SubsequentYearFirstPeriodParameterDefinition24342109(),
      FilterClass.EQUALS_FILTER,
    ),
  ])
}

/// /////////////////
// Accounts Payable
/// /////////////////

export const specialRelationsInterCompanyFilterField = (): FilterField => {
  return new FilterField(
    'supplier.supplierName',
    t('general.entity_fields.supplier.supplier_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsInterCompanyParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const specialRelationsTaxAuthoritiesFilterField = (): FilterField => {
  return new FilterField(
    'supplier.supplierName',
    t('general.entity_fields.supplier.supplier_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsTaxAuthoritiesParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const specialRelationsPensionFundsFilterField = (): FilterField => {
  return new FilterField(
    'supplier.supplierName',
    t('general.entity_fields.supplier.supplier_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsPensionFundsParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const specialRelationsEmployeesFilterField = (): FilterField => {
  return new FilterField(
    'supplier.supplierName',
    t('general.entity_fields.supplier.supplier_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsEmployeesParameterDefinition24342109(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}
