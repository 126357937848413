/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { v7 as UuidV7 } from 'uuid'

export default class Uuid {
  public static v7(): string {
    return UuidV7()
  }
}
