/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import type DataRequestorStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestorStoreRepositoryInterface'
import UserIsNotIn2faProcessException from '~/src/Domain/Identity/Exception/UserIsNotIn2faProcessException'
import type DataRequestTokenExpiryListener
  from '~/src/Infrastructure/DataRequest/DataRequestTokenExpiryListener'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type Subscriber from '~/src/Infrastructure/Shared/PubSub/Subscriber'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'
import DataRequestorStore from '~/src/UserInterface/DataRequest/store/DataRequestorStore'

export default class DataRequestorStoreRepository implements DataRequestorStoreRepositoryInterface {
  private readonly dataRequestTokenExpiryListener: DataRequestTokenExpiryListener
  private readonly store: ReturnType<typeof DataRequestorStore>
  private readonly subscriber: Subscriber

  public constructor({
    dataRequestTokenExpiryListener,
subscriber,
  }: Services) {
    this.dataRequestTokenExpiryListener = dataRequestTokenExpiryListener
    this.store = DataRequestorStore(pinia)
    this.subscriber = subscriber
  }

  public hasDataRequest(): boolean {
    return this.store.hasDataRequest()
  }

  public getDataRequest(): DataRequestProjection {
    return this.store.getDataRequest().value
  }

  public updateDataRequest(dataRequest: DataRequest): void {
    this.store.updateDataRequest(dataRequest)
  }

  public markState(dataRequest: DataRequest): void {
    this.store.markState(dataRequest)

    if (this.subscriber.isSubscribed()) {
      this.subscriber.unsubscribe()
    }

    this.subscriber.subscribe(dataRequest.recipient['@id'])
    this.dataRequestTokenExpiryListener.tryListen()
  }

  public resetState(): void {
    this.store.resetState()

    if (this.subscriber.isSubscribed()) {
      this.subscriber.unsubscribe()
    }
  }

  public isAuthenticated(): boolean {
    return this.store.isAuthenticated
  }

  public initiateAuthenticationProcess(email: string): void {
    this.store.initiateAuthenticationProcess(email)
  }

  public isAuthenticationProcessInitiated(): boolean {
    return this.store.isAuthenticationProcessInitiated
  }

  public authenticationProcessInitiatedFor(): string {
    const email = this.store.authenticationProcessInitiatedFor()
    if (email === undefined) {
      throw new UserIsNotIn2faProcessException('User is not in 2fa process')
    }

    return email
  }
}
