/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import WorkProgramDeletedEvent from '~/src/Domain/WorkProgram/Event/WorkProgramDeletedEvent'
import type WorkProgramStoreRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramStoreRepositoryInterface'

import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(WorkProgramDeletedEvent.NAME, 'workProgramDeletedEventHandler')
export default class WorkProgramDeletedEventHandler extends EventHandlerInterface<WorkProgramDeletedEvent> {
  private readonly workProgramStoreRepository: WorkProgramStoreRepositoryInterface

  public constructor({ workProgramStoreRepository }: Services) {
    super()
    this.workProgramStoreRepository = workProgramStoreRepository
  }

  public handle(event: WorkProgramDeletedEvent): void {
    this.workProgramStoreRepository.deleteStartedWorkProgram(event.workProgram)
  }
}
