/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractBetterDate from '~/src/Domain/Shared/BetterDate/AbstractBetterDate'
import type { StringableInterface } from '~/src/Domain/Shared/StringableInterface'

export default class BetterDate extends AbstractBetterDate implements StringableInterface {
  public override toString(): string {
    return this.value.format('YYYY-MM-DD')
  }
}
