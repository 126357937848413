/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ParameterValueObjectInterface from '~/src/Domain/Engagement/Parameter/ParameterValueObjectInterface'

export interface BankAccountGlAccountMappingValues {
  bankAccountName: string
  selected: BankAccountGlAccountMappingSelectedValues[]
}

export interface BankAccountGlAccountMappingSelectedValues {
  businessUnitName: string
  glAccount: string
}

export default class BankAccountGlAccountMappingValueObject extends ParameterValueObjectInterface {
  public constructor(public readonly values: BankAccountGlAccountMappingValues[]) {
    super()
  }
}
