/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import type DataPivotStepResult from '~/src/Domain/WorkProgram/StepResult/DataPivotStepResult'
import type DataTableStepResult from '~/src/Domain/WorkProgram/StepResult/DataTableStepResult'

export default class FinalStep extends StepInterface {
  public readonly hidden: boolean = false
  public readonly hiddenReason: undefined = undefined
  public readonly disabled: boolean = false
  public readonly stepResult: undefined | DataPivotStepResult | DataTableStepResult = undefined

  public constructor(
    public readonly slug: Slug,
    public readonly title: string,
    public readonly text: string,
  ) {
    super()
  }

  public hide(_hiddenReason: string): void {}
  public show(): void {}

  public equals(step: StepInterface): boolean {
    return this.slug.equals(step.slug)
  }
}
