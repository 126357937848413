/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { CoaMappingFileProps } from '~/src/Domain/CoaMapping/CoaMappingFile'
import CoaMappingFile from '~/src/Domain/CoaMapping/CoaMappingFile'
import CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'
import { CoaMappingStatus } from '~/src/Domain/CoaMapping/CoaMappingStatus'
import type { CoaMappingType } from '~/src/Domain/CoaMapping/CoaMappingType'
import type ValidationRule from '~/src/Domain/CoaMapping/ValidationRule'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface ValidationProgress extends DomainModelProps {
  errors: {
    fiscalYear: string
    businessUnitName: string
    glAccount: string
    jeLineCount: number
  }[]
  rule: ValidationRule
  status: CoaMappingStatus
}

export interface CoaMappingProps extends DomainModelProps {
  '@id': string
  '@type': string
  phase: string
  type: CoaMappingType
  createdDate: string
  updatedDate: string
  fulfilledDate: string | undefined
  errorsConfirmedDate: string | undefined
  errorsConfirmedBy: string | undefined
  validationProgression: ValidationProgress[]
  status: CoaMappingStatus
  files: CoaMappingFileProps[] | undefined
}

export default class CoaMapping implements DomainModelInterface<CoaMapping, CoaMappingProps> {
  public readonly '@id': CoaMappingIri
  public readonly '@type': string

  private constructor(
    id: CoaMappingIri,
    _type: string,
    public readonly phase: PhaseIri,
    public readonly type: CoaMappingType,
    public readonly createdDate: BetterDateTime,
    public readonly updatedDate: BetterDateTime,
    public readonly fulfilledDate: BetterDateTime | undefined,
    public readonly errorsConfirmedDate: BetterDateTime | undefined,
    public readonly errorsConfirmedBy: string | undefined,
    public readonly validationProgression: ValidationProgress[],
    public readonly status: CoaMappingStatus,
    public readonly files: CoaMappingFile[],
  ) {
    this['@id'] = id
    this['@type'] = _type
  }

  public getClassName(): string {
    return 'CoaMapping'
  }

  public hasStarted(): boolean {
    return this.status !== CoaMappingStatus.CREATED
  }

  public isFulfilled(): boolean {
    return this.fulfilledDate !== undefined
  }

  public canBeUpdated(): boolean {
    return [
      CoaMappingStatus.CREATED,
      CoaMappingStatus.IN_PROGRESS,
      CoaMappingStatus.VALIDATED_WITH_ERRORS,
    ].includes(this.status)
  }

  public hasValidationErrors(): boolean {
    return (
      [CoaMappingStatus.VALIDATED_WITH_ERRORS, CoaMappingStatus.USER_CONFIRMED_ERRORS].includes(
        this.status,
      ) || this.validationProgression.some((v) => v.errors.length > 0)
    )
  }

  public hasBeenChangedSinceLastFulfilledDate(): boolean {
    return this.fulfilledDate !== undefined && this.fulfilledDate <= this.updatedDate
  }

  public fromJSON(values: CoaMappingProps): CoaMapping {
    return new CoaMapping(
      new CoaMappingIri(values['@id']),
      values['@type'],
      new PhaseIri(values.phase),
      values.type,
      new BetterDateTime(values.createdDate),
      new BetterDateTime(values.updatedDate),
      values.fulfilledDate === undefined ? undefined : new BetterDateTime(values.fulfilledDate),
      values.errorsConfirmedDate === undefined
        ? undefined
        : new BetterDateTime(values.errorsConfirmedDate),
      values.errorsConfirmedBy,
      values.validationProgression,
      values.status,
      values.files?.map((f) => CoaMappingFile.prototype.fromJSON(f)) ?? [],
    )
  }

  public toJSON(): CoaMappingProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      type: this.type,
      phase: this.phase.toString(),
      createdDate: this.createdDate.toString(),
      updatedDate: this.updatedDate.toString(),
      fulfilledDate: this.fulfilledDate?.toString(),
      errorsConfirmedDate: this.errorsConfirmedDate?.toString(),
      errorsConfirmedBy: this.errorsConfirmedBy?.toString(),
      validationProgression: this.validationProgression,
      status: this.status,
      files: this.files.map((f) => f.toJSON()),
    }
  }
}
