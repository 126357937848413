/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { DataRequestProps } from '~/src/Domain/DataRequest/DataRequest'
import DataRequest from '~/src/Domain/DataRequest/DataRequest'
import DataRequestCollection from '~/src/Domain/DataRequest/DataRequestCollection'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'

const dataRequestStore = defineStore('data-request', () => {
  const dataRequests = ref<DataRequestProps[]>([])
  const dataRequestCollection = computed(
    () =>
      new DataRequestCollection(dataRequests.value.map((d) => DataRequest.prototype.fromJSON(d))),
  )

  const updateDataRequest = (dataRequest: DataRequest) => {
    const index = dataRequests.value.findIndex((d) => d['@id'] === dataRequest['@id'].toString())
    if (index === -1) {
      dataRequests.value.push(dataRequest.toJSON())
    } else {
      dataRequests.value[index] = dataRequest.toJSON()
    }
  }

  const deleteDataRequest = (dataRequest: DataRequestIri) => {
    const index = dataRequests.value.findIndex((e) => e['@id'] === dataRequest.toString())
    if (index !== -1) {
      dataRequests.value.splice(index, 1)
    }
  }

  const markState = (d: DataRequest[]) => (dataRequests.value = d.map((d) => d.toJSON()))
  const resetState = (): void => {
    dataRequests.value = []
  }

  return {
    dataRequests: dataRequests as Readonly<Ref<DataRequestProps[]>>,
    dataRequestCollection,
    updateDataRequest,
    deleteDataRequest,
    markState,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(dataRequestStore, import.meta.hot))
}

export default dataRequestStore
