/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DataCubeIri from '~/src/Domain/DataCube/DataCubeIri'
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface DataCubeProps extends DomainModelProps {
  '@id': string
  '@type': string
  analysisType: AnalysisType
  phase: string
  definitionName: string
  createdDate: string
  generatedDate: string | undefined
  populatedDate: string | undefined
  populated: boolean
}

export default class DataCube implements DomainModelInterface<DataCube, DataCubeProps> {
  public readonly '@id': DataCubeIri
  public readonly '@type': string

  private constructor(
    id: DataCubeIri,
    type: string,
    public readonly analysisType: AnalysisType,
    public readonly phase: PhaseIri,
    public readonly definitionName: string,
    public readonly createdDate: BetterDateTime,
    public readonly generatedDate: BetterDateTime | undefined,
    public readonly populatedDate: BetterDateTime | undefined,
    public readonly populated: boolean,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'DataRequest'
  }

  public fromJSON(values: DataCubeProps): DataCube {
    return new DataCube(
      new DataCubeIri(values['@id']),
      values['@type'],
      values.analysisType,
      new PhaseIri(values.phase),
      values.definitionName,
      new BetterDateTime(values.createdDate),
      values.generatedDate === undefined ? undefined : new BetterDateTime(values.generatedDate),
      values.populatedDate === undefined ? undefined : new BetterDateTime(values.populatedDate),
      values.populated,
    )
  }

  public toJSON(): DataCubeProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      analysisType: this.analysisType,
      phase: this.phase.toString(),
      definitionName: this.definitionName,
      createdDate: this.createdDate.toString(),
      generatedDate: this.generatedDate?.toString(),
      populatedDate: this.populatedDate?.toString(),
      populated: this.populated,
    }
  }
}
