/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface ExportDetailsProps extends JsonValueObjectProps {

}

export default abstract class ExportDetailsInterface<
  T extends JsonValueObjectInterface<T, Y>,
  Y extends JsonValueObjectProps,
> implements JsonValueObjectInterface<T, Y> {
  public abstract fromJSON(values: Y): T

  public abstract toJSON(): Y
}
