/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'

export default class CreateTwoWayCorrelationsExportCommand implements CommandInterface {
  public static readonly NAME = 'CREATE_TWO_WAY_CORRELATIONS_EXPORT_COMMAND'

  public constructor(
    public readonly phase: PhaseIri,
    public readonly fiscalYears: string[],
    public readonly businessUnitNames: string[],
    public readonly sourceNames: string[],
    public readonly primaryGlAccounts: string[],
    public readonly primaryAccountTypes: string[],
    public readonly primaryAccountSubtypes: string[],
    public readonly primaryDebitCreditIndicators: string[],
    public readonly secondaryGlAccounts: string[],
    public readonly secondaryAccountTypes: string[],
    public readonly secondaryAccountSubtypes: string[],
    public readonly secondaryDebitCreditIndicators: string[],
    public readonly extension: string,
    public readonly fields: string[],
    public readonly locale: string,
    public readonly url: string,
  ) {}
}
