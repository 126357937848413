/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AuditTeamMemberIri from '~/src/Domain/Engagement/AuditTeamMemberIri'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface AuditTeamMemberProps extends DomainModelProps {
  '@id': string
  '@type': string
  firstName: string
  lastName: string | undefined
  email: string
  avatar: string | undefined
  disabled: boolean
}

export default class AuditTeamMember
implements DomainModelInterface<AuditTeamMember, AuditTeamMemberProps> {
  public readonly '@id': AuditTeamMemberIri
  public readonly '@type': string

  private constructor(
    id: AuditTeamMemberIri,
    type: string,
    public readonly firstName: string,
    public readonly lastName: string | undefined,
    public readonly email: string,
    public readonly avatar: string | undefined,
    public readonly disabled: boolean,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'AuditTeamMember'
  }

  public getName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  public getAddress(): string {
    return `${this.firstName} ${this.lastName} <${this.email}>`
  }

  public getInitials(): string {
    return `${this.firstName.charAt(0)}${this.lastName?.charAt(0)}`
  }

  public fromJSON(values: AuditTeamMemberProps): AuditTeamMember {
    return new AuditTeamMember(
      new AuditTeamMemberIri(values['@id']),
      values['@type'],
      values.firstName,
      values.lastName,
      values.email,
      values.avatar,
      values.disabled,
    )
  }

  public toJSON(): AuditTeamMemberProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      avatar: this.avatar,
      disabled: this.disabled,
    }
  }
}
