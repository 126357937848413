/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum ApplicationId {
  GENERIC_ACCOUNTS_PAYABLE = 'a0afb0ca-8655-4979-b1f7-5c05ce239fce',
  GENERIC_ACCOUNTS_RECEIVABLE = '8302a922-2b39-4230-b769-c6f3cbcc48fb',
  ABN_AMRO_CASH_MANAGEMENT = '50443ab0-80f7-4ccb-b734-20b603249c68',
  ABN_AMRO_MIJN_ZAKELIJK = '044d27a0-6754-4559-8159-5821c75b352b',
  ASN_BANK = '5f08a9aa-1186-42ee-8516-5e0fbd3ca814',
  BANK_OF_AMERICA = 'f2e9e920-2bd3-4568-8fe3-3ab0f3106029',
  BELFIUS = '7ad25eeb-8553-41c6-99e7-dd1995b22b5e',
  BNG_BANK = '4b79bff7-e16e-492b-95f0-53880a5ad1b5',
  BNP_PARIBAS = 'c6a056f5-ffac-49c9-8608-a19b8f36ec7a',
  BNP_PARIBAS_FORTIS = 'cd22ee2d-e028-4c38-9f05-96106a455044',
  BUCKAROO = '65d155bf-0a88-4d4a-b443-cdd1853c7ec2',
  CAMT053 = '7a18ecd4-4434-4b5e-8c96-c44dd701c4a0',
  CITIBANK = '26d3f212-d9d4-444f-a898-f391e4bd1dd1',
  COMMERZBANK = '8de10549-f92e-41cf-bbd6-0e90f041653a',
  DANSKE_BANK = 'c5584253-e8a9-4685-94ff-1e9095ea9818',
  DE_VOLKSBANK = '16626210-34ad-4eac-9f62-0ede96a980b8',
  DEUTSCHE_BANK = '7ab9f12c-1bec-4fb6-8c60-698575758ee8',
  ERSTE_BANK = '36564806-32cc-4091-8955-d8b70c46fd97',
  HANDELSBANKEN = '75d4ce40-03d1-46c9-979c-353f68570db2',
  HSBC = '0ea072cb-7a15-4325-8400-c4d01813b79b',
  HYPO_TIROL = '2429604b-05a4-4639-9b51-865b7635ab1d',
  HYPO_VEREINSBANK = 'aa3ee68d-d5d3-4339-b04d-8d759ed81bf5',
  ICICI_BANK = '3db553b0-eb57-4d2b-8f2c-f6f4452babc0',
  ING_CREDIT_VIEW = '37921344-010f-4e5b-9244-4cc4b7fd30f7',
  ING_INSIDE_BUSINESS_PAYMENTS = 'a05d1073-f249-4e90-b933-f8619b697398',
  ING_MIJN_ZAKELIJK = '58446c91-66bf-4127-9038-93992cf50b26',
  J_SAFRA_SARASIN = '39c7c688-bc9d-4d0c-9b9f-9394f852a501',
  KBC = '73f4d917-de66-4eb4-b6f0-474435ed343c',
  NIBC = '0e2e59d6-02de-4a21-871b-ed05f52a0bae',
  NORDEA = 'a4fd6e1c-ed8f-4b48-a39c-240197d72f2a',
  PENTA = '0c729707-2aa6-4a8f-b571-35e14250ab27',
  RABOBANK = '40f0a9b8-0c32-4726-90ab-193e3949f94c',
  SPARKASSE = '6229390b-1e81-4ad2-be13-03650f4eed5e',
  TRIODOS = '7fff65fe-ed7f-40ff-9346-70cabecd3418',
  UNITED_OVERSEAS_BANK = '795f108a-508d-487c-baa1-d3c91e860960',
  VAN_LANSCHOT = '9941cc46-2e94-40c6-ab23-0587c83edfd3',
  WELLS_FARGO = 'e5ed336c-4265-4c65-81d4-2b5769c0d904',
  GENERIC = 'e6f4dd52-2a07-45c3-bac0-aec6f1e725d8',
  AAREON_REMS = '7b1dd8b0-1835-46a9-ad53-a69e0f2341ad',
  AAREON_TOBIAS365 = 'fc8d9866-a370-447e-ad86-f50a9041f02d',
  AAREON_TOBIAS = '465fee4d-58ef-480e-8458-783b89171e05',
  ACCOUNT_VIEW = 'f26b43a6-29f3-418c-b6bb-f18177b35184',
  ACTO_BUSINESS = 'b002592d-7093-4d73-ad07-68697e57059f',
  ADMICOM_VAKWARE = '91a6c8c8-e479-4713-b5e2-36f737b1cba8',
  AFAS_ONLINE = '66a47031-d6ed-4755-a572-f83787bf191b',
  AFAS_PROFIT = 'dd5a6748-0727-4259-ac2a-d392611cf671',
  AGRIWARE = 'cf48f102-30c3-4d56-ac3a-36af5dec1aaf',
  AUTOLINE = '3eb63835-e3f5-4921-9767-366d11125b2f',
  BAAN = 'c81281c2-ce35-4340-8946-c18794bb29c9',
  BESTMATE = '3ea9d524-ee6b-497b-aa77-cf0f193fcfb8',
  BOMAS = '0830d7f3-cd0c-4e49-b380-d30aa6f2e529',
  BOUW_WORKS = 'f02b1f09-cfc2-46c7-b6bb-6be4b1634f65',
  CASH = '40fe9518-62c1-41e6-be3e-341ba43bf483',
  CERM = '8738fee2-9954-48d5-9909-7554fd8ac8cb',
  CI_VISION_MIDDELEN = 'd8c0635f-100d-46ef-b1d3-0f71bdb4e775',
  DYNAMICS_EMPIRE = '98432199-42f6-4b97-9d46-32d60ec0127e',
  E_BOEKHOUDEN_NL = '587a95c2-bd11-42c5-975b-17c14c31d380',
  EPICOR = 'f04c12ac-f8a5-45a8-8bb3-fa76ad43b481',
  EXACT_FINANCIALS = '7b59db86-b742-4ed2-9ce2-1e597d66c0e5',
  EXACT_GLOBE_NEXT = '32b7546b-454b-4f69-9de9-cc48d976080b',
  EXACT_MAX = '4dedaead-5449-40c2-b57e-9ab020f887b9',
  EXACT_MULTIVERS = 'a0212aa6-156f-42c1-a0d3-e8b951e0682e',
  EXACT_ONLINE = '05303cfe-b6e6-4334-b2a2-eafee43d7d57',
  FINALL = '683e6047-cdef-45cd-86a3-7d8a4d3a6f5d',
  FOUR_PS_CONSTRUCT = '7b40cd84-a1ee-40bb-a75f-e22a3a7530e9',
  FOUR_PS_CONSTRUCT_BC = 'bd804a76-1d54-4923-b78a-9b72969e4f85',
  GENERIC_GENERAL_LEDGER = '536e4c18-d5c8-4cff-ad03-18d14dcd33be',
  GROEN_VISION = '33f75cb5-1a93-4094-a0d5-971e7e383277',
  HANDEL_EN_LOGISTIEK = '2b9d9b24-d4ea-4acd-ba05-3ec162c3588d',
  HANDSOFT = 'ca5ff4f4-7c47-4083-b8ff-6a84b697ad3e',
  IFS = '94733782-f718-49cc-9fb5-2cd68fae0972',
  INFOR_LN = 'eb152a96-6e4f-4827-99af-ffffe57514e9',
  INFOR_M3 = '9927ba4f-2299-4ea2-9cfa-967ac69521d2',
  INFOR_SYTELINE = 'cba24eab-f594-4eec-bdd5-abf0b202c7ad',
  INVENTIVE = 'db49d2de-69b0-4d50-84fa-a1cde9343b0b',
  IPTOR = '6ab979a4-301a-4642-bc94-b0b83a237ac1',
  ISAH_FINANCE = 'c14f12eb-83ba-41dd-b1d6-319a42b4e0b5',
  KEY2_FINANCIEN = 'aaaf9840-d73e-4813-b99d-c2c40c732876',
  KING_FINANCE = 'f3c06f46-d6f0-48e9-8e48-8179a2456943',
  METACOM = '77c1055f-ffe5-46b4-a668-87afbebcf627',
  MICROSOFT_DYNAMICS365_BUSINESS_CENTRAL = '589439b8-82e3-443b-a29f-73825ad8655d',
  MICROSOFT_DYNAMICS365_FINANCE_AND_OPERATIONS = 'b6d8b27c-c4d4-4460-b3d0-b57f498143b2',
  MICROSOFT_DYNAMICS_AX = '2987aa22-7d8e-45d1-8f73-1eb74670b8d3',
  MICROSOFT_DYNAMICS_AXAPTA2009 = '67cbca88-9ee5-4eb4-9a09-209dcc9c6b46',
  MICROSOFT_DYNAMICS_AXAPTA2012 = '9891475d-788c-46a9-998a-fa91da16a6e5',
  MICROSOFT_DYNAMICS_GREAT_PLAINS = '85f5564e-158c-4a9c-9208-6685f5dd0352',
  MICROSOFT_DYNAMICS_NAV_CLASSIC = '4d04020b-ec25-4721-97a8-3b41bf191153',
  MICROSOFT_DYNAMICS_NAVISION = '50d17f96-1784-455d-8342-7161a59e72da',
  ORACLE_EBSR11 = 'eb938347-697e-4df2-b72d-6253730402dd',
  ORACLE_EBSR12 = 'e749861d-d308-40aa-ab0f-7491b9854a6b',
  ORACLE_FUSION = 'b6970535-c31a-43e5-8ae3-5b7cbff8b7fa',
  ORACLE_JD_EDWARDS = '6b8dd9a7-ba73-42b9-8ceb-95878ce3c393',
  ORACLE_NETSUITE = 'fad50f2a-26e9-4d42-9b81-2a409610520f',
  QUICKBOOKS = '92ecc79b-e8ca-48bc-a318-0a920abb751c',
  SAP_BUSINESS_ONE = '8551f31c-eb4c-493e-8c3d-ed44821230c5',
  SAP_BY_DESIGN = '8925111b-7d96-42c2-a71c-b93d7418e32b',
  SAP_ECC = 'aba0e304-6536-4617-a270-8949e3f97b02',
  SAP_R3 = 'be972ace-5438-47b4-9d6a-ca928b577755',
  SHIPNET = '2c31c334-c4f0-4e47-ab3b-d0bdafbe0279',
  SNEL_START = '41d72545-5e9f-40e1-ac4c-02485563a94a',
  SYNTESS_ATRIUM = '4a580594-2e6d-42da-af2b-581cd0eafaa0',
  TWINFIELD = '65cb0acc-84e4-4817-8af7-c9c845c9efd0',
  UNIT4_DECADE = '228f5867-4a77-4975-af77-966e92ee9617',
  UNIT4_ERPX = '2613f8d6-28fc-4fc7-81b8-46fd63e1644c',
  UNIT4_FINANCIALS = '5178e0a9-ee8f-4fc3-89e5-730b85b520d1',
  UNIT4_MULTIVERS = '702ab218-9431-44d9-8699-61125a67fa4e',
  UNIT4_WHOLESALE = '18a64230-9df4-4a7d-8193-7d4d3fe2378a',
  VIEWPOINT = 'cb00969f-d991-4e3e-9a4b-dfa84103890e',
  VISIONPLANNER = '1512cf6d-e3fb-4097-a92e-31c7dbe32fff',
  VISMA_DOT_NET = 'ed6d13e9-345d-4455-b5fc-16ba1704d7c7',
  XBIS = '9ed501c0-ddb7-495d-83d8-a9ddf2ac2752',
  I_MUIS = 'd5f7619c-8027-4103-b18e-e3c3150dac2b',
}
