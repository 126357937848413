/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataCubeStoreRepositoryInterface from '~/src/Domain/DataCube/DataCubeStoreRepositoryInterface'
import DataCubeDeletedEvent from '~/src/Domain/DataCube/Event/DataCubeDeletedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(DataCubeDeletedEvent.NAME, 'dataCubeDeletedEventHandler')
export default class DataCubeDeletedEventHandler extends EventHandlerInterface<DataCubeDeletedEvent> {
  private readonly dataCubeStoreRepository: DataCubeStoreRepositoryInterface

  public constructor({ dataCubeStoreRepository }: Services) {
    super()
    this.dataCubeStoreRepository = dataCubeStoreRepository
  }

  public handle(event: DataCubeDeletedEvent): void {
    this.dataCubeStoreRepository.deleteDataCube(event.dataCube)
  }
}
