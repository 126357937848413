/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { type CookieRef, useCookie as useNuxtCookie } from '#app'
import useRouter from '~/src/UserInterface/App/composables/Navigation/useRouter'

export default function useCookie<T>(name: string, readonly: boolean = true): CookieRef<T> {
  const router = useRouter()

  return useNuxtCookie(name, {
    domain: `.${router.getHostname()}`,
    path: '/',
    secure: true,
    httpOnly: false,
    sameSite: 'strict',
    readonly: readonly ? undefined : false,
  })
}
