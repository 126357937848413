/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DefaultPivotTableSettings } from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import t from '~/src/Infrastructure/Shared/Translation/t'

export function usePivotTableDefaultSettings() {
  const bankStatementOverviewDefaultPivotTableSettings: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'bankAccount.name',
        title: t('general.entity_fields.bank_account.name'),
        type: 'count',
      },
      {
        key: 'bankAccount.account',
        title: t('general.entity_fields.bank_account.account'),
        type: 'count',
      },
      {
        key: 'bankAccount.currency',
        title: t('general.entity_fields.bank_account.currency'),
        type: 'count',
      },
      {
        key: 'bankAccount.type',
        title: t('general.entity_fields.bank_account.type'),
        type: 'count',
      },
      {
        key: 'bankAccount.businessIdentifierCode',
        title: t('general.entity_fields.bank_account.business_identifier_code'),
        type: 'count',
      },
      {
        key: 'bankStatement.statementId',
        title: t('general.entity_fields.bank_statement.statement_id'),
        type: 'count',
      },
      {
        key: 'bankStatement.createdDate',
        title: t('general.entity_fields.bank_statement.created_date'),
        type: 'count',
      },
      {
        key: 'bankStatement.fromDate',
        title: t('general.entity_fields.bank_statement.from_date'),
        type: 'count',
      },
      {
        key: 'bankStatement.toDate',
        title: t('general.entity_fields.bank_statement.to_date'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.bookingDate',
        title: t('general.entity_fields.bank_statement_entry.booking_date'),
        dataType: 'date',
        type: 'count',
      },
      {
        key: 'bankStatementEntry.debitCreditIndicator',
        title: t('general.entity_fields.bank_statement_entry.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.bankTransactionDomainCode',
        title: t('general.entity_fields.bank_statement_entry.bank_transaction_domain_code'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.bankTransactionFamilyCode',
        title: t('general.entity_fields.bank_statement_entry.bank_transaction_family_code'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.bankTransactionSubfamilyCode',
        title: t('general.entity_fields.bank_statement_entry.bank_transaction_subfamily_code'),
        type: 'count',
      },
      {
        key: 'purposeCode',
        title: t('general.entity_fields.bank_statement_entry_detail.purpose_code'),
        type: 'count',
      },
      {
        key: 'purposeClassification',
        title: t('general.entity_fields.bank_statement_entry_detail.purpose_classification'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.batchDebitCreditIndicator',
        title: t('general.entity_fields.bank_statement_entry.batch_debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.batchTotalAmount',
        title: t('general.entity_fields.bank_statement_entry.batch_total_amount'),
        type: 'sum',
      },
      {
        key: 'bankStatementEntry.batchNumberOfTransactions',
        title: t('general.entity_fields.bank_statement_entry.batch_number_of_transactions'),
        type: 'sum',
      },
      {
        key: 'bankStatementEntry.batchPaymentInformationIdentification',
        title: t(
          'general.entity_fields.bank_statement_entry.batch_payment_information_identification',
        ),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.isBatch',
        title: t('general.entity_fields.bank_statement_entry.is_batch'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.isBatchDetails',
        title: t('general.entity_fields.bank_statement_entry.is_batch_details'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.batchDetailsOrigin',
        title: t('general.entity_fields.bank_statement_entry.batch_details_origin'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.numberOfTransactionDetails',
        title: t('general.entity_fields.bank_statement_entry.number_of_transaction_details'),
        type: 'sum',
      },
      {
        key: 'bankStatementEntry.additionalEntryInformation',
        title: t('general.entity_fields.bank_statement_entry.additional_entry_information'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.accountServiceReference',
        title: t('general.entity_fields.bank_statement_entry.account_service_reference'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.valueDate',
        title: t('general.entity_fields.bank_statement_entry.value_date'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.status',
        title: t('general.entity_fields.bank_statement_entry.status'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.reversalIndicator',
        title: t('general.entity_fields.bank_statement_entry.reversal_indicator'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.currency',
        title: t('general.entity_fields.bank_statement_entry.currency'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.amount',
        title: t('general.entity_fields.bank_statement_entry.amount'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.reference',
        title: t('general.entity_fields.bank_statement_entry.reference'),
        type: 'count',
      },
      {
        key: 'bankStatementEntry.number',
        title: t('general.entity_fields.bank_statement_entry.number'),
        type: 'count',
      },
      {
        key: 'cpiScore',
        title: t('general.entity_fields.bank_statement_entry_detail.cpi_score'),
        type: 'count',
      },
      {
        key: 'cpiRank',
        title: t('general.entity_fields.bank_statement_entry_detail.cpi_rank'),
        type: 'count',
      },
      {
        key: 'cpiCountry',
        title: t('general.entity_fields.bank_statement_entry_detail.cpi_country'),
        type: 'count',
      },
      {
        key: 'cpiYear',
        title: t('general.entity_fields.bank_statement_entry_detail.cpi_year'),
        type: 'count',
      },
      {
        key: 'creditor.creditorName',
        title: t('general.entity_fields.creditor.creditor_name'),
        type: 'count',
      },
      {
        key: 'creditor.name',
        title: t('general.entity_fields.creditor.name'),
        type: 'count',
      },
      {
        key: 'creditor.bankAccount',
        title: t('general.entity_fields.creditor.bank_account'),
        type: 'count',
      },
      {
        key: 'creditor.country',
        title: t('general.entity_fields.creditor.country'),
        type: 'count',
      },
      {
        key: 'debtor.debtorName',
        title: t('general.entity_fields.debtor.debtor_name'),
        type: 'count',
      },
      {
        key: 'debtor.name',
        title: t('general.entity_fields.debtor.name'),
        type: 'count',
      },
      {
        key: 'debtor.bankAccount',
        title: t('general.entity_fields.debtor.bank_account'),
        type: 'count',
      },
      {
        key: 'debtor.country',
        title: t('general.entity_fields.debtor.country'),
        type: 'count',
      },
      {
        key: 'instructedAmount',
        title: t('general.entity_fields.bank_statement_entry_detail_overview.instructed_amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'instructedAmountCurrency',
        title: t(
          'general.entity_fields.bank_statement_entry_detail_overview.instructed_amount_currency',
        ),
        type: 'count',
      },
      {
        key: 'transactionAmount',
        title: t('general.entity_fields.bank_statement_entry_detail_overview.transaction_amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'transactionAmountCurrency',
        title: t(
          'general.entity_fields.bank_statement_entry_detail_overview.transaction_amount_currency',
        ),
        type: 'count',
      },
      {
        key: 'debitAmount',
        title: t('general.entity_fields.bank_statement_entry_detail_overview.debit_amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'creditAmount',
        title: t('general.entity_fields.bank_statement_entry_detail_overview.credit_amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'lineCount',
        title: t('general.entity_fields.bank_statement_entry_detail_overview.line_count'),
        type: 'sum',
        dataType: 'number',
      },
    ],
    formatSettings: [
      { key: 'bankStatementEntry.bookingDate', format: 'yyyy-MM-dd', type: 'date' },
      { key: 'bankStatementEntry.valueDate', format: 'yyyy-MM-dd', type: 'date' },
      { key: 'debitAmount', format: 'N2' },
      { key: 'creditAmount', format: 'N2' },
      { key: 'transactionAmount', format: 'N2' },
      { key: 'instructedAmount', format: 'N2' },
      { key: 'lineCount', format: 'N0' },
    ],
  }

  const businessUnitOverviewDefaultPivotTableSettings: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountSubtype',
        title: t('general.entity_fields.chart_of_accounts.account_subtype'),
        type: 'count',
      },
      {
        key: 'enteredBy.businessUserName',
        title: t('general.entity_fields.entered_by.business_user_name'),
        type: 'count',
      },
      {
        key: 'enteredBy.department',
        title: t('general.entity_fields.entered_by.department'),
        type: 'count',
      },
      {
        key: 'source.sourceName',
        title: t('general.entity_fields.source.source_name'),
        type: 'count',
      },
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.business_unit_overview.fiscal_year'),
        type: 'count',
      },
      {
        key: 'period',
        title: t('general.entity_fields.business_unit_overview.period'),
        dataType: 'number',
        type: 'count',
      },
      {
        key: 'currency',
        title: t('general.entity_fields.business_unit_overview.currency'),
        type: 'count',
      },
      {
        key: 'debitCreditIndicator',
        title: t('general.entity_fields.business_unit_overview.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'debitAmount',
        title: t('general.entity_fields.business_unit_overview.debit_amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'creditAmount',
        title: t('general.entity_fields.business_unit_overview.credit_amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'amount',
        title: t('general.entity_fields.business_unit_overview.amount'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'jeLineCount',
        title: t('general.entity_fields.business_unit_overview.je_line_count'),
        type: 'sum',
        dataType: 'number',
      },
    ],
    formatSettings: [
      { key: 'debitAmount', format: 'N2' },
      { key: 'creditAmount', format: 'N2' },
      { key: 'amount', format: 'N2' },
      { key: 'jeLineCount', format: 'N0' },
    ],
  }

  const daysLagDefaultPivotTableSettings: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.glAccount',
        title: t('general.entity_fields.chart_of_accounts.gl_account'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountType',
        title: t('general.entity_fields.chart_of_accounts.account_type'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountSubtype',
        title: t('general.entity_fields.chart_of_accounts.account_subtype'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.category',
        title: t('general.entity_fields.chart_of_accounts.category'),
        type: 'count',
      },
      {
        key: 'source.sourceName',
        title: t('general.entity_fields.source.source_name'),
        type: 'count',
      },
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.days_lag.fiscal_year'),
        type: 'count',
      },
      {
        key: 'period',
        title: t('general.entity_fields.days_lag.period'),
        dataType: 'number',
        type: 'count',
      },
      {
        key: 'currency',
        title: t('general.entity_fields.days_lag.currency'),
        type: 'count',
      },
      {
        key: 'debitCreditIndicator',
        title: t('general.entity_fields.days_lag.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'debitAmount',
        title: t('general.entity_fields.days_lag.debit_amount'),
        type: 'sum',
      },
      {
        key: 'creditAmount',
        title: t('general.entity_fields.days_lag.credit_amount'),
        type: 'sum',
      },
      {
        key: 'amount',
        title: t('general.entity_fields.days_lag.amount'),
        type: 'sum',
      },
      {
        key: 'jeLineCount',
        title: t('general.entity_fields.days_lag.je_line_count'),
        type: 'sum',
      },
      {
        key: 'daysLag',
        title: t('general.entity_fields.days_lag.days_lag'),
        type: 'count',
      },
    ],
    formatSettings: [
      { key: 'debitAmount', format: 'N2' },
      { key: 'creditAmount', format: 'N2' },
      { key: 'amount', format: 'N2' },
      { key: 'jeLineCount', format: 'N0' },
    ],
  }

  const preparerOverviewDefaultPivotTableSettings: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.glAccount',
        title: t('general.entity_fields.chart_of_accounts.gl_account'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountType',
        title: t('general.entity_fields.chart_of_accounts.account_type'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountSubtype',
        title: t('general.entity_fields.chart_of_accounts.account_subtype'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.category',
        title: t('general.entity_fields.chart_of_accounts.category'),
        type: 'count',
      },
      {
        key: 'enteredBy.businessUserName',
        title: t('general.entity_fields.entered_by.business_user_name'),
        type: 'count',
      },
      {
        key: 'enteredBy.department',
        title: t('general.entity_fields.entered_by.department'),
        type: 'count',
      },
      {
        key: 'source.sourceName',
        title: t('general.entity_fields.source.source_name'),
        type: 'count',
      },
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.preparer_overview.fiscal_year'),
        type: 'count',
      },
      {
        key: 'period',
        title: t('general.entity_fields.preparer_overview.period'),
        dataType: 'number',
        type: 'count',
      },
      {
        key: 'currency',
        title: t('general.entity_fields.preparer_overview.currency'),
        type: 'count',
      },
      {
        key: 'debitCreditIndicator',
        title: t('general.entity_fields.preparer_overview.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'debitAmount',
        title: t('general.entity_fields.preparer_overview.debit_amount'),
        type: 'sum',
      },
      {
        key: 'creditAmount',
        title: t('general.entity_fields.preparer_overview.credit_amount'),
        type: 'sum',
      },
      {
        key: 'amount',
        title: t('general.entity_fields.preparer_overview.amount'),
        type: 'sum',
      },
      {
        key: 'jeLineCount',
        title: t('general.entity_fields.preparer_overview.je_line_count'),
        type: 'sum',
      },
    ],
    formatSettings: [
      { key: 'debitAmount', format: 'N2' },
      { key: 'creditAmount', format: 'N2' },
      { key: 'amount', format: 'N2' },
      { key: 'jeLineCount', format: 'N0' },
    ],
  }

  const sourceOverviewDefaultPivotTableSettings: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.glAccount',
        title: t('general.entity_fields.chart_of_accounts.gl_account'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountType',
        title: t('general.entity_fields.chart_of_accounts.account_type'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountSubtype',
        title: t('general.entity_fields.chart_of_accounts.account_subtype'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.category',
        title: t('general.entity_fields.chart_of_accounts.category'),
        type: 'count',
      },
      {
        key: 'source.sourceName',
        title: t('general.entity_fields.source.source_name'),
        type: 'count',
      },
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.source_overview.fiscal_year'),
        type: 'count',
      },
      {
        key: 'period',
        title: t('general.entity_fields.source_overview.period'),
        dataType: 'number',
        type: 'count',
      },
      {
        key: 'currency',
        title: t('general.entity_fields.source_overview.currency'),
        type: 'count',
      },
      {
        key: 'debitCreditIndicator',
        title: t('general.entity_fields.source_overview.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'debitAmount',
        title: t('general.entity_fields.source_overview.debit_amount'),
        type: 'sum',
      },
      {
        key: 'creditAmount',
        title: t('general.entity_fields.source_overview.credit_amount'),
        type: 'sum',
      },
      {
        key: 'amount',
        title: t('general.entity_fields.source_overview.amount'),
        type: 'sum',
      },
      {
        key: 'jeLineCount',
        title: t('general.entity_fields.source_overview.je_line_count'),
        type: 'sum',
      },
    ],
    formatSettings: [
      { key: 'debitAmount', format: 'N2' },
      { key: 'creditAmount', format: 'N2' },
      { key: 'amount', format: 'N2' },
      { key: 'jeLineCount', format: 'N0' },
    ],
  }

  const trialBalanceRollForwardDefaultPivotTableSettings: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.trial_balance_roll_forward.fiscal_year'),
        type: 'count',
      },
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'glAccount',
        title: t('general.entity_fields.trial_balance_roll_forward.gl_account'),
        type: 'count',
      },
      {
        key: 'accountType',
        title: t('general.entity_fields.trial_balance_roll_forward.account_type'),
        type: 'count',
      },
      {
        key: 'accountSubtype',
        title: t('general.entity_fields.trial_balance_roll_forward.account_subtype'),
        type: 'count',
      },
      {
        key: 'category',
        title: t('general.entity_fields.trial_balance_roll_forward.category'),
        type: 'count',
      },
      {
        key: 'amountBeginning',
        title: t('general.entity_fields.trial_balance_roll_forward.amount_beginning'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'amountEnding',
        title: t('general.entity_fields.trial_balance_roll_forward.amount_ending'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'movementJe',
        title: t('general.entity_fields.trial_balance_roll_forward.movement_je'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'movementTb',
        title: t('general.entity_fields.trial_balance_roll_forward.movement_tb'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'difference',
        title: t('general.entity_fields.trial_balance_roll_forward.difference'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'amountCurrency',
        title: t('general.entity_fields.trial_balance_roll_forward.amount_currency'),
        type: 'count',
      },
      {
        key: 'tbGlCount',
        title: t('general.entity_fields.trial_balance_roll_forward.tb_gl_count'),
        type: 'sum',
        dataType: 'number',
      },
      {
        key: 'jeLineCount',
        title: t('general.entity_fields.trial_balance_roll_forward.je_line_count'),
        type: 'sum',
        dataType: 'number',
      },
    ],
    formatSettings: [
      { key: 'amountBeginning', format: 'N2' },
      { key: 'amountEnding', format: 'N2' },
      { key: 'movementTb', format: 'N2' },
      { key: 'movementJe', format: 'N2' },
      { key: 'difference', format: 'N2' },
      { key: 'jeLineCount', format: 'N0' },
      { key: 'tbGlCount', format: 'N0' },
    ],
  }

  const openAccountsReceivables: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.glAccount',
        title: t('general.entity_fields.chart_of_accounts.gl_account'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountType',
        title: t('general.entity_fields.chart_of_accounts.account_type'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountSubtype',
        title: t('general.entity_fields.chart_of_accounts.account_subtype'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.category',
        title: t('general.entity_fields.chart_of_accounts.category'),
        type: 'count',
      },
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.open_accounts_receivable.fiscal_year'),
        type: 'count',
      },
      {
        key: 'reportDate',
        title: t('general.entity_fields.open_accounts_receivable.report_date'),
        type: 'count',
        dataType: 'date',
      },
      {
        key: 'customer.customerName',
        title: t('general.entity_fields.customer.customer_name'),
        type: 'count',
      },
      {
        key: 'debtorInMasterDataIndicator',
        title: t('general.entity_fields.open_accounts_receivable.debtor_in_master_data_indicator'),
        type: 'count',
      },
      {
        key: 'invoiceNumber',
        title: t('general.entity_fields.open_accounts_receivable.invoice_number'),
        type: 'count',
      },
      {
        key: 'transactionId',
        title: t('general.entity_fields.open_accounts_receivable.transaction_id'),
        type: 'count',
      },
      {
        key: 'transactionType',
        title: t('general.entity_fields.open_accounts_receivable.transaction_type'),
        type: 'count',
      },
      {
        key: 'description',
        title: t('general.entity_fields.open_accounts_receivable.description'),
        type: 'count',
      },
      {
        key: 'transactionDate',
        title: t('general.entity_fields.open_accounts_receivable.transaction_date'),
        type: 'count',
        dataType: 'date',
      },
      {
        key: 'transactionAgeDays',
        title: t('general.entity_fields.open_accounts_receivable.transaction_age_days'),
        type: 'count',
        dataType: 'number',
      },
      {
        key: 'transactionAgeDaysBucket',
        title: t('general.entity_fields.open_accounts_receivable.transaction_age_days_bucket'),
        type: 'count',
        dataType: 'string',
      },
      {
        key: 'transactionDueDate',
        title: t('general.entity_fields.open_accounts_receivable.transaction_due_date'),
        type: 'count',
        dataType: 'date',
      },
      {
        key: 'transactionDaysLate',
        title: t('general.entity_fields.open_accounts_receivable.transaction_days_late'),
        type: 'count',
        dataType: 'number',
      },
      {
        key: 'transactionDaysLateBucket',
        title: t('general.entity_fields.open_accounts_receivable.transaction_days_late_bucket'),
        type: 'count',
        dataType: 'string',
      },
      {
        key: 'amountOutstanding',
        title: t('general.entity_fields.open_accounts_receivable.amount_outstanding'),
        type: 'sum',
      },
      {
        key: 'amountOutstandingPerCurrentYear',
        title: t(
          'general.entity_fields.open_accounts_receivable.amount_outstanding_per_current_year',
        ),
        type: 'sum',
      },
      {
        key: 'amountOutstandingPerPriorYear',
        title: t(
          'general.entity_fields.open_accounts_receivable.amount_outstanding_per_prior_year',
        ),
        type: 'sum',
      },
      {
        key: 'amountOutstandingPerSubsequentYear',
        title: t(
          'general.entity_fields.open_accounts_receivable.amount_outstanding_per_subsequent_year',
        ),
        type: 'sum',
      },
      {
        key: 'debitCreditIndicator',
        title: t('general.entity_fields.open_accounts_receivable.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'currency',
        title: t('general.entity_fields.open_accounts_receivable.currency'),
        type: 'count',
      },
      {
        key: 'documentAmountOutstanding',
        title: t('general.entity_fields.open_accounts_receivable.document_amount_outstanding'),
        type: 'sum',
      },
      {
        key: 'documentCurrency',
        title: t('general.entity_fields.open_accounts_receivable.document_currency'),
        type: 'count',
      },
      {
        key: 'documentAmountRate',
        title: t('general.entity_fields.open_accounts_receivable.document_amount_rate'),
        type: 'count',
      },
      {
        key: 'foreignCurrencyIndicator',
        title: t('general.entity_fields.open_accounts_receivable.foreign_currency_indicator'),
        type: 'count',
      },
      {
        key: 'amountChangePerSubsequentYearIndicator',
        title: t(
          'general.entity_fields.open_accounts_receivable.amount_change_per_subsequent_year_indicator',
        ),
        type: 'count',
      },
      {
        key: 'amountChangePerSubsequentYearAmount',
        title: t(
          'general.entity_fields.open_accounts_receivable.amount_change_per_subsequent_year_amount',
        ),
        type: 'sum',
      },
      {
        key: 'amountChangePerSubsequentYearCurrency',
        title: t(
          'general.entity_fields.open_accounts_receivable.amount_change_per_subsequent_year_currency',
        ),
        type: 'count',
      },
      {
        key: 'userDefined01',
        title: t('general.entity_fields.open_accounts_receivable.user_defined01'),
        type: 'count',
      },
      {
        key: 'userDefined02',
        title: t('general.entity_fields.open_accounts_receivable.user_defined02'),
        type: 'count',
      },
      {
        key: 'userDefined03',
        title: t('general.entity_fields.open_accounts_receivable.user_defined03'),
        type: 'count',
      },
    ],
    formatSettings: [
      { key: 'reportDate', format: 'yyyy-MM-dd', type: 'date' },
      { key: 'amountOutstanding', format: 'N2' },
      { key: 'amountChangePerSubsequentYearAmount', format: 'N2' },
    ],
  }

  const openAccountsPayables: DefaultPivotTableSettings = {
    fieldMappings: [
      {
        key: 'businessUnit.businessUnitName',
        title: t('general.entity_fields.business_unit.business_unit_name'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.glAccount',
        title: t('general.entity_fields.chart_of_accounts.gl_account'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountType',
        title: t('general.entity_fields.chart_of_accounts.account_type'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.accountSubtype',
        title: t('general.entity_fields.chart_of_accounts.account_subtype'),
        type: 'count',
      },
      {
        key: 'chartOfAccounts.category',
        title: t('general.entity_fields.chart_of_accounts.category'),
        type: 'count',
      },
      {
        key: 'fiscalYear',
        title: t('general.entity_fields.open_accounts_payable.fiscal_year'),
        type: 'count',
      },
      {
        key: 'reportDate',
        title: t('general.entity_fields.open_accounts_payable.report_date'),
        type: 'count',
        dataType: 'date',
      },
      {
        key: 'supplier.supplierName',
        title: t('general.entity_fields.supplier.supplier_name'),
        type: 'count',
      },
      {
        key: 'creditorInMasterDataIndicator',
        title: t('general.entity_fields.open_accounts_payable.creditor_in_master_data_indicator'),
        type: 'count',
      },
      {
        key: 'invoiceNumber',
        title: t('general.entity_fields.open_accounts_payable.invoice_number'),
        type: 'count',
      },
      {
        key: 'transactionId',
        title: t('general.entity_fields.open_accounts_payable.transaction_id'),
        type: 'count',
      },
      {
        key: 'transactionType',
        title: t('general.entity_fields.open_accounts_payable.transaction_type'),
        type: 'count',
      },
      {
        key: 'description',
        title: t('general.entity_fields.open_accounts_payable.description'),
        type: 'count',
      },
      {
        key: 'transactionDate',
        title: t('general.entity_fields.open_accounts_payable.transaction_date'),
        type: 'count',
        dataType: 'date',
      },
      {
        key: 'transactionAgeDays',
        title: t('general.entity_fields.open_accounts_payable.transaction_age_days'),
        type: 'count',
        dataType: 'number',
      },
      {
        key: 'transactionAgeDaysBucket',
        title: t('general.entity_fields.open_accounts_payable.transaction_age_days_bucket'),
        type: 'count',
        dataType: 'string',
      },
      {
        key: 'transactionDueDate',
        title: t('general.entity_fields.open_accounts_payable.transaction_due_date'),
        type: 'count',
        dataType: 'date',
      },
      {
        key: 'transactionDaysLate',
        title: t('general.entity_fields.open_accounts_payable.transaction_days_late'),
        type: 'count',
        dataType: 'number',
      },
      {
        key: 'transactionDaysLateBucket',
        title: t('general.entity_fields.open_accounts_payable.transaction_days_late_bucket'),
        type: 'count',
        dataType: 'string',
      },
      {
        key: 'amountOutstanding',
        title: t('general.entity_fields.open_accounts_payable.amount_outstanding'),
        type: 'sum',
      },
      {
        key: 'amountOutstandingPerCurrentYear',
        title: t('general.entity_fields.open_accounts_payable.amount_outstanding_per_current_year'),
        type: 'sum',
      },
      {
        key: 'amountOutstandingPerPriorYear',
        title: t('general.entity_fields.open_accounts_payable.amount_outstanding_per_prior_year'),
        type: 'sum',
      },
      {
        key: 'amountOutstandingPerSubsequentYear',
        title: t(
          'general.entity_fields.open_accounts_payable.amount_outstanding_per_subsequent_year',
        ),
        type: 'sum',
      },
      {
        key: 'debitCreditIndicator',
        title: t('general.entity_fields.open_accounts_payable.debit_credit_indicator'),
        type: 'count',
      },
      {
        key: 'currency',
        title: t('general.entity_fields.open_accounts_payable.currency'),
        type: 'count',
      },
      {
        key: 'documentAmountOutstanding',
        title: t('general.entity_fields.open_accounts_payable.document_amount_outstanding'),
        type: 'sum',
      },
      {
        key: 'documentCurrency',
        title: t('general.entity_fields.open_accounts_payable.document_currency'),
        type: 'count',
      },
      {
        key: 'documentAmountRate',
        title: t('general.entity_fields.open_accounts_payable.document_amount_rate'),
        type: 'count',
      },
      {
        key: 'foreignCurrencyIndicator',
        title: t('general.entity_fields.open_accounts_payable.foreign_currency_indicator'),
        type: 'count',
      },
      {
        key: 'userDefined01',
        title: t('general.entity_fields.open_accounts_payable.user_defined01'),
        type: 'count',
      },
      {
        key: 'userDefined02',
        title: t('general.entity_fields.open_accounts_payable.user_defined02'),
        type: 'count',
      },
      {
        key: 'userDefined03',
        title: t('general.entity_fields.open_accounts_payable.user_defined03'),
        type: 'count',
      },
    ],
    formatSettings: [
      { key: 'reportDate', format: 'yyyy-MM-dd', type: 'date' },
      { key: 'amountOutstanding', format: 'N2' },
      { key: 'amountOutstandingPerCurrentYear', format: 'N2' },
      { key: 'amountOutstandingPerPriorYear', format: 'N2' },
      { key: 'amountOutstandingPerSubsequentYear', format: 'N2' },
    ],
  }

  return {
    bankStatementOverviewDefaultPivotTableSettings,
    businessUnitOverviewDefaultPivotTableSettings,
    daysLagDefaultPivotTableSettings,
    preparerOverviewDefaultPivotTableSettings,
    sourceOverviewDefaultPivotTableSettings,
    trialBalanceRollForwardDefaultPivotTableSettings,
    openAccountsReceivables,
    openAccountsPayables,
  }
}
