/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtPlugin } from '#app'
import UpdateLocaleCommand from '~/src/Application/Shared/Translation/Command/UpdateLocaleCommand'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default defineNuxtPlugin({
  parallel: true,
  hooks: {
    'i18n:localeSwitched': async ({ newLocale }) => {
      const commandBus = useService('commandBus')
      const httpClient = useService('httpClient')
      const userApiRepository = useService('userApiRepository')
      const userStoreRepository = useService('userStoreRepository')

      httpClient.setLocale(newLocale)

      await Promise.all([
        commandBus.dispatch(UpdateLocaleCommand.NAME, new UpdateLocaleCommand(newLocale)),
        userStoreRepository.isAuthenticated() && userStoreRepository.getUser().locale !== newLocale
          ? userApiRepository.changeUser(userStoreRepository.getUser(), {
            locale: newLocale,
          })
          : [],
      ])
    },
  },
})
