/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Dayjs, ManipulateType } from 'dayjs'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import InvalidDateException from '~/src/Domain/Shared/BetterDate/Exception/InvalidDateException'
import 'dayjs/locale/en'
import 'dayjs/locale/nl'

dayjs.extend(relativeTime)
dayjs.extend(weekOfYear)

export default abstract class AbstractBetterDate {
  public value: Dayjs

  public constructor(date: Dayjs | string | number | undefined = undefined) {
    const dateValue
      = date === undefined
        ? dayjs()
        : typeof date === 'string' || typeof date === 'number'
          ? dayjs(date)
          : date

    if (!dateValue.isValid()) {
      throw new InvalidDateException(`${date?.toString()} is not a valid date.`)
    }

    this.value = dateValue
  }

  public add(value: number, unit?: ManipulateType) {
    this.value = this.value.add(value, unit)

    return this
  }

  public getTime(): number {
    return this.value.valueOf()
  }

  public getUnix(): number {
    return this.value.unix()
  }

  public getYear(): number {
    return this.value.year()
  }

  public getMonth(): number {
    return this.value.month() + 1
  }

  public getDay(): number {
    return this.value.date()
  }

  public getWeek(): number {
    return this.value.week()
  }

  public format(format: string): string {
    return this.value.format(format)
  }
}
