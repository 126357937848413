/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */

import type CoaMappingDTO from '~/src/Domain/CoaMapping/CoaMappingDTO'
import { type CellData, CellType } from '~/src/Domain/Shared/Spreadsheet/Spreadsheet'
import t from '~/src/Infrastructure/Shared/Translation/t'

export default class CoaMappingDTOCollection {
  private spreadsheetHeaders: string[] = [
    t('components.coa_mapping.spreadsheet.fiscalYear'),
    t('components.coa_mapping.spreadsheet.businessUnitName'),
    t('components.coa_mapping.spreadsheet.glAccount'),
    t('components.coa_mapping.spreadsheet.beginningBalance'),
    t('components.coa_mapping.spreadsheet.endingBalance'),
    t('components.coa_mapping.spreadsheet.mutationBalance'),
    t('components.coa_mapping.spreadsheet.journalEntryMutation'),
    t('components.coa_mapping.spreadsheet.difference'),
    t('components.coa_mapping.spreadsheet.jeLineCount'),
    t('components.coa_mapping.spreadsheet.accountType'),
    t('components.coa_mapping.spreadsheet.accountSubtype'),
    t('components.coa_mapping.spreadsheet.category'),
  ]

  public constructor(public readonly collection: CoaMappingDTO[]) {}

  public isMappingFinished(): boolean {
    return this.collection.every((c) => c.isMapped())
  }

  public getMappingProgress(): string {
    return (
      (this.collection.filter((c) => c.isMapped()).length / this.collection.length)
      * 100
    ).toFixed(1)
  }

  public getSortedCollection() {
    return this.collection.sort(
      (a, b) =>
        a.glAccount.localeCompare(b.glAccount, undefined, { numeric: true })
        || a.businessUnitName.localeCompare(b.businessUnitName, undefined, { numeric: true })
        || a.fiscalYear.localeCompare(b.fiscalYear, undefined, { numeric: true }),
    )
  }

  /**
   *  {
   *    0: {
   *      0: {
   *        v: 'Hello World',
   *      },
   *    },
   *  },
   */
  public getMappingForSpreadsheet(): CellData {
    const cellData: CellData = { 0: {} }

    for (const [index, header] of this.spreadsheetHeaders.entries()) {
      cellData[0][index] = {
        v: header,
        s: {
          bl: 1,
        },
      }
    }

    for (const [index, value] of this.getSortedCollection().entries()) {
      cellData[index + 1] = {
        0: {
          v: value.fiscalYear,
          t: CellType.FORCE_STRING,
        },
        1: {
          v: value.businessUnitName,
        },
        2: {
          v: value.glAccount,
        },
        3: {
          v: value.beginningBalance?.toNumber(),
          t: CellType.NUMBER,
        },
        4: {
          v: value.endingBalance?.toNumber(),
          t: CellType.NUMBER,
        },
        5: {
          v: value.mutationBalance?.toNumber(),
          t: CellType.NUMBER,
        },
        6: {
          v: value.journalEntryMutation?.toNumber(),
          t: CellType.NUMBER,
        },
        7: {
          v: value.difference?.toNumber(),
          t: CellType.NUMBER,
        },
        8: {
          v: value.jeLineCount,
          t: CellType.NUMBER,
        },
        9: {
          v: value.accountType,
        },
        10: {
          v: value.accountSubtype,
        },
        11: {
          v: value.category,
        },
        12: {
          v: value.id,
        },
      }
    }

    return cellData
  }

  public getMappingsToUpdate(): {
    id: string
    accountType: string | undefined
    accountSubtype: string | undefined
    category: string | undefined
  }[] {
    return this.collection.map((m) => ({
      id: m.id,
      accountType: m.accountType,
      accountSubtype: m.accountSubtype,
      category: m.category,
    }))
  }

  public getNumberOfRows(): number {
    return this.collection.length
  }

  public getNumberOfColumns(): number {
    if (this.collection.length === 0) {
      return 0
    }

    return Object.keys(this.collection[0]).length
  }

  public equalsTo(dtoCollection: CoaMappingDTOCollection): boolean {
    return this.collection.every((c) =>
      dtoCollection.collection.some((dto) => c.id === dto.id && dto.equalsTo(c)),
    )
  }
}
