/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default (
  key: string,
  parameters: any[] | { [key: string]: any } | undefined = undefined,
): string => {
  const translator = useService('translator')

  return translator.trans(key, parameters)
}
