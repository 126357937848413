/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type FileIri from '~/src/Domain/DataRequest/File/FileIri'

export default class CreateDataRequestFileExportCommand implements CommandInterface {
  public static readonly NAME = 'CREATE_DATA_REQUEST_FILE_EXPORT_COMMAND'

  public constructor(public readonly file: FileIri) {}
}
