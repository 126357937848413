/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import type DataPivotStepResult from '~/src/Domain/WorkProgram/StepResult/DataPivotStepResult'
import type DataTableStepResult from '~/src/Domain/WorkProgram/StepResult/DataTableStepResult'

export default abstract class StepInterface {
  public abstract readonly slug: Slug
  public abstract readonly title: string
  public abstract readonly text: string
  public abstract hidden: boolean
  public abstract hiddenReason: string | undefined
  public abstract disabled: boolean
  public abstract stepResult: undefined | DataPivotStepResult | DataTableStepResult

  public abstract hide(hiddenReason: string, force: boolean): void
  public abstract show(): void
  public abstract equals(step: StepInterface): boolean
}
