/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { RouteLocationNormalized } from 'vue-router'
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import UserAuthenticatedEvent from '~/src/Domain/Identity/Event/UserAuthenticatedEvent'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useCookie from '~/src/UserInterface/App/composables/useCookie'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'

export function routeOption(
  to: RouteLocationNormalized,
  key: string,
  value: string | boolean,
): boolean {
  return key in to.meta && to.meta[key] === value
}

/**
 * This middleware must be called on every page
 *
 * Prerequisites for this middleware:
 * - Of all middlewares, this one must be called first
 *
 * This middleware checks if the current user is a regular user, and should be authenticated:
 * - If the route key 'auth' is one of 'false' or 'dataRequest', the process continues
 * - If the user is not authenticated, and the 'auth' key is 'guest', the process continues
 * - If the user is not authenticated, and the 'auth' key is not 'guest', the user must be redirected to the login page
 * - If the user is authenticated, and the 'auth' key is 'guest', the user is logged out, the process continues
 * - If the user is authenticated, and the 'auth' key is not 'guest', the process continues
 *
 * It is possible that in state the user is not authenticated, but the user has a cookie 'authenticated' with the value 'true'.
 * This indicates the user has the necessary cookies to beeing authenticated, but it has just 'forgotten' it. This
 * happens when you refresh the page, then all state is reset. In that case, the user state must be populated.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[AUTH] Middleware fired')

  // return when auth is not required
  if (routeOption(to, 'auth', false)) {
    logger.info('[AUTH] Auth is not required')

    return
  }

  // data request auth should be handled via different middleware
  if (routeOption(to, 'auth', 'dataRequest')) {
    logger.info('[AUTH] Auth dataRequest is handled by different middleware')

    return
  }

  const nuxtApp = useNuxtApp()
  const userApiRepository = useService('userApiRepository')
  const eventBus = useService('eventBus')
  const { isAuthenticated } = useUserState()
  const security = useService('security')

  const authenticatedCookie = useCookie<boolean>('authenticated')

  if (!isAuthenticated.value && authenticatedCookie.value === true) {
    try {
      logger.info('[AUTH] Trying to authenticate')

      await userApiRepository.findUser()
      await eventBus.dispatch(UserAuthenticatedEvent.NAME, new UserAuthenticatedEvent())
    } catch {
      logger.info('[AUTH] Authentication failed, logging out')

      await security.logout()
      // eslint-disable-next-line ts/no-unsafe-argument
      return navigateTo(nuxtApp.$localePath('identity-Login'))
    }
  }

  // Make sure to always logout users that reach guest pages, for a clean state
  if (routeOption(to, 'auth', 'guest') && isAuthenticated.value) {
    logger.info(`[AUTH] User is authenticated but page ${to.path} requires a guest. Logging out`)

    await security.logout()
    return
  }

  // Allow non-logged in users to solely access guest pages, or send the user to the login page
  if (!isAuthenticated.value) {
    if (routeOption(to, 'auth', 'guest')) {
      logger.info('[AUTH] User is already a guest')

      return
    }

    logger.info('[AUTH] Redirecting user to the login page')
    const redirectCookie = useCookie<string>('redirect', false)
    redirectCookie.value = to.path

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(nuxtApp.$localePath('identity-Login'))
  }
})
