/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import DeleteWorkProgramCommand from '~/src/Application/WorkProgram/Command/DeleteWorkProgramCommand'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(DeleteWorkProgramCommand.NAME, 'deleteWorkProgramCommandHandler')
export default class DeleteWorkProgramCommandHandler extends CommandHandlerInterface<DeleteWorkProgramCommand> {
  private readonly workProgramApiRepository: WorkProgramApiRepositoryInterface

  public constructor({ workProgramApiRepository }: Services) {
    super()
    this.workProgramApiRepository = workProgramApiRepository
  }

  public async handle(command: DeleteWorkProgramCommand): Promise<void> {
    await this.workProgramApiRepository.deleteWorkProgram(command.workProgram)
  }
}
