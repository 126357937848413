/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type ParameterName from '~/src/Domain/Engagement/Parameter/ParameterName'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'

export default class AddParameterCommand implements CommandInterface {
  public static readonly NAME = 'ADD_PARAMETER_ENGAGEMENT_COMMAND'

  public constructor(
    public readonly phase: PhaseIri,
    public readonly name: ParameterName,
    public readonly values: Record<string, any>,
  ) {}
}
