/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import { FieldSorterDirection } from '~/src/Domain/Shared/Filter/FieldSorterDirection'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import { bankBookingDateRangeFilterField } from '~/src/Domain/WorkProgram/Fixtures/General/FilterFields'
import FiscalYearCurrentEndParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentEndParameterDefinition'
import FiscalYearCurrentStartParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentStartParameterDefinition'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import DataTableResultReference from '~/src/Domain/WorkProgram/ResultReference/DataTableResultReference'
import { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import StepSection from '~/src/Domain/WorkProgram/StepSection'
import StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'
import ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'
import WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class BankPayments20055194 implements WorkProgramFixtureInterface {
  public create(): () => WorkProgramProjection {
    return () =>
      new WorkProgramProjection(
        new Slug('bank-payments'),
        t('work_programs.20055194.bank_payments.title'),
        t('work_programs.20055194.bank_payments.text'),
        new StepSectionCollection([
          new StepSection(
            'intro',
            t('work_programs.20055194.bank_payments.sections.section_intro.lbl_navigation'),
            [
              new IntroStep(
                new Slug('intro'),
                t('work_programs.20055194.bank_payments.steps.step_intro.title'),
                t('work_programs.20055194.bank_payments.steps.step_intro.text'),
              ),
            ],
          ),
          new StepSection(
            'accounts',
            t('work_programs.20055194.bank_payments.sections.section_accounts.lbl_navigation'),
            [
              new ResultStep(
                new Slug('account-different-names'),
                t('work_programs.20055194.bank_payments.steps.step_account_different_names.title'),
                t('work_programs.20055194.bank_payments.steps.step_account_different_names.text'),
                new DataTableResultReference(
                  FieldsResultReference.BANK_STATEMENT_ENTRY_MULTIPLE_CUSTOMER_NAMES,
                  RepositoryReference.BANK_STATEMENT_ENTRY_MULTIPLE_CUSTOMER,
                  [],
                ),
              ),
              new ResultStep(
                new Slug('same-name-different-accounts'),
                t(
                  'work_programs.20055194.bank_payments.steps.step_same_name_different_accounts.title',
                ),
                t('work_programs.20055194.bank_payments.steps.step_same_name_different_accounts.text'),
                new DataTableResultReference(
                  FieldsResultReference.BANK_STATEMENT_ENTRY_MULTIPLE_BANK_ACCOUNT,
                  RepositoryReference.BANK_STATEMENT_ENTRY_MULTIPLE_BANK_ACCOUNT,
                  [],
                ),
              ),
              new ResultStep(
                new Slug('no-information'),
                t('work_programs.20055194.bank_payments.steps.step_no_contra_account.title'),
                t('work_programs.20055194.bank_payments.steps.step_no_contra_account.text'),
                new DataTableResultReference(
                  FieldsResultReference.BANK,
                  RepositoryReference.BANK_STATEMENT_ENTRY_DETAIL,
                  [
                    bankBookingDateRangeFilterField(),
                    new FilterField(
                      'bankStatementEntry.debitCreditIndicator',
                      t('general.entity_fields.bank_statement_entry.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'D')],
                    ),
                    new FilterField(
                      'debtor.debtorName',
                      t('general.entity_fields.debtor.debtor_name'),
                      [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
                      true,
                      true,
                    ),
                    new FilterField(
                      'creditor.creditorName',
                      t('general.entity_fields.creditor.creditor_name'),
                      [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
                      true,
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'timing',
            t('work_programs.20055194.bank_payments.sections.section_timing.lbl_navigation'),
            [
              new ResultStep(
                new Slug('booked-in-weekends'),
                t('work_programs.20055194.bank_payments.steps.step_booked_weekends.title'),
                t('work_programs.20055194.bank_payments.steps.step_booked_weekends.text'),
                new DataTableResultReference(
                  FieldsResultReference.BANK,
                  RepositoryReference.BANK_STATEMENT_ENTRY_DETAIL,
                  [
                    bankBookingDateRangeFilterField(),
                    new FilterField(
                      'bankStatementEntry.debitCreditIndicator',
                      t('general.entity_fields.bank_statement_entry.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'D')],
                      true,
                      true,
                    ),
                    new FilterField(
                      'bankStatementEntry.dateDimension.workdayWeekendText',
                      t('general.entity_fields.date_dimension.workday_weekend_text'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'Weekend')],
                      true,
                      true,
                    ),
                  ],
                  [
                    {
                      key: 'bankStatementEntry.amount',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('booked-on-bank-holidays'),
                t('work_programs.20055194.bank_payments.steps.step_booked_bank_holidays.title'),
                t('work_programs.20055194.bank_payments.steps.step_booked_bank_holidays.text'),
                new DataTableResultReference(
                  FieldsResultReference.BANK,
                  RepositoryReference.BANK_STATEMENT_ENTRY_DETAIL,
                  [
                    bankBookingDateRangeFilterField(),
                    new FilterField(
                      'bankStatementEntry.debitCreditIndicator',
                      t('general.entity_fields.bank_statement_entry.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'D')],
                      true,
                      true,
                    ),
                    new FilterField(
                      'bankStatementEntry.dateDimension.holidayIndicator',
                      t('general.entity_fields.date_dimension.holiday_text'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'Holiday')],
                      true,
                      true,
                    ),
                  ],
                  [
                    {
                      key: 'bankStatementEntry.amount',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'description',
            t('work_programs.20055194.bank_payments.sections.section_description.lbl_navigation'),
            [
              new ResultStep(
                new Slug('payments-unusual-descriptions'),
                t('work_programs.20055194.bank_payments.steps.step_unusual_descriptions.title'),
                t('work_programs.20055194.bank_payments.steps.step_unusual_descriptions.text'),
                new DataTableResultReference(
                  FieldsResultReference.BANK,
                  RepositoryReference.BANK_STATEMENT_ENTRY_DETAIL,
                  [
                    bankBookingDateRangeFilterField(),
                    new FilterField(
                      'bankStatementEntry.debitCreditIndicator',
                      t('general.entity_fields.bank_statement_entry.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'D')],
                      true,
                      true,
                    ),
                    new FilterField(
                      'remittanceInformation',
                      t('general.entity_fields.bank_statement_entry_detail.remittance_information'),
                      [new FilterFieldFilter(FilterClass.UNUSUAL_DESCRIPTION_FILTER, true)],
                      true,
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),

          // Finalize section does not need steps, as it has a specific page
          new StepSection(
            'finalize-export',
            t(
              'work_programs.20055194.bank_payments.sections.section_finalize_export.lbl_navigation',
            ),
            [
              new FinalStep(
                new Slug('finalize'),
                t('work_programs.20055194.bank_payments.steps.step_finalize.title'),
                t('work_programs.20055194.bank_payments.steps.step_finalize.text'),
              ),
            ],
          ),
        ]),
        [
          new ParameterProjection(new FiscalYearCurrentStartParameterDefinition()),
          new ParameterProjection(new FiscalYearCurrentEndParameterDefinition()),
        ],
        [AnalysisType.BANK],
        new ChamberOfCommerceId('20055194'),
      )
  }
}
