/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum Operator {
  // text search
  EQUALS = '=',
  LIKE = 'like',

  UNUSUAL_DESCRIPTION = 'ud',

  // multi value search
  IN = 'in',
  AND_IN = 'and',
  NOT_IN = 'not',

  // numbers
  MIN = 'min',
  MAX = 'max',

  LOWER_THAN = 'lt',
  LOWER_THAN_OR_EQUAL = 'lte',
  GREATER_THAN = 'gt',
  GREATER_THAN_ABSOLUTE = 'gta',
  GREATER_THAN_OR_EQUAL = 'gte',

  WHOLE_NUMBER = 'wn',

  THOUSANDS = 'th',

  // empty fields
  EXISTS = 'exists',
  OR_EXISTS = 'or_exists',
}
