/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AcceptCoaMappingWithErrorsCommand from '~/src/Application/CoaMapping/Command/AcceptCoaMappingWithErrorsCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type CoaMappingApiRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(AcceptCoaMappingWithErrorsCommand.NAME, 'acceptCoaMappingWithErrorsCommandHandler')
export default class AcceptCoaMappingWithErrorsCommandHandler extends CommandHandlerInterface<AcceptCoaMappingWithErrorsCommand> {
  private readonly coaMappingApiRepository: CoaMappingApiRepositoryInterface

  public constructor({ coaMappingApiRepository }: Services) {
    super()
    this.coaMappingApiRepository = coaMappingApiRepository
  }

  public async handle(command: AcceptCoaMappingWithErrorsCommand): Promise<void> {
    await this.coaMappingApiRepository.acceptWithErrors(command.coaMapping)
  }
}
