/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { unix } from 'dayjs'
import AbstractBetterDate from '~/src/Domain/Shared/BetterDate/AbstractBetterDate'
import type { StringableInterface } from '~/src/Domain/Shared/StringableInterface'

export default class BetterDateTime extends AbstractBetterDate implements StringableInterface {
  public static fromUnixTimestamp(seconds: number) {
    return new this(unix(seconds))
  }

  public diffInHours(date: BetterDateTime): number {
    return this.value.diff(date.value, 'h')
  }

  public fromNow(locale: string): string {
    return this.value.locale(locale).fromNow()
  }

  public override toString(): string {
    return this.value.format('YYYY-MM-DD HH:mm:ss')
  }
}
