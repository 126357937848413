/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import KebabCase from '~/src/Domain/Shared/Utils/KebabCase'

export default class Slug {
  public readonly PATTERN_REGEX = '^([a-z0-9]*)(-[a-z0-9]+)*$'

  public constructor(public readonly slug: string) {
    const regex = new RegExp(this.PATTERN_REGEX)
    if (slug === '' || !regex.test(slug)) {
      throw new Error(`A slug should be a non-empty kebab-case string, got ${slug}.`)
    }
  }

  public static fromString(string: string): Slug {
    return new Slug(KebabCase(string))
  }

  public toString(): string {
    return this.slug
  }

  public equals(slug: Slug): boolean {
    return this.slug === slug.toString()
  }
}
