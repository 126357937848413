/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum ExportType {
  COA_MAPPING = 'coa_mapping',
  DATA_REQUEST_ARCHIVE = 'data_request_archive',
  DATA_REQUEST_FILE = 'data_request_file',
  ENGAGEMENT_OVERVIEW = 'engagement_overview',
  MY_ENGAGEMENTS = 'my_engagements',
  OTHER_SIDE_OF_JOURNAL_ENTRIES = 'other_side_of_journal_entries',
  TRANSACTIONAL_DATA_PIVOT = 'transactional_data_pivot',
  TRANSACTIONAL_DATA_TABLE = 'transactional_data_table',
  TWO_WAY_CORRELATIONS = 'two_way_correlations',
  WORK_PROGRAM = 'work_program',
}
