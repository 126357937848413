/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DuplicateDataRequestCommand from '~/src/Application/DataRequest/Command/DuplicateDataRequestCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type DataRequestApiRepositoryInterface from '~/src/Domain/DataRequest/DataRequestApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(DuplicateDataRequestCommand.NAME, 'duplicateDataRequestCommandHandler')
export default class DuplicateDataRequestCommandHandler extends CommandHandlerInterface<DuplicateDataRequestCommand> {
  private readonly dataRequestApiRepository: DataRequestApiRepositoryInterface

  public constructor({ dataRequestApiRepository }: Services) {
    super()
    this.dataRequestApiRepository = dataRequestApiRepository
  }

  public async handle(command: DuplicateDataRequestCommand): Promise<void> {
    await this.dataRequestApiRepository.duplicate(command.dataRequest, command.message)
  }
}
