/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import UserHasNoOrganisationException from '~/src/Domain/Organisation/Exception/UserHasNoOrganisationException'
import type { OrganisationProps } from '~/src/Domain/Organisation/Organisation'
import Organisation from '~/src/Domain/Organisation/Organisation'

const organisationStore = defineStore('organisation', () => {
  const organisation = ref<OrganisationProps | undefined>(undefined)

  const getOrganisation = () => {
    if (organisation.value === undefined) {
      throw new UserHasNoOrganisationException('User has no organisation in state.')
    }

    return computed(() => Organisation.prototype.fromJSON(organisation.value as OrganisationProps))
  }

  const hasOrganisation = computed(() => organisation.value !== undefined)

  const updateOrganisation = (o: Organisation) => {
    if (organisation.value === undefined || organisation.value['@id'] !== o['@id'].toString()) {
      return
    }

    organisation.value = o.toJSON()
  }

  const activate = (o: Organisation) => {
    organisation.value = o.toJSON()
  }

  return {
    organisation: organisation as Readonly<Ref<OrganisationProps | undefined>>,
    getOrganisation,
    hasOrganisation,
    updateOrganisation,
    activate,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(organisationStore, import.meta.hot))
}

export default organisationStore
