/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import FilterNotFoundException from '~/src/Domain/Shared/Filter/Exception/FilterNotFoundException'
import AndEqualsFilter from '~/src/Domain/Shared/Filter/Filter/AndEqualsFilter'
import ApiValueFilter from '~/src/Domain/Shared/Filter/Filter/ApiValueFilter'
import BooleanFilter from '~/src/Domain/Shared/Filter/Filter/BooleanFilter'
import EnumFilter from '~/src/Domain/Shared/Filter/Filter/EnumFilter'
import EqualsFilter from '~/src/Domain/Shared/Filter/Filter/EqualsFilter'
import ExistsFilter from '~/src/Domain/Shared/Filter/Filter/ExistsFilter'
import GreaterThanAbsoluteFilter from '~/src/Domain/Shared/Filter/Filter/GreaterThanAbsoluteFilter'
import GreaterThanFilter from '~/src/Domain/Shared/Filter/Filter/GreaterThanFilter'
import GreaterThanOrEqualFilter from '~/src/Domain/Shared/Filter/Filter/GreaterThanOrEqualFilter'
import LowerThanFilter from '~/src/Domain/Shared/Filter/Filter/LowerThanFilter'
import LowerThanOrEqualFilter from '~/src/Domain/Shared/Filter/Filter/LowerThanOrEqualFilter'
import MaxFilter from '~/src/Domain/Shared/Filter/Filter/MaxFilter'
import MinFilter from '~/src/Domain/Shared/Filter/Filter/MinFilter'
import MultiApiValueFilter from '~/src/Domain/Shared/Filter/Filter/MultiApiValueFilter'
import NotApiValueFilter from '~/src/Domain/Shared/Filter/Filter/NotApiValueFilter'
import NotEqualsFilter from '~/src/Domain/Shared/Filter/Filter/NotEqualsFilter'
import PartialTextFilter from '~/src/Domain/Shared/Filter/Filter/PartialTextFilter'
import ThousandsFilter from '~/src/Domain/Shared/Filter/Filter/ThousandsFilter'
import UnusualDescriptionFilter from '~/src/Domain/Shared/Filter/Filter/UnusualDescriptionFilter'
import WholeNumberFilter from '~/src/Domain/Shared/Filter/Filter/WholeNumberFilter'

export enum FilterClass {
  API_VALUE_FILTER = 'ApiValueFilter',
  NOT_API_VALUE_FILTER = 'NotApiValueFilter',
  BOOLEAN_FILTER = 'BooleanFilter',
  MULTI_API_VALUE_FILTER = 'MultiApiValueFilter',
  ENUM_FILTER = 'EnumFilter',
  EQUALS_FILTER = 'EqualsFilter',
  EXISTS_FILTER = 'ExistsFilter',
  GREATER_THAN_ABSOLUTE_FILTER = 'GreaterThanAbsoluteFilter',
  GREATER_THAN_FILTER = 'GreaterThanFilter',
  GREATER_THAN_OR_EQUAL_FILTER = 'GreaterThanOrEqualFilter',
  LOWER_THAN_FILTER = 'LowerThanFilter',
  LOWER_THAN_OR_EQUAL_FILTER = 'LowerThanOrEqualFilter',
  MAX_FILTER = 'MaxFilter',
  MIN_FILTER = 'MinFilter',
  NOT_EQUALS_FILTER = 'NotEqualsFilter',
  AND_EQUALS_FILTER = 'AndEqualsFilter',
  PARTIAL_TEXT_FILTER = 'PartialTextFilter',
  UNUSUAL_DESCRIPTION_FILTER = 'UnusualDescriptionFilter',
  WHOLE_NUMBER_FILTER = 'WholeNumberFilter',
  THOUSANDS = 'ThousandsFilter',
}

export default function FilterFactory(filterClass: FilterClass) {
  switch (filterClass) {
    case FilterClass.API_VALUE_FILTER:
      return ApiValueFilter
    case FilterClass.NOT_API_VALUE_FILTER:
      return NotApiValueFilter
    case FilterClass.BOOLEAN_FILTER:
      return BooleanFilter
    case FilterClass.MULTI_API_VALUE_FILTER:
      return MultiApiValueFilter
    case FilterClass.ENUM_FILTER:
      return EnumFilter
    case FilterClass.EQUALS_FILTER:
      return EqualsFilter
    case FilterClass.EXISTS_FILTER:
      return ExistsFilter
    case FilterClass.GREATER_THAN_ABSOLUTE_FILTER:
      return GreaterThanAbsoluteFilter
    case FilterClass.GREATER_THAN_FILTER:
      return GreaterThanFilter
    case FilterClass.GREATER_THAN_OR_EQUAL_FILTER:
      return GreaterThanOrEqualFilter
    case FilterClass.LOWER_THAN_FILTER:
      return LowerThanFilter
    case FilterClass.LOWER_THAN_OR_EQUAL_FILTER:
      return LowerThanOrEqualFilter
    case FilterClass.MAX_FILTER:
      return MaxFilter
    case FilterClass.MIN_FILTER:
      return MinFilter
    case FilterClass.NOT_EQUALS_FILTER:
      return NotEqualsFilter
    case FilterClass.AND_EQUALS_FILTER:
      return AndEqualsFilter
    case FilterClass.PARTIAL_TEXT_FILTER:
      return PartialTextFilter
    case FilterClass.UNUSUAL_DESCRIPTION_FILTER:
      return UnusualDescriptionFilter
    case FilterClass.WHOLE_NUMBER_FILTER:
      return WholeNumberFilter
    case FilterClass.THOUSANDS:
      return ThousandsFilter
    default:
      throw new FilterNotFoundException(
        `Could not create filter for FilterClass ${String(filterClass)}`,
      )
  }
}
