/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { Hookable } from 'hookable'
import type QueryBusInterface from '~/src/Application/Shared/MessageBus/QueryBusInterface'
import type QueryHandlerInterface from '~/src/Application/Shared/MessageBus/QueryHandlerInterface'
import type QueryInterface from '~/src/Application/Shared/MessageBus/QueryInterface'
import type LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class QueryBus extends Hookable implements QueryBusInterface {
  private handlers: Record<string, QueryHandlerInterface<QueryInterface, any>> = {}
  private readonly logger: LoggerInterface

  public constructor({ logger }: Services) {
    super()
    this.logger = logger
  }

  public async dispatch<T extends QueryInterface, Y>(queryName: string, query: T): Promise<Y> {
    this.logger.info(`[${queryName}] Executing query`)

    if (!(queryName in this.handlers)) {
      throw new Error(`[${queryName}] No handler registered for  ${queryName}`)
    }

    return (await this.handlers[queryName].handle(query)) as Y
  }

  public registerHandler<T extends QueryInterface, Y>(
    queryName: string,
    handler: QueryHandlerInterface<T, Y>,
  ): void {
    this.handlers[queryName] = handler
  }
}
