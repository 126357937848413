/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class IngMijnZakelijkDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.ING_MIJN_ZAKELIJK === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.ING_MIJN_ZAKELIJK),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.ing_zakelijk.pages.xml_file.title'),
          '',
          [
            new Alert(
              t('components.data_request.ing_zakelijk.pages.intro.alert_background.message'),
              t('components.data_request.ing_zakelijk.pages.intro.alert_background.heading'),
              'bell',
            ),
            new Paragraph([
              t(
                'components.data_request.ing_zakelijk.pages.xml_file.tutorial_how_to_download_file',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.accounts.click_download_button'),
                  new Badge(15, undefined, 43, undefined),
                ),
              ],
              '/img/data-request/ing_zakelijk/accounts-overview.png',
            ),
            new Paragraph([
              t(
                'components.data_request.ing_zakelijk.pages.xml_file.paragraph_context_menu_is_opened',
              ),
              t(
                'components.data_request.ing_zakelijk.pages.xml_file.paragraph_download_banking_file',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.accounts.select_account_type'),
                  new Badge(16, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.accounts.select_all_accounts'),
                  new Badge(26, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.accounts.select_period'),
                  new Badge(35, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.accounts.select_camt053'),
                  new Badge(47, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.accounts.click_download'),
                  new Badge(90, undefined, 43, undefined),
                ),
              ],
              '/img/data-request/ing_zakelijk/download-transactions.png', // width 40%
            ),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.saving_accounts.select_saving_accounts'),
                  new Badge(16, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.ing_zakelijk.checklist.saving_accounts.select_all_accounts_or_desired_accounts',
                  ),
                  new Badge(26, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.saving_accounts.select_period'),
                  new Badge(35, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.saving_accounts.select_camt053'),
                  new Badge(47, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_zakelijk.checklist.saving_accounts.click_download'),
                  new Badge(90, undefined, 43, undefined),
                ),
              ],
              '/img/data-request/ing_zakelijk/download-saving-accounts-transactions.png', // width 40%
            ),
            new FileUploader(new GenericFileType(), ['application/xml', 'text/xml']),
          ],
        ),
      ]),
    )
  }
}
