/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import type DataRequestCollection from '~/src/Domain/DataRequest/DataRequestCollection'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import type DataRequestStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestStoreRepositoryInterface'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import DataRequestStore from '~/src/UserInterface/DataRequest/store/DataRequestStore'

export default class DataRequestStoreRepository implements DataRequestStoreRepositoryInterface {
  private readonly store: ReturnType<typeof DataRequestStore>

  public constructor() {
    this.store = DataRequestStore(pinia)
  }

  public getDataRequests(): DataRequestCollection {
    return this.store.dataRequestCollection
  }

  public updateDataRequest(dataRequest: DataRequest): void {
    return this.store.updateDataRequest(dataRequest)
  }

  public deleteDataRequest(dataRequest: DataRequestIri): void {
    this.store.deleteDataRequest(dataRequest)
  }

  public markState(dataRequests: DataRequest[]): void {
    this.store.markState(dataRequests)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
