/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import FileTypeInterface from '~/src/Domain/DataRequest/File/FileType/FileTypeInterface'
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'

export default class GenericFileType extends FileTypeInterface {
  public get name(): string {
    return 'generic'
  }

  public supports(analysisType: AnalysisType): boolean {
    return analysisType === AnalysisType.GENERIC
  }
}
