/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class WorkProgramFixtureFactory {
  private readonly ANALYTIKAL_COC_ID = new ChamberOfCommerceId('74123319')
  private readonly fixtures: (() => WorkProgramProjection)[]

  public constructor(fixtureDefinitions: WorkProgramFixtureInterface[]) {
    this.fixtures = fixtureDefinitions.map((f) => f.create())
  }

  public createForOrganisation(chamberOfCommerceId: ChamberOfCommerceId): WorkProgramProjection[] {
    return [...this.fixtures]
      .map((f) => f())
      .filter(
        (f) =>
          f.chamberOfCommerceId === undefined
          || f.chamberOfCommerceId.equals(chamberOfCommerceId)
          || chamberOfCommerceId.equals(this.ANALYTIKAL_COC_ID),
      )
  }
}
