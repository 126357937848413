/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import type AuditTeamMember from '~/src/Domain/Engagement/AuditTeamMember'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import Uuid from '~/src/Domain/Shared/Identifier/Uuid'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import type StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import type WorkProgram from '~/src/Domain/WorkProgram/WorkProgram'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import type ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'

export default class WorkProgramProjection {
  public readonly id: string = Uuid.v7()
  public iri: WorkProgramIri | undefined = undefined
  public isStarted = false
  public description: string | undefined = undefined
  public startedOn: BetterDateTime | undefined = undefined
  public startedBy: AuditTeamMember | undefined = undefined
  public lastModifiedOn: BetterDateTime | undefined = undefined
  public finished = false
  public phase: PhaseIri | undefined = undefined

  public constructor(
    public readonly slug: Slug,
    public readonly title: string,
    public readonly text: string,
    public readonly stepSectionCollection: StepSectionCollection,
    public readonly parameters: ParameterProjection[],
    public readonly analysisTypes: AnalysisType[],
    public readonly chamberOfCommerceId: ChamberOfCommerceId | undefined = undefined,
  ) {}

  public get hasStepWithResult(): boolean {
    return this
      .stepSectionCollection
      .stepSections
      .flatMap((s) => s.getResultSteps())
      .some((s) => !s.hidden && s.stepResult !== undefined)
  }

  public canBeFinished(): boolean {
    return (
      this.finished === false
      && this
        .stepSectionCollection
        .stepSections
        .flatMap((s) => s.getResultSteps())
        .filter((s) => s.hidden !== true)
        .every((s) => s.stepResult !== undefined)
    )
  }

  public getNumberOfStepsWitResult(): number {
    return this
      .stepSectionCollection
      .stepSections
      .flatMap((s) => s.getResultSteps())
      .filter((s) => s.hidden !== true && s.stepResult !== undefined)
      .length
  }

  public getNumberOfVisibleSteps(): number {
    return this
      .stepSectionCollection
      .stepSections
      .flatMap((s) => s.getResultSteps())
      .filter((s) => s.hidden !== true)
      .length
  }

  public getStepsWithoutResult(): StepInterface[] {
    return this
      .stepSectionCollection
      .stepSections
      .flatMap((s) => s.getResultSteps())
      .filter((s) => s.hidden !== true && s.stepResult === undefined)
  }

  public populateFromDomainModel(workProgram: WorkProgram) {
    if (!this.slug.equals(workProgram.slug)) {
      throw new Error(
        `Expecting a model with slug ${this.slug.toString()} but got ${workProgram.slug.toString()}`,
      )
    }

    this.iri = workProgram['@id']
    this.phase = workProgram.phase
    this.isStarted = true
    this.description = workProgram.description
    this.startedOn = workProgram.startedOn
    this.startedBy = workProgram.startedBy
    this.lastModifiedOn = workProgram.lastModifiedOn
    this.finished = workProgram.finished

    for (const section of this.stepSectionCollection.stepSections) {
      for (const step of section.getAllSteps()) {
        const foundStep = workProgram.steps.find((s) => s.slug.equals(step.slug))
        if (foundStep === undefined) {
          continue
        }

        step.hidden = foundStep.hidden
        step.hiddenReason = foundStep.hiddenReason
        step.stepResult = foundStep.stepResult
      }
    }

    for (const parameter of this.parameters) {
      const foundParameter = workProgram.parameters.find((p) =>
        p.name.equalsTo(parameter.parameterDefinition.getName()),
      )
      if (foundParameter === undefined) {
        continue
      }

      parameter.filter.value = foundParameter.value
    }
  }

  public hasPreviousStep(step: StepInterface): boolean {
    return this.stepSectionCollection.hasPreviousStep(step)
  }

  public getPreviousStep(step: StepInterface): StepInterface {
    return this.stepSectionCollection.getPreviousStep(step)
  }

  public hasNextStep(step: StepInterface): boolean {
    return this.stepSectionCollection.hasNextStep(step)
  }

  public getNextStep(step: StepInterface): StepInterface {
    return this.stepSectionCollection.getNextStep(step)
  }

  public getFirstStep(): StepInterface {
    return this.stepSectionCollection.getFirstStep()
  }

  public isLastStep(step: StepInterface): boolean {
    return this.stepSectionCollection.getLastStep().equals(step)
  }
}
