/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'

export default class CreateTransactionalDataPivotExportCommand implements CommandInterface {
  public static readonly NAME = 'CREATE_TRANSACTIONAL_DATA_PIVOT_EXPORT_COMMAND'

  public constructor(
    public readonly iri: GenericIri,
    public readonly extension: 'csv' | 'xlsx',
    public readonly rows: string[],
    public readonly columns: string[],
    public readonly values: { field: string, valueType: 'count' | 'sum' }[],
    public readonly url: string,
  ) {}
}
