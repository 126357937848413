/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { AuditTeamMemberProps } from '~/src/Domain/Engagement/AuditTeamMember'
import AuditTeamMember from '~/src/Domain/Engagement/AuditTeamMember'
import type { BankAccountProps } from '~/src/Domain/Engagement/BankAccount'
import BankAccount from '~/src/Domain/Engagement/BankAccount'
import type { BusinessUnitProps } from '~/src/Domain/Engagement/BusinessUnit'
import BusinessUnit from '~/src/Domain/Engagement/BusinessUnit'
import EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type { EngagementType } from '~/src/Domain/Engagement/EngagementType'
import type { FiscalYearProps } from '~/src/Domain/Engagement/FiscalYear'
import FiscalYear from '~/src/Domain/Engagement/FiscalYear'
import { FiscalYearType } from '~/src/Domain/Engagement/FiscalYearType'
import type { ParameterProps } from '~/src/Domain/Engagement/Parameter'
import Parameter from '~/src/Domain/Engagement/Parameter'
import ParameterCollection from '~/src/Domain/Engagement/ParameterCollection'
import type { PhaseProps } from '~/src/Domain/Engagement/Phase'
import Phase from '~/src/Domain/Engagement/Phase'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { PhaseType } from '~/src/Domain/Engagement/PhaseType'
import OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import WorkProgramNotFoundException
  from '~/src/Domain/WorkProgram/Exception/WorkProgramNotFoundException'

export interface EngagementProps extends DomainModelProps {
  '@id': string
  '@type': string
  token: string
  name: string
  description: string
  type: EngagementType
  phases: PhaseProps[]
  auditTeamManager: AuditTeamMemberProps
  contactPerson: AuditTeamMemberProps
  auditTeamMembers: AuditTeamMemberProps[]
  businessUnits: BusinessUnitProps[]
  bankAccounts: BankAccountProps[]
  fiscalYears: FiscalYearProps[]
  parameters: ParameterProps[]
  createdDate: string
  externalClientContactPersonName?: string
  externalClientContactPersonEmail?: string
  externalClientContactPersonOnboardingDate: string
  signOffDate: string
  internalClientCode: string | undefined
  internalEngagementCode: string | undefined
  archived: boolean
  organisation: string
}

export default class Engagement implements DomainModelInterface<Engagement, EngagementProps> {
  public readonly '@id': EngagementIri
  public readonly '@type': string
  public readonly phases: Phase[]

  private constructor(
    id: EngagementIri,
    _type: string,
    public readonly token: string,
    public readonly name: string,
    public readonly description: string,
    public readonly type: EngagementType,
    phases: Phase[],
    public readonly auditTeamManager: AuditTeamMember,
    public readonly contactPerson: AuditTeamMember,
    public readonly auditTeamMembers: AuditTeamMember[],
    public readonly businessUnits: BusinessUnit[],
    public readonly bankAccounts: BankAccount[],
    public readonly fiscalYears: FiscalYear[],
    public readonly parameters: ParameterCollection,
    public readonly createdDate: BetterDate,
    public readonly externalClientContactPersonName: string | undefined,
    public readonly externalClientContactPersonEmail: string | undefined,
    public readonly externalClientContactPersonOnboardingDate: BetterDate,
    public readonly signOffDate: BetterDate,
    public readonly archived: boolean,
    public readonly internalClientCode: string | undefined,
    public readonly internalEngagementCode: string | undefined,
    public readonly organisation: OrganisationIri,
  ) {
    this['@id'] = id
    this['@type'] = _type
    this.phases = phases.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
  }

  public getSlug(): Slug {
    return Slug.fromString(this.name)
  }

  /**
   * @throws {WorkProgramNotFoundException} when engagement does not have a phase with the given type.
   */
  public getPhaseByType(type: PhaseType): Phase {
    const phase = this.phases.find((p) => p.type === type)
    if (undefined === phase) {
      throw new WorkProgramNotFoundException(`Engagement does not have a phase with type ${type}`)
    }

    return phase
  }

  public getPhaseById(phaseId: PhaseIri): Phase {
    const phase = this.phases.find((p) => p['@id'].equalsTo(phaseId))
    if (undefined === phase) {
      throw new WorkProgramNotFoundException(`Engagement does not have a phase with id ${phaseId.toString()}`)
    }

    return phase
  }

  public getNameCurrentFiscalYear(): string | undefined {
    return this.fiscalYears.find((f) => f.type === FiscalYearType.CURRENT)?.name
  }

  public getPhaseByCurrentDate(now: BetterDate, showOnlyPhasesWithAnalysisTypes: boolean = false): Phase {
    const daysAgoThreshold = 7

    for (const phase of this.phases) {
      if (showOnlyPhasesWithAnalysisTypes && !phase.hasAnalysisTypes()) {
        continue
      }

      if (now >= phase.startDate && now <= phase.endDate) {
        return phase // Today is within the phase duration
      }

      if (now > phase.endDate && (now.getTime() - phase.endDate.getTime()) <= (daysAgoThreshold * 24 * 60 * 60 * 1000)) {
        return phase // Phase ended recently but is still considered current
      }

      if (now < phase.startDate) {
        return phase // Next upcoming phase
      }
    }

    if (showOnlyPhasesWithAnalysisTypes) {
      return this.getPhaseByCurrentDate(now, false)
    }

    return this.phases.slice(-1)[0]
  }

  public getClassName(): string {
    return 'Engagement'
  }

  public fromJSON(values: EngagementProps): Engagement {
    return new Engagement(
      new EngagementIri(values['@id']),
      values['@type'],
      values.token,
      values.name,
      values.description,
      values.type,
      values.phases.map((p) => Phase.prototype.fromJSON(p)),
      AuditTeamMember.prototype.fromJSON(values.auditTeamManager),
      AuditTeamMember.prototype.fromJSON(values.contactPerson),
      values.auditTeamMembers.map((a) => AuditTeamMember.prototype.fromJSON(a)),
      values.businessUnits.map((b) => BusinessUnit.prototype.fromJSON(b)),
      values.bankAccounts.map((b) => BankAccount.prototype.fromJSON(b)),
      values.fiscalYears.map((f) => FiscalYear.prototype.fromJSON(f)),
      new ParameterCollection(values.parameters.map((b) => Parameter.prototype.fromJSON(b))),
      new BetterDate(values.createdDate),
      values.externalClientContactPersonName,
      values.externalClientContactPersonEmail,
      new BetterDate(values.externalClientContactPersonOnboardingDate),
      new BetterDate(values.signOffDate),
      values.archived,
      values.internalClientCode,
      values.internalEngagementCode,
      new OrganisationIri(values.organisation),
    )
  }

  public toJSON(): EngagementProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      token: this.token,
      name: this.name,
      description: this.description,
      type: this.type,
      phases: this.phases.map((p) => p.toJSON()),
      auditTeamManager: this.auditTeamManager.toJSON(),
      contactPerson: this.contactPerson.toJSON(),
      auditTeamMembers: this.auditTeamMembers.map((a) => a.toJSON()),
      businessUnits: this.businessUnits.map((b) => b.toJSON()),
      bankAccounts: this.bankAccounts.map((b) => b.toJSON()),
      fiscalYears: this.fiscalYears.map((f) => f.toJSON()),
      parameters: this.parameters.parameters.map((p) => p.toJSON()),
      createdDate: this.createdDate.toString(),
      externalClientContactPersonName: this.externalClientContactPersonName,
      externalClientContactPersonEmail: this.externalClientContactPersonEmail,
      externalClientContactPersonOnboardingDate: this.externalClientContactPersonOnboardingDate.toString(),
      signOffDate: this.signOffDate.toString(),
      internalClientCode: this.internalClientCode,
      internalEngagementCode: this.internalEngagementCode,
      archived: this.archived,
      organisation: this.organisation.toString(),
    }
  }
}
