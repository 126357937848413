/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ContextMenuProps } from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import type ContextMenu from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import LogicException from '~/src/Domain/Shared/Exception/LogicException'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterValue, FilterValueType } from '~/src/Domain/Shared/Filter/FilterCollection'
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'
import clone from '~/src/Domain/Shared/Utils/Clone'
import t from '~/src/Infrastructure/Shared/Translation/t'

export type Axis = 'rows' | 'columns' | 'values' | 'filters'

export interface Row extends JsonValueObjectProps {
  key: string
  value?: string[] | undefined
}

export interface Column extends JsonValueObjectProps {
  key: string
  value?: string[] | undefined
}

export interface Value extends JsonValueObjectProps {
  key: string
  value?: string[] | undefined
}

export interface Filter extends JsonValueObjectProps {
  key: string
  value?: FilterValueType
}

export interface FieldMapping extends JsonValueObjectProps {
  key: string
  title: string
  type: 'count' | 'sum'
  dataType?: string
}

export interface FormatSetting extends JsonValueObjectProps {
  key: string
  format: string
  type?: 'date' | 'dateTime' | 'time' | undefined
}

export interface DefaultPivotTableSettings {
  fieldMappings: FieldMapping[]
  formatSettings: FormatSetting[]
}

export interface PivotTableDefinitionProps extends JsonValueObjectProps {
  id: string
  title: string
  rows: Row[]
  columns: Column[]
  values: Value[]
  filters: Filter[]
  fieldMappings: FieldMapping[]
  formatSettings: FormatSetting[]
  showColumnGrandTotals: boolean
  showRowGrandTotals: boolean
  contextMenu: ContextMenuProps | undefined
}

export default class PivotTableDefinition
implements JsonValueObjectInterface<PivotTableDefinition, PivotTableDefinitionProps> {
  public readonly originalState: PivotTableDefinition

  public constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly rows: Row[],
    public readonly columns: Column[],
    public readonly values: Value[],
    public filters: Filter[],
    public readonly fieldMappings: FieldMapping[],
    public readonly formatSettings: FormatSetting[],
    public readonly showColumnGrandTotals: boolean = true,
    public readonly showRowGrandTotals: boolean = true,
    public readonly contextMenu: ContextMenu | undefined = undefined,
  ) {
    this.originalState = clone(this)
  }

  public getFiltersForExport(): FilterValue[] {
    return [...this.filters, ...this.rows, ...this.columns, ...this.values]
      .filter((f) => f.value !== undefined)
      .map((f) => {
        let filterClass = FilterClass.EQUALS_FILTER
        let value = f.value

        const emptyValues = new Set([
          undefined,
          'nul',
          'null',
          'undefined',
          '',
          t('components.pivot.pivot.no_value'),
        ])
        if (
          (typeof f.value === 'string' && emptyValues.has(f.value))
          || (Array.isArray(f.value) && f.value.some((v) => typeof v === 'string' && emptyValues.has(v)))
        ) {
          filterClass = FilterClass.EXISTS_FILTER
          value = false
        } else if (Array.isArray(value) && value[0] === t('components.filter.type.boolean.value_on')) {
          value = true
        } else if (Array.isArray(value) && value[0] === t('components.filter.type.boolean.value_off')) {
          value = false
        }

        return {
          key: f.key,
          filterClass,
          value,
        }
      })
  }

  public getFiltersForApiRequest(): FilterValue[] {
    const filterValues: FilterValue[] = [
      {
        key: 'pagination',
        filterClass: FilterClass.EQUALS_FILTER,
        value: false,
      },
    ]

    for (const property of [...this.columns, ...this.rows, ...this.filters]) {
      filterValues.push({
        key: 'properties',
        filterClass: FilterClass.EQUALS_FILTER,
        value: [property.key],
      })
    }

    for (const value of this.values) {
      filterValues.push({
        key: `aggregates[${this.getValueTypeForField(value.key).toLowerCase()}]`,
        filterClass: FilterClass.EQUALS_FILTER,
        value: [value.key],
      })
    }

    return filterValues
  }

  public getTitleForField(key: string): string {
    const fieldMapping = this.fieldMappings.find((f) => f.key === key)
    if (fieldMapping === undefined) {
      throw new LogicException(
        `Cannot get title for field, as no fieldMapping is found for field with key ${key}.`,
      )
    }

    return fieldMapping.title
  }

  public getValueTypeForField(key: string): 'count' | 'sum' {
    const fieldMapping = this.fieldMappings.find((f) => f.key === key)
    if (fieldMapping === undefined) {
      throw new LogicException(
        `Cannot get value type for field, as no fieldMapping is found for field with key ${key}.`,
      )
    }

    return fieldMapping.type.toLowerCase() as 'count' | 'sum'
  }

  public getFieldsWithValue(): Row[] {
    return [...this.filters, ...this.rows, ...this.columns, ...this.values].filter(
      (f) => f.value !== undefined,
    ) as Row[]
  }

  public resetFilters(): void {
    for (const filter of this.filters) {
      filter.value = undefined
    }
  }

  public addFilter(filter: Filter): void {
    if (filter.value !== undefined && !Array.isArray(filter.value)) {
      filter.value = [filter.value.toString()]
    }

    if (this.hasField(filter.key)) {
      const axis = this.getAxis(filter.key)

      const field = this[axis].find((f) => f.key === filter.key)
      if (field === undefined) {
        throw new LogicException(
          `Cannot add filter for field, as no fieldMapping is found for "${filter.key}".`,
        )
      }

      field.value = filter.value
    } else {
      this.filters.push(filter)
    }
  }

  public mergeFilters(pivotTable: PivotTableDefinition): void {
    const fieldsWithValue = pivotTable.getFieldsWithValue()
    for (const fieldWithValue of fieldsWithValue) {
      this.addFilter(fieldWithValue)
    }
  }

  public hasField(key: string): boolean {
    return [...this.filters, ...this.rows, ...this.columns, ...this.values].some(
      (f) => f.key === key,
    )
  }

  public getAxis(key: string): Axis {
    if (this.filters.some((f) => f.key === key))
      return 'filters'
    if (this.rows.some((f) => f.key === key))
      return 'rows'
    if (this.columns.some((f) => f.key === key))
      return 'columns'
    if (this.values.some((f) => f.key === key))
      return 'values'

    throw new LogicException(`Unknown Axis for key "${key}".`)
  }

  public getOriginalState(): PivotTableDefinition {
    return this.originalState
  }

  public fromJSON(values: PivotTableDefinitionProps): PivotTableDefinition {
    return new PivotTableDefinition(
      values.id,
      values.title,
      values.rows,
      values.columns,
      values.values,
      values.filters,
      values.fieldMappings,
      values.formatSettings,
      values.showColumnGrandTotals,
      values.showRowGrandTotals,
      undefined,
    )
  }

  public toJSON(): PivotTableDefinitionProps {
    return {
      id: this.id,
      title: this.title,
      rows: this.rows,
      columns: this.columns,
      values: this.values,
      filters: this.filters,
      fieldMappings: this.fieldMappings,
      formatSettings: this.formatSettings,
      showColumnGrandTotals: this.showColumnGrandTotals,
      showRowGrandTotals: this.showRowGrandTotals,
      contextMenu: undefined,
    }
  }
}
