/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { CoaMappingProps } from '~/src/Domain/CoaMapping/CoaMapping'
import CoaMapping from '~/src/Domain/CoaMapping/CoaMapping'
import type CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'

const coaMappingStore = defineStore('coa-mapping', () => {
  const coaMappings = ref<CoaMappingProps[]>([])

  const updateCoaMapping = (coaMapping: CoaMapping) => {
    const index = coaMappings.value.findIndex((d) => d['@id'] === coaMapping['@id'].toString())
    if (index === -1) {
      coaMappings.value.push(coaMapping.toJSON())
    } else {
      coaMappings.value[index] = coaMapping.toJSON()
    }
  }
  const deleteCoaMapping = (coaMapping: CoaMappingIri) => {
    const index = coaMappings.value.findIndex((e) => e['@id'] === coaMapping.toString())
    if (index !== -1) {
      coaMappings.value.splice(index, 1)
    }
  }

  const markState = (d: CoaMapping[]) => (coaMappings.value = d.map((d) => d.toJSON()))
  const resetState = (): void => {
    coaMappings.value = []
  }

  return {
    coaMappings: computed(() => coaMappings.value.map((c) => CoaMapping.prototype.fromJSON(c))),
    updateCoaMapping,
    deleteCoaMapping,
    markState,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(coaMappingStore, import.meta.hot))
}

export default coaMappingStore
