/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataRequestStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestStoreRepositoryInterface'
import DataRequestDeletedEvent from '~/src/Domain/DataRequest/Event/DataRequestDeletedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(DataRequestDeletedEvent.NAME, 'dataRequestDeletedEventHandler')
export default class DataRequestDeletedEventHandler extends EventHandlerInterface<DataRequestDeletedEvent> {
  private readonly dataRequestStoreRepository: DataRequestStoreRepositoryInterface

  public constructor({ dataRequestStoreRepository }: Services) {
    super()
    this.dataRequestStoreRepository = dataRequestStoreRepository
  }

  public handle(event: DataRequestDeletedEvent): void {
    this.dataRequestStoreRepository.deleteDataRequest(event.dataRequest)
  }
}
