/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class AbnAmroMijnZakelijkDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.ABN_AMRO_MIJN_ZAKELIJK === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.ABN_AMRO_MIJN_ZAKELIJK),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.abn_amro_zakelijk.pages.xml_file.title'),
          '',
          [
            new Alert(
              t('components.data_request.abn_amro_zakelijk.pages.intro.alert_background.message'),
              t('components.data_request.abn_amro_zakelijk.pages.intro.alert_background.heading'),
            ),
            new Paragraph([
              t(
                'components.data_request.abn_amro_zakelijk.pages.xml_file.tutorial_how_to_download_file',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.abn_amro_zakelijk.checklist.accounts_overview.open_accounts_overview',
                  ),
                  new Badge(5, undefined, 29, undefined),
                ),
              ],
              '/img/data-request/abn_amro_zakelijk/accounts_overview.png',
            ),
            new Paragraph([
              t(
                'components.data_request.abn_amro_zakelijk.pages.xml_file.paragraph_open_transactions_download',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.abn_amro_zakelijk.checklist.accounts_overview.click_transactions_download_link',
                  ),
                  new Badge(74, undefined, 25, undefined),
                ),
              ],
              '/img/data-request/abn_amro_zakelijk/transactions_download_link.png',
            ),
            new Paragraph([
              t(
                'components.data_request.abn_amro_zakelijk.pages.xml_file.paragraph_download_banking_file',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.abn_amro_zakelijk.checklist.download.select_accounts'),
                  new Badge(26, undefined, 52, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.abn_amro_zakelijk.checklist.download.select_option_select_period_and_fill_in_start_and_end_date',
                  ),
                  new Badge(36, undefined, 24, undefined),
                ),
                new ChecklistItem(
                  t('data_request.abn_amro_zakelijk.checklist.download.select_format_camt053'),
                  new Badge(67, undefined, 20, undefined),
                ),
                new ChecklistItem(
                  t('data_request.abn_amro_zakelijk.checklist.download.click_download'),
                  new Badge(85, undefined, 71, undefined),
                ),
              ],
              '/img/data-request/abn_amro_zakelijk/download_camt_file.png',
            ),
            new FileUploader(new GenericFileType(), ['application/xml', 'text/xml']),
          ],
        ),
      ]),
    )
  }
}
