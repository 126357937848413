/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataCubeApiRepositoryInterface from '~/src/Domain/DataCube/DataCubeApiRepositoryInterface'
import type DataCubeStoreRepositoryInterface from '~/src/Domain/DataCube/DataCubeStoreRepositoryInterface'
import EngagementActivatedEvent from '~/src/Domain/Engagement/Event/EngagementActivatedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(EngagementActivatedEvent.NAME, 'dataCubeEngagementActivatedEventHandler')
export default class DataCubeEngagementActivatedEventHandler extends EventHandlerInterface<EngagementActivatedEvent> {
  private readonly dataCubeApiRepository: DataCubeApiRepositoryInterface
  private readonly dataCubeStoreRepository: DataCubeStoreRepositoryInterface

  public constructor({ dataCubeApiRepository, dataCubeStoreRepository }: Services) {
    super()
    this.dataCubeApiRepository = dataCubeApiRepository
    this.dataCubeStoreRepository = dataCubeStoreRepository
  }

  public async handle(event: EngagementActivatedEvent): Promise<void> {
    this.dataCubeStoreRepository.resetState()

    const dataCubes = await this.dataCubeApiRepository.findAll(event.engagement)
    this.dataCubeStoreRepository.markState(dataCubes)
  }
}
