/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtPlugin } from '#app'
import PortalVue from 'portal-vue'

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    nuxtApp.vueApp.use(PortalVue)
  },
})
