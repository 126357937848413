/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import useString from '~/src/UserInterface/App/composables/useString'

export default class Paragraph extends ContentInterface {
  public readonly paragraphs: string[]

  public constructor(
    _paragraphs: string[],
    public readonly title: string | undefined = undefined,
  ) {
    super()

    const { replaceUrlsHtml } = useString()
    this.paragraphs = _paragraphs.map((p) => replaceUrlsHtml(p))
  }
}
