/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import KebabCase from '~/src/Domain/Shared/Utils/KebabCase'

const toSentence = (string: string) => {
  const interim = KebabCase(string).replaceAll('-', ' ')
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0)
    return '0 Bytes'

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${Number.parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`
}

const extractUrls = (str: string, lower: boolean = false): string[] | undefined => {
  const regexp = /https?:\/\/www\.?[\w#%+.:=@~-]{1,256}\.[\d()a-z]{1,6}\b[\w!#%&'()+./:=?@~-]*/gi
  const bracketsRegexp = /[()]/g

  const urls = str.match(regexp)
  if (urls === null) {
    return undefined
  }

  return lower
    ? urls.map((item) => item.toLowerCase().replaceAll(bracketsRegexp, ''))
    : urls.map((item) => item.replaceAll(bracketsRegexp, ''))
}

const replaceUrlsHtml = (str: string, lower: boolean = false): string => {
  const urls = extractUrls(str, lower)
  if (urls === undefined) {
    return str
  }

  return urls
    .reduce((a, b) => {
      a.push(str.replaceAll(b, `<a href="${b}" title="${b}" target="_blank">${b}</a>`))
      return a
    }, [] as string[])
    .join('')
}

/**
 * Convert string utils
 *
 * {@see https://github.com/zellwk/javascript/tree/master/src/utils/convert-case}
 */
export default function useString() {
  return { extractUrls, formatBytes, replaceUrlsHtml, toSentence }
}
