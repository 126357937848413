/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type Phase from '~/src/Domain/Engagement/Phase'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default function useEngagementState() {
  const engagementActivator = useService('engagementActivator')
  const engagementStoreRepository = useService('engagementStoreRepository')
  const engagements = computed(() => engagementStoreRepository.getEngagements())
  const hasCurrentEngagement = computed(() => engagementStoreRepository.hasCurrentEngagement())
  const hasCurrentPhase = computed(() => engagementStoreRepository.hasCurrentPhase())

  const getCurrentEngagement = (): ComputedRef<Engagement> => {
    return computed(() => engagementStoreRepository.getCurrentEngagement())
  }

  const getCurrentEngagementIri = (): ComputedRef<EngagementIri> => {
    return computed(() => engagementStoreRepository.getCurrentEngagement()['@id'])
  }

  const getCurrentPhase = (): ComputedRef<Phase> => {
    return computed(() => engagementStoreRepository.getCurrentPhase())
  }

  const getCurrentPhaseIri = (): ComputedRef<PhaseIri> => {
    return computed(() => engagementStoreRepository.getCurrentPhase()['@id'])
  }

  const activate = async (e: EngagementIri, p: PhaseIri) => {
    await engagementActivator.activate(e, p)
  }

  return {
    engagements,
    hasCurrentEngagement,
    getCurrentEngagement,
    getCurrentEngagementIri,
    hasCurrentPhase,
    getCurrentPhase,
    getCurrentPhaseIri,
    activate,
  }
}
