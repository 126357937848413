/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import type { DataRequestProps } from '~/src/Domain/DataRequest/DataRequest'
import DataRequest from '~/src/Domain/DataRequest/DataRequest'
import DataRequestNotFoundException from '~/src/Domain/DataRequest/Exception/DataRequestNotFoundException'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

const dataRequestorStore = defineStore('data-requestor', () => {
  const currentDataRequest = ref<DataRequestProps | undefined>(undefined)
  const currentDataRequestProjection = ref<DataRequestProjection | undefined>(undefined)

  const authenticationProcessInitiated = ref<string | undefined>(undefined)

  const factory = useService('dataRequestFixtureFactory')
  watch(currentDataRequest, (newValue) => {
    if (newValue === undefined) {
      currentDataRequestProjection.value = undefined
      return
    }

    currentDataRequestProjection.value = factory.createForDataRequest(
      DataRequest.prototype.fromJSON(currentDataRequest.value as DataRequestProps),
    )
  })

  const getDataRequest = () => {
    if (
      currentDataRequest.value === undefined
      || currentDataRequestProjection.value === undefined
    ) {
      throw new DataRequestNotFoundException('User has no current data request.')
    }

    return computed(() => currentDataRequestProjection.value as DataRequestProjection)
  }

  const hasDataRequest = () => currentDataRequestProjection.value !== undefined

  const updateDataRequest = (dataRequest: DataRequest) => {
    if (
      currentDataRequest.value === undefined
      || currentDataRequest.value['@id'] !== dataRequest['@id'].toString()
    ) {
      return
    }

    currentDataRequest.value = dataRequest.toJSON()
  }

  const isAuthenticated = computed(() => currentDataRequest.value !== undefined)
  const isAuthenticationProcessInitiated = computed(
    () => authenticationProcessInitiated.value !== undefined,
  )
  const authenticationProcessInitiatedFor = () => authenticationProcessInitiated.value
  const initiateAuthenticationProcess = (email: string) =>
    (authenticationProcessInitiated.value = email)
  const resetAuthenticationProcessInitiation = () =>
    (authenticationProcessInitiated.value = undefined)

  const markState = (dataRequest: DataRequest) => {
    currentDataRequest.value = dataRequest.toJSON()
  }

  const resetState = (): void => {
    currentDataRequest.value = undefined
    resetAuthenticationProcessInitiation()
  }

  return {
    getDataRequest,
    hasDataRequest,
    updateDataRequest,
    markState,
    resetState,
    isAuthenticated,
    isAuthenticationProcessInitiated,
    authenticationProcessInitiatedFor,
    initiateAuthenticationProcess,
    resetAuthenticationProcessInitiation,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(dataRequestorStore, import.meta.hot))
}

export default dataRequestorStore
