/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CreateCoaMappingExportCommand from '~/src/Application/Export/Command/CreateCoaMappingExportCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import ExportCreatedEvent from '~/src/Domain/Export/Event/ExportCreatedEvent'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(CreateCoaMappingExportCommand.NAME, 'createCoaMappingExportCommandHandler')
export default class CreateCoaMappingExportCommandHandler extends CommandHandlerInterface<CreateCoaMappingExportCommand> {
  private readonly eventBus: EventBusInterface
  private readonly exportApiRepository: ExportApiRepositoryInterface

  public constructor({ eventBus, exportApiRepository }: Services) {
    super()
    this.eventBus = eventBus
    this.exportApiRepository = exportApiRepository
  }

  public async handle(command: CreateCoaMappingExportCommand): Promise<void> {
    await Promise.allSettled([
      this.exportApiRepository.createCoaMappingExport(command.coaMapping),
      await this.eventBus.dispatch(ExportCreatedEvent.NAME, new ExportCreatedEvent()),
    ])
  }
}
