/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import LogicException from '~/src/Domain/Shared/Exception/LogicException'
import type { FilterValue } from '~/src/Domain/Shared/Filter/FilterCollection'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import type DataPivotStepResult from '~/src/Domain/WorkProgram/StepResult/DataPivotStepResult'
import type DataTableStepResult from '~/src/Domain/WorkProgram/StepResult/DataTableStepResult'
import type ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'

export default class FilterField {
  public constructor(
    public readonly key: string,
    public readonly label: string,
    public readonly filters: (FilterFieldFilter | ParameterizedFilterFieldFilter)[],
    public readonly required: boolean = true,
    public readonly readOnly: boolean = false,

    /**
     * Additional description that gets shown at the filter field on how it is relevant to use this field.
     *
     * Often gets used to tell the user that a field is required to fill-in.
     */
    public readonly description: string | undefined = undefined,
  ) {}

  /**
   * Get filterValues for a given work program step, from parameters and earlier saved filters.
   *
   * When parametersOnly is true, earlier saved filters will be ignored.
   */
  public getFilterValues(
    stepResult: DataPivotStepResult | DataTableStepResult | undefined,
    parameters: ParameterProjection[],
    parametersOnly = false,
  ): FilterValue[] {
    return (
      this
        .filters
        .filter((filter) => {
          return !(filter instanceof FilterFieldFilter) || filter.value !== undefined
        })
        .map((filter) => {
          if (!parametersOnly && stepResult !== undefined) {
            const filterValueFromState = this.getFilterValueFromState(filter, stepResult)
            if (filterValueFromState.value !== undefined) {
              return filterValueFromState
            }
          }

          if (filter instanceof FilterFieldFilter) {
            return filter.getFilterValue(this.key, this.readOnly)
          }

          if (filter instanceof ParameterizedFilterFieldFilter) {
            return filter.getFilterValue(this.key, parameters, this.readOnly)
          }

          throw new LogicException(
            `Unable to get FilterValue for filter ${String(filter)} on field ${this.key}`,
          )
        })
    )
  }

  private getFilterValueFromState = (
    filter: FilterFieldFilter | ParameterizedFilterFieldFilter,
    stepResult: DataPivotStepResult | DataTableStepResult,
  ): FilterValue => {
    const filterValue = stepResult
      .filterValues
      .find(
        (f) => f.field === this.key && f.filterClass === filter.filterClass,
      )

    return {
      key: this.key,
      filterClass: filter.filterClass,
      value: filterValue?.value ?? undefined,
      readOnly: this.readOnly,
    }
  }
}
