/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { normalizeURL } from 'ufo'
import type FilterCollection from '~/src/Domain/Shared/Filter/FilterCollection'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import type { RepositoryReadInterface } from '~/src/Infrastructure/Shared/Repository/RepositoryReadInterface'

export default class WorkProgramResultIriBuilder {
  public build(
    repository: RepositoryReadInterface<any, any>,
    filterCollection: FilterCollection,
  ): GenericIri {
    return new GenericIri(normalizeURL(repository.getBaseUrl().concat(`?${filterCollection.toUrlParameter()}`)))
  }
}
