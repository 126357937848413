/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CreateTwoWayCorrelationsExportCommand from '~/src/Application/Export/Command/CreateTwoWayCorrelationsExportCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import ExportCreatedEvent from '~/src/Domain/Export/Event/ExportCreatedEvent'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(CreateTwoWayCorrelationsExportCommand.NAME, 'createTwoWayCorrelationsExportCommandHandler')
export default class CreateTwoWayCorrelationsExportCommandHandler extends CommandHandlerInterface<CreateTwoWayCorrelationsExportCommand> {
  private readonly eventBus: EventBusInterface
  private readonly exportApiRepository: ExportApiRepositoryInterface

  public constructor({ eventBus, exportApiRepository }: Services) {
    super()
    this.eventBus = eventBus
    this.exportApiRepository = exportApiRepository
  }

  public async handle(command: CreateTwoWayCorrelationsExportCommand): Promise<void> {
    await Promise.allSettled([
      this.exportApiRepository.createTwoWayCorrelationsExport(
        command.phase,
        command.fiscalYears,
        command.businessUnitNames,
        command.sourceNames,
        command.primaryGlAccounts,
        command.primaryAccountTypes,
        command.primaryAccountSubtypes,
        command.primaryDebitCreditIndicators,
        command.secondaryGlAccounts,
        command.secondaryAccountTypes,
        command.secondaryAccountSubtypes,
        command.secondaryDebitCreditIndicators,
        command.extension,
        command.fields,
        command.locale,
        command.url,
      ),
      await this.eventBus.dispatch(ExportCreatedEvent.NAME, new ExportCreatedEvent()),
    ])
  }
}
