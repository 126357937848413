/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type RouterInterface from '~/src/Application/Shared/Router/RouterInterface'
import type DataRequestSecurityInterface from '~/src/Domain/DataRequest/DataRequestSecurityInterface'
import DataRequestRecipient2faInitiatedEvent from '~/src/Domain/DataRequest/Event/DataRequestRecipient2faInitiatedEvent'
import Invalid2faException from '~/src/Domain/Identity/Exception/Invalid2faException'
import UserIsNotIn2faProcessException from '~/src/Domain/Identity/Exception/UserIsNotIn2faProcessException'
import type { LoginResponse } from '~/src/Domain/Identity/SecurityInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class DataRequestSecurity implements DataRequestSecurityInterface {
  private readonly eventBus: EventBusInterface
  private readonly httpClient: HttpClient
  private readonly router: RouterInterface

  public constructor({ eventBus, httpClient, router }: Services) {
    this.eventBus = eventBus
    this.httpClient = httpClient
    this.router = router
  }

  public async login(url: string, username: string): Promise<void> {
    const response = await this.httpClient.get<LoginResponse>(url)

    if (response.login === 'success' && !response.two_factor_complete) {
      await this.eventBus.dispatch(DataRequestRecipient2faInitiatedEvent.NAME, new DataRequestRecipient2faInitiatedEvent(username))
    }
  }

  public async login2fa(token: string): Promise<void> {
    try {
      const response = await this.httpClient.post<LoginResponse>(
        '/v1/data_request_recipient/auth/2fa',
        {
          token,
        },
      )

      if (response?.error === '2fa_failed') {
        throw new Invalid2faException(response.error)
      }
    } catch (error) {
      if (error instanceof Invalid2faException) {
        throw error
      }

      throw new UserIsNotIn2faProcessException(
        'User is not in a two-factor authentication process.',
      )
    }
  }

  public async requestNewEmailAuthToken(): Promise<void> {
    await this.httpClient.post('/v1/data_request_recipient/auth/email-token')
  }

  public async logout(): Promise<void> {
    try {
      await this.httpClient.post('/v1/data_request_recipient/auth/logout')
    } finally {
      await this.router.replace('/')
    }
  }

  public async refreshJwtToken(): Promise<void> {
    await this.httpClient.post('/v1/data_request_recipient/auth/refresh')
  }
}
