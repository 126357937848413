/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class RabobankDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.RABOBANK === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.RABOBANK),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.rabobank.pages.xml_file.title'),
          '',
          [
            new Alert(
              t('components.data_request.rabobank.pages.intro.alert_background.message'),
              t('components.data_request.rabobank.pages.intro.alert_background.heading'),
              'bell',
            ),
            new Paragraph([
              t('components.data_request.rabobank.pages.xml_file.download_camt_file.title'),
              t(
                'components.data_request.rabobank.pages.xml_file.download_camt_file.paragraph_text',
              ),
              t('components.data_request.rabobank.pages.xml_file.login_using_the_orange_button'),
              t('components.data_request.rabobank.pages.xml_file.download_button'),
              t('components.data_request.rabobank.pages.xml_file.tutorial_how_to_download_file'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.rabobank.checklist.accounts_overview.click_download'),
                  new Badge(14, undefined, 32, undefined),
                ),
              ],
              '/img/data-request/rabobank/accounts_overview.png',
            ),
            new Paragraph([
              t(
                'components.data_request.rabobank.pages.xml_file.paragraph_open_transactions_download',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.rabobank.checklist.transactions.select_accounts_tab'),
                  new Badge(22, undefined, 18, undefined),
                ),
                new ChecklistItem(
                  t('data_request.rabobank.checklist.transactions.select_camt053_format'),
                  new Badge(36, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t('data_request.rabobank.checklist.transactions.select_all_or_specific_accounts'),
                  new Badge(45, undefined, 28, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.rabobank.checklist.transactions.in_case_of_specific_accounts_select_them_here',
                  ),
                  new Badge(51, undefined, 20, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.rabobank.checklist.transactions.select_option_specific_date_range',
                  ),
                  new Badge(90, undefined, 19, undefined),
                ),
              ],
              '/img/data-request/rabobank/transactions_download.png',
            ),
            new Paragraph([
              t('components.data_request.rabobank.pages.xml_file.paragraph_download_banking_file'),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.rabobank.checklist.download.select_specific_date_range'),
                  new Badge(27, undefined, 22, undefined),
                ),
                new ChecklistItem(
                  t('data_request.rabobank.checklist.download.enter_start_and_end_dates'),
                  new Badge(44, undefined, 66, undefined),
                ),
                new ChecklistItem(
                  t('data_request.rabobank.checklist.download.click_download'),
                  new Badge(81, undefined, 50, undefined),
                ),
              ],
              '/img/data-request/rabobank/transactions_date_range.png',
            ),
            new FileUploader(new GenericFileType(), ['application/xml', 'text/xml']),
          ],
        ),
      ]),
    )
  }
}
