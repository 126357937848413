/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */

import { isEqual as lodashIsEqual } from 'lodash-es'
import {
  isProxy,
  isReactive,
  isRef,
  toRaw,
} from 'vue'

export const deepGet = (obj: Record<any, any>, keys: string[]) => keys.reduce((xs, x) => xs?.[x] ?? undefined, obj)

export const get = <T>(obj: Record<any, any>, path: string): T =>
  deepGet(
    obj,
    path
      .replace(/\[([^[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t) => t !== ''),
  ) as T

export const isObject = (item: any) => {
  return item !== undefined && typeof item === 'object' && !Array.isArray(item)
}

export const isEqual = (val1: any, val2: any): boolean => {
  return lodashIsEqual(val1, val2)
}

export const mergeDeep = <T extends Record<any, any>>(target: T, ...sources: T[]): T => {
  if (sources.length === 0)
    return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (target[key] === undefined) {
          Object.assign(target, {
            [key]: {},
          })
        }
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, {
          [key]: source[key],
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const deepToRaw = <T extends Record<string, any>>(sourceObj: T): T => {
  const objectIterator = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item))
    }
    if (isRef(input) || isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input))
    }
    // eslint-disable-next-line ts/strict-boolean-expressions
    if (input && typeof input === 'object') {
      // eslint-disable-next-line ts/no-unsafe-argument
      return Object.keys(input).reduce((acc, key) => {
        // eslint-disable-next-line ts/no-unsafe-member-access
        acc[key as keyof typeof acc] = objectIterator(input[key])
        return acc
      }, {} as T)
    }
    return input
  }

  return objectIterator(sourceObj)
}
