/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandBusInterface from '~/src/Application/Shared/MessageBus/CommandBusInterface'
import type CommandHandlerInterface
  from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type { Container, Services } from '~/src/Infrastructure/Shared/Container/Container'

const commandHandlers: { commandName: string, commandHandlerName: keyof Services }[] = []

export function AsCommandHandler<T extends keyof Services>(commandName: string, commandHandlerName: T) {
  return function (_target: any) {
    commandHandlers.push({ commandName, commandHandlerName })
  }
}

export function initializeCommandHandlers(container: Container, commandBus: CommandBusInterface): void {
  for (const { commandName, commandHandlerName } of commandHandlers) {
    commandBus.registerHandler(commandName, {
      handle: async (command: CommandInterface) => {
        const handler = container.resolve<CommandHandlerInterface<CommandInterface>>(commandHandlerName)
        await handler.handle(command)
      },
    })
  }
}
