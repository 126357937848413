/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DataCube, { type DataCubeProps } from '~/src/Domain/DataCube/DataCube'
import type DataCubeApiRepositoryInterface from '~/src/Domain/DataCube/DataCubeApiRepositoryInterface'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class DataCubeApiRepository implements DataCubeApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async findAll(engagement: EngagementIri): Promise<DataCube[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<DataCubeProps>>(
        `${engagement.toString()}/data_cubes?pagination=false`,
      ),
      DataCube.prototype.fromJSON,
    )

    return response['hydra:member']
  }
}
