/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type Export from '~/src/Domain/Export/Export'

export default class DownloadExportCommand implements CommandInterface {
  public static readonly NAME = 'DOWNLOAD_EXPORT_COMMAND'

  public constructor(public readonly item: Export) {}
}
