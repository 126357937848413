/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import IntroductionStep from '~/src/Domain/DataRequest/Step/IntroductionStep'
import type StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import SummaryStep from '~/src/Domain/DataRequest/Step/SummaryStep'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'

export default class StepCollection {
  public readonly steps: StepInterface[]

  public constructor(steps: StepInterface[]) {
    this.steps = [new IntroductionStep(), ...steps, new SummaryStep()]
  }

  public getStep(slug: Slug): StepInterface {
    const step = this.steps.find((s) => s.slug.equals(slug))
    if (step !== undefined) {
      return step
    }

    throw new Error(`No step found with slug "${slug.toString()}".`)
  }

  public hasNextStep(slug: Slug): boolean {
    return !new SummaryStep().slug.equals(slug)
  }

  public getNextStep(slug: Slug): StepInterface {
    return this.steps[this.steps.findIndex((s) => s.slug.equals(slug)) + 1]
  }

  public hasPreviousStep(slug: Slug) {
    return !new IntroductionStep().slug.equals(slug)
  }

  public getPreviousStep(slug: Slug): StepInterface {
    return this.steps[this.steps.findIndex((s) => s.slug.equals(slug)) - 1]
  }
}
