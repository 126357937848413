/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type ParameterIri from '~/src/Domain/Engagement/ParameterIri'

export default class ChangeParameterCommand implements CommandInterface {
  public static readonly NAME = 'CHANGE_PARAMETER_ENGAGEMENT_COMMAND'

  public constructor(
    public readonly engagement: EngagementIri,
    public readonly parameter: ParameterIri,
    public readonly values: Record<string, any>,
  ) {}
}
