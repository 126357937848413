/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type FileTypeInterface from '~/src/Domain/DataRequest/File/FileType/FileTypeInterface'
import type ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'

export default abstract class StepInterface {
  public abstract get slug(): Slug
  public abstract get title(): string
  public abstract get description(): string
  public abstract get contents(): ContentInterface[]
  public abstract get fileTypes(): FileTypeInterface[]
  public abstract get isOptional(): boolean
}
