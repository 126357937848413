/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { decodeHTML } from 'entities'
import { transform } from 'ultrahtml'
import sanitize from 'ultrahtml/transformers/sanitize'

export default class HtmlSanitizer {
  public async sanitize(html: string): Promise<string> {
    return transform(decodeHTML(html), [
      sanitize({
        allowElements: [
          'address',
          'article',
          'aside',
          'footer',
          'header',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'hgroup',
          'main',
          'nav',
          'section',
          'blockquote',
          'dd',
          'div',
          'dl',
          'dt',
          'figcaption',
          'figure',
          'hr',
          'li',
          'main',
          'ol',
          'p',
          'pre',
          'ul',
          'a',
          'abbr',
          'b',
          'bdi',
          'bdo',
          'br',
          'cite',
          'code',
          'data',
          'dfn',
          'em',
          'i',
          'kbd',
          'mark',
          'q',
          'rb',
          'rp',
          'rt',
          'rtc',
          'ruby',
          's',
          'samp',
          'small',
          'span',
          'strong',
          'sub',
          'sup',
          'time',
          'u',
          'var',
          'wbr',
          'caption',
          'col',
          'colgroup',
          'table',
          'tbody',
          'td',
          'tfoot',
          'th',
          'thead',
          'tr',
        ],
        allowAttributes: {
          a: ['href', 'name', 'target'],
          // We don't currently allow img itself by default, but
          // these attributes would make sense if we did.
          img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
        },
      }),
    ])
  }

  public removeAllHtml(html: string): string {
    return decodeHTML(html).replaceAll(/<[^>]+>/g, '')
  }
}
