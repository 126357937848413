/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EngagementActivatorInterface from '~/src/Domain/Engagement/EngagementActivatorInterface'
import type EngagementApiRepositoryInterface from '~/src/Domain/Engagement/EngagementApiRepositoryInterface'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class EngagementActivator implements EngagementActivatorInterface {
  private readonly engagementApiRepository: EngagementApiRepositoryInterface
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface

  public constructor({ engagementApiRepository, engagementStoreRepository }: Services) {
    this.engagementApiRepository = engagementApiRepository
    this.engagementStoreRepository = engagementStoreRepository
  }

  public async activate(engagement: EngagementIri, phase: PhaseIri): Promise<void> {
    await Promise.allSettled([
      this.engagementApiRepository.activate(phase),
      this.engagementStoreRepository.activate(engagement, phase),
    ])
  }
}
