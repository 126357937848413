/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  EngagementExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'

export interface WorkProgramExportDetailsProps extends EngagementExportDetailsProps {
  workProgramId: string
  title: string
  description: string
  steps: { stepSlug: string, title: string, description: string }[]
  locale: string
}
export default class WorkProgramExportDetails extends EngagementExportDetailsInterface<
  WorkProgramExportDetails,
  WorkProgramExportDetailsProps
> {
  private constructor(
    public readonly engagementId: string,
    public readonly workProgramId: string,
    public readonly title: string,
    public readonly description: string,
    public readonly steps: { stepSlug: string, title: string, description: string }[],
    public readonly locale: string,
  ) {
    super()
  }

  public fromJSON(values: WorkProgramExportDetailsProps): WorkProgramExportDetails {
    return new WorkProgramExportDetails(
      values.engagementId,
      values.workProgramId,
      values.title,
      values.description,
      values.steps,
      values.locale,
    )
  }

  public toJSON(): WorkProgramExportDetailsProps {
    return {
      engagementId: this.engagementId,
      workProgramId: this.workProgramId,
      title: this.title,
      description: this.description,
      steps: this.steps,
      locale: this.locale,
    }
  }
}
