/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  EngagementExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'

export interface DataRequestArchiveExportDetailsProps extends EngagementExportDetailsProps {
  dataRequestId: string
}

export default class DataRequestArchiveExportDetails extends EngagementExportDetailsInterface<
  DataRequestArchiveExportDetails,
  DataRequestArchiveExportDetailsProps
> {
  private constructor(
    public readonly engagementId: string,
    public readonly dataRequestId: string,
  ) {
    super()
  }

  public fromJSON(values: DataRequestArchiveExportDetailsProps): DataRequestArchiveExportDetails {
    return new DataRequestArchiveExportDetails(values.engagementId, values.dataRequestId)
  }

  public toJSON(): DataRequestArchiveExportDetailsProps {
    return {
      engagementId: this.engagementId,
      dataRequestId: this.dataRequestId,
    }
  }
}
