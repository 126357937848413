/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { NuxtApp } from '#app'
import type TranslatorInterface from '~/src/Domain/Shared/Translation/TranslatorInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class Translator implements TranslatorInterface {
  private readonly nuxtI18n: NuxtApp['$i18n']

  public constructor({ nuxtI18n }: Services) {
    this.nuxtI18n = nuxtI18n
  }

  public getLocale(): string {
    return this.nuxtI18n.locale.value
  }

  public async setLocale(locale: string): Promise<void> {
    await this.nuxtI18n.setLocale(locale)
  }

  public trans(key: string, parameters: Record<string, unknown> | undefined = undefined): string {
    return this.nuxtI18n.t(key, parameters ?? {})
  }
}
