/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { SorterValue } from '~/src/Domain/Shared/Filter/FilterCollection'
import type FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import BaseResultReference from '~/src/Domain/WorkProgram/ResultReference/BaseResultReferences'
import type { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import type { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'

/**
 * In addition to the field sorters that are editable at the work program step, sorters can get
 * defined that by default will be passed along to make the backend aware of, for example, how
 * to interpret the data analysis output relevance.
 */
export default class DataTableResultReference extends BaseResultReference {
  public constructor(
    public readonly fieldReference: FieldsResultReference,
    public readonly repositoryReference: RepositoryReference,
    public readonly defaultFilters: FilterField[],
    /**
     * In addition to the field sorters that are editable at the work program step, sorters can get
     * defined that by default will be passed along to make the backend aware of, for example, how
     * to interpret the data analysis output relevance.
     */
    public readonly defaultSorters: SorterValue[] = [],
  ) {
    super()
  }
}
