/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import BalancingIndicator from '~/src/Domain/GeneralLedger/BalancingIndicator'
import ContextMenu from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import DrillThroughContextMenuItem from '~/src/Domain/Shared/ContextMenu/DrillThroughContextMenuItem'
import OtherSideOfJournalEntryContextMenuItem from '~/src/Domain/Shared/ContextMenu/OtherSideOfJournalEntryContextMenuItem'
import { FieldSorterDirection } from '~/src/Domain/Shared/Filter/FieldSorterDirection'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import {
  businessUnitFilterField,
  fiscalYearFilterField,
  manualSourceNameFilterField,
} from '~/src/Domain/WorkProgram/Fixtures/General/FilterFields'
import BusinessUnitParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BusinessUnitParameterDefinition'
import EntriesNearEndPeriodEndDateParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/EntriesNearEndPeriodEndDateParameterDefinition'
import EntriesNearEndPeriodStartDateParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/EntriesNearEndPeriodStartDateParameterDefinition'
import FiscalYearCurrentEndParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentEndParameterDefinition'
import FiscalYearParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearParameterDefinition'
import ManagementBusinessUserParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ManagementBusinessUserParameterDefinition'
import ManualBookingSourcesParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ManualBookingSourcesParameterDefinition'
import OfficeHoursEndParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/OfficeHoursEndParameterDefinition'
import OfficeHoursStartParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/OfficeHoursStartParameterDefinition'
import PerformanceMaterialityThresholdParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/PerformanceMaterialityThresholdParameterDefinition'
import UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import DataTableResultReference from '~/src/Domain/WorkProgram/ResultReference/DataTableResultReference'
import { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import PivotResultReference from '~/src/Domain/WorkProgram/ResultReference/PivotResultReference'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import StepSection from '~/src/Domain/WorkProgram/StepSection'
import StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { usePivotTableDefaultSettings } from '~/src/UserInterface/App/composables/PivotTable/usePivotTableDefaultSettings'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'
import ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'
import WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class ManagementFraud implements WorkProgramFixtureInterface {
  public create(): () => WorkProgramProjection {
    const { preparerOverviewDefaultPivotTableSettings, sourceOverviewDefaultPivotTableSettings }
      = usePivotTableDefaultSettings()

    return () => {
      const parameters = [
        new ParameterProjection(new PerformanceMaterialityThresholdParameterDefinition()),
        new ParameterProjection(new FiscalYearParameterDefinition()),
        new ParameterProjection(new BusinessUnitParameterDefinition()),
        new ParameterProjection(new ManualBookingSourcesParameterDefinition()),
        new ParameterProjection(new EntriesNearEndPeriodStartDateParameterDefinition()),
        new ParameterProjection(new EntriesNearEndPeriodEndDateParameterDefinition()),
        new ParameterProjection(new OfficeHoursStartParameterDefinition()),
        new ParameterProjection(new OfficeHoursEndParameterDefinition()),
        new ParameterProjection(new FiscalYearCurrentEndParameterDefinition()),
        new ParameterProjection(new ManagementBusinessUserParameterDefinition()),
        new ParameterProjection(
          new UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition(),
        ),
      ]

      return new WorkProgramProjection(
        new Slug('management-fraud'),
        t('work_programs.analytikal.management_fraud.title'),
        t('work_programs.analytikal.management_fraud.text'),
        new StepSectionCollection([
          new StepSection(
            'intro',
            t('work_programs.analytikal.management_fraud.sections.section_intro.lbl_navigation'),
            [
              new IntroStep(
                new Slug('intro'),
                t('work_programs.analytikal.management_fraud.steps.step_intro.title'),
                t('work_programs.analytikal.management_fraud.steps.step_intro.text'),
              ),
            ],
          ),
          new StepSection(
            'data-validation',
            t(
              'work_programs.analytikal.management_fraud.sections.section_data_validation.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('journal-entry-balance'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entry_balance.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entry_balance.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.UNBALANCED_JOURNAL_ENTRY,
                  RepositoryReference.UNBALANCED_JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'balancingIndicator',
                      t('general.entity_fields.unbalanced_journal_entry.balancing_indicator'),
                      [
                        new FilterFieldFilter(FilterClass.ENUM_FILTER, [
                          BalancingIndicator.UNBALANCED,
                        ]),
                      ],
                      true,
                      true,
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('reconciling-general-ledger-accounts'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_reconciling_general_ledger_accounts.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_reconciling_general_ledger_accounts.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.TRIAL_BALANCE_ROLL_FORWARD,
                  RepositoryReference.TRIAL_BALANCE_ROLL_FORWARD,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'difference',
                      t('general.entity_fields.trial_balance_roll_forward.difference'),
                      [new FilterFieldFilter(FilterClass.NOT_EQUALS_FILTER, 0)],
                      true,
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),

          new StepSection(
            'source-and-creator',
            t(
              'work_programs.analytikal.management_fraud.sections.section_source_and_creator.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('manual-journal-entries'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_manual_journal_entries.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_manual_journal_entries.text',
                ),
                new PivotResultReference(
                  RepositoryReference.SOURCE_OVERVIEW,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    manualSourceNameFilterField(),
                  ],
                  new PivotTableDefinition(
                    'manual-journal-entries',
                    t(
                      'work_programs.analytikal.management_fraud.steps.step_manual_journal_entries.title',
                    ),
                    [
                      { key: 'chartOfAccounts.accountType' },
                      { key: 'chartOfAccounts.accountSubtype' },
                    ],
                    [{ key: 'fiscalYear' }, { key: 'period' }, { key: 'currency' }],
                    [{ key: 'amount' }],
                    [{ key: 'source.sourceName' }, { key: 'businessUnit.businessUnitName' }],
                    sourceOverviewDefaultPivotTableSettings.fieldMappings,
                    sourceOverviewDefaultPivotTableSettings.formatSettings,
                    true,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToGeneralLedgerJournalEntriesPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'journal-entry',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToGeneralLedgerJournalEntriesPage()
                        },
                      ),
                      new OtherSideOfJournalEntryContextMenuItem(),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-by-management'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_management.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_management.text',
                ),
                new PivotResultReference(
                  RepositoryReference.PREPARER_OVERVIEW,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredBy.businessUserName',
                      t('general.entity_fields.entered_by.business_user_name'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new ManagementBusinessUserParameterDefinition(),
                          FilterClass.MULTI_API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      true,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.entered_by_username',
                      ),
                    ),
                  ],
                  new PivotTableDefinition(
                    'journal-entries-by-management',
                    t(
                      'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_management.title',
                    ),
                    [
                      { key: 'enteredBy.department' },
                      { key: 'enteredBy.businessUserName' },
                      { key: 'source.sourceName' },
                      { key: 'debitCreditIndicator' },
                    ],
                    [{ key: 'fiscalYear' }, { key: 'period' }, { key: 'currency' }],
                    [{ key: 'amount' }],
                    [
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'enteredBy.businessUserName' },
                    ],
                    preparerOverviewDefaultPivotTableSettings.fieldMappings,
                    preparerOverviewDefaultPivotTableSettings.formatSettings,
                    true,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToGeneralLedgerJournalEntriesPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'journal-entry',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToGeneralLedgerJournalEntriesPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-by-unusual-accounts'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_unusual_accounts.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_unusual_accounts.text',
                ),
                new PivotResultReference(
                  RepositoryReference.PREPARER_OVERVIEW,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredBy.businessUserName',
                      t('general.entity_fields.entered_by.business_user_name'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition(),
                          FilterClass.MULTI_API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      true,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.entered_by_username',
                      ),
                    ),
                  ],
                  new PivotTableDefinition(
                    'journal-entries-by-unusual-accounts',
                    t(
                      'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_unusual_accounts.title',
                    ),
                    [
                      { key: 'enteredBy.department' },
                      { key: 'enteredBy.businessUserName' },
                      { key: 'source.sourceName' },
                      { key: 'debitCreditIndicator' },
                    ],
                    [{ key: 'fiscalYear' }, { key: 'period' }, { key: 'currency' }],
                    [{ key: 'amount' }],
                    [
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'enteredBy.businessUserName' },
                    ],
                    preparerOverviewDefaultPivotTableSettings.fieldMappings,
                    preparerOverviewDefaultPivotTableSettings.formatSettings,
                    true,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToGeneralLedgerJournalEntriesPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'journal-entry',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToGeneralLedgerJournalEntriesPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
            ],
          ),

          new StepSection(
            'trends-and-timing',
            t(
              'work_programs.analytikal.management_fraud.sections.section_trends_and_timing.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('journal-entries-near-period-end'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_near_period_end.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_near_period_end.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredDate',
                      t('general.entity_fields.journal_entry.entered_date'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new EntriesNearEndPeriodStartDateParameterDefinition(),
                          FilterClass.GREATER_THAN_OR_EQUAL_FILTER,
                        ),
                        new ParameterizedFilterFieldFilter(
                          new EntriesNearEndPeriodEndDateParameterDefinition(),
                          FilterClass.LOWER_THAN_OR_EQUAL_FILTER,
                        ),
                      ],
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entry-timing'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entry_timing.title',
                ),
                t('work_programs.analytikal.management_fraud.steps.step_journal_entry_timing.text'),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredHour',
                      t('general.entity_fields.journal_entry.entered_hour'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new OfficeHoursStartParameterDefinition(),
                          FilterClass.LOWER_THAN_FILTER,
                        ),
                        new ParameterizedFilterFieldFilter(
                          new OfficeHoursEndParameterDefinition(),
                          FilterClass.GREATER_THAN_FILTER,
                        ),
                      ],
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-after-period-end'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_after_period_end.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_after_period_end.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredDate',
                      t('general.entity_fields.journal_entry.entered_date'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new FiscalYearCurrentEndParameterDefinition(),
                          FilterClass.GREATER_THAN_OR_EQUAL_FILTER,
                        ),
                      ],
                    ),
                  ],
                ),
              ),
            ],
          ),

          new StepSection(
            'text-and-description',
            t(
              'work_programs.analytikal.management_fraud.sections.section_text_and_description.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('journal-entries-without-descriptions'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_without_descriptions.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_without_descriptions.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'chartOfAccounts.accountType',
                      t('general.entity_fields.chart_of_accounts.account_type'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                      false,
                      // warning: t(
                      //   'pages.general_ledger.work_program.management_fraud.warnings.account_type'
                      // ),
                    ),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.source_code',
                      ),
                      // warning: t(
                      //   'pages.general_ledger.work_program.management_fraud.warnings.account_type'
                      // ),
                    ),
                    new FilterField(
                      'headerDescription',
                      t('general.entity_fields.journal_entry.header_description'),
                      [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
                      false,
                      true,
                    ),
                    new FilterField(
                      'lineDescription',
                      t('general.entity_fields.journal_entry.line_description'),
                      [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
                      false,
                      true,
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-with-significant-impact'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_with_significant_impact.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_with_significant_impact.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    new FilterField(
                      'chartOfAccounts.accountType',
                      t('general.entity_fields.chart_of_accounts.account_type'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                    ),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.source_code',
                      ),
                    ),
                  ],
                  [{ key: 'amount', direction: FieldSorterDirection.DESC }],
                ),
              ),
            ],
          ),

          new StepSection(
            'correlation-and-related-entries',
            t(
              'work_programs.analytikal.management_fraud.sections.section_correlation_and_related_entries.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('journal-entries-by-unusual-source'),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_unusual_source.title',
                ),
                t(
                  'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_unusual_source.text',
                ),
                new PivotResultReference(
                  RepositoryReference.SOURCE_OVERVIEW,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                    ),
                    new FilterField(
                      'debitCreditIndicator',
                      t('general.entity_fields.journal_entry.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                    ),
                    new FilterField(
                      'chartOfAccounts.accountType',
                      t('general.entity_fields.chart_of_accounts.account_type'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.account_type',
                      ),
                    ),
                    new FilterField(
                      'chartOfAccounts.accountSubtype',
                      t('general.entity_fields.chart_of_accounts.account_subtype'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
                      false,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.account_type',
                      ),
                    ),
                  ],
                  new PivotTableDefinition(
                    'journal-entries-by-unusual-source',
                    t(
                      'work_programs.analytikal.management_fraud.steps.step_journal_entries_by_unusual_source.title',
                    ),
                    [
                      { key: 'chartOfAccounts.accountType' },
                      { key: 'chartOfAccounts.accountSubtype' },
                      { key: 'chartOfAccounts.glAccount' },
                    ],
                    [{ key: 'source.sourceName' }, { key: 'currency' }],
                    [{ key: 'amount' }, { key: 'jeLineCount' }],
                    [
                      { key: 'fiscalYear' },
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'debitCreditIndicator' },
                    ],
                    sourceOverviewDefaultPivotTableSettings.fieldMappings,
                    sourceOverviewDefaultPivotTableSettings.formatSettings,
                    true,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToGeneralLedgerJournalEntriesPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'journal-entry',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToGeneralLedgerJournalEntriesPage()
                        },
                      ),
                      new OtherSideOfJournalEntryContextMenuItem(),
                    ]),
                  ),
                ),
              ),
            ],
          ),

          // Finalize section does not need steps, as it has a specific page
          new StepSection(
            'finalization',
            t(
              'work_programs.analytikal.management_fraud.sections.section_finalize_export.lbl_navigation',
            ),
            [
              new FinalStep(
                new Slug('finalize'),
                t('work_programs.analytikal.management_fraud.steps.step_finalize.title'),
                t('work_programs.analytikal.management_fraud.steps.step_finalize.text'),
              ),
            ],
          ),
        ]),
        parameters,
        [AnalysisType.GENERAL_LEDGER],
      )
    }
  }
}
