/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import UserUpdatedEvent from '~/src/Domain/Identity/Event/UserUpdatedEvent'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(UserUpdatedEvent.NAME, 'userUpdatedEventHandler')
export default class UserUpdatedEventHandler extends EventHandlerInterface<UserUpdatedEvent> {
  private readonly userStoreRepository: UserStoreRepositoryInterface

  public constructor({ userStoreRepository }: Services) {
    super()
    this.userStoreRepository = userStoreRepository
  }

  public handle(event: UserUpdatedEvent): void {
    this.userStoreRepository.updateUser(event.user)
  }
}
