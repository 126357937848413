/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataCube from '~/src/Domain/DataCube/DataCube'
import type DataCubeCollection from '~/src/Domain/DataCube/DataCubeCollection'
import type DataCubeIri from '~/src/Domain/DataCube/DataCubeIri'
import type DataCubeStoreRepositoryInterface from '~/src/Domain/DataCube/DataCubeStoreRepositoryInterface'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import DataCubeStore from '~/src/UserInterface/DataCube/store/DataCubeStore'

export default class DataCubeStoreRepository implements DataCubeStoreRepositoryInterface {
  private readonly store: ReturnType<typeof DataCubeStore>

  public constructor() {
    this.store = DataCubeStore(pinia)
  }

  public getDataCubes(): DataCubeCollection {
    return this.store.dataCubeCollection
  }

  public updateDataCube(dataCube: DataCube) {
    this.store.updateDataCube(dataCube)
  }

  public deleteDataCube(dataCube: DataCubeIri) {
    this.store.deleteDataCube(dataCube)
  }

  public markState(dataCubes: DataCube[]): void {
    this.store.markState(dataCubes)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
