/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'

export default class ArchiveEngagementCommand implements CommandInterface {
  public static readonly NAME = 'ARCHIVE_ENGAGEMENT_COMMAND'

  public constructor(public readonly engagement: EngagementIri) {}
}
