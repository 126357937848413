/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ExportDetailsProps } from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import ExportDetailsInterface from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'

export interface EngagementOverviewExportDetailsProps extends ExportDetailsProps {
  locale: string
}
export default class EngagementOverviewExportDetails extends ExportDetailsInterface<
  EngagementOverviewExportDetails,
  EngagementOverviewExportDetailsProps
> {
  private constructor(
    public readonly locale: string,
  ) {
    super()
  }

  public fromJSON(values: EngagementOverviewExportDetailsProps): EngagementOverviewExportDetails {
    return new EngagementOverviewExportDetails(
      values.locale,
    )
  }

  public toJSON(): EngagementOverviewExportDetailsProps {
    return {
      locale: this.locale,
    }
  }
}
