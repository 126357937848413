/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type WorkProgram from '~/src/Domain/WorkProgram/WorkProgram'

export default class WorkProgramUpdatedEvent implements EventInterface {
  public static readonly NAME = 'WORK_PROGRAM_UPDATED'

  public constructor(public readonly workProgram: WorkProgram) {}
}
