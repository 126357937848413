/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DataIntegrityValidator } from '~/src/Domain/DataRequest/DataIntegrityValidator'
import { DataIntegrityPillar } from '~/src/Domain/DataRequest/Validation/DataIntegrityPillar'
import type { Severity } from '~/src/Domain/DataRequest/Validation/Severity'
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface DataQualityFindingProps extends JsonValueObjectProps {
  pillar: DataIntegrityPillar.DATA_QUALITY
  severity: Severity
  code: DataIntegrityValidator
  context: Record<string | number, any>
}

export default class DataQualityFinding
implements JsonValueObjectInterface<DataQualityFinding, DataQualityFindingProps> {
  private constructor(
    public readonly severity: Severity,
    public readonly code: DataIntegrityValidator,
    public readonly context: Record<string | number, any>,
  ) {}

  public fromJSON(values: DataQualityFindingProps): DataQualityFinding {
    return new DataQualityFinding(values.severity, values.code, values.context)
  }

  public toJSON(): DataQualityFindingProps {
    return {
      pillar: DataIntegrityPillar.DATA_QUALITY,
      severity: this.severity,
      code: this.code,
      context: this.context,
    }
  }
}
