/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export default class Badge {
  public constructor(
    public readonly top: number | undefined = undefined,
    public readonly bottom: number | undefined = undefined,
    public readonly left: number | undefined = undefined,
    public readonly right: number | undefined = undefined,
  ) {}
}
