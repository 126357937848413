/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { useRoute } from 'vue-router'
import type { ResolveReturn, Route } from '~/src/Application/Shared/Router/RouterInterface'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default function useNavigation() {
  const route = useRoute()
  const router = useService('router')
  const uuidEncoder = useService('uuidEncoder')

  const refreshPage = (): void => {
    router.refreshPage()
  }

  const resolve = (to: Route): ResolveReturn => {
    return router.resolve(to)
  }

  const navigateTo = async (route: Route): Promise<void> => {
    await router.navigateTo(route)
  }

  const navigateToLocalePath = async (
    route: Route,
    locale: string | undefined = undefined,
  ): Promise<void> => {
    await router.navigateToLocalePath(route, locale)
  }

  const getLocalePath = (route: Route, locale: string | undefined = undefined): string => {
    return router.getLocalePath(route, locale)
  }

  return {
    route,
    router,
    uuidEncoder,
    refreshPage,
    resolve,
    navigateTo,
    navigateToLocalePath,
    getLocalePath,
  }
}
