/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Export from '~/src/Domain/Export/Export'
import type ExportCollection from '~/src/Domain/Export/ExportCollection'
import type ExportIri from '~/src/Domain/Export/ExportIri'
import type ExportStoreRepositoryInterface from '~/src/Domain/Export/ExportStoreRepositoryInterface'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import ExportStore from '~/src/UserInterface/Export/store/ExportStore'

export default class ExportStoreRepository implements ExportStoreRepositoryInterface {
  private readonly store: ReturnType<typeof ExportStore>

  public constructor() {
    this.store = ExportStore(pinia)
  }

  public getExports(): ExportCollection {
    return this.store.exportCollection
  }

  public async updateExport(updatedExport: Export): Promise<void> {
    await this.store.updateExport(updatedExport)
  }

  public deleteExport(deletedExport: ExportIri): void {
    this.store.deleteExport(deletedExport)
  }

  public markState(exports: Export[]): void {
    this.store.markState(exports)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
