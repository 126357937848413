/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware } from '#app'
import { ref } from 'vue'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import { routeOption } from '~/src/UserInterface/App/middleware/02.auth.global'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

const called = ref<boolean>(false)

/**
 * This middleware must be called on each page
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated
 *
 * This middleware populates state, by fetching the users' organisation, engagements and exports (if any).
 * This middleware must only run once.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[STATE] Middleware fired')

  if (
    called.value === true
    || routeOption(to, 'auth', 'dataRequest')
    || routeOption(to, 'auth', 'guest')
    || routeOption(to, 'auth', false)
  ) {
    return
  }

  const { getUser, isAuthenticated } = useUserState()
  if (!isAuthenticated.value) {
    return
  }

  const user = getUser()

  // Get organisation
  const { hasOrganisation } = useOrganisationState()
  if (!hasOrganisation.value) {
    const organisationApiRepository = useService('organisationApiRepository')
    let organisation: Organisation | undefined

    if (
      user.isAdmin()
      && 'organisationId' in to.params
      && typeof to.params.organisationId === 'string'
    ) {
      const uuidEncoder = useService('uuidEncoder')
      const organisationId = uuidEncoder.decode(to.params.organisationId)
      try {
        organisation = await organisationApiRepository.find(OrganisationIri.fromId(organisationId))
      } catch {
        organisation = await organisationApiRepository.findOwn(user)
      }
    } else {
      organisation = await organisationApiRepository.findOwn(user)
    }

    if (organisation !== undefined) {
      const organisationActivator = useService('organisationActivator')
      await organisationActivator.activate(organisation)
    }
  }

  called.value = true
})
