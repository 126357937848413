/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type FileTypeInterface from '~/src/Domain/DataRequest/File/FileType/FileTypeInterface'
import OptionalFileTypeInterface from '~/src/Domain/DataRequest/File/FileType/OptionalFileTypeInterface'
import type ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import useString from '~/src/UserInterface/App/composables/useString'

export default class Step extends StepInterface {
  public readonly description: string

  public constructor(
    public readonly slug: Slug,
    public readonly title: string,
    _description: string,
    public readonly contents: ContentInterface[],
  ) {
    super()

    const { replaceUrlsHtml } = useString()
    this.description = replaceUrlsHtml(_description)
  }

  public get fileTypes(): FileTypeInterface[] {
    return this
      .contents
      .filter((f): f is FileUploader => f instanceof FileUploader)
      .map((f) => f.fileType)
  }

  public get isOptional(): boolean {
    return (
      this.contents.length > 0
      && this
        .contents
        .filter((c): c is FileUploader => c instanceof FileUploader)
        .every((c) => c.fileType instanceof OptionalFileTypeInterface)
    )
  }
}
