/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CollectionIsEmptyException from '~/src/Domain/Shared/Collection/Exception/CollectionIsEmptyException'
import ItemNotFoundException from '~/src/Domain/Shared/Collection/Exception/ItemNotFoundException'

export default abstract class Collection<T extends { id: string }> {
  public constructor(protected items: T[]) {}

  public findById(id: string): T {
    const item = this.items.find((i) => i.id === id)
    if (item === undefined) {
      throw new ItemNotFoundException(`No item found with ID "${id}"`)
    }

    return item
  }

  public findAll(): T[] {
    return this.items
  }

  public count(): number {
    return this.items.length
  }

  public hasItems(): boolean {
    return this.count() > 0
  }

  public first(): T {
    if (!this.hasItems()) {
      throw new CollectionIsEmptyException('Collection is empty.')
    }

    return this.items[0]
  }

  public update(item: T): void {
    const index = this.items.findIndex((t) => t.id === item.id)
    if (index > -1) {
      this.items[index] = item
    } else {
      this.items.push(item)
    }
  }
}
