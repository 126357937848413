/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { DataCubeProps } from '~/src/Domain/DataCube/DataCube'
import DataCube from '~/src/Domain/DataCube/DataCube'
import DataCubeCollection from '~/src/Domain/DataCube/DataCubeCollection'
import type DataCubeIri from '~/src/Domain/DataCube/DataCubeIri'

const dataCubeStore = defineStore('datacube', () => {
  const dataCubes = ref<DataCubeProps[]>([])
  const dataCubeCollection = computed(
    () => new DataCubeCollection(dataCubes.value.map((d) => DataCube.prototype.fromJSON(d))),
  )

  const updateDataCube = (dataCube: DataCube) => {
    const index = dataCubes.value.findIndex((d) => d['@id'] === dataCube['@id'].toString())
    if (index === -1) {
      dataCubes.value.push(dataCube.toJSON())
    } else {
      dataCubes.value[index] = dataCube.toJSON()
    }
  }
  const deleteDataCube = (dataCube: DataCubeIri) => {
    const index = dataCubes.value.findIndex((e) => e['@id'] === dataCube.toString())
    if (index !== -1) {
      dataCubes.value.splice(index, 1)
    }
  }

  const markState = (d: DataCube[]) => (dataCubes.value = d.map((d) => d.toJSON()))
  const resetState = (): void => {
    dataCubes.value = []
  }

  return {
    dataCubes: dataCubes as Readonly<Ref<DataCubeProps[]>>,
    dataCubeCollection,
    updateDataCube,
    deleteDataCube,
    markState,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(dataCubeStore, import.meta.hot))
}

export default dataCubeStore
