/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ContextMenuItem from '~/src/Domain/Shared/ContextMenu/ContextMenuItem'
import type { NavigationType } from '~/src/Domain/Shared/ContextMenu/DrillThroughContextMenuItem'
import type {
  FilterSorterValuesFromHistory,
  FilterValue,
} from '~/src/Domain/Shared/Filter/FilterCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'

export default class OtherSideOfJournalEntryContextMenuItem extends ContextMenuItem {
  public constructor() {
    super(
      t('components.pivot_table.context_menu.other_side_of_journal_entry'),
      'search',
      async ({
        filterValues,
        navigation: { navigateToGeneralLedgerOtherSideOfJournalEntryPage },
      }: {
        filterValues: FilterValue[]
        updateFilterSorterHistory: (filterSorterHistory: FilterSorterValuesFromHistory) => void
        navigation: NavigationType
      }) => {
        await navigateToGeneralLedgerOtherSideOfJournalEntryPage(
          filterValues.reduce(
            (agg, f) => {
              if (f.value !== undefined) {
                agg[f.key] = f.value.toString()
              }

              return agg
            },
            {} as Record<string, string | string[] | undefined>,
          ),
        )
      },
    )
  }
}
