/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import StartWorkProgramCommand from '~/src/Application/WorkProgram/Command/StartWorkProgramCommand'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(StartWorkProgramCommand.NAME, 'startWorkProgramCommandHandler')
export default class StartWorkProgramCommandHandler extends CommandHandlerInterface<StartWorkProgramCommand> {
  private readonly workProgramApiRepository: WorkProgramApiRepositoryInterface

  public constructor({ workProgramApiRepository }: Services) {
    super()
    this.workProgramApiRepository = workProgramApiRepository
  }

  public async handle(command: StartWorkProgramCommand): Promise<void> {
    await this.workProgramApiRepository.startWorkProgram(
      command.phase,
      command.slug,
      command.description,
      command.steps,
      command.parameters,
    )
  }
}
