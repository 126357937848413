/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type Export from '~/src/Domain/Export/Export'

export default class ExportPreparedEvent implements EventInterface {
  public static readonly NAME = 'EXPORT_PREPARED'

  public constructor(public readonly updatedExport: Export) {}
}
