/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  CoaMappingExportDetailsProps,
} from '~/src/Domain/CoaMapping/Export/CoaMappingExportDetails'
import CoaMappingExportDetails from '~/src/Domain/CoaMapping/Export/CoaMappingExportDetails'
import type {
  DataRequestArchiveExportDetailsProps,
} from '~/src/Domain/DataRequest/Export/DataRequestArchiveExportDetails'
import DataRequestArchiveExportDetails
  from '~/src/Domain/DataRequest/Export/DataRequestArchiveExportDetails'
import type {
  DataRequestFileExportDetailsProps,
} from '~/src/Domain/DataRequest/Export/DataRequestFileExportDetails'
import DataRequestFileExportDetails
  from '~/src/Domain/DataRequest/Export/DataRequestFileExportDetails'
import type {
  EngagementOverviewExportDetailsProps,
} from '~/src/Domain/Engagement/Export/EngagementOverviewExportDetails'
import EngagementOverviewExportDetails
  from '~/src/Domain/Engagement/Export/EngagementOverviewExportDetails'
import type {
  MyEngagementsExportDetailsProps,
} from '~/src/Domain/Engagement/Export/MyEngagementsExportDetails'
import MyEngagementsExportDetails from '~/src/Domain/Engagement/Export/MyEngagementsExportDetails'
import type {
  ExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import type ExportDetailsInterface from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import type {
  TransactionalDataPivotExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/TransactionalDataPivotExportDetails'
import TransactionalDataPivotExportDetails
  from '~/src/Domain/Export/ExportDetails/TransactionalDataPivotExportDetails'
import type {
  TransactionalDataTableExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/TransactionalDataTableExportDetails'
import TransactionalDataTableExportDetails
  from '~/src/Domain/Export/ExportDetails/TransactionalDataTableExportDetails'
import { ExportType } from '~/src/Domain/Export/ExportType'
import type {
  OtherSideOfJournalEntriesExportDetailsProps,
} from '~/src/Domain/GeneralLedger/Export/OtherSideOfJournalEntriesExportDetails'
import OtherSideOfJournalEntriesExportDetails
  from '~/src/Domain/GeneralLedger/Export/OtherSideOfJournalEntriesExportDetails'
import type {
  TwoWayCorrelationsExportDetailsProps,
} from '~/src/Domain/GeneralLedger/Export/TwoWayCorrelationsExportDetails'
import TwoWayCorrelationsExportDetails
  from '~/src/Domain/GeneralLedger/Export/TwoWayCorrelationsExportDetails'
import type {
  WorkProgramExportDetailsProps,
} from '~/src/Domain/WorkProgram/Export/WorkProgramExportDetails'
import WorkProgramExportDetails from '~/src/Domain/WorkProgram/Export/WorkProgramExportDetails'

export default class ExportDetailsFactory {
  public static create(type: ExportType, values: ExportDetailsProps): ExportDetailsInterface<any, ExportDetailsProps> {
    const factories: Record<ExportType, () => ExportDetailsInterface<any, ExportDetailsProps>> = {
      [ExportType.COA_MAPPING]: () => CoaMappingExportDetails.prototype.fromJSON(values as CoaMappingExportDetailsProps),
      [ExportType.DATA_REQUEST_ARCHIVE]: () => DataRequestArchiveExportDetails.prototype.fromJSON(values as DataRequestArchiveExportDetailsProps),
      [ExportType.DATA_REQUEST_FILE]: () => DataRequestFileExportDetails.prototype.fromJSON(values as DataRequestFileExportDetailsProps),
      [ExportType.ENGAGEMENT_OVERVIEW]: () => EngagementOverviewExportDetails.prototype.fromJSON(values as EngagementOverviewExportDetailsProps),
      [ExportType.MY_ENGAGEMENTS]: () => MyEngagementsExportDetails.prototype.fromJSON(values as MyEngagementsExportDetailsProps),
      [ExportType.OTHER_SIDE_OF_JOURNAL_ENTRIES]: () => OtherSideOfJournalEntriesExportDetails.prototype.fromJSON(values as OtherSideOfJournalEntriesExportDetailsProps),
      [ExportType.TRANSACTIONAL_DATA_PIVOT]: () => TransactionalDataPivotExportDetails.prototype.fromJSON(values as TransactionalDataPivotExportDetailsProps),
      [ExportType.TRANSACTIONAL_DATA_TABLE]: () => TransactionalDataTableExportDetails.prototype.fromJSON(values as TransactionalDataTableExportDetailsProps),
      [ExportType.TWO_WAY_CORRELATIONS]: () => TwoWayCorrelationsExportDetails.prototype.fromJSON(values as TwoWayCorrelationsExportDetailsProps),
      [ExportType.WORK_PROGRAM]: () => WorkProgramExportDetails.prototype.fromJSON(values as WorkProgramExportDetailsProps),
    }

    return factories[type]()
  }
}
