/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractIri from '~/src/Domain/Shared/Identifier/AbstractIri'

export default class OrganisationIri extends AbstractIri<OrganisationIri> {
  public toId(): string {
    return this.iri.toString().split('/').pop()!
  }

  public static fromId(organisationId: string): OrganisationIri {
    return new OrganisationIri(`/v1/organisations/${organisationId}`)
  }
}
