/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type PartialTextFilterValueType = string | string[]

export default class PartialTextFilter extends AbstractFilter<PartialTextFilterValueType> {
  public constructor() {
    super(Operator.LIKE, FilterType.TEXT, FilterClass.PARTIAL_TEXT_FILTER)
  }

  protected toUrlParameters(key: string, value: PartialTextFilterValueType): string[] {
    return (Array.isArray(value) ? value : [value]).map(
      (v) => `${key}[${this.operator}][]=${encodeURIComponent(v.toString())}`,
    )
  }
}
