/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import EngagementArchivedEvent from '~/src/Domain/Engagement/Event/EngagementArchivedEvent'

import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(EngagementArchivedEvent.NAME, 'engagementArchivedEventHandler')
export default class EngagementArchivedEventHandler extends EventHandlerInterface<EngagementArchivedEvent> {
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface

  public constructor({ engagementStoreRepository }: Services) {
    super()
    this.engagementStoreRepository = engagementStoreRepository
  }

  public handle(event: EngagementArchivedEvent): void {
    this.engagementStoreRepository.delete(event.engagement)
  }
}
