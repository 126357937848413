/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataRequestApiRepositoryInterface from '~/src/Domain/DataRequest/DataRequestApiRepositoryInterface'
import type DataRequestStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestStoreRepositoryInterface'
import EngagementActivatedEvent from '~/src/Domain/Engagement/Event/EngagementActivatedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(EngagementActivatedEvent.NAME, 'dataRequestEngagementActivatedEventHandler')
export default class DataRequestEngagementActivatedEventHandler extends EventHandlerInterface<EngagementActivatedEvent> {
  private readonly dataRequestApiRepository: DataRequestApiRepositoryInterface
  private readonly dataRequestStoreRepository: DataRequestStoreRepositoryInterface

  public constructor({ dataRequestApiRepository, dataRequestStoreRepository }: Services) {
    super()
    this.dataRequestApiRepository = dataRequestApiRepository
    this.dataRequestStoreRepository = dataRequestStoreRepository
  }

  public async handle(event: EngagementActivatedEvent): Promise<void> {
    this.dataRequestStoreRepository.resetState()

    const dataRequests = await this.dataRequestApiRepository.findAll(event.engagement)
    this.dataRequestStoreRepository.markState(dataRequests)
  }
}
