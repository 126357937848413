/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import type { MemberProps } from '~/src/Domain/Organisation/Member'
import Member from '~/src/Domain/Organisation/Member'
import OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import Slug from '~/src/Domain/Shared/Identifier/Slug'

export interface OrganisationProps extends DomainModelProps {
  '@id': string
  '@type': string
  chamberOfCommerceId: string
  name: string
  description: string
  logo: string
  primaryContact: MemberProps
  members: MemberProps[]
  created: string
  activeUntil: string
  validated: boolean
}

export default class Organisation implements DomainModelInterface<Organisation, OrganisationProps> {
  public readonly '@id': OrganisationIri
  public readonly '@type': string

  private constructor(
    id: OrganisationIri,
    type: string,
    public readonly chamberOfCommerceId: ChamberOfCommerceId,
    public readonly name: string,
    public readonly description: string,
    public readonly logo: string,
    public readonly primaryContact: Member,
    public readonly members: Member[],
    public readonly created: BetterDateTime,
    public readonly activeUntil: BetterDateTime,
    public readonly validated: boolean,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getSlug(): Slug {
    return Slug.fromString(this.name)
  }

  public getClassName(): string {
    return 'Organisation'
  }

  public fromJSON(values: OrganisationProps): Organisation {
    return new Organisation(
      new OrganisationIri(values['@id']),
      values['@type'],
      new ChamberOfCommerceId(values.chamberOfCommerceId),
      values.name,
      values.description,
      values.logo,
      Member.prototype.fromJSON(values.primaryContact),
      values.members.map((m) => Member.prototype.fromJSON(m)),
      new BetterDateTime(values.created),
      new BetterDateTime(values.activeUntil),
      values.validated,
    )
  }

  public toJSON(): OrganisationProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      chamberOfCommerceId: this.chamberOfCommerceId.toString(),
      name: this.name,
      description: this.description,
      logo: this.logo,
      primaryContact: this.primaryContact.toJSON(),
      members: this.members.map((m) => m.toJSON()),
      created: this.created.toString(),
      activeUntil: this.activeUntil.toString(),
      validated: this.validated,
    }
  }
}
