/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import ParameterIri from '~/src/Domain/WorkProgram/ParameterIri'
import ParameterName from '~/src/Domain/WorkProgram/ParameterName'

export interface ParameterProps extends DomainModelProps {
  '@id': string
  '@type': string
  name: string
  value: string | string[]
}

export default class Parameter implements DomainModelInterface<Parameter, ParameterProps> {
  public readonly '@id': ParameterIri
  public readonly '@type': string

  private constructor(
    id: ParameterIri,
    type: string,
    public readonly name: ParameterName,
    public value: string | string[],
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'WorkProgramParameter'
  }

  public fromJSON(values: ParameterProps): Parameter {
    return new Parameter(
      new ParameterIri(values['@id']),
      values['@type'],
      new ParameterName(values.name),
      values.value,
    )
  }

  public toJSON(): ParameterProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      name: this.name.toString(),
      value: this.value,
    }
  }
}
