/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import EngagementUpdatedEvent from '~/src/Domain/Engagement/Event/EngagementUpdatedEvent'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(EngagementUpdatedEvent.NAME, 'engagementUpdatedEventHandler')
export default class EngagementUpdatedEventHandler extends EventHandlerInterface<EngagementUpdatedEvent> {
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface
  private readonly organisationStoreRepository: OrganisationStoreRepositoryInterface

  public constructor({ engagementStoreRepository, organisationStoreRepository }: Services) {
    super()
    this.engagementStoreRepository = engagementStoreRepository
    this.organisationStoreRepository = organisationStoreRepository
  }

  public handle(event: EngagementUpdatedEvent): void {
    if (
      this.organisationStoreRepository.hasOrganisation()
      && this.organisationStoreRepository.getOrganisation()['@id'].equalsTo(event.engagement.organisation)
    ) {
      this.engagementStoreRepository.update(event.engagement)
    }
  }
}
