/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import { FieldSorterDirection } from '~/src/Domain/Shared/Filter/FieldSorterDirection'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import {
  businessUnitFilterField,
  fiscalYearFilterField,
  headerDescriptionExistsFilterField,
  headerDescriptionUnusualFilterField,
  lineDescriptionExistsFilterField,
  lineDescriptionUnusualFilterField,
  manualSourceNameFilterField,
} from '~/src/Domain/WorkProgram/Fixtures/24342109/FilterFields24342109'
import BusinessUnitParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/BusinessUnitParameterDefinition24342109'
import EndDateReportingPeriodParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/EndDateReportingPeriodParameterDefinition24342109'
import FiscalYearParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/FiscalYearParameterDefinition24342109'
import ManualBookingSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/ManualBookingSourcesParameterDefinition24342109'
import TrivialAmountParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/TrivialAmountParameterDefinition24342109'
import UnusualBusinessUsersParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/UnusualBusinessUsersParameterDefinition24342109'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import DataTableResultReference from '~/src/Domain/WorkProgram/ResultReference/DataTableResultReference'
import { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import StepSection from '~/src/Domain/WorkProgram/StepSection'
import StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'
import ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'
import WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class InternalControl24342109 implements WorkProgramFixtureInterface {
  public create(): () => WorkProgramProjection {
    return () => {
      const parameters = [
        new ParameterProjection(new FiscalYearParameterDefinition24342109()),
        new ParameterProjection(new EndDateReportingPeriodParameterDefinition24342109()),
        new ParameterProjection(new BusinessUnitParameterDefinition24342109()),
        new ParameterProjection(new ManualBookingSourcesParameterDefinition24342109()),
        new ParameterProjection(new TrivialAmountParameterDefinition24342109()),
        new ParameterProjection(new UnusualBusinessUsersParameterDefinition24342109()),
      ]

      return new WorkProgramProjection(
        new Slug('internal-control'),
        t('work_programs.24342109.internal_control.title'),
        t('work_programs.24342109.internal_control.text'),
        new StepSectionCollection([
          new StepSection(
            'intro',
            t('work_programs.24342109.internal_control.sections.section_intro.lbl_navigation'),
            [
              new IntroStep(
                new Slug('intro'),
                t('work_programs.24342109.internal_control.steps.step_intro.title'),
                t('work_programs.24342109.internal_control.steps.step_intro.text'),
              ),
            ],
          ),
          new StepSection(
            'origin',
            t('work_programs.24342109.internal_control.sections.section_origin.lbl_navigation'),
            [
              new ResultStep(
                new Slug('journal-entries-manual-sources'),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_manual_sources.title',
                ),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_manual_sources.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    manualSourceNameFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                  [
                    {
                      key: 'absoluteAmount',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-created-unauthorized-personnel'),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_created_unauthorized_personnel.title',
                ),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_created_unauthorized_personnel.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredBy.businessUserName',
                      t('general.entity_fields.entered_by.business_user_name'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new UnusualBusinessUsersParameterDefinition24342109(),
                          FilterClass.MULTI_API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.entered_by_username',
                      ),
                    ),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'timing',
            t('work_programs.24342109.internal_control.sections.section_timing.lbl_navigation'),
            [
              new ResultStep(
                new Slug('journal-entries-created-after-reporting-period'),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_created_after_reporting_period.title',
                ),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_created_after_reporting_period.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'enteredDate',
                      t('general.entity_fields.journal_entry.entered_date'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new EndDateReportingPeriodParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_FILTER,
                        ),
                      ],
                    ),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'description',
            t(
              'work_programs.24342109.internal_control.sections.section_description.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('journal-entries-unusual-description'),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_unusual_description.title',
                ),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_unusual_description.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    headerDescriptionUnusualFilterField(),
                    lineDescriptionUnusualFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-without-description'),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_without_description.title',
                ),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_without_description.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    headerDescriptionExistsFilterField(),
                    lineDescriptionExistsFilterField(),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'other',
            t('work_programs.24342109.internal_control.sections.section_other.lbl_navigation'),
            [
              new ResultStep(
                new Slug('journal-entries-rounded-amount'),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_rounded_amount.title',
                ),
                t(
                  'work_programs.24342109.internal_control.steps.step_journal_entries_rounded_amount.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                        new FilterFieldFilter(FilterClass.THOUSANDS, true),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'finalize-export',
            t(
              'work_programs.24342109.internal_control.sections.section_finalize_export.lbl_navigation',
            ),
            [
              new FinalStep(
                new Slug('finalize'),
                t('work_programs.24342109.internal_control.steps.step_finalize.title'),
                t('work_programs.24342109.internal_control.steps.step_finalize.text'),
              ),
            ],
          ),
        ]),
        parameters,
        [AnalysisType.GENERAL_LEDGER],
        new ChamberOfCommerceId('24342109'),
      )
    }
  }
}
