/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  EngagementExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'

export interface OtherSideOfJournalEntriesExportDetailsProps extends EngagementExportDetailsProps {
  iri: string
  fiscalYears: string[]
  businessUnitNames: string[]
  sourceNames: string[]
  primaryGlAccounts: string[]
  primaryAccountTypes: string[]
  primaryAccountSubtypes: string[]
  primaryDebitCreditIndicators: string[]
  extension: string
  fields: string[]
  locale: string
}
export default class OtherSideOfJournalEntriesExportDetails extends EngagementExportDetailsInterface<
  OtherSideOfJournalEntriesExportDetails,
  OtherSideOfJournalEntriesExportDetailsProps
> {
  private constructor(
    public readonly engagementId: string,
    public readonly iri: GenericIri,
    public readonly fiscalYears: string[],
    public readonly businessUnitNames: string[],
    public readonly sourceNames: string[],
    public readonly primaryGlAccounts: string[],
    public readonly primaryAccountTypes: string[],
    public readonly primaryAccountSubtypes: string[],
    public readonly primaryDebitCreditIndicators: string[],
    public readonly extension: string,
    public readonly fields: string[],
    public readonly locale: string,
  ) {
    super()
  }

  public fromJSON(
    values: OtherSideOfJournalEntriesExportDetailsProps,
  ): OtherSideOfJournalEntriesExportDetails {
    return new OtherSideOfJournalEntriesExportDetails(
      values.engagementId,
      new GenericIri(values.iri),
      values.fiscalYears,
      values.businessUnitNames,
      values.sourceNames,
      values.primaryGlAccounts,
      values.primaryAccountTypes,
      values.primaryAccountSubtypes,
      values.primaryDebitCreditIndicators,
      values.extension,
      values.fields,
      values.locale,
    )
  }

  public toJSON(): OtherSideOfJournalEntriesExportDetailsProps {
    return {
      engagementId: this.engagementId,
      iri: this.iri.toString(),
      fiscalYears: this.fiscalYears,
      businessUnitNames: this.businessUnitNames,
      sourceNames: this.sourceNames,
      primaryGlAccounts: this.primaryGlAccounts,
      primaryAccountTypes: this.primaryAccountTypes,
      primaryAccountSubtypes: this.primaryAccountSubtypes,
      primaryDebitCreditIndicators: this.primaryDebitCreditIndicators,
      extension: this.extension,
      fields: this.fields,
      locale: this.locale,
    }
  }
}
