/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import type { StepResultProps } from '~/src/Domain/WorkProgram/StepResult/StepResultInterface'
import StepResultInterface from '~/src/Domain/WorkProgram/StepResult/StepResultInterface'

export interface DataPivotStepResultProps extends StepResultProps {
  filterValues: {
    field: string
    filterClass: FilterClass
    value: number | number[] | string | string[] | boolean
  }[]
  iri: string
  rows: string[]
  columns: string[]
  values: { field: string, valueType: 'count' | 'sum' }[]
}

export default class DataPivotStepResult extends StepResultInterface<
  DataPivotStepResult,
  DataPivotStepResultProps
> {
  private constructor(
    public readonly filterValues: {
      field: string
      filterClass: FilterClass
      value: number | number[] | string | string[] | boolean
    }[],
    public readonly iri: GenericIri,
    public readonly rows: string[],
    public readonly columns: string[],
    public readonly values: { field: string, valueType: 'count' | 'sum' }[],
  ) {
    super()
  }

  public fromJSON(values: DataPivotStepResultProps): DataPivotStepResult {
    return new DataPivotStepResult(
      values.filterValues,
      new GenericIri(values.iri),
      values.rows,
      values.columns,
      values.values,
    )
  }

  public toJSON(): DataPivotStepResultProps {
    return {
      filterValues: this.filterValues,
      iri: this.iri.toString(),
      rows: this.rows,
      columns: this.columns,
      values: this.values,
    }
  }
}
