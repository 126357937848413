/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type UnusualDescriptionValueType = boolean

export default class UnusualDescriptionFilter extends AbstractFilter<UnusualDescriptionValueType> {
  public constructor() {
    super(Operator.UNUSUAL_DESCRIPTION, FilterType.BOOLEAN, FilterClass.UNUSUAL_DESCRIPTION_FILTER)
  }

  protected toUrlParameters(key: string, value: UnusualDescriptionValueType): string {
    return `${key}[${this.operator}]=${encodeURIComponent(value.toString())}`
  }
}
