/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type User from '~/src/Domain/Identity/User'
import type ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import type MemberIri from '~/src/Domain/Organisation/MemberIri'
import type { OrganisationProps } from '~/src/Domain/Organisation/Organisation'
import Organisation from '~/src/Domain/Organisation/Organisation'
import type OrganisationApiRepositoryInterface from '~/src/Domain/Organisation/OrganisationApiRepositoryInterface'
import type { AddMemberPayload } from '~/src/Domain/Organisation/OrganisationApiRepositoryInterface'
import type OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import LogicException from '~/src/Domain/Shared/Exception/LogicException'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class OrganisationApiRepository implements OrganisationApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async create(payload: Record<string, any>): Promise<void> {
    await this.httpClient.post('/v1/organisations', payload)
  }

  public async update(organisation: OrganisationIri, payload: Record<string, any>): Promise<void> {
    await this.httpClient.patch(organisation.toString(), payload)
  }

  public async find(organisation: OrganisationIri): Promise<Organisation> {
    const response = await this.httpClient.get<OrganisationProps>(organisation.toString())

    return Organisation.prototype.fromJSON(response)
  }

  public async findAll(): Promise<Organisation[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<OrganisationProps>>(
        `/v1/organisations`,
      ),
      Organisation.prototype.fromJSON,
    )

    return response['hydra:member']
  }

  public async findOwn(user: User): Promise<Organisation | undefined> {
    try {
      return Organisation.prototype.fromJSON(
        await this.httpClient.get<OrganisationProps>(`${user['@id'].toString()}/organisation`),
      )
    } catch {
      return undefined
    }
  }

  public async findByChamberOfCommerceId(
    chamberOfCommerceId: ChamberOfCommerceId,
  ): Promise<Organisation> {
    const url = new URL('/v1/organisations', this.httpClient.getBaseUrl())
    url.searchParams.append('chamberOfCommerceId', chamberOfCommerceId.toString())

    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<OrganisationProps>>(url.toString()),
      Organisation.prototype.fromJSON,
    )

    if (response['hydra:totalItems'] === 0) {
      throw new LogicException(
        `No organisation found with chamberOfCommerceId ${chamberOfCommerceId.toString()}.`,
      )
    }

    return response['hydra:member'][0]
  }

  public async validate(organisation: OrganisationIri): Promise<void> {
    await this.httpClient.patch(`${organisation.toString()}/validate`)
  }

  public async addMember(organisation: OrganisationIri, payload: AddMemberPayload): Promise<void> {
    await this.httpClient.post(`${organisation.toString()}/members`, payload)
  }

  public async removeMember(member: MemberIri) {
    await this.httpClient.delete(member.toString())
  }
}
