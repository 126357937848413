/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { EngagementProps } from '~/src/Domain/Engagement/Engagement'
import Engagement from '~/src/Domain/Engagement/Engagement'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import EngagementActivatedEvent from '~/src/Domain/Engagement/Event/EngagementActivatedEvent'
import NoCurrentEngagementException from '~/src/Domain/Engagement/Exception/NoCurrentEngagementException'
import type { PhaseProps } from '~/src/Domain/Engagement/Phase'
import Phase from '~/src/Domain/Engagement/Phase'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'

const engagementStore = defineStore('engagement', () => {
  const eventBus = useService('eventBus')
  const engagements = ref<EngagementProps[]>([])
  const currentEngagementIri = ref<string | undefined>(undefined)
  const currentPhaseIri = ref<string | undefined>(undefined)

  const currentEngagement = computed<EngagementProps | undefined>(() =>
    engagements.value.find((e) => e['@id'] === currentEngagementIri.value?.toString()),
  )
  const hasCurrentEngagement = computed(() => currentEngagement.value !== undefined)

  const getCurrentEngagement = () => {
    if (currentEngagement.value === undefined) {
      throw new NoCurrentEngagementException('User has no current engagement.')
    }

    return computed(() => Engagement.prototype.fromJSON(currentEngagement.value as EngagementProps))
  }

  const currentPhase = computed<PhaseProps | undefined>(() =>
    currentEngagement.value?.phases.find((p) => p['@id'] === currentPhaseIri.value?.toString()),
  )
  const hasCurrentPhase = computed(() => currentPhase.value !== undefined)

  const getCurrentPhase = () => {
    if (currentPhase.value === undefined) {
      throw new NoCurrentEngagementException('User has no current phase.')
    }

    return computed(() => Phase.prototype.fromJSON(currentPhase.value as PhaseProps))
  }

  const activate = async (engagement: EngagementIri, phase: PhaseIri) => {
    currentEngagementIri.value = engagement.toString()
    currentPhaseIri.value = phase.toString()

    await eventBus.dispatch(EngagementActivatedEvent.NAME, new EngagementActivatedEvent(engagement, phase))
  }

  const markState = (e: Engagement[]) => {
    engagements.value = e.map((e) => e.toJSON())
  }

  const updateEngagement = (engagement: Engagement) => {
    const index = engagements.value.findIndex((e) => e['@id'] === engagement['@id'].toString())
    if (index === -1) {
      engagements.value.push(engagement.toJSON())
    } else {
      engagements.value[index] = engagement.toJSON()
    }
  }
  const deleteEngagement = (engagement: EngagementIri) => {
    const index = engagements.value.findIndex((e) => e['@id'] === engagement.toString())
    if (index !== -1) {
      engagements.value.splice(index, 1)

      if (currentEngagementIri.value === engagement?.toString()) {
        currentEngagementIri.value = undefined
      }
    }
  }

  const resetState = (): void => {
    engagements.value = []
    currentEngagementIri.value = undefined
    currentPhaseIri.value = undefined
  }

  return {
    engagements: computed(() => engagements.value.map((e) => Engagement.prototype.fromJSON(e))),
    currentEngagement: currentEngagement as Readonly<Ref<EngagementProps | undefined>>,
    currentPhase: currentPhase as Readonly<Ref<PhaseProps | undefined>>,
    hasCurrentEngagement,
    getCurrentEngagement,
    hasCurrentPhase,
    getCurrentPhase,
    activate,
    markState,
    updateEngagement,
    deleteEngagement,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(engagementStore, import.meta.hot))
}

export default engagementStore
