/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import { getService } from '~/src/Infrastructure/Shared/Container/Container'

export default function useService<TKey extends keyof Services>(identifier: TKey): Services[TKey] {
  return getService(identifier)
}
