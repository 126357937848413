/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import ContextMenu from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import DrillThroughContextMenuItem from '~/src/Domain/Shared/ContextMenu/DrillThroughContextMenuItem'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import {
  amountChangePerSubsequentYearIndicatorFilterField,
  specialRelationsInterCompanyFilterField,
  specialRelationsPensionFundsFilterField,
  specialRelationsRelatedPartiesFilterField,
  specialRelationsTaxAuthoritiesFilterField,
} from '~/src/Domain/WorkProgram/Fixtures/20055194/FilterFields'
import SpecialRelationsInterCompanyParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsInterCompanyParameterDefinition20055194'
import SpecialRelationsPensionFundsParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsPensionFundsParameterDefinition20055194'
import SpecialRelationsRelatedPartiesParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsRelatedPartiesParameterDefinition20055194'
import SpecialRelationsTaxAuthoritiesParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsTaxAuthoritiesParameterDefinition20055194'
import {
  accountsReceivableReportDateFilterField,
  businessUnitFilterField,
  fiscalYearFilterField,
} from '~/src/Domain/WorkProgram/Fixtures/General/FilterFields'
import BusinessUnitParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BusinessUnitParameterDefinition'
import FiscalYearParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearParameterDefinition'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import DataTableResultReference from '~/src/Domain/WorkProgram/ResultReference/DataTableResultReference'
import { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import PivotResultReference from '~/src/Domain/WorkProgram/ResultReference/PivotResultReference'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import StepSection from '~/src/Domain/WorkProgram/StepSection'
import StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { usePivotTableDefaultSettings } from '~/src/UserInterface/App/composables/PivotTable/usePivotTableDefaultSettings'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'
import ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'
import WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class AccountsReceivables20055194 implements WorkProgramFixtureInterface {
  public create(): () => WorkProgramProjection {
    return () => {
      const { openAccountsReceivables } = usePivotTableDefaultSettings()

      const parameters = [
        new ParameterProjection(new FiscalYearParameterDefinition()),
        new ParameterProjection(new BusinessUnitParameterDefinition()),
        new ParameterProjection(new SpecialRelationsInterCompanyParameterDefinition20055194()),
        new ParameterProjection(new SpecialRelationsTaxAuthoritiesParameterDefinition20055194()),
        new ParameterProjection(new SpecialRelationsPensionFundsParameterDefinition20055194()),
        new ParameterProjection(new SpecialRelationsRelatedPartiesParameterDefinition20055194()),
      ]

      return new WorkProgramProjection(
        new Slug('accounts-receivables'),
        t('work_programs.20055194.accounts_receivables.title'),
        t('work_programs.20055194.accounts_receivables.text'),
        new StepSectionCollection([
          new StepSection(
            'intro',
            t('work_programs.20055194.accounts_receivables.sections.section_intro.lbl_navigation'),
            [
              new IntroStep(
                new Slug('intro'),
                t('work_programs.20055194.accounts_receivables.steps.step_intro.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_intro.text'),
              ),
            ],
          ),
          new StepSection(
            'regular',
            t(
              'work_programs.20055194.accounts_receivables.sections.section_regular.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('ar-gl-reconciliation'),
                t('work_programs.20055194.accounts_receivables.steps.step_gl_reconciliation.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_gl_reconciliation.text'),
                new PivotResultReference(
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [businessUnitFilterField()],
                  new PivotTableDefinition(
                    'ar-gl-reconciliation',
                    t(
                      'work_programs.20055194.accounts_receivables.steps.step_gl_reconciliation.title',
                    ),
                    [
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'chartOfAccounts.glAccount' },
                    ],
                    [{ key: 'reportDate' }, { key: 'currency' }],
                    [{ key: 'amountOutstanding' }],
                    [{ key: 'debitCreditIndicator' }],
                    openAccountsReceivables.fieldMappings,
                    openAccountsReceivables.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToAccountsReceivableOverviewPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'accounts-receivable-overview',
                            filters: filterValues,
                            sorters: [],
                          })
                          await navigateToAccountsReceivableOverviewPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('open-items'),
                t('work_programs.20055194.accounts_receivables.steps.step_open_items.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_open_items.text'),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('aging'),
                t('work_programs.20055194.accounts_receivables.steps.step_aging.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_aging.text'),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE_AGING,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                    amountChangePerSubsequentYearIndicatorFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-inter-company'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_inter_company.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_inter_company.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                    specialRelationsInterCompanyFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-tax-authorities'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_tax_authorities.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_tax_authorities.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                    specialRelationsTaxAuthoritiesFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-pension-funds'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_pension_funds.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_pension_funds.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                    specialRelationsPensionFundsFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-related-parties'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_related_parties.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_special_relations_related_parties.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                    specialRelationsRelatedPartiesFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('total-balance'),
                t('work_programs.20055194.accounts_receivables.steps.step_total_balance.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_total_balance.text'),
                new PivotResultReference(
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [fiscalYearFilterField(), businessUnitFilterField()],
                  new PivotTableDefinition(
                    'ar-negative-balance',
                    t('work_programs.20055194.accounts_receivables.steps.step_total_balance.title'),
                    [{ key: 'businessUnit.businessUnitName' }, { key: 'customer.customerName' }],
                    [{ key: 'reportDate' }, { key: 'currency' }],
                    [{ key: 'amountOutstanding' }],
                    [
                      { key: 'fiscalYear' },
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'debitCreditIndicator' },
                      { key: 'amountOutstanding' },
                    ],
                    openAccountsReceivables.fieldMappings,
                    openAccountsReceivables.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToAccountsReceivableOverviewPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'accounts-receivable-overview',
                            filters: filterValues,
                            sorters: [],
                          })
                          await navigateToAccountsReceivableOverviewPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('foreign-currency'),
                t('work_programs.20055194.accounts_receivables.steps.step_foreign_currency.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_foreign_currency.text'),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                    new FilterField(
                      'foreignCurrencyIndicator',
                      t(
                        'general.entity_fields.open_accounts_receivable.foreign_currency_indicator',
                      ),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'true')],
                      true,
                      true,
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('cutoff-procedures-summary'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_cutoff_procedures_summary.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_cutoff_procedures_summary.text',
                ),
                new PivotResultReference(
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [fiscalYearFilterField(), businessUnitFilterField()],
                  new PivotTableDefinition(
                    'cutoff-procedures-summary',
                    t(
                      'work_programs.20055194.accounts_receivables.steps.step_cutoff_procedures_summary.title',
                    ),
                    [
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'fiscalYear' },
                      { key: 'reportDate' },
                      { key: 'amountChangePerSubsequentYearIndicator' },
                    ],
                    [{ key: 'currency' }, { key: 'amountChangePerSubsequentYearCurrency' }],
                    [{ key: 'amountOutstanding' }, { key: 'amountChangePerSubsequentYearAmount' }],
                    [{ key: 'debitCreditIndicator' }],
                    openAccountsReceivables.fieldMappings,
                    openAccountsReceivables.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToAccountsReceivableOverviewPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'accounts-receivable-overview',
                            filters: filterValues,
                            sorters: [],
                          })
                          await navigateToAccountsReceivableOverviewPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('cutoff-procedures-details'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_cutoff_procedures_details.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_cutoff_procedures_details.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_RECEIVABLE_CUTOFF,
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsReceivableReportDateFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('largest-debtors'),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_largest_debtors_per_report_date.title',
                ),
                t(
                  'work_programs.20055194.accounts_receivables.steps.step_largest_debtors_per_report_date.text',
                ),
                new PivotResultReference(
                  RepositoryReference.ACCOUNTS_RECEIVABLE,
                  [businessUnitFilterField()],
                  new PivotTableDefinition(
                    'ar-gl-reconciliation',
                    t(
                      'work_programs.20055194.accounts_receivables.steps.step_largest_debtors_per_report_date.title',
                    ),
                    [{ key: 'businessUnit.businessUnitName' }, { key: 'customer.customerName' }],
                    [{ key: 'reportDate' }, { key: 'currency' }],
                    [{ key: 'amountOutstanding' }],
                    [{ key: 'debitCreditIndicator' }],
                    openAccountsReceivables.fieldMappings,
                    openAccountsReceivables.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToAccountsReceivableOverviewPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'accounts-receivable-overview',
                            filters: filterValues,
                            sorters: [],
                          })
                          await navigateToAccountsReceivableOverviewPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
            ],
          ),
          new StepSection(
            'finalize-export',
            t(
              'work_programs.20055194.accounts_receivables.sections.section_finalize_export.lbl_navigation',
            ),
            [
              new FinalStep(
                new Slug('finalize'),
                t('work_programs.20055194.accounts_receivables.steps.step_finalize.title'),
                t('work_programs.20055194.accounts_receivables.steps.step_finalize.text'),
              ),
            ],
          ),
        ]),
        parameters,
        [AnalysisType.GENERAL_LEDGER, /** AnalysisType.BANK, */ AnalysisType.ACCOUNTS_RECEIVABLE],
        new ChamberOfCommerceId('20055194'),
      )
    }
  }
}
