/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum RepositoryReference {
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  ACCOUNTS_PAYABLE_MULTIPLE_BANK_ACCOUNTS_SAME_SUPPLIER_NAME = 'ACCOUNTS_PAYABLE_MULTIPLE_BANK_ACCOUNTS_SAME_SUPPLIER_NAME',
  ACCOUNTS_PAYABLE_MULTIPLE_SUPPLIER_NAMES_SAME_BANK_ACCOUNT = 'ACCOUNTS_PAYABLE_MULTIPLE_SUPPLIER_NAMES_SAME_BANK_ACCOUNT',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_BALANCE_PER_DAY = 'BANK_BALANCE_PER_DAY',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BANK_STATEMENT_ENTRY_DETAIL = 'BANK_STATEMENT_ENTRY_DETAIL',
  BANK_STATEMENT_ENTRY_DETAIL_CORRUPTION_PERCEPTION_INDEX = 'BANK_STATEMENT_ENTRY_DETAIL_CORRUPTION_PERCEPTION_INDEX',
  BANK_STATEMENT_ENTRY_DETAIL_OVERVIEW = 'BANK_STATEMENT_ENTRY_DETAIL_OVERVIEW',
  BANK_STATEMENT_ENTRY_MULTIPLE_BANK_ACCOUNT = 'BANK_STATEMENT_ENTRY_MULTIPLE_BANK_ACCOUNT',
  BANK_STATEMENT_ENTRY_MULTIPLE_CUSTOMER = 'BANK_STATEMENT_ENTRY_MULTIPLE_CUSTOMER',
  BANK_STATEMENT_ENTRY_VALIDATION = 'BANK_STATEMENT_ENTRY_VALIDATION',
  BANK_STATEMENT_JOURNAL_ENTRY_DAILY_RECONCILIATION = 'BANK_STATEMENT_JOURNAL_ENTRY_DAILY_RECONCILIATION',
  BANK_STATEMENT_JOURNAL_ENTRY_MONTHLY_RECONCILIATION = 'BANK_STATEMENT_JOURNAL_ENTRY_MONTHLY_RECONCILIATION',
  BANK_STATEMENT_JOURNAL_ENTRY_YEARLY_RECONCILIATION = 'BANK_STATEMENT_JOURNAL_ENTRY_YEARLY_RECONCILIATION',
  BANK_STATEMENT_VALIDATION = 'BANK_STATEMENT_VALIDATION',
  BUSINESS_UNIT_OVERVIEW = 'BUSINESS_UNIT_OVERVIEW',
  CHARTS_OF_ACCOUNTS = 'CHARTS_OF_ACCOUNTS',
  COUNT_PER_PERIOD = 'COUNT_PER_PERIOD',
  CUSTOMER = 'CUSTOMER',
  DAYS_LAG = 'DAYS_LAG',
  ENTERED_DATE = 'ENTERED_DATE',
  ENTERED_TIME = 'ENTERED_TIME',
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
  OTHER_SIDE_OF_JOURNAL_ENTRY = 'OTHER_SIDE_OF_JOURNAL_ENTRY',
  PREPARER_OVERVIEW = 'PREPARER_OVERVIEW',
  SOURCE_OVERVIEW = 'SOURCE_OVERVIEW',
  SUPPLIER = 'SUPPLIER',
  TRIAL_BALANCE_ROLL_FORWARD = 'TRIAL_BALANCE_ROLL_FORWARD',
  TWO_WAY_CORRELATION = 'TWO_WAY_CORRELATION',
  UNBALANCED_JOURNAL_ENTRY = 'UNBALANCED_JOURNAL_ENTRY',
}
