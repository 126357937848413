/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import DataRequestRecipientAuthenticatedEvent from '~/src/Domain/DataRequest/Event/DataRequestRecipientAuthenticatedEvent'
import type UserApiRepositoryInterface from '~/src/Domain/Identity/UserApiRepositoryInterface'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(DataRequestRecipientAuthenticatedEvent.NAME, 'dataRequestRecipientAuthenticatedEventHandler')
export default class DataRequestRecipientAuthenticatedEventHandler extends EventHandlerInterface<DataRequestRecipientAuthenticatedEvent> {
  private readonly cookies: CookiesInterface
  private readonly userApiRepository: UserApiRepositoryInterface
  private readonly userStoreRepository: UserStoreRepositoryInterface

  public constructor({ cookies, userApiRepository, userStoreRepository }: Services) {
    super()
    this.cookies = cookies
    this.userApiRepository = userApiRepository
    this.userStoreRepository = userStoreRepository
  }

  public async handle(_event: DataRequestRecipientAuthenticatedEvent): Promise<void> {
    const user = await this.userApiRepository.findUser()

    const tokenExpiry = this.cookies.get<number>('auth_expiration')
    this.userStoreRepository.markState(user, BetterDateTime.fromUnixTimestamp(tokenExpiry))
  }
}
