/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DataIntegrityValidator } from '~/src/Domain/DataRequest/DataIntegrityValidator'
import { DataIntegrityPillar } from '~/src/Domain/DataRequest/Validation/DataIntegrityPillar'
import type { Severity } from '~/src/Domain/DataRequest/Validation/Severity'
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface DataEnrichmentFindingProps extends JsonValueObjectProps {
  pillar: DataIntegrityPillar.DATA_ENRICHMENT
  severity: Severity
  code: DataIntegrityValidator
  context: Record<string | number, any>
}

export default class DataEnrichmentFinding
implements JsonValueObjectInterface<DataEnrichmentFinding, DataEnrichmentFindingProps> {
  private constructor(
    public readonly severity: Severity,
    public readonly code: DataIntegrityValidator,
    public readonly context: Record<string | number, any>,
  ) {}

  public fromJSON(values: DataEnrichmentFindingProps): DataEnrichmentFinding {
    return new DataEnrichmentFinding(values.severity, values.code, values.context)
  }

  public toJSON(): DataEnrichmentFindingProps {
    return {
      pillar: DataIntegrityPillar.DATA_ENRICHMENT,
      severity: this.severity,
      code: this.code,
      context: this.context,
    }
  }
}
