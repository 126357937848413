/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { NuxtApp, navigateTo as NuxtNavigateTo } from '#app'
import type { BuildResolverOptions } from 'awilix/lib/resolvers'
import type { Router as VueRouter } from 'vue-router'
import {
  asClass,
  asFunction,
  asValue,
  type Constructor,
  createContainer,
  InjectionMode,
  Lifetime,
} from 'awilix'
import AcceptCoaMappingWithErrorsCommandHandler from '~/src/Application/CoaMapping/Command/AcceptCoaMappingWithErrorsCommandHandler'
import CreateCoaMappingCommandHandler from '~/src/Application/CoaMapping/Command/CreateCoaMappingCommandHandler'
import FulfillCoaMappingCommandHandler from '~/src/Application/CoaMapping/Command/FulfillCoaMappingCommandHandler'
import UpdateCoaMappingCommandHandler from '~/src/Application/CoaMapping/Command/UpdateCoaMappingCommandHandler'
import CoaMappingDeletedEventHandler from '~/src/Application/CoaMapping/Event/CoaMappingDeletedEventHandler'
import CoaMappingEngagementActivatedEventHandler from '~/src/Application/CoaMapping/Event/CoaMappingEngagementActivatedEventHandler'
import CoaMappingUpdatedEventHandler from '~/src/Application/CoaMapping/Event/CoaMappingUpdatedEventHandler'
import DataCubeDeletedEventHandler from '~/src/Application/DataCube/Event/DataCubeDeletedEventHandler'
import DataCubeEngagementActivatedEventHandler from '~/src/Application/DataCube/Event/DataCubeEngagementActivatedEventHandler'
import DataCubeUpdatedEventHandler from '~/src/Application/DataCube/Event/DataCubeUpdatedEventHandler'
import DeleteDataRequestCommandHandler from '~/src/Application/DataRequest/Command/DeleteDataRequestCommandHandler'
import DuplicateDataRequestCommandHandler from '~/src/Application/DataRequest/Command/DuplicateDataRequestCommandHandler'
import DataRequestDeletedEventHandler from '~/src/Application/DataRequest/Event/DataRequestDeletedEventHandler'
import DataRequestEngagementActivatedEventHandler from '~/src/Application/DataRequest/Event/DataRequestEngagementActivatedEventHandler'
import DataRequestRecipient2faInitiatedEventHandler from '~/src/Application/DataRequest/Event/DataRequestRecipient2faInitiatedEventHandler'
import DataRequestRecipientAuthenticatedEventHandler from '~/src/Application/DataRequest/Event/DataRequestRecipientAuthenticatedEventHandler'
import DataRequestUpdatedEventHandler from '~/src/Application/DataRequest/Event/DataRequestUpdatedEventHandler'
import AddParameterCommandHandler from '~/src/Application/Engagement/Command/AddParameterCommandHandler'
import ArchiveEngagementCommandHandler from '~/src/Application/Engagement/Command/ArchiveEngagementCommandHandler'
import ChangeParameterCommandHandler from '~/src/Application/Engagement/Command/ChangeParameterCommandHandler'
import DeleteParameterCommandHandler from '~/src/Application/Engagement/Command/DeleteParameterCommandHandler'
import EngagementArchivedEventHandler from '~/src/Application/Engagement/Event/EngagementArchivedEventHandler'
import EngagementUpdatedEventHandler from '~/src/Application/Engagement/Event/EngagementUpdatedEventHandler'
import CreateCoaMappingExportCommandHandler from '~/src/Application/Export/Command/CreateCoaMappingExportCommandHandler'
import CreateDataRequestArchiveExportCommandHandler from '~/src/Application/Export/Command/CreateDataRequestArchiveExportCommandHandler'
import CreateDataRequestFileExportCommandHandler from '~/src/Application/Export/Command/CreateDataRequestFileExportCommandHandler'
import CreateOtherSideOfJournalEntriesExportCommandHandler from '~/src/Application/Export/Command/CreateOtherSideOfJournalEntriesExportCommandHandler'
import CreateTransactionalDataPivotExportCommandHandler from '~/src/Application/Export/Command/CreateTransactionalDataPivotExportCommandHandler'
import CreateTransactionalDataTableExportCommandHandler from '~/src/Application/Export/Command/CreateTransactionalDataTableExportCommandHandler'
import CreateTwoWayCorrelationsExportCommandHandler from '~/src/Application/Export/Command/CreateTwoWayCorrelationsExportCommandHandler'
import CreateWorkProgramExportCommandHandler from '~/src/Application/Export/Command/CreateWorkProgramExportCommandHandler'
import DownloadExportCommandHandler from '~/src/Application/Export/Command/DownloadExportCommandHandler'
import ExportDeletedEventHandler from '~/src/Application/Export/Event/ExportDeletedEventHandler'
import ExportPreparedEventHandler from '~/src/Application/Export/Event/ExportPreparedEventHandler'
import ExportUpdatedEventHandler from '~/src/Application/Export/Event/ExportUpdatedEventHandler'
import ExportUserAuthenticatedEventHandler from '~/src/Application/Export/Event/ExportUserAuthenticatedEventHandler'
import ExportUserLogoutEventHandler from '~/src/Application/Export/Event/ExportUserLogoutEventHandler'
import User2faInitiatedEventHandler from '~/src/Application/Identity/Event/User2faInitiatedEventHandler'
import UserAuthenticatedEventHandler from '~/src/Application/Identity/Event/UserAuthenticatedEventHandler'
import UserLogoutEventHandler from '~/src/Application/Identity/Event/UserLogoutEventHandler'
import UserUpdatedEventHandler from '~/src/Application/Identity/Event/UserUpdatedEventHandler'
import OrganisationUpdatedEventHandler from '~/src/Application/Organisation/Event/OrganisationUpdatedEventHandler'
import { initializeCommandHandlers } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import { initializeEventHandlers } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import { initializeQueryHandlers } from '~/src/Application/Shared/MessageBus/AsQueryHandler'
import type CommandBusInterface from '~/src/Application/Shared/MessageBus/CommandBusInterface'
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type QueryBusInterface from '~/src/Application/Shared/MessageBus/QueryBusInterface'
import type RouterInterface from '~/src/Application/Shared/Router/RouterInterface'
import UpdateLocaleCommandHandler from '~/src/Application/Shared/Translation/Command/UpdateLocaleCommandHandler'
import ChangeWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/ChangeWorkProgramCommandHandler'
import DeleteWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/DeleteWorkProgramCommandHandler'
import DuplicateWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/DuplicateWorkProgramCommandHandler'
import FinishWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/FinishWorkProgramCommandHandler'
import MarkDataPivotStepResultWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/MarkDataPivotStepResultWorkProgramCommandHandler'
import MarkDataTableStepResultWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/MarkDataTableStepResultWorkProgramCommandHandler'
import ReopenWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/ReopenWorkProgramCommandHandler'
import StartWorkProgramCommandHandler from '~/src/Application/WorkProgram/Command/StartWorkProgramCommandHandler'
import WorkProgramDeletedEventHandler from '~/src/Application/WorkProgram/Event/WorkProgramDeletedEventHandler'
import WorkProgramEngagementActivatedEventHandler from '~/src/Application/WorkProgram/Event/WorkProgramEngagementActivatedEventHandler'
import WorkProgramLanguageUpdatedEventHandler from '~/src/Application/WorkProgram/Event/WorkProgramLanguageUpdatedEventHandler'
import WorkProgramUpdatedEventHandler from '~/src/Application/WorkProgram/Event/WorkProgramUpdatedEventHandler'
import GenericAccountsPayableDataRequestFixture from '~/src/Domain/AccountsPayable/Application/GenericAccountsPayable/GenericAccountsPayableDataRequestFixture'
import OpenAccountsPayableFileType from '~/src/Domain/AccountsPayable/FileType/OpenAccountsPayableFileType'
import GenericAccountsReceivableDataRequestFixture from '~/src/Domain/AccountsReceivable/Application/GenericAccountsReceivableApplication/GenericAccountsReceivableDataRequestFixture'
import OpenAccountsReceivableFileType from '~/src/Domain/AccountsReceivable/FileType/OpenAccountsReceivableFileType'
import AbnAmroMijnZakelijkDataRequestFixture from '~/src/Domain/Bank/Application/AbnAmroZakelijk/AbnAmroMijnZakelijkDataRequestFixture'
import Camt053BankDataRequestFixture from '~/src/Domain/Bank/Application/Camt053/Camt053BankDataRequestFixture'
import Camt05300102FileType from '~/src/Domain/Bank/Application/Camt053/FileType/Camt05300102FileType'
import Pain001001FileType from '~/src/Domain/Bank/Application/Camt053/FileType/Pain001001FileType'
import IngInsideBusinessPaymentsDataRequestFixture from '~/src/Domain/Bank/Application/IngInsideBusiness/IngInsideBusinessPaymentsDataRequestFixture'
import IngMijnZakelijkDataRequestFixture from '~/src/Domain/Bank/Application/IngZakelijk/IngMijnZakelijkDataRequestFixture'
import RabobankBatchExportDutchFileType from '~/src/Domain/Bank/Application/Rabobank/FileType/RabobankBatchExportDutchFileType'
import RabobankDataRequestFixture from '~/src/Domain/Bank/Application/Rabobank/RabobankDataRequestFixture'
import VanLanschotDataRequestFixture from '~/src/Domain/Bank/Application/VanLanschot/VanLanschotDataRequestFixture'
import type CoaMappingApiRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingApiRepositoryInterface'
import type CoaMappingStoreRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingStoreRepositoryInterface'
import type DataCubeApiRepositoryInterface from '~/src/Domain/DataCube/DataCubeApiRepositoryInterface'
import type DataCubeStoreRepositoryInterface from '~/src/Domain/DataCube/DataCubeStoreRepositoryInterface'
import type DataRequestApiRepositoryInterface from '~/src/Domain/DataRequest/DataRequestApiRepositoryInterface'
import type DataRequestorApiRepositoryInterface from '~/src/Domain/DataRequest/DataRequestorApiRepositoryInterface'
import type DataRequestorStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestorStoreRepositoryInterface'
import type DataRequestSecurityInterface from '~/src/Domain/DataRequest/DataRequestSecurityInterface'
import type DataRequestStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestStoreRepositoryInterface'
import FileTypeFactory from '~/src/Domain/DataRequest/File/FileType/FileTypeFactory'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import DataRequestFixtureFactory from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureFactory'
import GenericDataRequestFixture from '~/src/Domain/DataRequest/Fixtures/GenericDataRequestFixture'
import IntroductionStepStatusBuilder from '~/src/Domain/DataRequest/Step/Status/IntroductionStepStatusBuilder'
import StatusBuilderFactory from '~/src/Domain/DataRequest/Step/Status/StatusBuilderFactory'
import StepStatusBuilder from '~/src/Domain/DataRequest/Step/Status/StepStatusBuilder'
import SummaryStepStatusBuilder from '~/src/Domain/DataRequest/Step/Status/SummaryStepStatusBuilder'
import type EngagementApiRepositoryInterface from '~/src/Domain/Engagement/EngagementApiRepositoryInterface'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type ExportStoreRepositoryInterface from '~/src/Domain/Export/ExportStoreRepositoryInterface'
import ExactGlobeNextDataRequestFixture from '~/src/Domain/GeneralLedger/Application/ExactGlobeNext/ExactGlobeNextDataRequestFixture'
import ExactOnlineDataRequestFixture from '~/src/Domain/GeneralLedger/Application/ExactOnline/ExactOnlineDataRequestFixture'
import BusinessUnitFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/BusinessUnitFileType'
import BusinessUserFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/BusinessUserFileType'
import CustomerFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/CustomerFileType'
import JournalEntryFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/JournalEntryFileType'
import SourceFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/SourceFileType'
import SupplierFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/SupplierFileType'
import TrialBalanceFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/TrialBalanceFileType'
import GenericGeneralLedgerDataRequestFixture from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/GenericGeneralLedgerDataRequestFixture'
import AnnualReportFileType from '~/src/Domain/GeneralLedger/Application/Twinfield/FileType/AnnualReportFileType'
import GeneralLedgerDetailsFileType from '~/src/Domain/GeneralLedger/Application/Twinfield/FileType/GeneralLedgerDetailsFileType'
import TwinfieldDataRequestFixture from '~/src/Domain/GeneralLedger/Application/Twinfield/TwinfieldDataRequestFixture'
import type SecurityInterface from '~/src/Domain/Identity/SecurityInterface'
import type UserApiRepositoryInterface from '~/src/Domain/Identity/UserApiRepositoryInterface'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import type OrganisationApiRepositoryInterface from '~/src/Domain/Organisation/OrganisationApiRepositoryInterface'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import type ClockInterface from '~/src/Domain/Shared/Clock/ClockInterface'
import SystemClock from '~/src/Domain/Shared/Clock/SystemClock'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import type FilterSorterStoreRepositoryInterface from '~/src/Domain/Shared/Filter/FilterSorterStoreRepositoryInterface'
import type UuidEncoderInterface from '~/src/Domain/Shared/Identifier/UuidEncoderInterface'
import type LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'
import type PivotTableStoreRepositoryInterface from '~/src/Domain/Shared/PivotTable/PivotTableStoreRepositoryInterface'
import type TranslatorInterface from '~/src/Domain/Shared/Translation/TranslatorInterface'
import AccountsReceivables20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/AccountsReceivables20055194'
import BankPayments20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/BankPayments20055194'
import GeneralLedger20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/GeneralLedger20055194'
import AccountsPayables24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/AccountsPayables24342109'
import InternalControl24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/InternalControl24342109'
import AccountsPayablesGlAccountsParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/AccountsPayablesGlAccountsParameterDefinition24342109'
import BusinessUnitParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/BusinessUnitParameterDefinition24342109'
import EndDateReportingPeriodParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/EndDateReportingPeriodParameterDefinition24342109'
import FiscalYearParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/FiscalYearParameterDefinition24342109'
import ManualBookingSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/ManualBookingSourcesParameterDefinition24342109'
import PurchasingSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/PurchasingSourcesParameterDefinition24342109'
import TrivialAmountParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/TrivialAmountParameterDefinition24342109'
import UnusualBusinessUsersParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/UnusualBusinessUsersParameterDefinition24342109'
import ManagementFraud from '~/src/Domain/WorkProgram/Fixtures/General/ManagementFraud'
import BookingDelayParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BookingDelayParameterDefinition'
import BusinessUnitParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BusinessUnitParameterDefinition'
import EntriesNearEndPeriodEndDateParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/EntriesNearEndPeriodEndDateParameterDefinition'
import EntriesNearEndPeriodStartDateParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/EntriesNearEndPeriodStartDateParameterDefinition'
import FiscalYearCurrentEndParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentEndParameterDefinition'
import FiscalYearCurrentStartParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentStartParameterDefinition'
import FiscalYearParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearParameterDefinition'
import FiscalYearSubsequentStartParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearSubsequentStartParameterDefinition'
import ManagementBusinessUserParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ManagementBusinessUserParameterDefinition'
import ManualBookingSourcesParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ManualBookingSourcesParameterDefinition'
import OfficeHoursEndParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/OfficeHoursEndParameterDefinition'
import OfficeHoursStartParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/OfficeHoursStartParameterDefinition'
import PerformanceMaterialityThresholdParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/PerformanceMaterialityThresholdParameterDefinition'
import ProfitAndLossAccountsParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ProfitAndLossAccountsParameterDefinition'
import UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition'
import ParameterDefinitionFactory from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionFactory'
import WorkProgramFixtureFactory from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureFactory'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type WorkProgramStoreRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramStoreRepositoryInterface'
import CoaMappingApiRepository from '~/src/Infrastructure/CoaMapping/CoaMappingApiRepository'
import CoaMappingStoreRepository from '~/src/Infrastructure/CoaMapping/CoaMappingStoreRepository'
import CoaMappingPublisher from '~/src/Infrastructure/CoaMapping/PubSub/CoaMappingPublisher'
import DataCubeApiRepository from '~/src/Infrastructure/DataCube/DataCubeApiRepository'
import DataCubeStoreRepository from '~/src/Infrastructure/DataCube/DataCubeStoreRepository'
import DataCubePublisher from '~/src/Infrastructure/DataCube/PubSub/DataCubePublisher'
import DataRequestApiRepository from '~/src/Infrastructure/DataRequest/DataRequestApiRepository'
import DataRequestorApiRepository from '~/src/Infrastructure/DataRequest/DataRequestorApiRepository'
import DataRequestorStoreRepository from '~/src/Infrastructure/DataRequest/DataRequestorStoreRepository'
import DataRequestSecurity from '~/src/Infrastructure/DataRequest/DataRequestSecurity'
import DataRequestStoreRepository from '~/src/Infrastructure/DataRequest/DataRequestStoreRepository'
import DataRequestTokenExpiryListener from '~/src/Infrastructure/DataRequest/DataRequestTokenExpiryListener'
import DataRequestPublisher from '~/src/Infrastructure/DataRequest/PubSub/DataRequestPublisher'
import EngagementActivator from '~/src/Infrastructure/Engagement/EngagementActivator'
import EngagementApiRepository from '~/src/Infrastructure/Engagement/EngagementApiRepository'
import EngagementStoreRepository from '~/src/Infrastructure/Engagement/EngagementStoreRepository'
import EngagementPublisher from '~/src/Infrastructure/Engagement/PubSub/EngagementPublisher'
import ExportApiRepository from '~/src/Infrastructure/Export/ExportApiRepository'
import ExportStoreRepository from '~/src/Infrastructure/Export/ExportStoreRepository'
import ExportPublisher from '~/src/Infrastructure/Export/PubSub/ExportPublisher'
import UserPublisher from '~/src/Infrastructure/Identity/PubSub/UserPublisher'
import Security from '~/src/Infrastructure/Identity/Security'
import TokenExpiryListener from '~/src/Infrastructure/Identity/TokenExpiryListener'
import UserApiRepository from '~/src/Infrastructure/Identity/UserApiRepository'
import UserStoreRepository from '~/src/Infrastructure/Identity/UserStoreRepository'
import OrganisationActivator from '~/src/Infrastructure/Organisation/OrganisationActivator'
import OrganisationApiRepository from '~/src/Infrastructure/Organisation/OrganisationApiRepository'
import OrganisationStoreRepository from '~/src/Infrastructure/Organisation/OrganisationStoreRepository'
import OrganisationPublisher from '~/src/Infrastructure/Organisation/PubSub/OrganisationPublisher'
import Cookies from '~/src/Infrastructure/Shared/Cookies/Cookies'
import FilterSorterStoreRepository from '~/src/Infrastructure/Shared/Filter/FilterSorterStoreRepository'
import HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'
import UuidEncoder from '~/src/Infrastructure/Shared/Identifier/UuidEncoder'
import DebugLogger from '~/src/Infrastructure/Shared/Logger/DebugLogger'
import SentryLogger from '~/src/Infrastructure/Shared/Logger/SentryLogger'
import CommandBus from '~/src/Infrastructure/Shared/MessageBus/CommandBus'
import EventBus from '~/src/Infrastructure/Shared/MessageBus/EventBus'
import QueryBus from '~/src/Infrastructure/Shared/MessageBus/QueryBus'
import PivotTableStoreRepository from '~/src/Infrastructure/Shared/PivotTable/PivotTableStoreRepository'
import PivotTableToSyncfusionPivotTransformer from '~/src/Infrastructure/Shared/PivotTable/PivotTableToSyncfusionPivotTransformer'
import SyncfusionPivotToPivotTableTransformer from '~/src/Infrastructure/Shared/PivotTable/SyncfusionPivotToPivotTableTransformer'
import { initializePublishers } from '~/src/Infrastructure/Shared/PubSub/AsPublisher'
import Publisher from '~/src/Infrastructure/Shared/PubSub/Publisher'
import Subscriber from '~/src/Infrastructure/Shared/PubSub/Subscriber'
import Router from '~/src/Infrastructure/Shared/Router/Router'
import HtmlSanitizer from '~/src/Infrastructure/Shared/Sanitizer/HtmlSanitizer'
import Translator from '~/src/Infrastructure/Shared/Translation/Translator'
import WorkProgramPublisher from '~/src/Infrastructure/WorkProgram/PubSub/WorkProgramPublisher'
import WorkProgramApiRepository from '~/src/Infrastructure/WorkProgram/WorkProgramApiRepository'
import WorkProgramResultIriBuilder from '~/src/Infrastructure/WorkProgram/WorkProgramResultIriBuilder'
import WorkProgramStoreRepository from '~/src/Infrastructure/WorkProgram/WorkProgramStoreRepository'

export interface Services {
  // VALUES
  nuxtFetch: typeof $fetch
  nuxtI18n: NuxtApp['$i18n']
  nuxtLocalePath: NuxtApp['$localePath']
  nuxtNavigateTo: typeof NuxtNavigateTo
  nuxtRouter: VueRouter
  hostname: string

  // COA MAPPING
  coaMappingApiRepository: CoaMappingApiRepositoryInterface
  coaMappingStoreRepository: CoaMappingStoreRepositoryInterface
  coaMappingPublisher: CoaMappingPublisher
  createCoaMappingCommandHandler: CreateCoaMappingCommandHandler
  updateCoaMappingCommandHandler: UpdateCoaMappingCommandHandler
  fulfillCoaMappingCommandHandler: FulfillCoaMappingCommandHandler
  acceptCoaMappingWithErrorsCommandHandler: AcceptCoaMappingWithErrorsCommandHandler
  coaMappingEngagementActivatedEventHandler: CoaMappingEngagementActivatedEventHandler
  coaMappingUpdatedEventHandler: CoaMappingUpdatedEventHandler
  coaMappingDeletedEventHandler: CoaMappingDeletedEventHandler

  // DATA CUBE
  dataCubeApiRepository: DataCubeApiRepositoryInterface
  dataCubeStoreRepository: DataCubeStoreRepositoryInterface
  dataCubeEngagementActivatedEventHandler: DataCubeEngagementActivatedEventHandler
  dataCubeUpdatedEventHandler: DataCubeUpdatedEventHandler
  dataCubeDeletedEventHandler: DataCubeDeletedEventHandler
  dataCubePublisher: DataCubePublisher

  // DATA REQUEST
  dataRequestSecurity: DataRequestSecurityInterface
  dataRequestTokenExpiryListener: DataRequestTokenExpiryListener
  dataRequestApiRepository: DataRequestApiRepositoryInterface
  dataRequestStoreRepository: DataRequestStoreRepositoryInterface
  dataRequestorApiRepository: DataRequestorApiRepositoryInterface
  dataRequestorStoreRepository: DataRequestorStoreRepositoryInterface
  dataRequestUpdatedEventHandler: DataRequestUpdatedEventHandler
  dataRequestDeletedEventHandler: DataRequestDeletedEventHandler
  dataRequestEngagementActivatedEventHandler: DataRequestEngagementActivatedEventHandler
  dataRequestRecipient2faInitiatedEventHandler: DataRequestRecipient2faInitiatedEventHandler
  dataRequestRecipientAuthenticatedEventHandler: DataRequestRecipientAuthenticatedEventHandler
  deleteDataRequestCommandHandler: DeleteDataRequestCommandHandler
  duplicateDataRequestCommandHandler: DuplicateDataRequestCommandHandler
  fileTypeFactory: FileTypeFactory
  statusBuilderFactory: StatusBuilderFactory
  dataRequestFixtureFactory: DataRequestFixtureFactory
  dataRequestPublisher: DataRequestPublisher

  // ENGAGEMENT
  engagementApiRepository: EngagementApiRepositoryInterface
  engagementStoreRepository: EngagementStoreRepositoryInterface
  engagementActivator: EngagementActivator
  archiveEngagementCommandHandler: ArchiveEngagementCommandHandler
  engagementUpdatedEventHandler: EngagementUpdatedEventHandler
  engagementArchivedEventHandler: EngagementArchivedEventHandler
  addParameterCommandHandler: AddParameterCommandHandler
  changeParameterCommandHandler: ChangeParameterCommandHandler
  deleteParameterCommandHandler: DeleteParameterCommandHandler
  engagementPublisher: EngagementPublisher

  // GENERAL LEDGER

  // EXPORT
  exportApiRepository: ExportApiRepositoryInterface
  exportStoreRepository: ExportStoreRepositoryInterface
  createCoaMappingExportCommandHandler: CreateCoaMappingExportCommandHandler
  createDataRequestArchiveExportCommandHandler: CreateDataRequestArchiveExportCommandHandler
  createDataRequestFileExportCommandHandler: CreateDataRequestFileExportCommandHandler
  createTransactionalDataTableExportCommandHandler: CreateTransactionalDataTableExportCommandHandler
  createTransactionalDataPivotExportCommandHandler: CreateTransactionalDataPivotExportCommandHandler
  createWorkProgramExportCommandHandler: CreateWorkProgramExportCommandHandler
  createTwoWayCorrelationsExportCommandHandler: CreateTwoWayCorrelationsExportCommandHandler
  createOtherSideOfJournalEntriesExportCommandHandler: CreateOtherSideOfJournalEntriesExportCommandHandler
  downloadExportCommandHandler: DownloadExportCommandHandler
  exportUserAuthenticatedEventHandler: ExportUserAuthenticatedEventHandler
  exportUserLogoutEventHandler: ExportUserLogoutEventHandler
  exportUpdatedEventHandler: ExportUpdatedEventHandler
  exportDeletedEventHandler: ExportDeletedEventHandler
  exportPreparedEventHandler: ExportPreparedEventHandler
  exportPublisher: ExportPublisher

  // IDENTITY
  security: SecurityInterface
  userApiRepository: UserApiRepositoryInterface
  userStoreRepository: UserStoreRepositoryInterface
  uuidEncoder: UuidEncoderInterface
  tokenExpiryListener: TokenExpiryListener
  userAuthenticatedEventHandler: UserAuthenticatedEventHandler
  user2faInitiatedEventHandler: User2faInitiatedEventHandler
  userLogoutEventHandler: UserLogoutEventHandler
  userUpdatedEventHandler: UserUpdatedEventHandler
  userPublisher: UserPublisher

  // ORGANISATION
  organisationActivator: OrganisationActivator
  organisationApiRepository: OrganisationApiRepositoryInterface
  organisationStoreRepository: OrganisationStoreRepositoryInterface
  organisationUpdatedEventHandler: OrganisationUpdatedEventHandler
  organisationPublisher: OrganisationPublisher

  // WORKPROGRAM
  workProgramApiRepository: WorkProgramApiRepositoryInterface
  workProgramStoreRepository: WorkProgramStoreRepositoryInterface
  workProgramEngagementActivatedEventHandler: WorkProgramEngagementActivatedEventHandler
  workProgramLanguageUpdatedEventHandler: WorkProgramLanguageUpdatedEventHandler
  workProgramUpdatedEventHandler: WorkProgramUpdatedEventHandler
  workProgramDeletedEventHandler: WorkProgramDeletedEventHandler
  startWorkProgramCommandHandler: StartWorkProgramCommandHandler
  changeWorkProgramCommandHandler: ChangeWorkProgramCommandHandler
  duplicateWorkProgramCommandHandler: DuplicateWorkProgramCommandHandler
  markDataPivotStepResultWorkProgramCommandHandler: MarkDataPivotStepResultWorkProgramCommandHandler
  markDataTableStepResultWorkProgramCommandHandler: MarkDataTableStepResultWorkProgramCommandHandler
  finishWorkProgramCommandHandler: FinishWorkProgramCommandHandler
  reopenWorkProgramCommandHandler: ReopenWorkProgramCommandHandler
  deleteWorkProgramCommandHandler: DeleteWorkProgramCommandHandler
  workProgramResultIriBuilder: WorkProgramResultIriBuilder
  parameterDefinitionFactory: ParameterDefinitionFactory
  workProgramFixtureFactory: WorkProgramFixtureFactory
  workProgramPublisher: WorkProgramPublisher

  // SHARED
  clock: ClockInterface
  cookies: CookiesInterface
  commandBus: CommandBusInterface
  eventBus: EventBusInterface
  queryBus: QueryBusInterface
  filterSorterStoreRepository: FilterSorterStoreRepositoryInterface
  httpClient: HttpClient
  logger: LoggerInterface
  pivotTableStoreRepository: PivotTableStoreRepositoryInterface
  pivotTableToSyncfusionPivotTransformer: PivotTableToSyncfusionPivotTransformer
  syncfusionPivotToPivotTableTransformer: SyncfusionPivotToPivotTableTransformer
  subscriber: Subscriber
  publisher: Publisher
  router: RouterInterface
  htmlSanitizer: HtmlSanitizer
  translator: TranslatorInterface
  updateLocaleCommandHandler: UpdateLocaleCommandHandler
}

const container = createContainer<Services>({ injectionMode: InjectionMode.PROXY, strict: true })
export type Container = typeof container

const registerValue = <TKey extends keyof Services, TValue>(key: TKey, value: TValue): void => {
  container.register(key, asValue(value))
}

const registerFunction = <TKey extends keyof Services, TValue>(
  key: TKey,
  fn: (args: Pick<Services, TKey>) => TValue,
  opts?: BuildResolverOptions<TValue>,
): void => {
  container.register(key, asFunction(fn, {
    lifetime: Lifetime.SCOPED,
    ...opts,
  }))
}

export const getService = <TKey extends keyof Services>(key: TKey): Services[TKey] =>
  container.resolve(key)

const registerService = <TKey extends keyof Services>(key: TKey, service: Constructor<Services[TKey]>): void => {
  container.register(key, asClass(service, {
    lifetime: Lifetime.SCOPED,
  }))
}

type BuildContainer = (
  config: NuxtApp['$config'],
  nuxtApp: NuxtApp,
  fetch: typeof $fetch,
  nuxtNavigateTo: typeof NuxtNavigateTo,
) => void

/**
 * Build the container
 */
export const buildContainer: BuildContainer = (
  config: NuxtApp['$config'],
  nuxtApp: NuxtApp,
  fetch: typeof $fetch,
  nuxtNavigateTo: typeof NuxtNavigateTo,
): void => {
  registerValue('hostname', config.public.HOST_NAME)
  registerValue('nuxtFetch', fetch)
  registerValue('nuxtI18n', nuxtApp.$i18n)
  registerValue('nuxtLocalePath', nuxtApp.$localePath)
  registerValue('nuxtNavigateTo', nuxtNavigateTo)
  registerValue('nuxtRouter', nuxtApp.$router)

  // COA MAPPING
  registerService('coaMappingApiRepository', CoaMappingApiRepository)
  registerService('coaMappingStoreRepository', CoaMappingStoreRepository)
  registerService('coaMappingPublisher', CoaMappingPublisher)
  registerService('createCoaMappingCommandHandler', CreateCoaMappingCommandHandler)
  registerService('updateCoaMappingCommandHandler', UpdateCoaMappingCommandHandler)
  registerService('fulfillCoaMappingCommandHandler', FulfillCoaMappingCommandHandler)
  registerService('acceptCoaMappingWithErrorsCommandHandler', AcceptCoaMappingWithErrorsCommandHandler)
  registerService('coaMappingEngagementActivatedEventHandler', CoaMappingEngagementActivatedEventHandler)
  registerService('coaMappingUpdatedEventHandler', CoaMappingUpdatedEventHandler)
  registerService('coaMappingDeletedEventHandler', CoaMappingDeletedEventHandler)
  // DATA CUBE
  registerService('dataCubeApiRepository', DataCubeApiRepository)
  registerService('dataCubeStoreRepository', DataCubeStoreRepository)
  registerService('dataCubeEngagementActivatedEventHandler', DataCubeEngagementActivatedEventHandler)
  registerService('dataCubeUpdatedEventHandler', DataCubeUpdatedEventHandler)
  registerService('dataCubeDeletedEventHandler', DataCubeDeletedEventHandler)
  registerService('dataCubePublisher', DataCubePublisher)
  // DATA REQUEST
  registerService('dataRequestSecurity', DataRequestSecurity)
  registerService('dataRequestTokenExpiryListener', DataRequestTokenExpiryListener)
  registerService('dataRequestApiRepository', DataRequestApiRepository)
  registerService('dataRequestStoreRepository', DataRequestStoreRepository)
  registerService('dataRequestorApiRepository', DataRequestorApiRepository)
  registerService('dataRequestorStoreRepository', DataRequestorStoreRepository)
  registerService('dataRequestUpdatedEventHandler', DataRequestUpdatedEventHandler)
  registerService('dataRequestDeletedEventHandler', DataRequestDeletedEventHandler)
  registerService('dataRequestEngagementActivatedEventHandler', DataRequestEngagementActivatedEventHandler)
  registerService('dataRequestRecipient2faInitiatedEventHandler', DataRequestRecipient2faInitiatedEventHandler)
  registerService('dataRequestRecipientAuthenticatedEventHandler', DataRequestRecipientAuthenticatedEventHandler)
  registerService('deleteDataRequestCommandHandler', DeleteDataRequestCommandHandler)
  registerService('duplicateDataRequestCommandHandler', DuplicateDataRequestCommandHandler)
  registerFunction('fileTypeFactory', () =>
    new FileTypeFactory([
      new GenericFileType(),
      // Generic General Ledger
      new BusinessUnitFileType(),
      new BusinessUserFileType(),
      new CustomerFileType(),
      new JournalEntryFileType(),
      new SourceFileType(),
      new SupplierFileType(),
      new TrialBalanceFileType(),
      // Twinfield
      new AnnualReportFileType(),
      new GeneralLedgerDetailsFileType(),
      // Bank
      new Camt05300102FileType(),
      new Pain001001FileType(),
      new RabobankBatchExportDutchFileType(),
      // AP
      new OpenAccountsPayableFileType(),
      // AR
      new OpenAccountsReceivableFileType(),
    ]))
  registerFunction('statusBuilderFactory', () =>
    new StatusBuilderFactory([
      new IntroductionStepStatusBuilder(),
      new StepStatusBuilder(),
      new SummaryStepStatusBuilder(),
    ]))
  registerFunction('dataRequestFixtureFactory', () =>
    new DataRequestFixtureFactory([
      // ACCOUNTS PAYABLE
      new GenericAccountsPayableDataRequestFixture(),
      // ACCOUNTS RECEIVABLE
      new GenericAccountsReceivableDataRequestFixture(),
      // BANK
      new AbnAmroMijnZakelijkDataRequestFixture(),
      new Camt053BankDataRequestFixture(),
      new IngInsideBusinessPaymentsDataRequestFixture(),
      new IngMijnZakelijkDataRequestFixture(),
      new RabobankDataRequestFixture(),
      new VanLanschotDataRequestFixture(),
      // GENERAL LEDGER
      new GenericGeneralLedgerDataRequestFixture(),
      new ExactGlobeNextDataRequestFixture(),
      new ExactOnlineDataRequestFixture(),
      new TwinfieldDataRequestFixture(),
      // OTHER
      new GenericDataRequestFixture(),
    ]))
  registerService('dataRequestPublisher', DataRequestPublisher)
  // ENGAGEMENT
  registerService('engagementApiRepository', EngagementApiRepository)
  registerService('engagementStoreRepository', EngagementStoreRepository)
  registerService('engagementActivator', EngagementActivator)
  registerService('archiveEngagementCommandHandler', ArchiveEngagementCommandHandler)
  registerService('engagementUpdatedEventHandler', EngagementUpdatedEventHandler)
  registerService('engagementArchivedEventHandler', EngagementArchivedEventHandler)
  registerService('addParameterCommandHandler', AddParameterCommandHandler)
  registerService('changeParameterCommandHandler', ChangeParameterCommandHandler)
  registerService('deleteParameterCommandHandler', DeleteParameterCommandHandler)
  registerService('engagementPublisher', EngagementPublisher)
  // GENERAL LEDGER
  // EXPORT
  registerService('exportApiRepository', ExportApiRepository)
  registerService('exportStoreRepository', ExportStoreRepository)
  registerService('createCoaMappingExportCommandHandler', CreateCoaMappingExportCommandHandler)
  registerService('createDataRequestArchiveExportCommandHandler', CreateDataRequestArchiveExportCommandHandler)
  registerService('createDataRequestFileExportCommandHandler', CreateDataRequestFileExportCommandHandler)
  registerService('createTransactionalDataTableExportCommandHandler', CreateTransactionalDataTableExportCommandHandler)
  registerService('createTransactionalDataPivotExportCommandHandler', CreateTransactionalDataPivotExportCommandHandler)
  registerService('createWorkProgramExportCommandHandler', CreateWorkProgramExportCommandHandler)
  registerService('createTwoWayCorrelationsExportCommandHandler', CreateTwoWayCorrelationsExportCommandHandler)
  registerService('createOtherSideOfJournalEntriesExportCommandHandler', CreateOtherSideOfJournalEntriesExportCommandHandler)
  registerService('downloadExportCommandHandler', DownloadExportCommandHandler)
  registerService('exportUserAuthenticatedEventHandler', ExportUserAuthenticatedEventHandler)
  registerService('exportUserLogoutEventHandler', ExportUserLogoutEventHandler)
  registerService('exportUpdatedEventHandler', ExportUpdatedEventHandler)
  registerService('exportDeletedEventHandler', ExportDeletedEventHandler)
  registerService('exportPreparedEventHandler', ExportPreparedEventHandler)
  registerService('exportPublisher', ExportPublisher)
  // IDENTITY
  registerService('security', Security)
  registerService('userApiRepository', UserApiRepository)
  registerService('userStoreRepository', UserStoreRepository)
  registerService('uuidEncoder', UuidEncoder)
  registerService('tokenExpiryListener', TokenExpiryListener)
  registerService('userAuthenticatedEventHandler', UserAuthenticatedEventHandler)
  registerService('user2faInitiatedEventHandler', User2faInitiatedEventHandler)
  registerService('userLogoutEventHandler', UserLogoutEventHandler)
  registerService('userUpdatedEventHandler', UserUpdatedEventHandler)
  registerService('userPublisher', UserPublisher)
  // ORGANISATION
  registerService('organisationActivator', OrganisationActivator)
  registerService('organisationApiRepository', OrganisationApiRepository)
  registerService('organisationStoreRepository', OrganisationStoreRepository)
  registerService('organisationUpdatedEventHandler', OrganisationUpdatedEventHandler)
  registerService('organisationPublisher', OrganisationPublisher)
  // WORKPROGRAM
  registerService('workProgramApiRepository', WorkProgramApiRepository)
  registerService('workProgramStoreRepository', WorkProgramStoreRepository)
  registerService('workProgramEngagementActivatedEventHandler', WorkProgramEngagementActivatedEventHandler)
  registerService('workProgramLanguageUpdatedEventHandler', WorkProgramLanguageUpdatedEventHandler)
  registerService('workProgramUpdatedEventHandler', WorkProgramUpdatedEventHandler)
  registerService('workProgramDeletedEventHandler', WorkProgramDeletedEventHandler)
  registerService('startWorkProgramCommandHandler', StartWorkProgramCommandHandler)
  registerService('changeWorkProgramCommandHandler', ChangeWorkProgramCommandHandler)
  registerService('duplicateWorkProgramCommandHandler', DuplicateWorkProgramCommandHandler)
  registerService('markDataPivotStepResultWorkProgramCommandHandler', MarkDataPivotStepResultWorkProgramCommandHandler)
  registerService('markDataTableStepResultWorkProgramCommandHandler', MarkDataTableStepResultWorkProgramCommandHandler)
  registerService('finishWorkProgramCommandHandler', FinishWorkProgramCommandHandler)
  registerService('reopenWorkProgramCommandHandler', ReopenWorkProgramCommandHandler)
  registerService('deleteWorkProgramCommandHandler', DeleteWorkProgramCommandHandler)
  registerService('workProgramResultIriBuilder', WorkProgramResultIriBuilder)
  registerFunction('parameterDefinitionFactory', () =>
    new ParameterDefinitionFactory([
      // General
      new BookingDelayParameterDefinition(),
      new BusinessUnitParameterDefinition(),
      new ManagementBusinessUserParameterDefinition(),
      new EntriesNearEndPeriodEndDateParameterDefinition(),
      new EntriesNearEndPeriodStartDateParameterDefinition(),
      new FiscalYearCurrentEndParameterDefinition(),
      new FiscalYearCurrentStartParameterDefinition(),
      new FiscalYearSubsequentStartParameterDefinition(),
      new FiscalYearParameterDefinition(),
      new ManualBookingSourcesParameterDefinition(),
      new OfficeHoursEndParameterDefinition(),
      new OfficeHoursStartParameterDefinition(),
      new UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition(),
      new PerformanceMaterialityThresholdParameterDefinition(),
      new ProfitAndLossAccountsParameterDefinition(),
      // 24342109
      new BusinessUnitParameterDefinition24342109(),
      new EndDateReportingPeriodParameterDefinition24342109(),
      new FiscalYearParameterDefinition24342109(),
      new ManualBookingSourcesParameterDefinition24342109(),
      new TrivialAmountParameterDefinition24342109(),
      new UnusualBusinessUsersParameterDefinition24342109(),
      new AccountsPayablesGlAccountsParameterDefinition24342109(),
      new PurchasingSourcesParameterDefinition24342109(),
    ]))
  registerFunction('workProgramFixtureFactory', () =>
    new WorkProgramFixtureFactory([
      // GENERAL WORK PROGRAMS
      new ManagementFraud(),
      // CLIENT SPECIFIC WORK PROGRAMS
      // 20055194
      new BankPayments20055194(),
      new GeneralLedger20055194(),
      new AccountsReceivables20055194(),
      // 24342109
      new InternalControl24342109(),
      new AccountsPayables24342109(),
    ]))
  registerService('workProgramPublisher', WorkProgramPublisher)
  // SHARED
  registerService('clock', SystemClock)
  registerService('cookies', Cookies)
  registerService('filterSorterStoreRepository', FilterSorterStoreRepository)
  registerService('httpClient', HttpClient)
  registerService('logger', import.meta.env.PROD ? SentryLogger : DebugLogger)
  registerService('pivotTableStoreRepository', PivotTableStoreRepository)
  registerService('pivotTableToSyncfusionPivotTransformer', PivotTableToSyncfusionPivotTransformer)
  registerService('syncfusionPivotToPivotTableTransformer', SyncfusionPivotToPivotTableTransformer)
  registerService('subscriber', Subscriber)
  registerService('publisher', Publisher)
  registerService('router', Router)
  registerService('htmlSanitizer', HtmlSanitizer)
  registerService('translator', Translator)
  registerService('updateLocaleCommandHandler', UpdateLocaleCommandHandler)
  registerService('commandBus', CommandBus)
  registerService('eventBus', EventBus)
  registerService('queryBus', QueryBus)

  const publisher = getService('publisher')
  initializePublishers(container, publisher)

  const commandBus = getService('commandBus')
  initializeCommandHandlers(container, commandBus)

  const eventBus = getService('eventBus')
  initializeEventHandlers(container, eventBus)

  const queryBus = getService('queryBus')
  initializeQueryHandlers(container, queryBus)
}
