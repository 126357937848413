/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import MultiApiValueFilter from '~/src/Domain/Shared/Filter/Filter/MultiApiValueFilter'
import type ParameterDefinitionInterface from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionInterface'
import ParameterName from '~/src/Domain/WorkProgram/ParameterName'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'

export default class UserWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition
implements ParameterDefinitionInterface {
  public getName(): ParameterName {
    return new ParameterName(
      'userWhoShouldNotCreateFinancialTransactionsBusinessUserParameterDefinition',
    )
  }

  public getTitle(): string {
    return t(
      'work_program.parameters.user_who_should_not_create_financial_transactions_users.title',
    )
  }

  public getDescription(): string {
    return t(
      'work_program.parameters.user_who_should_not_create_financial_transactions_users.description',
    )
  }

  public getFilter(): AbstractFilter<any> {
    return new MultiApiValueFilter(RepositoryReference.JOURNAL_ENTRY, [
      'enteredBy.department',
      'enteredBy.businessUserName',
    ])
  }

  public getIcon(): string {
    return 'person-badge'
  }
}
