/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { useCookie } from '#app'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import CookieNotFoundException from '~/src/Domain/Shared/Cookies/Exception/CookieNotFoundException'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class Cookies implements CookiesInterface {
  public readonly options: Record<string, unknown>

  public constructor({ router }: Services) {
    this.options = {
      domain: `.${router.getHostname()}`,
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
    } as const
  }

  public get<T>(name: string): T {
    const cookie = useCookie<T | null | undefined>(name, { ...this.options, readonly: true })
    if (cookie.value === undefined || cookie.value === null) {
      throw new CookieNotFoundException(`Cookie "${name}" not found.`)
    }

    return cookie.value
  }

  public tryGet<T>(name: string): T | undefined {
    return (
      useCookie<T | null | undefined>(name, { ...this.options, readonly: true }).value ?? undefined
    )
  }

  public set(name: string, value: string): void {
    const cookie = useCookie(name, this.options)
    cookie.value = value
  }

  public remove(name: string): void {
    const cookie = useCookie(name, this.options)
    cookie.value = undefined
  }
}
