/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import GenericFileType from '~/src/Domain/DataRequest/File/FileType/GenericFileType'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import ChecklistItem from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class IngInsideBusinessPaymentsDataRequestFixture
implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.ING_INSIDE_BUSINESS_PAYMENTS === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.ING_INSIDE_BUSINESS_PAYMENTS),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.ing_inside_business.pages.xml_file.title'),
          '',
          [
            new Alert(
              t('components.data_request.ing_inside_business.pages.intro.alert_background.message'),
              t('components.data_request.ing_inside_business.pages.intro.alert_background.heading'),
            ),
            new Paragraph([
              t(
                'components.data_request.ing_inside_business.pages.xml_file.tutorial_how_to_download_file',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t(
                    'data_request.ing_inside_busines.checklist.accounts_overview.open_accounts_overview',
                  ),
                  new Badge(4, undefined, 13, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.ing_inside_busines.checklist.accounts_overview.select_all_statements',
                  ),
                  new Badge(25, undefined, 22, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.ing_inside_busines.checklist.accounts_overview.click_save_icon_on_the_right',
                  ),
                  new Badge(18, undefined, 90, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.ing_inside_busines.checklist.accounts_overview.select_download_by_clicking',
                  ),
                  new Badge(28, undefined, 94, undefined),
                ),
              ],
              '/img/data-request/ing_inside_business/accounts_overview.png',
            ),
            new Paragraph([
              t(
                'components.data_request.ing_inside_business.pages.xml_file.paragraph_download_banking_file',
              ),
            ]),
            new Checklist(
              [
                new ChecklistItem(
                  t('data_request.ing_inside_busines.checklist.download.select_fileformat_camt'),
                  new Badge(35, undefined, 43, undefined),
                ),
                new ChecklistItem(
                  t(
                    'data_request.ing_inside_busines.checklist.download.check_integritycheckfile_creation',
                  ),
                  new Badge(57, undefined, 34, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_inside_busines.checklist.download.check_compress_zip'),
                  new Badge(73, undefined, 34, undefined),
                ),
                new ChecklistItem(
                  t('data_request.ing_inside_busines.checklist.download.click_download'),
                  new Badge(86, undefined, 57, undefined),
                ),
              ],
              '/img/data-request/ing_inside_business/download_camt_file.png',
            ),
            new FileUploader(new GenericFileType(), ['application/xml', 'text/xml']),
          ],
        ),
      ]),
    )
  }
}
