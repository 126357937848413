/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DownloadExportCommand from '~/src/Application/Export/Command/DownloadExportCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(DownloadExportCommand.NAME, 'downloadExportCommandHandler')
export default class DownloadExportCommandHandler extends CommandHandlerInterface<DownloadExportCommand> {
  private readonly exportApiRepository: ExportApiRepositoryInterface

  public constructor({ exportApiRepository }: Services) {
    super()
    this.exportApiRepository = exportApiRepository
  }

  public async handle(command: DownloadExportCommand): Promise<void> {
    await this.exportApiRepository.download(command.item)
  }
}
