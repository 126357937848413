/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import Uuid from '~/src/Domain/Shared/Identifier/Uuid'
import type ParameterDefinitionInterface from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionInterface'

export default class ParameterProjection {
  public readonly id: string = Uuid.v7()
  public filter: AbstractFilter<any>

  public constructor(public readonly parameterDefinition: ParameterDefinitionInterface) {
    this.filter = parameterDefinition.getFilter()
  }
}
