/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { Hookable } from 'hookable'
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class EventBus extends Hookable implements EventBusInterface {
  private handlers: Record<string, EventHandlerInterface<EventInterface>[]> = {}
  private readonly logger: LoggerInterface

  public constructor({ logger }: Services) {
    super()
    this.logger = logger
  }

  public async dispatch<T extends EventInterface>(eventName: string, event: T): Promise<void> {
    this.logger.info(`[${eventName}] Publishing event`)

    if (!(eventName in this.handlers)) {
      this.logger.info(`[${eventName}] No handler registered for  ${eventName}`)
      return
    }

    await Promise.all(this.handlers[eventName].map(async (handler) => handler.handle(event)))
  }

  public registerHandler<T extends EventInterface>(
    eventName: string,
    eventHandler: EventHandlerInterface<T>,
  ): void {
    if (!(eventName in this.handlers)) {
      this.handlers[eventName] = []
    }

    this.handlers[eventName].push(eventHandler)
  }
}
