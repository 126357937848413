/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type { EngagementProps } from '~/src/Domain/Engagement/Engagement'
import Engagement from '~/src/Domain/Engagement/Engagement'
import EngagementArchivedEvent from '~/src/Domain/Engagement/Event/EngagementArchivedEvent'
import EngagementUpdatedEvent from '~/src/Domain/Engagement/Event/EngagementUpdatedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import { AsPublisher } from '~/src/Infrastructure/Shared/PubSub/AsPublisher'
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import { Type } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

@AsPublisher('engagementPublisher')
export default class EngagementPublisher implements PublisherInterface {
  private REGEX_PATTERN
    = '^/v1/engagements/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'

  private readonly eventBus: EventBusInterface

  public constructor({ eventBus }: Services) {
    this.eventBus = eventBus
  }

  public async publish(message: Message): Promise<void> {
    if (!('@id' in message.data && message.data['@id'].match(this.REGEX_PATTERN) !== null)) {
      return
    }

    if (message.type === Type.UPDATE) {
      const engagement = Engagement.prototype.fromJSON(message.data as EngagementProps)
      if (engagement.archived === false) {
        await this.eventBus.dispatch(EngagementUpdatedEvent.NAME, new EngagementUpdatedEvent(engagement))
      } else {
        await this.eventBus.dispatch(EngagementArchivedEvent.NAME, new EngagementArchivedEvent(engagement['@id']))
      }
    }
  }
}
