/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'

export default abstract class EventHandlerInterface<T extends EventInterface> {
  public abstract handle(event: T): void | Promise<void>
}
