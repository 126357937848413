/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useService from '~/src/UserInterface/App/composables/Container/useService'

export default function useOrganisationState() {
  const organisationStoreRepository = useService('organisationStoreRepository')
  const hasOrganisation = computed(() => organisationStoreRepository.hasOrganisation())

  const getOrganisation = (): ComputedRef<Organisation> => {
    return computed(() => organisationStoreRepository.getOrganisation())
  }

  const activate = (o: Organisation) => {
    organisationStoreRepository.activate(o)
  }

  return {
    getOrganisation,
    hasOrganisation,
    activate,
  }
}
