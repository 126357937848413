/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import AccountsReceivablesGlAccountsParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/AccountsReceivablesGlAccountsParameterDefinition'
import BusinessUnitParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BusinessUnitParameterDefinition'
import FiscalYearCurrentEndParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentEndParameterDefinition'
import FiscalYearCurrentStartParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearCurrentStartParameterDefinition'
import FiscalYearParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearParameterDefinition'
import ManualBookingSourcesParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ManualBookingSourcesParameterDefinition'
import PerformanceMaterialityThresholdParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/PerformanceMaterialityThresholdParameterDefinition'
import ProfitAndLossAccountsParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ProfitAndLossAccountsParameterDefinition'
import SubsequentYearParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/SubsequentYearParameterDefinition'
import t from '~/src/Infrastructure/Shared/Translation/t'

/// /////////////////
// General Ledger
/// /////////////////

export const fiscalYearFilterField = (): FilterField => {
  return new FilterField('fiscalYear', t('general.entity_fields.journal_entry.fiscal_year'), [
    new ParameterizedFilterFieldFilter(
      new FiscalYearParameterDefinition(),
      FilterClass.API_VALUE_FILTER,
    ),
  ])
}
export const businessUnitFilterField = (): FilterField => {
  return new FilterField(
    'businessUnit.businessUnitName',
    t('general.entity_fields.business_unit.business_unit_name'),
    [
      new ParameterizedFilterFieldFilter(
        new BusinessUnitParameterDefinition(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    false,
  )
}

export const profitAndLossAccountFilterField = (): FilterField => {
  return new FilterField(
    'chartOfAccounts.accountType',
    t('general.entity_fields.chart_of_accounts.account_type'),
    [
      new ParameterizedFilterFieldFilter(
        new ProfitAndLossAccountsParameterDefinition(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
  )
}

export const holidayIndicatorFilterField = (): FilterField => {
  return new FilterField(
    'dateDimension.holidayIndicator',
    t('general.entity_fields.date_dimension.holiday_indicator'),
    [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'Holiday')],
    true,
    true,
  )
}

export const workdayWeekendFilterField = (): FilterField => {
  return new FilterField(
    'dateDimension.workdayWeekendText',
    t('general.entity_fields.date_dimension.workday_weekend_text'),
    [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'Weekend')],
  )
}

export const headerDescriptionExistsFilterField = (): FilterField => {
  return new FilterField(
    'headerDescription',
    t('general.entity_fields.journal_entry.header_description'),
    [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
    true,
    true,
  )
}

export const headerDescriptionUnusualFilterField = (): FilterField => {
  return new FilterField(
    'headerDescription',
    t('general.entity_fields.journal_entry.header_description'),
    [new FilterFieldFilter(FilterClass.UNUSUAL_DESCRIPTION_FILTER, true)],
    true,
    true,
  )
}

export const lineDescriptionExistsFilterField = (): FilterField => {
  return new FilterField(
    'lineDescription',
    t('general.entity_fields.journal_entry.line_description'),
    [new FilterFieldFilter(FilterClass.EXISTS_FILTER, false)],
    true,
    true,
  )
}

export const lineDescriptionUnusualFilterField = (): FilterField => {
  return new FilterField(
    'lineDescription',
    t('general.entity_fields.journal_entry.line_description'),
    [new FilterFieldFilter(FilterClass.UNUSUAL_DESCRIPTION_FILTER, true)],
    true,
    true,
  )
}

export const limitFilterField = (): FilterField => {
  return new FilterField(
    'limit',
    t('general.filters.limit'),
    [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 20)],
    true,
    true,
  )
}

export const outsideWorkingHoursFilterField = (): FilterField => {
  return new FilterField(
    'enteredHour',
    t('general.entity_fields.entered_time.entered_hour'),
    [
      new FilterFieldFilter(FilterClass.LOWER_THAN_FILTER, 6),
      new FilterFieldFilter(FilterClass.GREATER_THAN_FILTER, 20),
    ],
    true,
    false,
  )
}

export const manualSourceNameFilterField = (): FilterField => {
  return new FilterField(
    'source.sourceName',
    t('general.entity_fields.source.source_name'),
    [
      new ParameterizedFilterFieldFilter(
        new ManualBookingSourcesParameterDefinition(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    false,
    t('pages.general_ledger.work_program.management_fraud.descriptions.source_code'),
  )
}

/// /////////////////
// Bank
/// /////////////////

/**
 * In comparison with the {fiscalYearFilterField} this is a range of start and end date of the booking date.
 *
 * @see {fiscalYearFilterField}
 */
export const bankBookingDateRangeFilterField = (): FilterField => {
  return new FilterField(
    'bankStatementEntry.bookingDate',
    t('general.entity_fields.bank_statement_entry.booking_date'),
    [
      new ParameterizedFilterFieldFilter(
        new FiscalYearCurrentStartParameterDefinition(),
        FilterClass.GREATER_THAN_OR_EQUAL_FILTER,
      ),
      new ParameterizedFilterFieldFilter(
        new FiscalYearCurrentEndParameterDefinition(),
        FilterClass.LOWER_THAN_OR_EQUAL_FILTER,
      ),
    ],
  )
}

export const bankMaterialityThresholdFilterField = (): FilterField => {
  return new FilterField(
    'transactionAmount',
    t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
    [
      new ParameterizedFilterFieldFilter(
        new PerformanceMaterialityThresholdParameterDefinition(),
        FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
      ),
    ],
  )
}

/// /////////////////
// Accounts Receivables
/// /////////////////

export const subsequentYearFilterField = (): FilterField => {
  return new FilterField('fiscalYear', t('general.entity_fields.journal_entry.fiscal_year'), [
    new ParameterizedFilterFieldFilter(
      new SubsequentYearParameterDefinition(),
      FilterClass.API_VALUE_FILTER,
    ),
  ])
}

export const accountsReceivableReportDateFilterField = (): FilterField => {
  return new FilterField(
    'reportDate',
    t('general.entity_fields.open_accounts_receivable.report_date'),
    [new FilterFieldFilter(FilterClass.EQUALS_FILTER, undefined)],
    false,
    false,
  )
}

export const accountsReceivablesGlAccountsFilterField = (): FilterField => {
  return new FilterField(
    'chartOfAccounts.glAccount',
    t('general.entity_fields.chart_of_accounts.gl_account'),
    [
      new ParameterizedFilterFieldFilter(
        new AccountsReceivablesGlAccountsParameterDefinition(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
  )
}
