/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import FiscalYearIri from '~/src/Domain/Engagement/FiscalYearIri'
import type { FiscalYearType } from '~/src/Domain/Engagement/FiscalYearType'
import BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface FiscalYearProps extends DomainModelProps {
  '@id': string
  '@type': string
  name: string
  type: FiscalYearType
  fromDate: string
  toDate: string
}

export default class FiscalYear implements DomainModelInterface<FiscalYear, FiscalYearProps> {
  public readonly '@id': FiscalYearIri
  public readonly '@type': string

  private constructor(
    id: FiscalYearIri,
    _type: string,
    public readonly name: string,
    public readonly type: FiscalYearType,
    public readonly fromDate: BetterDate,
    public readonly toDate: BetterDate,
  ) {
    this['@id'] = id
    this['@type'] = _type
  }

  public getClassName(): string {
    return 'FiscalYear'
  }

  public fromJSON(values: FiscalYearProps): FiscalYear {
    return new FiscalYear(
      new FiscalYearIri(values['@id']),
      values['@type'],
      values.name,
      values.type,
      new BetterDate(values.fromDate),
      new BetterDate(values.toDate),
    )
  }

  public toJSON(): FiscalYearProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      name: this.name,
      type: this.type,
      fromDate: this.fromDate?.toString(),
      toDate: this.toDate?.toString(),
    }
  }
}
