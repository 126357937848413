/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'

export default abstract class FileTypeInterface {
  public abstract get name(): string

  public abstract supports(analysisType: AnalysisType): boolean
}
