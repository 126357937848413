/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum DataIntegrityPillar {
  DATA_CORRUPTION = 'DATA_CORRUPTION',
  DATA_ENRICHMENT = 'DATA_ENRICHMENT',
  DATA_INTEGRATION = 'DATA_INTEGRATION',
  DATA_QUALITY = 'DATA_QUALITY',
}
