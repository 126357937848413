/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import PivotTableCollection from '~/src/Domain/Shared/PivotTable/PivotTableCollection'
import PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import type PivotTableStoreRepositoryInterface from '~/src/Domain/Shared/PivotTable/PivotTableStoreRepositoryInterface'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import PivotTableStore from '~/src/UserInterface/Shared/PivotTable/store/PivotTableStore'

export default class PivotTableStoreRepository implements PivotTableStoreRepositoryInterface {
  private readonly store: ReturnType<typeof PivotTableStore>

  public constructor() {
    this.store = PivotTableStore(pinia)
  }

  public getPivotTableCollection(phase: PhaseIri): PivotTableCollection {
    return new PivotTableCollection(
      (this.store.pivotTables[phase.toString()] ?? []).map((p) =>
        PivotTableDefinition.prototype.fromJSON(p),
      ),
    )
  }

  public updatePivotTable(phase: PhaseIri, pivotTable: PivotTableDefinition): void {
    this.store.updatePivotTable(phase, pivotTable)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
