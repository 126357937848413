/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DeleteParameterCommand from '~/src/Application/Engagement/Command/DeleteParameterCommand'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type EngagementApiRepositoryInterface from '~/src/Domain/Engagement/EngagementApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class DeleteParameterCommandHandler extends CommandHandlerInterface<DeleteParameterCommand> {
  private readonly engagementApiRepository: EngagementApiRepositoryInterface

  public constructor({ engagementApiRepository }: Services) {
    super()
    this.engagementApiRepository = engagementApiRepository
  }

  public async handle(command: DeleteParameterCommand): Promise<void> {
    await this.engagementApiRepository.deleteParameter(command.parameter)
  }
}
