/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { useThrottleFn } from '@vueuse/core'
import LogicException from '~/src/Domain/Shared/Exception/LogicException'
import type AbstractIri from '~/src/Domain/Shared/Identifier/AbstractIri'
import type LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'
import deepReplace from '~/src/Domain/Shared/Utils/DeepReplace'
import parseJsonString from '~/src/Domain/Shared/Utils/ParseJsonString'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'
import type Publisher from '~/src/Infrastructure/Shared/PubSub/Publisher'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

export default class Subscriber {
  private readonly hubUrl = '/.well-known/mercure'

  private readonly CONNECTING = 0
  private readonly OPEN = 1
  private readonly CLOSED = 2

  private readyState = 0

  private eventSource: EventSource | undefined
  private lastEventId: string | undefined

  private msBeforeAutoReconnect = 3000
  private timer: NodeJS.Timeout | undefined = undefined

  private throttlers: { [key: string]: (data: Message) => void } = {}

  private readonly httpClient: HttpClient
  private readonly logger: LoggerInterface
  private readonly publisher: Publisher

  public constructor({ httpClient, logger, publisher }: Services) {
    this.httpClient = httpClient
    this.logger = logger
    this.publisher = publisher
  }

  public isSubscribed(): boolean {
    return this.eventSource !== undefined
  }

  public subscribe(topic: AbstractIri<any>): void {
    if (this.eventSource !== undefined) {
      throw new LogicException(
        'An EventSource is already defined. Did you close it before reconnecting?',
      )
    }

    const url = new URL(this.hubUrl, this.httpClient.getBaseUrl())
    url.searchParams.append('topic', topic.toString())

    if (this.lastEventId !== undefined) {
      url.searchParams.append('lastEventId', this.lastEventId)
    }

    this.eventSource = new EventSource(url.toString(), { withCredentials: true })
    this.eventSource.addEventListener('open', (e) => this.onOpen(e))
    this.eventSource.addEventListener('message', (e: MessageEvent<string>) => {
      this.onMessage(e).catch(() => {
        // Intentionally left empty. Handle the error if needed.
      })
    })
    this.eventSource.addEventListener('error', (e) => this.onError(e, topic))
  }

  public unsubscribe(): void {
    this.close()
  }

  private onOpen(e: Event) {
    this.logger.info('ES connection opened', { event: e })

    if (this.readyState === this.CONNECTING) {
      this.readyState = this.OPEN
    }
  }

  private async onMessage(e: MessageEvent<string>): Promise<void> {
    const data = deepReplace(parseJsonString<Message>(e.data), (value) =>
      value === null ? undefined : value)

    const fn = async (data: Message) => {
      this.logger.info('ES message received', { message: data })

      await this.publisher.publish(data)
      this.lastEventId = e.lastEventId
    }

    if (data.type === 'delete') {
      await fn(data)
      return
    }

    const key = `${data.type}-${data.data['@id']}`
    if (!(key in this.throttlers)) {
      // eslint-disable-next-line ts/no-misused-promises
      this.throttlers[key] = useThrottleFn<(data: Message) => void>(fn, 2500, true)
    }

    this.throttlers[key](data)
  }

  private onError(e: Event, topic: AbstractIri<any>) {
    this.logger.info('ES error received', { event: e })

    if (this.readyState === this.OPEN) {
      this.readyState = this.CONNECTING
    }

    if (this.readyState === this.CLOSED) {
      this.close()

      this.logger.info('Reconnecting ES...')

      const timeout = Math.round(this.msBeforeAutoReconnect * Math.random())
      this.timer = setTimeout(() => this.subscribe(topic), timeout)
    }
  }

  private close() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = undefined
    }

    this.eventSource?.close()
    this.eventSource = undefined
  }
}
