/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type MinFilterValueType = string

export default class MinFilter extends AbstractFilter<MinFilterValueType> {
  public constructor(filterType: FilterType) {
    super(Operator.MIN, filterType, FilterClass.MIN_FILTER)
  }

  protected toUrlParameters(key: string, value: MinFilterValueType): string {
    return `${key}[${this.operator}][]=${encodeURIComponent(value.toString())}`
  }
}
