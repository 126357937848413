/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataCubeStoreRepositoryInterface from '~/src/Domain/DataCube/DataCubeStoreRepositoryInterface'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import type WorkProgram from '~/src/Domain/WorkProgram/WorkProgram'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'
import type WorkProgramStoreRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'
import WorkProgramStore from '~/src/UserInterface/WorkProgram/store/WorkProgramStore'

export default class WorkProgramStoreRepository implements WorkProgramStoreRepositoryInterface {
  private readonly dataCubeStoreRepository: DataCubeStoreRepositoryInterface
  private readonly store: ReturnType<typeof WorkProgramStore>

  public constructor({ dataCubeStoreRepository }: Services) {
    this.dataCubeStoreRepository = dataCubeStoreRepository
    this.store = WorkProgramStore(pinia)
  }

  public getAvailableWorkPrograms(phase: PhaseIri): WorkProgramProjection[] {
    return this.store.workPrograms.filter(
      (w) => !w.isStarted
        && !w.finished
        && w.analysisTypes.every((a) =>
          this.dataCubeStoreRepository.getDataCubes().haveAllDataCubesBeenPopulatedFor(a, phase),
        ),
    ) as WorkProgramProjection[]
  }

  public getUnavailableWorkPrograms(phase: PhaseIri): WorkProgramProjection[] {
    return this.store.workPrograms.filter(
      (w) => !w.isStarted
        && !w.finished
        && !w.analysisTypes.every((a) =>
          this.dataCubeStoreRepository.getDataCubes().haveAllDataCubesBeenPopulatedFor(a, phase),
        ),
    ) as WorkProgramProjection[]
  }

  public hasStartedWorkPrograms(phase: PhaseIri): boolean {
    return this.getStartedWorkPrograms(phase).length > 0
  }

  public getStartedWorkPrograms(phase: PhaseIri): WorkProgramProjection[] {
    return this.store.workPrograms.filter(
      (w) =>
        w.phase?.equalsTo(phase) && w.isStarted && !w.finished,
    ) as WorkProgramProjection[]
  }

  public getFinishedWorkPrograms(phase: PhaseIri): WorkProgramProjection[] {
    return this.store.workPrograms.filter((w) =>
      w.phase?.equalsTo(phase) && w.finished) as WorkProgramProjection[]
  }

  public updateStartedWorkProgram(workProgram: WorkProgram) {
    this.store.updateStartedWorkProgram(workProgram)
  }

  public deleteStartedWorkProgram(workProgram: WorkProgramIri) {
    this.store.deleteStartedWorkProgram(workProgram)
  }

  public markState(workPrograms: WorkProgram[], fixtures: WorkProgramProjection[]): void {
    this.store.markState(workPrograms, fixtures)
  }

  public resetState(): void {
    this.store.resetState()
  }

  public activateWorkProgram(workProgramSlug: Slug, iri: WorkProgramIri | undefined): void {
    this.store.activateWorkProgram(workProgramSlug, iri)
  }

  public activateWorkProgramStep(workProgramStep: Slug) {
    this.store.activateWorkProgramStep(workProgramStep)
  }

  public hasCurrentWorkProgram(): boolean {
    return this.store.hasCurrentWorkProgram
  }

  public getCurrentWorkProgram(): WorkProgramProjection {
    return this.store.getCurrentWorkProgram().value
  }

  public getCurrentWorkProgramStep(): StepInterface {
    return this.store.getCurrentWorkProgramStep().value
  }

  public markWorkProgramFixtures(workPrograms: WorkProgramProjection[]): void {
    this.store.markWorkProgramFixtures(workPrograms)
  }
}
