/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import useString from '~/src/UserInterface/App/composables/useString'

export default class Section extends ContentInterface {
  public readonly description: string | undefined

  public constructor(
    public readonly title: string | undefined = undefined,
    _description: string | undefined = undefined,
    public readonly contents: ContentInterface[] = [],
  ) {
    super()

    const { replaceUrlsHtml } = useString()
    this.description = _description === undefined ? undefined : replaceUrlsHtml(_description)
  }
}
