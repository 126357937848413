/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import OpenAccountsPayableFileType from '~/src/Domain/AccountsPayable/FileType/OpenAccountsPayableFileType'
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import SupplierFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/SupplierFileType'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class GenericAccountsPayableDataRequestFixture
implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.GENERIC_ACCOUNTS_PAYABLE === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.GENERIC_ACCOUNTS_PAYABLE),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.accounts_payable.pages.accounts_payable.title'),
          t('components.data_request.accounts_payable.pages.accounts_payable.description'),
          [
            new FileUploader(new OpenAccountsPayableFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('suppliers'),
          t('components.data_request.accounts_payable.pages.suppliers.title'),
          t('components.data_request.accounts_payable.pages.suppliers.description'),
          [
            new FileUploader(new SupplierFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
      ]),
    )
  }
}
