/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'

export default class UpdateCoaMappingCommand implements CommandInterface {
  public static readonly NAME = 'UPDATE_COA_MAPPING'

  public constructor(
    public readonly coaMapping: CoaMappingIri,
    public readonly mapping: {
      id: string
      accountType: string | undefined
      accountSubtype: string | undefined
      category: string | undefined
    }[],
  ) {}
}
