/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtPlugin } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'

const handler = () => {
  const subscriber = useService('subscriber')
  if (subscriber.isSubscribed()) {
    return
  }

  const userStore = useService('userStoreRepository')
  const dataRequestorStore = useService('dataRequestorStoreRepository')

  if (userStore.isAuthenticated()) {
    subscriber.subscribe(userStore.getUser()['@id'])
  } else if (dataRequestorStore.hasDataRequest()) {
    subscriber.subscribe(dataRequestorStore.getDataRequest().recipient['@id'])
  }
}

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    nuxtApp.hook('app:mounted', () => {
      document.addEventListener('visibilitychange', handler, false)
    })

    const _unmount = nuxtApp.vueApp.unmount
    nuxtApp.vueApp.unmount = () => {
      document.removeEventListener('visibilitychange', handler, false)
      useService('subscriber').unsubscribe()
      _unmount()
    }
  },
})
