/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import InvalidChamberOfCommerceIdException from '~/src/Domain/Organisation/Exception/InvalidChamberOfCommerceIdException'

export default class ChamberOfCommerceId {
  private readonly CHAMBER_OF_COMMERCE_ID_TEXT_LENGTH = 8
  private readonly NUMBER_REGEX = /^\d+$/

  private readonly chamberOfCommerceId: string

  public constructor(chamberOfCommerceId: string) {
    if (!this.NUMBER_REGEX.test(chamberOfCommerceId)) {
      throw new InvalidChamberOfCommerceIdException(
        `Chamber of Commerce ID can only contains numbers, got '${chamberOfCommerceId}'`,
      )
    }

    if (chamberOfCommerceId.length !== this.CHAMBER_OF_COMMERCE_ID_TEXT_LENGTH) {
      throw new InvalidChamberOfCommerceIdException(
        `Chamber of Commerce ID must be 8 characters long, got '${chamberOfCommerceId}'`,
      )
    }

    this.chamberOfCommerceId = chamberOfCommerceId
  }

  public equals(chamberOfCommerceId: ChamberOfCommerceId) {
    return this.toString() === chamberOfCommerceId.toString()
  }

  public toString(): string {
    return this.chamberOfCommerceId
  }
}
