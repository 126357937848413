/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ParameterValueObjectNotFoundException from '~/src/Domain/Engagement/Exception/ParameterValueObjectNotFoundException'
import type { BankAccountGlAccountMappingValues } from '~/src/Domain/Engagement/Parameter/BankAccountGlAccountMappingValueObject'
import BankAccountGlAccountMappingValueObject from '~/src/Domain/Engagement/Parameter/BankAccountGlAccountMappingValueObject'
import ParameterName from '~/src/Domain/Engagement/Parameter/ParameterName'
import type ParameterValueObjectInterface from '~/src/Domain/Engagement/Parameter/ParameterValueObjectInterface'
import ParameterIri from '~/src/Domain/Engagement/ParameterIri'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface ParameterProps extends DomainModelProps {
  '@id': string
  '@type': string
  phase: string
  name: string
  valueObject: Record<string, any>
  lastUpdatedDate: string
}

export default class Parameter implements DomainModelInterface<Parameter, ParameterProps> {
  public readonly '@id': ParameterIri
  public readonly '@type': string

  private constructor(
    id: ParameterIri,
    type: string,
    public readonly phase: PhaseIri,
    public readonly name: ParameterName,
    public readonly valueObject: Record<string, any>,
    public readonly lastUpdatedDate: BetterDateTime,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'Parameter'
  }

  public fromJSON(values: ParameterProps): Parameter {
    const valueObjectValues = values.valueObject
    const parameterName = new ParameterName(values.name)

    let valueObject: ParameterValueObjectInterface
    if (parameterName.equalsTo(new ParameterName('bank-account-gl-account-mapping'))) {
      valueObject = new BankAccountGlAccountMappingValueObject(
        valueObjectValues as BankAccountGlAccountMappingValues[],
      )
    } else {
      throw ParameterValueObjectNotFoundException.withName(parameterName)
    }

    return new Parameter(
      new ParameterIri(values['@id']),
      values['@type'],
      new PhaseIri(values.phase),
      parameterName,
      valueObject,
      new BetterDateTime(values.lastUpdatedDate),
    )
  }

  public toJSON(): ParameterProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      phase: this.phase.toString(),
      name: this.name.toString(),
      valueObject: this.valueObject,
      lastUpdatedDate: this.lastUpdatedDate.toString(),
    }
  }
}
