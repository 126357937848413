/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum CoaMappingStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  FULFILLED = 'fulfilled',
  VALIDATED = 'validated',
  VALIDATED_WITH_ERRORS = 'validated_with_errors',
  USER_CONFIRMED_ERRORS = 'user_confirmed_errors',
  STORED = 'stored',
  CLOSED = 'closed',
}
