/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ExportDetailsProps } from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import ExportDetailsInterface from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'

export interface MyEngagementsExportDetailsProps extends ExportDetailsProps {
  locale: string
}
export default class MyEngagementsExportDetails extends ExportDetailsInterface<
  MyEngagementsExportDetails,
  MyEngagementsExportDetailsProps
> {
  private constructor(
    public readonly locale: string,
  ) {
    super()
  }

  public fromJSON(values: MyEngagementsExportDetailsProps): MyEngagementsExportDetails {
    return new MyEngagementsExportDetails(
      values.locale,
    )
  }

  public toJSON(): MyEngagementsExportDetailsProps {
    return {
      locale: this.locale,
    }
  }
}
