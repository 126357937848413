/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataRequestorStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestorStoreRepositoryInterface'
import type DataRequestStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestStoreRepositoryInterface'
import DataRequestUpdatedEvent from '~/src/Domain/DataRequest/Event/DataRequestUpdatedEvent'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(DataRequestUpdatedEvent.NAME, 'dataRequestUpdatedEventHandler')
export default class DataRequestUpdatedEventHandler extends EventHandlerInterface<DataRequestUpdatedEvent> {
  private readonly dataRequestStoreRepository: DataRequestStoreRepositoryInterface
  private readonly dataRequestorStoreRepository: DataRequestorStoreRepositoryInterface
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface

  public constructor({ dataRequestStoreRepository, dataRequestorStoreRepository, engagementStoreRepository }: Services) {
    super()
    this.dataRequestStoreRepository = dataRequestStoreRepository
    this.dataRequestorStoreRepository = dataRequestorStoreRepository
    this.engagementStoreRepository = engagementStoreRepository
  }

  public handle(event: DataRequestUpdatedEvent): void {
    if (
      this.engagementStoreRepository.hasCurrentEngagement()
      && this.engagementStoreRepository.getCurrentEngagement()['@id'].equalsTo(event.dataRequest.engagement['@id'])
    ) {
      this.dataRequestStoreRepository.updateDataRequest(event.dataRequest)
    }

    if (
      this.dataRequestorStoreRepository.hasDataRequest()
      && this.dataRequestorStoreRepository.getDataRequest()['@id'].equalsTo(event.dataRequest['@id'])
    ) {
      this.dataRequestorStoreRepository.updateDataRequest(event.dataRequest)
    }
  }
}
