/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CoaMapping from '~/src/Domain/CoaMapping/CoaMapping'
import type CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'
import type CoaMappingStoreRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingStoreRepositoryInterface'
import CoaMappingNotFoundException from '~/src/Domain/CoaMapping/Exception/CoaMappingNotFoundException'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import CoaMappingStore from '~/src/UserInterface/CoaMapping/store/CoaMappingStore'

export default class CoaMappingStoreRepository implements CoaMappingStoreRepositoryInterface {
  private readonly store: ReturnType<typeof CoaMappingStore>

  public constructor() {
    this.store = CoaMappingStore(pinia)
  }

  public findAll(): CoaMapping[] {
    return this.store.coaMappings
  }

  public findById(coaMapping: CoaMappingIri): CoaMapping {
    const found = this.store.coaMappings.find((c) => c['@id'].equalsTo(coaMapping))
    if (found === undefined) {
      throw new CoaMappingNotFoundException(`No COA mapping found with ID "${coaMapping.toString()}"`)
    }

    return found
  }

  public updateCoaMapping(coaMapping: CoaMapping): void {
    this.store.updateCoaMapping(coaMapping)
  }

  public deleteCoaMapping(coaMapping: CoaMappingIri): void {
    this.store.deleteCoaMapping(coaMapping)
  }

  public markState(coaMappings: CoaMapping[]): void {
    this.store.markState(coaMappings)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
