/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type UpdateLocaleCommand from '~/src/Application/Shared/Translation/Command/UpdateLocaleCommand'
import LanguageUpdatedEvent from '~/src/Application/Shared/Translation/Event/LanguageUpdatedEvent'
import type CookiesInterface from '~/src/Domain/Shared/Cookies/CookiesInterface'
import type TranslatorInterface from '~/src/Domain/Shared/Translation/TranslatorInterface'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class UpdateLocaleCommandHandler extends CommandHandlerInterface<UpdateLocaleCommand> {
  public constructor(
    private readonly cookies: CookiesInterface,
    private readonly eventBus: EventBusInterface,
    private readonly httpClient: HttpClient,
    private readonly translator: TranslatorInterface,
  ) {
    super()
  }

  public async handle(command: UpdateLocaleCommand): Promise<void> {
    this.cookies.set('i18n', command.locale)
    await this.eventBus.dispatch(LanguageUpdatedEvent.NAME, new LanguageUpdatedEvent(command.locale))
  }
}
