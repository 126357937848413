/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ContextMenuItem from '~/src/Domain/Shared/ContextMenu/ContextMenuItem'
import type {
  FilterSorterValuesFromHistory,
  FilterValue,
} from '~/src/Domain/Shared/Filter/FilterCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import type useAccountsPayableNavigation from '~/src/UserInterface/AccountsPayable/composables/useAccountsPayableNavigation'
import type useAccountsReceivableNavigation from '~/src/UserInterface/AccountsReceivable/composables/useAccountsReceivableNavigation'
import type useBankNavigation from '~/src/UserInterface/Bank/composables/useBankNavigation'
import type useGeneralLedgerNavigation from '~/src/UserInterface/GeneralLedger/composables/useGeneralLedgerNavigation'

export type NavigationType = ReturnType<typeof useGeneralLedgerNavigation> &
  ReturnType<typeof useBankNavigation> &
  ReturnType<typeof useAccountsReceivableNavigation> &
  ReturnType<typeof useAccountsPayableNavigation>

export type DrillThroughContextMenuItemAction = ({
  filterValues,
  updateFilterSorterHistory,
  navigation,
}: {
  filterValues: FilterValue[]
  updateFilterSorterHistory: (filterSorterHistory: FilterSorterValuesFromHistory) => void
  navigation: NavigationType
}) => Promise<void>

export default class DrillThroughContextMenuItem extends ContextMenuItem {
  public constructor(onClick: DrillThroughContextMenuItemAction) {
    super(t('components.pivot_table.context_menu.drill_through'), 'table', onClick)
  }
}
