/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import ExportPreparedEvent from '~/src/Domain/Export/Event/ExportPreparedEvent'
import type ExportApiRepositoryInterface from '~/src/Domain/Export/ExportApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(ExportPreparedEvent.NAME, 'exportPreparedEventHandler')
export default class ExportPreparedEventHandler extends EventHandlerInterface<ExportPreparedEvent> {
  private readonly exportApiRepository: ExportApiRepositoryInterface

  public constructor({ exportApiRepository }: Services) {
    super()
    this.exportApiRepository = exportApiRepository
  }

  public async handle(event: ExportPreparedEvent): Promise<void> {
    await this.exportApiRepository.download(event.updatedExport)
  }
}
