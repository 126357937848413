/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import type { StepResultProps } from '~/src/Domain/WorkProgram/StepResult/StepResultInterface'
import StepResultInterface from '~/src/Domain/WorkProgram/StepResult/StepResultInterface'

export interface DataTableStepResultProps extends StepResultProps {
  filterValues: {
    field: string
    filterClass: FilterClass
    value: number | number[] | string | string[] | boolean
  }[]
  iri: string
  fields: string[]
}

export default class DataTableStepResult extends StepResultInterface<
  DataTableStepResult,
  DataTableStepResultProps
> {
  private constructor(
    public readonly filterValues: {
      field: string
      filterClass: FilterClass
      value: number | number[] | string | string[] | boolean
    }[],
    public readonly iri: GenericIri,
    public readonly fields: string[],
  ) {
    super()
  }

  public fromJSON(values: DataTableStepResultProps): DataTableStepResult {
    return new DataTableStepResult(values.filterValues, new GenericIri(values.iri), values.fields)
  }

  public toJSON(): DataTableStepResultProps {
    return {
      filterValues: this.filterValues,
      iri: this.iri.toString(),
      fields: this.fields,
    }
  }
}
