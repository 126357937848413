/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import Collection from '~/src/Domain/Shared/Collection/Collection'
import type ContextMenu from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import type PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'

export default class PivotTableCollection extends Collection<PivotTableDefinition> {
  public constructor(
    items: PivotTableDefinition[],
    public readonly contextMenu: ContextMenu | undefined = undefined,
  ) {
    super(items)
  }
}
