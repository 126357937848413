/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CoaMappingDTOCategory from '~/src/Domain/CoaMapping/CoaMappingDTOCategory'
import Decimal from '~/src/Domain/Shared/Decimal/Decimal'
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface CoaMappingDTOProps extends JsonValueObjectProps {
  id: string
  fiscalYear: string
  businessUnitName: string
  glAccount: string
  beginningBalance: string | undefined
  endingBalance: string | undefined
  mutationBalance: string | undefined
  journalEntryMutation: string | undefined
  difference: string | undefined
  jeLineCount: number
  accountType: string | undefined
  accountSubtype: string | undefined
  category: CoaMappingDTOCategory | undefined
}

export default class CoaMappingDTO
implements JsonValueObjectInterface<CoaMappingDTO, CoaMappingDTOProps> {
  private constructor(
    public readonly id: string,
    public readonly fiscalYear: string,
    public readonly businessUnitName: string,
    public readonly glAccount: string,
    public readonly beginningBalance: Decimal | undefined,
    public readonly endingBalance: Decimal | undefined,
    public readonly mutationBalance: Decimal | undefined,
    public readonly journalEntryMutation: Decimal | undefined,
    public readonly difference: Decimal | undefined,
    public readonly jeLineCount: number,
    public readonly accountType: string | undefined,
    public readonly accountSubtype: string | undefined,
    public readonly category: CoaMappingDTOCategory | undefined,
  ) {}

  public getClassName(): string {
    return 'CoaMappingDTO'
  }

  public isMapped(): boolean {
    return (
      this.accountType !== undefined
      && this.accountSubtype !== undefined
      && this.category !== undefined
    )
  }

  public equalsTo(dto: CoaMappingDTO): boolean {
    return (
      this.id === dto.id
      && this.accountType === dto.accountType
      && this.accountSubtype === dto.accountSubtype
      && this.category === dto.category
    )
  }

  public fromJSON(values: CoaMappingDTOProps): CoaMappingDTO {
    return new CoaMappingDTO(
      values.id,
      values.fiscalYear,
      values.businessUnitName,
      values.glAccount,
      values.beginningBalance !== undefined ? new Decimal(values.beginningBalance) : undefined,
      values.endingBalance !== undefined ? new Decimal(values.endingBalance) : undefined,
      values.mutationBalance !== undefined ? new Decimal(values.mutationBalance) : undefined,
      values.journalEntryMutation !== undefined
        ? new Decimal(values.journalEntryMutation)
        : undefined,
      values.difference !== undefined ? new Decimal(values.difference) : undefined,
      values.jeLineCount,
      values.accountType,
      values.accountSubtype,
      values.category,
    )
  }

  public toJSON(): CoaMappingDTOProps {
    return {
      id: this.id,
      fiscalYear: this.fiscalYear,
      businessUnitName: this.businessUnitName,
      glAccount: this.glAccount,
      beginningBalance: this.beginningBalance?.toString(),
      endingBalance: this.endingBalance?.toString(),
      mutationBalance: this.mutationBalance?.toString(),
      journalEntryMutation: this.journalEntryMutation?.toString(),
      difference: this.difference?.toString(),
      jeLineCount: this.jeLineCount,
      accountType: this.accountType,
      accountSubtype: this.accountSubtype,
      category: this.category,
    }
  }
}
