/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { FilterSorterValuesFromHistory } from '~/src/Domain/Shared/Filter/FilterCollection'
import {
  useEncryptStorage,
} from '~/src/UserInterface/Shared/Storage/useEncryptStorage'

const filterCollectionStore = defineStore(
  'filter-collection',
  () => {
    const filterSorterValues = ref<Record<string, FilterSorterValuesFromHistory[]>>({})

    const update = (
      phase: PhaseIri,
      filterSorterHistory: FilterSorterValuesFromHistory,
    ): void => {
      if (!(phase.toString() in filterSorterValues.value)) {
        filterSorterValues.value[phase.toString()] = []
      }

      const index = filterSorterValues.value[phase.toString()].findIndex(
        (f) => f.id === filterSorterHistory.id,
      )
      if (index === -1) {
        filterSorterValues.value[phase.toString()].push(filterSorterHistory)
      } else {
        filterSorterValues.value[phase.toString()][index] = filterSorterHistory
      }
    }

    const resetState = (): void => {
      filterSorterValues.value = {}
    }

    return {
      filterSorterValues,
      update,
      resetState,
    }
  },
  {
    persist: {
      storage: useEncryptStorage(),
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(filterCollectionStore, import.meta.hot))
}

export default filterCollectionStore
