/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataRequestorStoreRepositoryInterface
  from '~/src/Domain/DataRequest/DataRequestorStoreRepositoryInterface'
import DataRequestRecipient2faInitiatedEvent from '~/src/Domain/DataRequest/Event/DataRequestRecipient2faInitiatedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(DataRequestRecipient2faInitiatedEvent.NAME, 'dataRequestRecipient2faInitiatedEventHandler')
export default class DataRequestRecipient2faInitiatedEventHandler extends EventHandlerInterface<DataRequestRecipient2faInitiatedEvent> {
  private readonly dataRequestorStoreRepository: DataRequestorStoreRepositoryInterface

  public constructor({ dataRequestorStoreRepository }: Services) {
    super()
    this.dataRequestorStoreRepository = dataRequestorStoreRepository
  }

  public async handle(event: DataRequestRecipient2faInitiatedEvent): Promise<void> {
    this.dataRequestorStoreRepository.initiateAuthenticationProcess(event.username)
  }
}
