/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { Status } from '~/src/Domain/DataRequest/Step/Status/Status'
import type StatusBuilderInterface from '~/src/Domain/DataRequest/Step/Status/StatusBuilderInterface'
import type StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import SummaryStep from '~/src/Domain/DataRequest/Step/SummaryStep'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class SummaryStepStatusBuilder implements StatusBuilderInterface {
  public supports(step: StepInterface): boolean {
    return step instanceof SummaryStep
  }

  public build(_step: StepInterface, _dataRequest: DataRequestProjection): Status {
    return Status.INITIAL

    // const requiredFileGroups = [
    //   ...new Set(
    //     dataRequest.steps.flatMap((s) => {
    //       const fileTypes = [
    //         ...new Set(
    //           s.contents
    //             .filter(
    //               (f): f is FileUploader =>
    //                 f instanceof FileUploader && !(f.fileType instanceof OptionalFileTypeInterface),
    //             )
    //             .map((f) => f.fileType),
    //         ),
    //       ]
    //
    //       return fileTypes.flatMap((f) => dataRequest.getFileGroups(s.slug, f))
    //     }),
    //   ),
    // ]
    // const optionalFileGroups = [
    //   ...new Set(
    //     dataRequest.steps.flatMap((s) => {
    //       const fileTypes = [
    //         ...new Set(
    //           s.contents
    //             .filter(
    //               (f): f is FileUploader =>
    //                 f instanceof FileUploader && f.fileType instanceof OptionalFileTypeInterface,
    //             )
    //             .map((f) => f.fileType),
    //         ),
    //       ]
    //
    //       return fileTypes.flatMap((f) => dataRequest.getFileGroups(s.slug, f))
    //     }),
    //   ),
    // ]
    //
    // if (requiredFileGroups.length === 0) {
    //   return Status.INITIAL
    // }
    //
    // const numberOfExpectedRequiredFileGroups = dataRequest.steps.reduce((a, step) => {
    //   a += step.contents.filter(
    //     (c) => c instanceof FileUploader && !(c.fileType instanceof OptionalFileTypeInterface),
    //   ).length
    //   return a
    // }, 0)
    //
    // if (
    //   [...requiredFileGroups, ...optionalFileGroups].some(
    //     (g) => g.hasDataIntegrityErrors || g.hasDataIntegrityWarnings,
    //   )
    // ) {
    //   return Status.HAS_ERRORS
    // }
    //
    // // For GenericApplication, it is sufficient to have files uploaded to be able to fulfill the data request
    // if (!dataRequest.application.isProcessable) {
    //   return Status.COMPLETED
    // }
    //
    // if (
    //   numberOfExpectedRequiredFileGroups === requiredFileGroups.length &&
    //   requiredFileGroups.every((g) => g.haveAllFilesBeenTransformed) &&
    //   optionalFileGroups.every((g) => g.haveAllFilesBeenTransformed)
    // ) {
    //   return Status.COMPLETED
    // }
    //
    // return Status.PENDING
  }
}
