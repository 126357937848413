/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import MarkDataPivotStepResultWorkProgramCommand from '~/src/Application/WorkProgram/Command/MarkDataPivotStepResultWorkProgramCommand'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(MarkDataPivotStepResultWorkProgramCommand.NAME, 'markDataPivotStepResultWorkProgramCommandHandler')
export default class MarkDataPivotStepResultWorkProgramCommandHandler extends CommandHandlerInterface<MarkDataPivotStepResultWorkProgramCommand> {
  private readonly workProgramApiRepository: WorkProgramApiRepositoryInterface

  public constructor({ workProgramApiRepository }: Services) {
    super()
    this.workProgramApiRepository = workProgramApiRepository
  }

  public async handle(command: MarkDataPivotStepResultWorkProgramCommand): Promise<void> {
    await this.workProgramApiRepository.markDataPivotStepResult(
      command.workProgram,
      command.slug,
      command.iri,
      command.filterValues,
      command.rows,
      command.columns,
      command.values,
    )
  }
}
