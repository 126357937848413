/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Organisation from '~/src/Domain/Organisation/Organisation'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import OrganisationStore from '~/src/UserInterface/Organisation/store/OrganisationStore'

export default class OrganisationStoreRepository implements OrganisationStoreRepositoryInterface {
  private readonly store: ReturnType<typeof OrganisationStore>

  public constructor() {
    this.store = OrganisationStore(pinia)
  }

  public getOrganisation(): Organisation {
    return this.store.getOrganisation().value
  }

  public hasOrganisation(): boolean {
    return this.store.hasOrganisation
  }

  public updateOrganisation(organisation: Organisation): void {
    return this.store.updateOrganisation(organisation)
  }

  public activate(organisation: Organisation): void {
    this.store.activate(organisation)
  }
}
