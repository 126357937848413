/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ExportDetailsProps } from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import ExportDetailsInterface from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'

export interface EngagementExportDetailsProps extends ExportDetailsProps {
  engagementId: string
}

export default abstract class EngagementExportDetailsInterface<
  T extends ExportDetailsInterface<T, Y>,
  Y extends ExportDetailsProps,
> extends ExportDetailsInterface<T, Y> {
  public abstract engagementId: string
}
