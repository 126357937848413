/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'

export default class Item {
  public constructor(
    public readonly message: string,
    public readonly badge: Badge,
    public readonly ticked: boolean = false,
  ) {}
}
