/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import FulfillCoaMappingCommand from '~/src/Application/CoaMapping/Command/FulfillCoaMappingCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type CoaMappingApiRepositoryInterface from '~/src/Domain/CoaMapping/CoaMappingApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(FulfillCoaMappingCommand.NAME, 'fulfillCoaMappingCommandHandler')
export default class FulfillCoaMappingCommandHandler extends CommandHandlerInterface<FulfillCoaMappingCommand> {
  private readonly coaMappingApiRepository: CoaMappingApiRepositoryInterface

  public constructor({ coaMappingApiRepository }: Services) {
    super()
    this.coaMappingApiRepository = coaMappingApiRepository
  }

  public async handle(command: FulfillCoaMappingCommand): Promise<void> {
    await this.coaMappingApiRepository.fulfill(command.coaMapping)
  }
}
