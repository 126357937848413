/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'

export class StartWorkProgramCommandStep {
  public readonly slug: Slug
  public readonly hidden: boolean
  public readonly hiddenReason: string | undefined

  public constructor(slug: Slug, hidden: boolean, hiddenReason: string | undefined) {
    this.slug = slug
    this.hidden = hidden
    this.hiddenReason = hiddenReason
  }
}

export class StartWorkProgramCommandParameter {
  public readonly name: string
  public readonly value: string | string[] | number | number[]

  public constructor(name: string, value: string | string[] | number | number[]) {
    this.name = name
    this.value = value
  }
}

export default class StartWorkProgramCommand implements CommandInterface {
  public static readonly NAME = 'START_WORK_PROGRAM_COMMAND'

  public readonly phase: PhaseIri
  public readonly steps: StartWorkProgramCommandStep[]
  public readonly parameters: StartWorkProgramCommandParameter[]

  public constructor(
    phase: PhaseIri,
    public readonly slug: Slug,
    public readonly description: string | undefined,
    steps: { slug: Slug, hidden: boolean, hiddenReason: undefined | string }[],
    parameters: { name: string, value: string }[],
  ) {
    this.phase = phase
    this.steps = steps.map((s) => new StartWorkProgramCommandStep(s.slug, s.hidden, s.hiddenReason))
    this.parameters = parameters.map((p) => new StartWorkProgramCommandParameter(p.name, p.value))
  }
}
