/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Route } from '~/src/Application/Shared/Router/RouterInterface'
import type {
  RegisterPayload,
  RequestResetPasswordPayload,
} from '~/src/Domain/Identity/SecurityInterface'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import useIdentityNavigation from '~/src/UserInterface/Identity/composables/useIdentityNavigation'

export default function useSecurity() {
  const cookies = useService('cookies')
  const security = useService('security')

  const { navigateToLocalePath, navigateTo, router } = useNavigation()
  const { navigateToIdentity2faPage } = useIdentityNavigation()

  const login = async (username: string, password: string): Promise<void> => {
    await security.login(username, password)
    await navigateToIdentity2faPage()
  }

  const requestNewEmailAuthToken = async (): Promise<void> => {
    await security.requestNewEmailAuthToken()
  }

  const login2fa = async (token: string): Promise<void> => {
    await security.login2fa(token)

    const userLocale = cookies.get<string>('i18n')
    const redirect = cookies.tryGet<string>('redirect')

    if (redirect === undefined) {
      await navigateToLocalePath('Index', userLocale)
    } else {
      cookies.remove('redirect')
      await navigateTo({ path: redirect } as Route)
    }
  }

  const logout = async (): Promise<void> => {
    try {
      await security.logout()
    } finally {
      await router.replace('/')
    }
  }

  const refreshJwtToken = async () => security.refreshJwtToken()

  const requestResetPassword = async (payload: RequestResetPasswordPayload) =>
    security.requestResetPassword(payload)

  const register = async (payload: RegisterPayload): Promise<void> =>
    security.register(payload)

  return {
    login,
    login2fa,
    requestNewEmailAuthToken,
    requestResetPassword,
    logout,
    refreshJwtToken,
    register,
  }
}
