import { default as _91_46_46_46slug_93ZgQzh67oOLMeta } from "/home/node/app/src/UserInterface/App/pages/[...slug].vue?macro=true";
import { default as error8GzlbxCFQYMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/error.vue?macro=true";
import { default as landingbZgomChTtGMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/landing.vue?macro=true";
import { default as _91loginUrl_93pFoOMyyWvMMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/login/[email]/[loginUrl].vue?macro=true";
import { default as _2faMklkNxCyCXMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/login/[email]/2fa.vue?macro=true";
import { default as _91stepSlug_93YNS6bNDMxqMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/[stepSlug].vue?macro=true";
import { default as introduction7EbXSVcfKBMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/introduction.vue?macro=true";
import { default as successnp9UJ222NhMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/success.vue?macro=true";
import { default as summaryWAaHikiXrBMeta } from "/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/summary.vue?macro=true";
import { default as open_45itemsrat1iF3cEoMeta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/explorers/open-items.vue?macro=true";
import { default as indexHJsLhh6HtPMeta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/index.vue?macro=true";
import { default as creditorsEg0WOLY5H9Meta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/master-data/creditors.vue?macro=true";
import { default as multiple_45bank_45accounts_45same_45supplier_45namet601Q2ngHpMeta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/multiple-bank-accounts-same-supplier-name.vue?macro=true";
import { default as multiple_45supplier_45names_45same_45bank_45accountZmEA2NzCF8Meta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/multiple-supplier-names-same-bank-account.vue?macro=true";
import { default as open_45itemsV8ZOI8RKqWMeta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/open-items.vue?macro=true";
import { default as accounts_45payableRizLOxutCfMeta } from "/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable.vue?macro=true";
import { default as open_45itemsr0gBjnJWOhMeta } from "/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/explorers/open-items.vue?macro=true";
import { default as indexT5mSC4ika2Meta } from "/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/index.vue?macro=true";
import { default as debtorsSfupbr1dJwMeta } from "/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/master-data/debtors.vue?macro=true";
import { default as open_45items4AXmaLVuQFMeta } from "/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/open-items.vue?macro=true";
import { default as accounts_45receivableKwKY5rK1JlMeta } from "/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable.vue?macro=true";
import { default as bank_45statementsFsHdJ6LoqhMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/bank-statements.vue?macro=true";
import { default as corruption_45perception_45indices6DqygnJaS3Meta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/corruption-perception-indices.vue?macro=true";
import { default as bank_45statements9The4DJfMxMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/explorers/bank-statements.vue?macro=true";
import { default as indexPkCCgqOTPCMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/index.vue?macro=true";
import { default as journal_45entry_45reconciliation3dHkd3e8y5Meta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/journal-entry-reconciliation.vue?macro=true";
import { default as multiple_45bank_45accountsQDwIr751SrMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/multiple-bank-accounts.vue?macro=true";
import { default as multiple_45customersOW2GtjsmQ5Meta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/multiple-customers.vue?macro=true";
import { default as overview5iYnJu8z2mMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/overview.vue?macro=true";
import { default as validationUDUa5CvooIMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/validation.vue?macro=true";
import { default as bankPfHFhTcjlQMeta } from "/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank.vue?macro=true";
import { default as other_45side_45of_45jekGLWsZuBEyMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/booking-patterns/other-side-of-je.vue?macro=true";
import { default as two_45way_45correlation71URzoJzbzMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/booking-patterns/two-way-correlation.vue?macro=true";
import { default as business_45unitsy8lNCBnjLaMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/business-units.vue?macro=true";
import { default as preparers9mapO9zOS3Meta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/preparers.vue?macro=true";
import { default as sourcesqvNbTyEyGQMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/sources.vue?macro=true";
import { default as indexjTu8kGauKIMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/index.vue?macro=true";
import { default as journal_45entriesicKcg5F6fQMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/journal-entries.vue?macro=true";
import { default as chart_45of_45accountsrCltp9zARPMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/master-data/chart-of-accounts.vue?macro=true";
import { default as overviewIoiYRk7yiWMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/overview.vue?macro=true";
import { default as days_45lagMGL32ETG0LMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/days-lag.vue?macro=true";
import { default as entered_45datesyCQTv0P5IOMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/entered-dates.vue?macro=true";
import { default as entered_45timesVQDM5OuZu9Meta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/entered-times.vue?macro=true";
import { default as validationskdNrbBV1DMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/validation.vue?macro=true";
import { default as general_45ledgerUF17sXOcTGMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger.vue?macro=true";
import { default as indextXNTcrbWgsMeta } from "/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/index.vue?macro=true";
import { default as introductionejCg6dObG1Meta } from "/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/introduction.vue?macro=true";
import { default as mapping21jn4FdtADMeta } from "/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/mapping.vue?macro=true";
import { default as successJNirQY8tpzMeta } from "/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/success.vue?macro=true";
import { default as summaryRXCrr5zrJRMeta } from "/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/summary.vue?macro=true";
import { default as introductionB2qJBpVQpLMeta } from "/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/introduction.vue?macro=true";
import { default as finalise8pY4vERm4RMeta } from "/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/finalise.vue?macro=true";
import { default as settingscUkEJ034b9Meta } from "/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/settings.vue?macro=true";
import { default as _91stepSlug_939dxxqaNtoFMeta } from "/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/step/[stepSlug].vue?macro=true";
import { default as _91workProgramSlug_93StJRDLkjZVMeta } from "/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug].vue?macro=true";
import { default as introductiong63IbiaE9jMeta } from "/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramSlug]/introduction.vue?macro=true";
import { default as indexmc2Lcls12jMeta } from "/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/index.vue?macro=true";
import { default as successzLPLxVGwa9Meta } from "/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/[token]/success.vue?macro=true";
import { default as addTwwb1YNAmaMeta } from "/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/add.vue?macro=true";
import { default as indexXM7iuwIu3nMeta } from "/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/index.vue?macro=true";
import { default as indexk1wDTt6szyMeta } from "/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/index.vue?macro=true";
import { default as indexsOaFOAGCnQMeta } from "/home/node/app/src/UserInterface/Organisation/pages/admin/engagements/index.vue?macro=true";
import { default as index6sqmWgFEFaMeta } from "/home/node/app/src/UserInterface/Organisation/pages/admin/index.vue?macro=true";
import { default as successm8TNqaSfpKMeta } from "/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/add/[chamberOfCommerceId]/success.vue?macro=true";
import { default as indexdKI9gw8O4pMeta } from "/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/add/index.vue?macro=true";
import { default as indexHW3EUzx4NoMeta } from "/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/index.vue?macro=true";
import { default as Index2iov6jSUj2Meta } from "/home/node/app/src/UserInterface/App/pages/Index.vue?macro=true";
import { default as _2falYbKZEnVPgMeta } from "/home/node/app/src/UserInterface/Identity/pages/login/2fa.vue?macro=true";
import { default as indexMBiFKHNaHYMeta } from "/home/node/app/src/UserInterface/Identity/pages/login/index.vue?macro=true";
import { default as indexkqiwwLwzUAMeta } from "/home/node/app/src/UserInterface/Identity/pages/logout/index.vue?macro=true";
import { default as audit_45teamfqwEsXaId3Meta } from "/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/audit-team.vue?macro=true";
import { default as coa_45mappingsiLOkqWCx5DMeta } from "/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/coa-mappings.vue?macro=true";
import { default as indexQVuXiyHOLZMeta } from "/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/add/index.vue?macro=true";
import { default as successjqwMR1P0KAMeta } from "/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/add/success.vue?macro=true";
import { default as indexL343HEMlFnMeta } from "/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/index.vue?macro=true";
import { default as indexGfTC2byajjMeta } from "/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/index.vue?macro=true";
import { default as confirm_45email6SR3cFMGt9Meta } from "/home/node/app/src/UserInterface/Identity/pages/register/confirm-email.vue?macro=true";
import { default as indexDT6spQnw9MMeta } from "/home/node/app/src/UserInterface/Identity/pages/register/index.vue?macro=true";
import { default as _91email_9342mvmFnIeDMeta } from "/home/node/app/src/UserInterface/Identity/pages/register/success/[email].vue?macro=true";
import { default as request_45reset_45password1dIFMpjKMcMeta } from "/home/node/app/src/UserInterface/Identity/pages/request-reset-password.vue?macro=true";
import { default as reset_45passwordbtgIw9jthLMeta } from "/home/node/app/src/UserInterface/Identity/pages/reset-password.vue?macro=true";
import { default as editU3ycIgRSWVMeta } from "/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/edit.vue?macro=true";
import { default as exports3Oqd3uWTIAMeta } from "/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/exports.vue?macro=true";
import { default as indexVrByxzufMpMeta } from "/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/index.vue?macro=true";
export default [
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ZgQzh67oOLMeta || {},
    component: () => import("/home/node/app/src/UserInterface/App/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ZgQzh67oOLMeta || {},
    component: () => import("/home/node/app/src/UserInterface/App/pages/[...slug].vue")
  },
  {
    name: "dataRequest-Error___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/error",
    meta: error8GzlbxCFQYMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/error.vue")
  },
  {
    name: "dataRequest-Error___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/error",
    meta: error8GzlbxCFQYMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/error.vue")
  },
  {
    name: "dataRequest-Landing___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/landing",
    meta: landingbZgomChTtGMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/landing.vue")
  },
  {
    name: "dataRequest-Landing___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/landing",
    meta: landingbZgomChTtGMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/landing.vue")
  },
  {
    name: "dataRequest-Authorise___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/login/:email()/:loginUrl()",
    meta: _91loginUrl_93pFoOMyyWvMMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/login/[email]/[loginUrl].vue")
  },
  {
    name: "dataRequest-Authorise___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/login/:email()/:loginUrl()",
    meta: _91loginUrl_93pFoOMyyWvMMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/login/[email]/[loginUrl].vue")
  },
  {
    name: "dataRequest-Authorise2fa___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/login/:email()/2fa",
    meta: _2faMklkNxCyCXMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/login/[email]/2fa.vue")
  },
  {
    name: "dataRequest-Authorise2fa___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/login/:email()/2fa",
    meta: _2faMklkNxCyCXMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/login/[email]/2fa.vue")
  },
  {
    name: "dataRequest-Step___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/stappen/:stepSlug()",
    meta: _91stepSlug_93YNS6bNDMxqMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/[stepSlug].vue")
  },
  {
    name: "dataRequest-Step___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/steps/:stepSlug()",
    meta: _91stepSlug_93YNS6bNDMxqMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/[stepSlug].vue")
  },
  {
    name: "dataRequest-IntroductionStep___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/stappen/introductie",
    meta: introduction7EbXSVcfKBMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/introduction.vue")
  },
  {
    name: "dataRequest-IntroductionStep___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/steps/introduction",
    meta: introduction7EbXSVcfKBMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/introduction.vue")
  },
  {
    name: "dataRequest-SuccessStep___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/stappen/success",
    meta: successnp9UJ222NhMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/success.vue")
  },
  {
    name: "dataRequest-SuccessStep___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/steps/success",
    meta: successnp9UJ222NhMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/success.vue")
  },
  {
    name: "dataRequest-SummaryStep___nl",
    path: "/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/stappen/samenvatting",
    meta: summaryWAaHikiXrBMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/summary.vue")
  },
  {
    name: "dataRequest-SummaryStep___en",
    path: "/en/:dataRequestId()/:dataRequestSlug()/:dataRequestRecipientId()/steps/summary",
    meta: summaryWAaHikiXrBMeta || {},
    component: () => import("/home/node/app/src/UserInterface/DataRequest/pages/[dataRequestId]/[dataRequestSlug]/[dataRequestRecipientId]/steps/summary.vue")
  },
  {
    name: accounts_45payableRizLOxutCfMeta?.name,
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/accounts-payable",
    meta: accounts_45payableRizLOxutCfMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable.vue"),
    children: [
  {
    name: "accountsPayable-explorer-AccountsPayables___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/crediteuren/verkenners/openstaande-posten",
    meta: open_45itemsrat1iF3cEoMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/explorers/open-items.vue")
  },
  {
    name: "accountsPayable-Index___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/crediteuren",
    meta: indexHJsLhh6HtPMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/index.vue")
  },
  {
    name: "accountsPayable-masterData-Creditors___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/crediteuren/stamdata/crediteuren",
    meta: creditorsEg0WOLY5H9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/master-data/creditors.vue")
  },
  {
    name: "accountsPayable-MultipleBankAccountsSameSupplierName___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/crediteuren/meerdere-bankrekeningen-zelfde-naam",
    meta: multiple_45bank_45accounts_45same_45supplier_45namet601Q2ngHpMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/multiple-bank-accounts-same-supplier-name.vue")
  },
  {
    name: "accountsPayable-MultipleSupplierNamesSameBankAccount___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/crediteuren/meerdere-namen-zelfde-bankrekeningen",
    meta: multiple_45supplier_45names_45same_45bank_45accountZmEA2NzCF8Meta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/multiple-supplier-names-same-bank-account.vue")
  },
  {
    name: "accountsPayable-Overview___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/crediteuren/openstaande-posten",
    meta: open_45itemsV8ZOI8RKqWMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/open-items.vue")
  }
]
  },
  {
    name: accounts_45payableRizLOxutCfMeta?.name,
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/accounts-payable",
    meta: accounts_45payableRizLOxutCfMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable.vue"),
    children: [
  {
    name: "accountsPayable-explorer-AccountsPayables___en",
    path: "explorers/open-items",
    meta: open_45itemsrat1iF3cEoMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/explorers/open-items.vue")
  },
  {
    name: "accountsPayable-Index___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/accounts-payable",
    meta: indexHJsLhh6HtPMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/index.vue")
  },
  {
    name: "accountsPayable-masterData-Creditors___en",
    path: "master-data/creditors",
    meta: creditorsEg0WOLY5H9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/master-data/creditors.vue")
  },
  {
    name: "accountsPayable-MultipleBankAccountsSameSupplierName___en",
    path: "multiple-bank-accounts-same-supplier-name",
    meta: multiple_45bank_45accounts_45same_45supplier_45namet601Q2ngHpMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/multiple-bank-accounts-same-supplier-name.vue")
  },
  {
    name: "accountsPayable-MultipleSupplierNamesSameBankAccount___en",
    path: "multiple-supplier-names-same-bank-account",
    meta: multiple_45supplier_45names_45same_45bank_45accountZmEA2NzCF8Meta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/multiple-supplier-names-same-bank-account.vue")
  },
  {
    name: "accountsPayable-Overview___en",
    path: "open-items",
    meta: open_45itemsV8ZOI8RKqWMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsPayable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-payable/open-items.vue")
  }
]
  },
  {
    name: accounts_45receivableKwKY5rK1JlMeta?.name,
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/accounts-receivable",
    meta: accounts_45receivableKwKY5rK1JlMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable.vue"),
    children: [
  {
    name: "accountsReceivable-explorer-AccountsReceivables___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/debiteuren/verkenners/openstaande-posten",
    meta: open_45itemsr0gBjnJWOhMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/explorers/open-items.vue")
  },
  {
    name: "accountsReceivable-Index___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/debiteuren",
    meta: indexT5mSC4ika2Meta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/index.vue")
  },
  {
    name: "accountsReceivable-masterData-Debtors___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/debiteuren/stamdata/debiteuren",
    meta: debtorsSfupbr1dJwMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/master-data/debtors.vue")
  },
  {
    name: "accountsReceivable-Overview___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/debiteuren/openstaande-posten",
    meta: open_45items4AXmaLVuQFMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/open-items.vue")
  }
]
  },
  {
    name: accounts_45receivableKwKY5rK1JlMeta?.name,
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/accounts-receivable",
    meta: accounts_45receivableKwKY5rK1JlMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable.vue"),
    children: [
  {
    name: "accountsReceivable-explorer-AccountsReceivables___en",
    path: "explorers/open-items",
    meta: open_45itemsr0gBjnJWOhMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/explorers/open-items.vue")
  },
  {
    name: "accountsReceivable-Index___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/accounts-receivable",
    meta: indexT5mSC4ika2Meta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/index.vue")
  },
  {
    name: "accountsReceivable-masterData-Debtors___en",
    path: "master-data/debtors",
    meta: debtorsSfupbr1dJwMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/master-data/debtors.vue")
  },
  {
    name: "accountsReceivable-Overview___en",
    path: "open-items",
    meta: open_45items4AXmaLVuQFMeta || {},
    component: () => import("/home/node/app/src/UserInterface/AccountsReceivable/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/accounts-receivable/open-items.vue")
  }
]
  },
  {
    name: bankPfHFhTcjlQMeta?.name,
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/bank",
    meta: bankPfHFhTcjlQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank.vue"),
    children: [
  {
    name: "bank-BankStatementEntryDetails___nl",
    path: "banktransacties",
    meta: bank_45statementsFsHdJ6LoqhMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/bank-statements.vue")
  },
  {
    name: "bank-BankStatementEntryDetailCorruptionPerceptionIndices___nl",
    path: "banktransacties/corruptie-perceptie-index",
    meta: corruption_45perception_45indices6DqygnJaS3Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/corruption-perception-indices.vue")
  },
  {
    name: "bank-explorer-BankStatementEntryDetails___nl",
    path: "verkenners/banktransacties",
    meta: bank_45statements9The4DJfMxMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/explorers/bank-statements.vue")
  },
  {
    name: "bank-Index___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/bank",
    meta: indexPkCCgqOTPCMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/index.vue")
  },
  {
    name: "bank-BankStatementJournalEntryReconciliation___nl",
    path: "journaalpost-aansluiting",
    meta: journal_45entry_45reconciliation3dHkd3e8y5Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/journal-entry-reconciliation.vue")
  },
  {
    name: "bank-BankStatementEntryDetailMultipleBankAccounts___nl",
    path: "meerdere-bank-accounts",
    meta: multiple_45bank_45accountsQDwIr751SrMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/multiple-bank-accounts.vue")
  },
  {
    name: "bank-BankStatementEntryDetailMultipleCustomers___nl",
    path: "meerdere-namen",
    meta: multiple_45customersOW2GtjsmQ5Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/multiple-customers.vue")
  },
  {
    name: "bank-Overview___nl",
    path: "overzicht",
    meta: overview5iYnJu8z2mMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/overview.vue")
  },
  {
    name: "bank-BankStatementEntryValidation___nl",
    path: "validatie",
    meta: validationUDUa5CvooIMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/validation.vue")
  }
]
  },
  {
    name: bankPfHFhTcjlQMeta?.name,
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/bank",
    meta: bankPfHFhTcjlQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank.vue"),
    children: [
  {
    name: "bank-BankStatementEntryDetails___en",
    path: "bank-statements",
    meta: bank_45statementsFsHdJ6LoqhMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/bank-statements.vue")
  },
  {
    name: "bank-BankStatementEntryDetailCorruptionPerceptionIndices___en",
    path: "bank-statements/corruption-perception-indices",
    meta: corruption_45perception_45indices6DqygnJaS3Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/corruption-perception-indices.vue")
  },
  {
    name: "bank-explorer-BankStatementEntryDetails___en",
    path: "explorers/bank-statements",
    meta: bank_45statements9The4DJfMxMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/explorers/bank-statements.vue")
  },
  {
    name: "bank-Index___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/bank",
    meta: indexPkCCgqOTPCMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/index.vue")
  },
  {
    name: "bank-BankStatementJournalEntryReconciliation___en",
    path: "journal-entry-reconciliation",
    meta: journal_45entry_45reconciliation3dHkd3e8y5Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/journal-entry-reconciliation.vue")
  },
  {
    name: "bank-BankStatementEntryDetailMultipleBankAccounts___en",
    path: "multiple-bank-accounts",
    meta: multiple_45bank_45accountsQDwIr751SrMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/multiple-bank-accounts.vue")
  },
  {
    name: "bank-BankStatementEntryDetailMultipleCustomers___en",
    path: "multiple-customers",
    meta: multiple_45customersOW2GtjsmQ5Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/multiple-customers.vue")
  },
  {
    name: "bank-Overview___en",
    path: "overview",
    meta: overview5iYnJu8z2mMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/overview.vue")
  },
  {
    name: "bank-BankStatementEntryValidation___en",
    path: "validation",
    meta: validationUDUa5CvooIMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Bank/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/bank/validation.vue")
  }
]
  },
  {
    name: general_45ledgerUF17sXOcTGMeta?.name,
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/general-ledger",
    meta: general_45ledgerUF17sXOcTGMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger.vue"),
    children: [
  {
    name: "generalLedger-bookingPattern-OtherSideOfJournalEntries___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/boekingsgangen/tegenrekeningen",
    meta: other_45side_45of_45jekGLWsZuBEyMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/booking-patterns/other-side-of-je.vue")
  },
  {
    name: "generalLedger-bookingPattern-TwoWayCorrelations___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/boekingsgangen/tweeweg-correlatie",
    meta: two_45way_45correlation71URzoJzbzMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/booking-patterns/two-way-correlation.vue")
  },
  {
    name: "generalLedger-explorer-BusinessUnits___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/verkenners/bedrijfseenheden",
    meta: business_45unitsy8lNCBnjLaMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/business-units.vue")
  },
  {
    name: "generalLedger-explorer-Preparers___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/verkenners/gebruikers",
    meta: preparers9mapO9zOS3Meta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/preparers.vue")
  },
  {
    name: "generalLedger-explorer-Sources___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/verkenners/dagboeken",
    meta: sourcesqvNbTyEyGQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/sources.vue")
  },
  {
    name: "generalLedger-Index___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek",
    meta: indexjTu8kGauKIMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/index.vue")
  },
  {
    name: "generalLedger-JournalEntries___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/journaalposten",
    meta: journal_45entriesicKcg5F6fQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/journal-entries.vue")
  },
  {
    name: "generalLedger-masterData-ChartsOfAccounts___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/stamdata/rekeningschema",
    meta: chart_45of_45accountsrCltp9zARPMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/master-data/chart-of-accounts.vue")
  },
  {
    name: "generalLedger-Overview___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/overzicht",
    meta: overviewIoiYRk7yiWMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/overview.vue")
  },
  {
    name: "generalLedger-timing-DaysLag___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/timing/boekingsvertraging",
    meta: days_45lagMGL32ETG0LMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/days-lag.vue")
  },
  {
    name: "generalLedger-timing-EnteredDates___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/timing/invoer-datums",
    meta: entered_45datesyCQTv0P5IOMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/entered-dates.vue")
  },
  {
    name: "generalLedger-timing-EnteredTimes___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/timing/invoer-tijden",
    meta: entered_45timesVQDM5OuZu9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/entered-times.vue")
  },
  {
    name: "generalLedger-GLValidation___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/grootboek/validatie",
    meta: validationskdNrbBV1DMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/validation.vue")
  }
]
  },
  {
    name: general_45ledgerUF17sXOcTGMeta?.name,
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/general-ledger",
    meta: general_45ledgerUF17sXOcTGMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger.vue"),
    children: [
  {
    name: "generalLedger-bookingPattern-OtherSideOfJournalEntries___en",
    path: "booking-patterns/other-side-of-je",
    meta: other_45side_45of_45jekGLWsZuBEyMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/booking-patterns/other-side-of-je.vue")
  },
  {
    name: "generalLedger-bookingPattern-TwoWayCorrelations___en",
    path: "booking-patterns/two-way-correlation",
    meta: two_45way_45correlation71URzoJzbzMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/booking-patterns/two-way-correlation.vue")
  },
  {
    name: "generalLedger-explorer-BusinessUnits___en",
    path: "explorers/business-units",
    meta: business_45unitsy8lNCBnjLaMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/business-units.vue")
  },
  {
    name: "generalLedger-explorer-Preparers___en",
    path: "explorers/preparers",
    meta: preparers9mapO9zOS3Meta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/preparers.vue")
  },
  {
    name: "generalLedger-explorer-Sources___en",
    path: "explorers/sources",
    meta: sourcesqvNbTyEyGQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/explorers/sources.vue")
  },
  {
    name: "generalLedger-Index___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/general-ledger",
    meta: indexjTu8kGauKIMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/index.vue")
  },
  {
    name: "generalLedger-JournalEntries___en",
    path: "journal-entries",
    meta: journal_45entriesicKcg5F6fQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/journal-entries.vue")
  },
  {
    name: "generalLedger-masterData-ChartsOfAccounts___en",
    path: "master-data/chart-of-accounts",
    meta: chart_45of_45accountsrCltp9zARPMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/master-data/chart-of-accounts.vue")
  },
  {
    name: "generalLedger-Overview___en",
    path: "overview",
    meta: overviewIoiYRk7yiWMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/overview.vue")
  },
  {
    name: "generalLedger-timing-DaysLag___en",
    path: "timing/days-lag",
    meta: days_45lagMGL32ETG0LMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/days-lag.vue")
  },
  {
    name: "generalLedger-timing-EnteredDates___en",
    path: "timing/entered-dates",
    meta: entered_45datesyCQTv0P5IOMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/entered-dates.vue")
  },
  {
    name: "generalLedger-timing-EnteredTimes___en",
    path: "timing/entered-times",
    meta: entered_45timesVQDM5OuZu9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/timing/entered-times.vue")
  },
  {
    name: "generalLedger-GLValidation___en",
    path: "validation",
    meta: validationskdNrbBV1DMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/general-ledger/validation.vue")
  }
]
  },
  {
    name: "dashboard-Index___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()",
    meta: indextXNTcrbWgsMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/index.vue")
  },
  {
    name: "dashboard-Index___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()",
    meta: indextXNTcrbWgsMeta || {},
    component: () => import("/home/node/app/src/UserInterface/GeneralLedger/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/index.vue")
  },
  {
    name: "settings-coaMapping-IntroductionStarted___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/instellingen/rekeningschema/:coaMappingId()/introductie",
    meta: introductionejCg6dObG1Meta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/introduction.vue")
  },
  {
    name: "settings-coaMapping-IntroductionStarted___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/settings/charts-of-accounts/:coaMappingId()/introduction",
    meta: introductionejCg6dObG1Meta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/introduction.vue")
  },
  {
    name: "settings-coaMapping-Mapping___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/instellingen/rekeningschema/:coaMappingId()/koppeling",
    meta: mapping21jn4FdtADMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/mapping.vue")
  },
  {
    name: "settings-coaMapping-Mapping___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/settings/charts-of-accounts/:coaMappingId()/mapping",
    meta: mapping21jn4FdtADMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/mapping.vue")
  },
  {
    name: "settings-coaMapping-Success___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/instellingen/rekeningschema/:coaMappingId()/success",
    meta: successJNirQY8tpzMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/success.vue")
  },
  {
    name: "settings-coaMapping-Success___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/settings/charts-of-accounts/:coaMappingId()/success",
    meta: successJNirQY8tpzMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/success.vue")
  },
  {
    name: "settings-coaMapping-Summary___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/instellingen/rekeningschema/:coaMappingId()/samenvatting",
    meta: summaryRXCrr5zrJRMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/summary.vue")
  },
  {
    name: "settings-coaMapping-Summary___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/settings/charts-of-accounts/:coaMappingId()/summary",
    meta: summaryRXCrr5zrJRMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/[coaMappingId]/summary.vue")
  },
  {
    name: "settings-coaMapping-Introduction___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/instellingen/rekeningschema/introductie",
    meta: introductionB2qJBpVQpLMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/introduction.vue")
  },
  {
    name: "settings-coaMapping-Introduction___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/settings/charts-of-accounts/introduction",
    meta: introductionB2qJBpVQpLMeta || {},
    component: () => import("/home/node/app/src/UserInterface/CoaMapping/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/settings/charts-of-accounts/introduction.vue")
  },
  {
    name: "organisationId-organisationSlug-engagementId-engagementSlug-phaseId-phaseSlug-workprograms-workProgramId-workProgramSlug___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/workprograms/:workProgramId()/:workProgramSlug()",
    meta: _91workProgramSlug_93StJRDLkjZVMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug].vue"),
    children: [
  {
    name: "workprogram-Finalise___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/werkprogrammas/:workProgramId()/:workProgramSlug()/finaliseren",
    meta: finalise8pY4vERm4RMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/finalise.vue")
  },
  {
    name: "workprogram-Settings___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/werkprogrammas/:workProgramId()/:workProgramSlug()/instellingen",
    meta: settingscUkEJ034b9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/settings.vue")
  },
  {
    name: "workprogram-Step___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/werkprogrammas/:workProgramId()/:workProgramSlug()/step/:stepSlug()",
    meta: _91stepSlug_939dxxqaNtoFMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/step/[stepSlug].vue")
  }
]
  },
  {
    name: "organisationId-organisationSlug-engagementId-engagementSlug-phaseId-phaseSlug-workprograms-workProgramId-workProgramSlug___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/workprograms/:workProgramId()/:workProgramSlug()",
    meta: _91workProgramSlug_93StJRDLkjZVMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug].vue"),
    children: [
  {
    name: "workprogram-Finalise___en",
    path: "finalise",
    meta: finalise8pY4vERm4RMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/finalise.vue")
  },
  {
    name: "workprogram-Settings___en",
    path: "settings",
    meta: settingscUkEJ034b9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/settings.vue")
  },
  {
    name: "workprogram-Step___en",
    path: "step/:stepSlug()",
    meta: _91stepSlug_939dxxqaNtoFMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramId]/[workProgramSlug]/step/[stepSlug].vue")
  }
]
  },
  {
    name: "workprogram-Introduction___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/werkprogrammas/:workProgramSlug()/introductie",
    meta: introductiong63IbiaE9jMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramSlug]/introduction.vue")
  },
  {
    name: "workprogram-Introduction___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/workprograms/:workProgramSlug()/introduction",
    meta: introductiong63IbiaE9jMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/[workProgramSlug]/introduction.vue")
  },
  {
    name: "workprogram-Overview___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/werkprogrammas",
    meta: indexmc2Lcls12jMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/index.vue")
  },
  {
    name: "workprogram-Overview___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/:phaseId()/:phaseSlug()/workprograms",
    meta: indexmc2Lcls12jMeta || {},
    component: () => import("/home/node/app/src/UserInterface/WorkProgram/pages/[organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/[phaseId]/[phaseSlug]/workprograms/index.vue")
  },
  {
    name: "settings-organisation-EngagementAddSuccess___nl",
    path: "/:organisationId()/:organisationSlug()/instellingen/opdrachten/toevoegen/:token()/success",
    meta: successzLPLxVGwa9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/[token]/success.vue")
  },
  {
    name: "settings-organisation-EngagementAddSuccess___en",
    path: "/en/:organisationId()/:organisationSlug()/settings/engagements/add/:token()/success",
    meta: successzLPLxVGwa9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/[token]/success.vue")
  },
  {
    name: "settings-organisation-EngagementAdd___nl",
    path: "/:organisationId()/:organisationSlug()/instellingen/opdrachten/toevoegen",
    meta: addTwwb1YNAmaMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/add.vue")
  },
  {
    name: "settings-organisation-EngagementAdd___en",
    path: "/en/:organisationId()/:organisationSlug()/settings/engagements/add",
    meta: addTwwb1YNAmaMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/add.vue")
  },
  {
    name: "settings-organisation-Engagement___nl",
    path: "/:organisationId()/:organisationSlug()/instellingen/opdrachten",
    meta: indexXM7iuwIu3nMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/index.vue")
  },
  {
    name: "settings-organisation-Engagement___en",
    path: "/en/:organisationId()/:organisationSlug()/settings/engagements",
    meta: indexXM7iuwIu3nMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/engagements/index.vue")
  },
  {
    name: "settings-organisation-General___nl",
    path: "/:organisationId()/:organisationSlug()/instellingen",
    meta: indexk1wDTt6szyMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/index.vue")
  },
  {
    name: "settings-organisation-General___en",
    path: "/en/:organisationId()/:organisationSlug()/settings",
    meta: indexk1wDTt6szyMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/[organisationId]/[organisationSlug]/settings/index.vue")
  },
  {
    name: "admin-engagements-List___nl",
    path: "/beheer/opdrachten",
    meta: indexsOaFOAGCnQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/engagements/index.vue")
  },
  {
    name: "admin-engagements-List___en",
    path: "/en/admin/engagements",
    meta: indexsOaFOAGCnQMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/engagements/index.vue")
  },
  {
    name: "admin-Dashboard___nl",
    path: "/beheer",
    meta: index6sqmWgFEFaMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/index.vue")
  },
  {
    name: "admin-Dashboard___en",
    path: "/en/admin",
    meta: index6sqmWgFEFaMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/index.vue")
  },
  {
    name: "admin-organisations-Success___nl",
    path: "/beheer/organisaties/toevoegen/:chamberOfCommerceId()/success",
    meta: successm8TNqaSfpKMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/add/[chamberOfCommerceId]/success.vue")
  },
  {
    name: "admin-organisations-Success___en",
    path: "/en/admin/organisations/add/:chamberOfCommerceId()/success",
    meta: successm8TNqaSfpKMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/add/[chamberOfCommerceId]/success.vue")
  },
  {
    name: "admin-organisations-Add___nl",
    path: "/beheer/organisaties/toevoegen",
    meta: indexdKI9gw8O4pMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/add/index.vue")
  },
  {
    name: "admin-organisations-Add___en",
    path: "/en/admin/organisations/add",
    meta: indexdKI9gw8O4pMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/add/index.vue")
  },
  {
    name: "admin-organisations-List___nl",
    path: "/beheer/organisaties",
    meta: indexHW3EUzx4NoMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/index.vue")
  },
  {
    name: "admin-organisations-List___en",
    path: "/en/admin/organisations",
    meta: indexHW3EUzx4NoMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Organisation/pages/admin/organisations/index.vue")
  },
  {
    name: "landing___nl",
    path: "/",
    meta: Index2iov6jSUj2Meta || {},
    component: () => import("/home/node/app/src/UserInterface/App/pages/Index.vue")
  },
  {
    name: "landing___en",
    path: "/en",
    meta: Index2iov6jSUj2Meta || {},
    component: () => import("/home/node/app/src/UserInterface/App/pages/Index.vue")
  },
  {
    name: "identity-Login2fa___nl",
    path: "/login/2fa",
    meta: _2falYbKZEnVPgMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/login/2fa.vue")
  },
  {
    name: "identity-Login2fa___en",
    path: "/en/login/2fa",
    meta: _2falYbKZEnVPgMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/login/2fa.vue")
  },
  {
    name: "identity-Login___nl",
    path: "/login",
    meta: indexMBiFKHNaHYMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/login/index.vue")
  },
  {
    name: "identity-Login___en",
    path: "/en/login",
    meta: indexMBiFKHNaHYMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/login/index.vue")
  },
  {
    name: "identity-Logout___nl",
    path: "/uitloggen",
    meta: indexkqiwwLwzUAMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/logout/index.vue")
  },
  {
    name: "identity-Logout___en",
    path: "/en/logout",
    meta: indexkqiwwLwzUAMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/logout/index.vue")
  },
  {
    name: "settings-engagement-AuditTeam___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/instellingen/audit-team",
    meta: audit_45teamfqwEsXaId3Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/audit-team.vue")
  },
  {
    name: "settings-engagement-AuditTeam___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/settings/audit-team",
    meta: audit_45teamfqwEsXaId3Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/audit-team.vue")
  },
  {
    name: "settings-engagement-CoaMapping___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/instellingen/rekeningschemas",
    meta: coa_45mappingsiLOkqWCx5DMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/coa-mappings.vue")
  },
  {
    name: "settings-engagement-CoaMapping___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/settings/coa-mappings",
    meta: coa_45mappingsiLOkqWCx5DMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/coa-mappings.vue")
  },
  {
    name: "settings-engagement-DataRequestAdd___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/instellingen/dataverzoeken/toevoegen",
    meta: indexQVuXiyHOLZMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/add/index.vue")
  },
  {
    name: "settings-engagement-DataRequestAdd___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/settings/data-requests/add",
    meta: indexQVuXiyHOLZMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/add/index.vue")
  },
  {
    name: "settings-engagement-DataRequestAddSuccess___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/instellingen/dataverzoeken/toevoegen/success",
    meta: successjqwMR1P0KAMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/add/success.vue")
  },
  {
    name: "settings-engagement-DataRequestAddSuccess___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/settings/data-requests/add/success",
    meta: successjqwMR1P0KAMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/add/success.vue")
  },
  {
    name: "settings-engagement-DataRequest___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/instellingen/dataverzoeken",
    meta: indexL343HEMlFnMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/index.vue")
  },
  {
    name: "settings-engagement-DataRequest___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/settings/data-requests",
    meta: indexL343HEMlFnMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/data-requests/index.vue")
  },
  {
    name: "settings-engagement-General___nl",
    path: "/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/instellingen",
    meta: indexGfTC2byajjMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/index.vue")
  },
  {
    name: "settings-engagement-General___en",
    path: "/en/:organisationId()/:organisationSlug()/:engagementId()/:engagementSlug()/settings",
    meta: indexGfTC2byajjMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Engagement/pages/organisationId]/[organisationSlug]/[engagementId]/[engagementSlug]/settings/index.vue")
  },
  {
    name: "identity-ConfirmEmail___nl",
    path: "/registreren/bevestig-email",
    meta: confirm_45email6SR3cFMGt9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/register/confirm-email.vue")
  },
  {
    name: "identity-ConfirmEmail___en",
    path: "/en/register/confirm-email",
    meta: confirm_45email6SR3cFMGt9Meta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/register/confirm-email.vue")
  },
  {
    name: "identity-Register___nl",
    path: "/registreren",
    meta: indexDT6spQnw9MMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/register/index.vue")
  },
  {
    name: "identity-Register___en",
    path: "/en/register",
    meta: indexDT6spQnw9MMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/register/index.vue")
  },
  {
    name: "identity-Registered___nl",
    path: "/registreren/success/:email()",
    meta: _91email_9342mvmFnIeDMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/register/success/[email].vue")
  },
  {
    name: "identity-Registered___en",
    path: "/en/register/success/:email()",
    meta: _91email_9342mvmFnIeDMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/register/success/[email].vue")
  },
  {
    name: "identity-RequestResetPassword___nl",
    path: "/herstel-wachtwoord-aanvragen",
    meta: request_45reset_45password1dIFMpjKMcMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/request-reset-password.vue")
  },
  {
    name: "identity-RequestResetPassword___en",
    path: "/en/request-reset-password",
    meta: request_45reset_45password1dIFMpjKMcMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/request-reset-password.vue")
  },
  {
    name: "identity-ResetPassword___nl",
    path: "/herstel-wachtwoord",
    meta: reset_45passwordbtgIw9jthLMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/reset-password.vue")
  },
  {
    name: "identity-ResetPassword___en",
    path: "/en/reset-password",
    meta: reset_45passwordbtgIw9jthLMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/reset-password.vue")
  },
  {
    name: "settings-profile-ProfileEdit___nl",
    path: "/user/:userId()/:userSlug()/profile/wijzigen",
    meta: editU3ycIgRSWVMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/edit.vue")
  },
  {
    name: "settings-profile-ProfileEdit___en",
    path: "/en/user/:userId()/:userSlug()/profile/edit",
    meta: editU3ycIgRSWVMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/edit.vue")
  },
  {
    name: "settings-profile-Exports___nl",
    path: "/gebruiker/:userId()/:userSlug()/profiel/exports",
    meta: exports3Oqd3uWTIAMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/exports.vue")
  },
  {
    name: "settings-profile-Exports___en",
    path: "/en/user/:userId()/:userSlug()/profile/exports",
    meta: exports3Oqd3uWTIAMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/exports.vue")
  },
  {
    name: "settings-profile-Profile___nl",
    path: "/gebruiker/:userId()/:userSlug()/profiel",
    meta: indexVrByxzufMpMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/index.vue")
  },
  {
    name: "settings-profile-Profile___en",
    path: "/en/user/:userId()/:userSlug()/profile",
    meta: indexVrByxzufMpMeta || {},
    component: () => import("/home/node/app/src/UserInterface/Identity/pages/user/[userId]/[userSlug]/profile/index.vue")
  }
]