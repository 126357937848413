/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { useRuntimeConfig } from '#app'
import {
  createAutoAnimatePlugin,
  createLocalStoragePlugin,
  createMultiStepPlugin,
} from '@formkit/addons'
import { en, nl } from '@formkit/i18n'
import {
  autocomplete,
  createProPlugin,
  datepicker,
  dropdown,
  mask,
  rating,
  repeater,
  slider,
  taglist,
  toggle,
  transferlist,
} from '@formkit/pro'
import { generateClasses } from '@formkit/themes'
import { defineFormKitConfig } from '@formkit/vue'
import { destr } from 'destr'
import { createValidationInputClassPlugin } from '~/src/Infrastructure/Shared/Form/createValidationInputClassPlugin'
import { useEncryptStorage } from '~/src/UserInterface/Shared/Storage/useEncryptStorage'
import '@formkit/addons/css/multistep'

export default defineFormKitConfig(() => {
  const config = useRuntimeConfig()
  const proPlugin = createProPlugin(config.public.FORMKIT_LICENSE, {
    dropdown,
    toggle,
    repeater,
    rating,
    autocomplete,
    datepicker,
    taglist,
    mask,
    transferlist,
    slider,
  })

  const storage = useEncryptStorage()

  return {
    locales: { nl, en },
    plugins: [
      proPlugin,
      createAutoAnimatePlugin(),
      createMultiStepPlugin(),
      createLocalStoragePlugin({
        beforeSave: (payload: string): string => storage.encrypt(JSON.stringify(payload)),
        beforeLoad: (payload: string): string => destr(storage.decrypt(payload)),
        clearOnSubmit: true,
      }),
      createValidationInputClassPlugin(),
    ],
    config: {
      classes: generateClasses({
        global: {
          // applies to all input types
          outer: 'mb-3',
          input: '$reset form-control',
          label: 'form-label',
          messages: 'list-unstyled small mb-0',
          message: 'invalid-feedback',
          help: 'form-text',
        },
        checkbox: {
          label: '$reset',
          wrapper: 'checkbox-wrapper',
          inner: '$reset',
          input: '$reset form-check-input',
          legend: 'fs-4',
          options: '$reset form-check',
        },
        select: {
          input: '$reset form-select',
        },
        submit: {
          outer: '$reset',
          wrapper: '$reset d-flex justify-content-center mt-3 mb-3',
          input: '$reset',
        },
        radio: {
          label: 'form-check-label',
          wrapper: 'radio-wrapper',
          inner: '$reset form-check',
          input: '$reset form-check-input',
          legend: '$reset form-check-label',
        },
      }),
    },
  }
})
