/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import Exception from '~/src/Domain/Shared/Exception/Exception'

/**
 * Exception that is a result of coding, and should be fixed by the developer.
 */
export default class LogicException extends Exception {
  public constructor(message: string) {
    super(message)
    this.name = 'LogicException'
  }
}
