/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { NuxtApp } from '#app'
import { defineNuxtPlugin } from '#app'
import { L10n, registerLicense, setCulture } from '@syncfusion/ej2-base'
import * as EJ2_EN_LOCALE from '@syncfusion/ej2-locale/src/en-US.json'
import * as EJ2_NL_LOCALE from '@syncfusion/ej2-locale/src/nl.json'

// @ts-expect-error import works
// eslint-disable-next-line ts/no-unsafe-member-access
L10n.load({ nl: EJ2_NL_LOCALE.default.nl, en: EJ2_EN_LOCALE.default['en-US'] })

export default defineNuxtPlugin({
  parallel: true,
  hooks: {
    'i18n:localeSwitched': ({ newLocale }) => {
      setCulture(newLocale)
    },
  },
  setup: (nuxtApp) => {
    registerLicense(nuxtApp.$config.public.SYNCFUSION_LICENSE)
    setCulture((nuxtApp.$i18n as NuxtApp['$i18n']).locale.value)
  },
})
