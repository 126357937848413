/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import StepIri from '~/src/Domain/WorkProgram/StepIri'
import type { DataPivotStepResultProps } from '~/src/Domain/WorkProgram/StepResult/DataPivotStepResult'
import DataPivotStepResult from '~/src/Domain/WorkProgram/StepResult/DataPivotStepResult'
import type { DataTableStepResultProps } from '~/src/Domain/WorkProgram/StepResult/DataTableStepResult'
import DataTableStepResult from '~/src/Domain/WorkProgram/StepResult/DataTableStepResult'

export interface StepProps extends DomainModelProps {
  '@id': string
  '@type': string
  slug: string
  hidden: boolean
  hiddenReason: undefined | string
  stepResult:
    | undefined
    | {
      filterValues: {
        field: string
        filterClass: FilterClass
        value: number | number[] | string | string[] | boolean
      }[]
      iri: string
      rows: string[]
      columns: string[]
      values: { field: string, valueType: 'count' | 'sum' }[]
    }
    | {
      filterValues: {
        field: string
        filterClass: FilterClass
        value: number | number[] | string | string[] | boolean
      }[]
      iri: string
      fields: string[]
    }
}

export default class Step implements DomainModelInterface<Step, StepProps> {
  public readonly '@id': StepIri
  public readonly '@type': string

  private constructor(
    id: StepIri,
    type: string,
    public readonly slug: Slug,
    public readonly hidden: boolean,
    public readonly hiddenReason: undefined | string,
    public readonly stepResult: undefined | DataPivotStepResult | DataTableStepResult,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'Step'
  }

  public fromJSON(values: StepProps): Step {
    return new Step(
      new StepIri(values['@id']),
      values['@type'],
      new Slug(values.slug),
      values.hidden,
      values.hiddenReason,
      values.stepResult === undefined
        ? undefined
        : 'rows' in values.stepResult
          ? DataPivotStepResult.prototype.fromJSON(values.stepResult as DataPivotStepResultProps)
          : DataTableStepResult.prototype.fromJSON(values.stepResult as DataTableStepResultProps),
    )
  }

  public toJSON(): StepProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      slug: this.slug.toString(),
      hidden: this.hidden,
      hiddenReason: this.hiddenReason,
      stepResult: this.stepResult?.toJSON(),
    }
  }
}
