/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class DataRequestFixtureFactory {
  public constructor(private readonly fixtureDefinitions: DataRequestFixtureInterface[]) {}

  public createForDataRequest(dataRequest: DataRequest): DataRequestProjection {
    const definition = [...this.fixtureDefinitions].find((d) =>
      d.supportsApplication(dataRequest.applicationId),
    )

    if (definition !== undefined) {
      return definition.create(dataRequest)
    }

    throw new Error(
      `Could not find a fixture with application ID "${dataRequest.applicationId.toString()}"`,
    )
  }
}
