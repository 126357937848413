/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'

export default class DataRequestCollection {
  public constructor(public readonly dataRequests: DataRequest[]) {}

  public hasItems(): boolean {
    return this.dataRequests.length > 0
  }

  public hasProcessedDataRequestsForAnalysisType(analysisType: AnalysisType, phase: PhaseIri): boolean {
    return this.dataRequests.some((d) => d.archived === true && d.analysisType === analysisType && d.phase['@id'].equalsTo(phase))
  }

  public hasFulfilledDataRequestsForAnalysisType(analysisType: AnalysisType, phase: PhaseIri): boolean {
    return this.dataRequests.some((d) => d.fulfilled === true && d.analysisType === analysisType && d.phase['@id'].equalsTo(phase))
  }

  public hasUnfulfilledDataRequestsForAnalysisType(analysisType: AnalysisType, phase: PhaseIri): boolean {
    return this.dataRequests.some((d) => d.fulfilled === false && d.analysisType === analysisType && d.phase['@id'].equalsTo(phase))
  }

  public hasUnfulfilledExistingDataRequest(analysisType: string, applicationId: string): boolean {
    if (analysisType === '' || applicationId === '') {
      return false
    }

    return this.dataRequests.some(
      (d) =>
        d.analysisType === analysisType
        && d.applicationId.toString() === applicationId
        && d.fulfilled === false,
    )
  }

  public numberOfDataRequests(): number {
    return this.dataRequests.length
  }

  public numberOfProcessedDataRequests(): number {
    return this.dataRequests.filter((d) => d.archived).length
  }

  public numberOfFiles(): number {
    return this
      .dataRequests
      .flatMap((d) => d
        .files
        .filter((f) => f.parentFile === undefined),
      )
      .length
  }

  public getGroupedByAnalysisType(): Map<AnalysisType, DataRequest[]> {
    const order = this.getAnalysisTypesOrder()

    return [...this.dataRequests]
      .sort((a, b) => order[a.analysisType] - order[b.analysisType])
      .reduce(
        (a, g) => a.set(g.analysisType, [...(a.get(g.analysisType) ?? []), g]),
        new Map<AnalysisType, DataRequest[]>(),
      )
  }

  public getLastFulfilledDateByAnalysisType(
    analysisType: AnalysisType,
  ): undefined | BetterDateTime {
    const populatedDates = this
      .dataRequests
      .filter((d) => d.analysisType === analysisType && d.fulfilled === true)
      .map((d) => d.fulfilledDate)
      .sort((a, b) => b!.getTime() - a!.getTime())

    if (populatedDates.length === 0) {
      return undefined
    }

    return populatedDates[0]
  }

  private getAnalysisTypesOrder(): Record<AnalysisType, number> {
    return {
      [AnalysisType.BANK]: 2,
      [AnalysisType.BANK_GENERAL_LEDGER]: 2,
      [AnalysisType.ACCOUNTS_PAYABLE]: 4,
      [AnalysisType.ACCOUNTS_RECEIVABLE]: 3,
      [AnalysisType.GENERAL_LEDGER]: 1,
      [AnalysisType.GENERIC]: 0,
      [AnalysisType.PERSONNEL]: 5,
    }
  }
}
