/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import ApiValueFilter from '~/src/Domain/Shared/Filter/Filter/ApiValueFilter'
import type ParameterDefinitionInterface from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionInterface'
import ParameterName from '~/src/Domain/WorkProgram/ParameterName'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'

export default class ProfitAndLossAccountsParameterDefinition
implements ParameterDefinitionInterface {
  public getName(): ParameterName {
    return new ParameterName('profitAndLossAccounts')
  }

  public getTitle(): string {
    return t('work_program.parameters.profit_and_loss_accounts.title')
  }

  public getDescription(): string {
    return t('work_program.parameters.profit_and_loss_accounts.description')
  }

  public getFilter(): AbstractFilter<any> {
    return new ApiValueFilter(RepositoryReference.JOURNAL_ENTRY, 'chartOfAccounts.accountType')
  }

  public getIcon(): string {
    return 'card-checklist'
  }
}
