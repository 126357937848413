/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type ExportStoreRepositoryInterface from '~/src/Domain/Export/ExportStoreRepositoryInterface'
import UserLogoutEvent from '~/src/Domain/Identity/Event/UserLogoutEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(UserLogoutEvent.NAME, 'exportUserLogoutEventHandler')
export default class ExportUserLogoutEventHandler extends EventHandlerInterface<UserLogoutEvent> {
  private readonly exportStoreRepository: ExportStoreRepositoryInterface

  public constructor({ exportStoreRepository }: Services) {
    super()
    this.exportStoreRepository = exportStoreRepository
  }

  public handle(_event: UserLogoutEvent): void {
    this.exportStoreRepository.resetState()
  }
}
