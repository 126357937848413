/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import LogicException from '~/src/Domain/Shared/Exception/LogicException'

export default class ItemNotFoundException extends LogicException {
  public constructor(message: string) {
    super(message)
    this.name = 'ItemNotFoundException'
  }
}
