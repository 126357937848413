/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import CoaMappingFileIri from '~/src/Domain/CoaMapping/CoaMappingFileIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface CoaMappingFileProps extends DomainModelProps {
  '@id': string
  '@type': string
  createdDate: string
  originalFileName: string
}

export default class CoaMappingFile
implements DomainModelInterface<CoaMappingFile, CoaMappingFileProps> {
  public readonly '@id': CoaMappingFileIri
  public readonly '@type': string

  private constructor(
    id: CoaMappingFileIri,
    type: string,
    public readonly createdDate: BetterDateTime,
    public readonly originalFileName: string,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'CoaMapping'
  }

  public fromJSON(values: CoaMappingFileProps): CoaMappingFile {
    return new CoaMappingFile(
      new CoaMappingFileIri(values['@id']),
      values['@type'],
      new BetterDateTime(values.createdDate),
      values.originalFileName,
    )
  }

  public toJSON(): CoaMappingFileProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      createdDate: this.createdDate.toString(),
      originalFileName: this.originalFileName,
    }
  }
}
