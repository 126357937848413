/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { IFetchError as FetchError } from 'ofetch'
import type RouterInterface from '~/src/Application/Shared/Router/RouterInterface'
import deepReplace from '~/src/Domain/Shared/Utils/DeepReplace'
import parseJsonString from '~/src/Domain/Shared/Utils/ParseJsonString'
import type AbstractTokenExpiryListener from '~/src/Infrastructure/Identity/AbstractTokenExpiryListener'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useSecurity from '~/src/UserInterface/Identity/composables/useSecurity'

export default class HttpClient {
  private readonly baseUrl: string
  private readonly nuxtFetch: typeof $fetch
  private readonly router: RouterInterface
  private locale: string

  public constructor({ nuxtFetch, router }: Services) {
    this.nuxtFetch = nuxtFetch
    this.router = router
    this.locale = 'nl'
    this.baseUrl = `https://${router.getHostname()}`
  }

  public getBaseUrl(): string {
    return this.baseUrl
  }

  public getHeaders(): HeadersInit {
    return {
      Accept: 'application/ld+json, application/json;q=0.8',
      'Accept-Language': this.locale,
      'Content-Type': 'application/json',
    }
  }

  public setLocale(locale: string): void {
    this.locale = locale
  }

  public async get<T>(url: string): Promise<T> {
    return (await this.nuxtFetch(url, {
      method: 'GET',
      headers: this.getHeaders(),
      credentials: 'include',
      parseResponse: (responseText: string) => this.parseResponse(responseText),
    }).catch(
      async (error: FetchError) => this._handleFetchError(error, 'GET', this.getHeaders()),
    )) as T
  }

  public async post<T>(url: string, payload?: Record<any, any>, headers?: HeadersInit): Promise<T> {
    return (await this.nuxtFetch<T>(url, {
      method: 'POST',
      headers: headers ?? this.getHeaders(),
      credentials: 'include',
      body: this.parsePayload(payload),
      parseResponse: (responseText: string) => this.parseResponse(responseText),
    }).catch(
      async (error: FetchError) =>
        this._handleFetchError(error, 'POST', this.getHeaders(), payload ?? {}),
    )) as T
  }

  public async put<T>(url: string, payload?: Record<any, any>): Promise<T> {
    return (await this.nuxtFetch<T>(url, {
      method: 'PUT',
      headers: this.getHeaders(),
      credentials: 'include',
      body: this.parsePayload(payload),
      parseResponse: (responseText: string) => this.parseResponse(responseText),
    }).catch(
      async (error: FetchError) =>
        this._handleFetchError(error, 'PUT', this.getHeaders(), payload ?? {}),
    )) as T
  }

  public async patch<T>(url: string, payload?: Record<any, any>): Promise<T> {
    return (await this.nuxtFetch<T>(url, {
      method: 'PATCH',
      headers: {
        ...this.getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
      credentials: 'include',
      body: this.parsePayload(payload),
      parseResponse: (responseText: string) => this.parseResponse(responseText),
    }).catch(
      async (error: FetchError) =>
        this._handleFetchError(
          error,
          'PATCH',
          {
            ...this.getHeaders(),
            'Content-Type': 'application/merge-patch+json',
          },
          payload ?? {},
        ),
    )) as T
  }

  public async delete<T>(url: string): Promise<T> {
    return (await this.nuxtFetch<T>(url, {
      method: 'DELETE',
      headers: this.getHeaders(),
      credentials: 'include',
      body: {},
      parseResponse: (responseText: string) => this.parseResponse(responseText),
    }).catch(
      async (error: FetchError) =>
        this._handleFetchError(error, 'DELETE', this.getHeaders(), {}),
    )) as T
  }

  /**
   * For uniform validation checks, we convert null's to undefined so we only have to check for undefined
   * when validating responses.
   */
  private parsePayload(payload?: Record<any, any>) {
    if (payload === undefined) {
      return {}
    }

    return deepReplace(payload, (value) => (value === undefined || value === '' ? null : value))
  }

  /**
   * Before sending the request, the payload is serialized to JSON. Undefined values are not allowed
   * in JSON, which results in the keys being omitted in the request.
   *
   * Undefined values are replaced with null's, so all payload keys are kept and null values are
   * validated in the backend.
   */
  private parseResponse(responseText: string): string | undefined {
    if (responseText === 'null') {
      return undefined
    }

    return deepReplace(parseJsonString(responseText), (value) =>
      value === null ? undefined : value)
  }

  // @todo move below out of the httpClient class
  private async _handleFetchError(
    e: FetchError,
    method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE',
    headers: HeadersInit,
    payload?: Record<any, any>,
  ) {
    if (e.request === undefined || e.response === undefined) {
      throw e
    }

    let security: { logout: () => Promise<void>, refreshJwtToken: () => Promise<void> }
    let tokenExpiryListener: AbstractTokenExpiryListener

    const userStoreRepository = useService('userStoreRepository')
    const dataRequestorStoreRepository = useService('dataRequestorStoreRepository')
    if (userStoreRepository.isAuthenticated()) {
      security = useSecurity()
      tokenExpiryListener = useService('tokenExpiryListener')
    } else if (dataRequestorStoreRepository.isAuthenticated()) {
      security = useService('dataRequestSecurity')
      tokenExpiryListener = useService('dataRequestTokenExpiryListener')
    } else {
      throw e
    }

    if (
      e.response.url.endsWith('/v1/auth/refresh') === false
      && (e.response._data as { code: number, message: string }).code === 401
      && ['Expired JWT Token', 'JWT Token not found'].includes(
        (e.response._data as { code: number, message: string }).message,
      )
    ) {
      try {
        await security.refreshJwtToken()
        if (!tokenExpiryListener.isListening()) {
          tokenExpiryListener.tryListen()
        }

        return await this.nuxtFetch(e.request, {
          method,
          headers,
          credentials: 'include',
          body: payload,
        })
      } catch {
        await security.logout()
      }
    }

    throw e
  }
}
