/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { captureMessage } from '@sentry/nuxt'
import type { LoggerContext, LoggerMessage } from '~/src/Domain/Shared/Logger/LoggerInterface'
import LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'

export default class SentryLogger extends LoggerInterface {
  public emergency(message: LoggerMessage, context?: LoggerContext): void {
    captureMessage(message.toString(), context)
  }

  public alert(message: LoggerMessage, context?: LoggerContext): void {
    captureMessage(message.toString(), context)
  }

  public critical(message: LoggerMessage, context?: LoggerContext): void {
    captureMessage(message.toString(), context)
  }

  public error(message: LoggerMessage, context?: LoggerContext): void {
    captureMessage(message.toString(), context)
  }

  public warning(_message: LoggerMessage, _context?: LoggerContext): void {}

  public notice(_message: LoggerMessage, _context?: LoggerContext): void {}

  public info(_message: LoggerMessage, _context?: LoggerContext): void {}

  public debug(_message: LoggerMessage, _context?: LoggerContext): void {}
}
