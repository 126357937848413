/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type DataCubeIri from '~/src/Domain/DataCube/DataCubeIri'

export default class DataCubeDeletedEvent implements EventInterface {
  public static readonly NAME = 'DATA_CUBE_DELETED'

  public constructor(public readonly dataCube: DataCubeIri) {}
}
