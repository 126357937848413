/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type FileTypeInterface from '~/src/Domain/DataRequest/File/FileType/FileTypeInterface'
import type ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'

export default class IntroductionStep extends StepInterface {
  public constructor(
    public readonly slug: Slug = new Slug('introduction'),
    public readonly title: string = t('pages.data_request.step.introduction.title'),
    public readonly description: string = t('pages.data_request.step.introduction.description'),
    public readonly contents: ContentInterface[] = [],
  ) {
    super()
  }

  public get fileTypes(): FileTypeInterface[] {
    return []
  }

  public get isOptional(): boolean {
    return false
  }
}
