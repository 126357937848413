/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface FileNameDetailsProps extends JsonValueObjectProps {
  fileName: string
  tmpFileName: string
  fileNameInArchive: string | undefined
}

export default class FileNameDetails
implements JsonValueObjectInterface<FileNameDetails, FileNameDetailsProps> {
  private constructor(
    public readonly fileName: string,
    public readonly tmpFileName: string,
    public readonly fileNameInArchive: string | undefined,
  ) {}

  public fromJSON(values: FileNameDetailsProps): FileNameDetails {
    return new FileNameDetails(values.fileName, values.tmpFileName, values.fileNameInArchive)
  }

  public toJSON(): FileNameDetailsProps {
    return {
      fileName: this.fileName,
      tmpFileName: this.tmpFileName,
      fileNameInArchive: this.fileNameInArchive,
    }
  }
}
