/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type { DataRequestProps } from '~/src/Domain/DataRequest/DataRequest'
import DataRequest from '~/src/Domain/DataRequest/DataRequest'
import DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import type DataRequestorStoreRepositoryInterface from '~/src/Domain/DataRequest/DataRequestorStoreRepositoryInterface'
import DataRequestRecipientIri from '~/src/Domain/DataRequest/DataRequestRecipientIri'
import DataRequestDeletedEvent from '~/src/Domain/DataRequest/Event/DataRequestDeletedEvent'
import DataRequestUpdatedEvent from '~/src/Domain/DataRequest/Event/DataRequestUpdatedEvent'
import FileIri from '~/src/Domain/DataRequest/File/FileIri'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import { AsPublisher } from '~/src/Infrastructure/Shared/PubSub/AsPublisher'
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import { Type } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

@AsPublisher('dataRequestPublisher')
export default class DataRequestPublisher implements PublisherInterface {
  private REGEX_PATTERN
    = '^/v1/engagements/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/data_requests/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'

  private dataRequestorStoreRepository: DataRequestorStoreRepositoryInterface
  private eventBus: EventBusInterface

  public constructor({ dataRequestorStoreRepository, eventBus }: Services) {
    this.dataRequestorStoreRepository = dataRequestorStoreRepository
    this.eventBus = eventBus
  }

  public async publish(message: Message): Promise<void> {
    if (!('@id' in message.data && message.data['@id'].match(this.REGEX_PATTERN) !== null)) {
      return
    }

    if (message.type === Type.UPDATE) {
      const dataRequestProps = message.data as DataRequestProps
      if (this.dataRequestorStoreRepository.isAuthenticated()) {
        const dataRequestRecipientId = new DataRequestRecipientIri(dataRequestProps.recipient['@id']).toId()
        const dataRequestId = new DataRequestIri(dataRequestProps['@id']).toId()

        dataRequestProps['@id']
          = `/v1/data_request_recipients/${dataRequestRecipientId}/data_requests/${dataRequestId}`

        for (const fileProps of dataRequestProps.files) {
          const fileId = new FileIri(fileProps['@id']).toId()
          fileProps['@id']
            = `/v1/data_request_recipients/${dataRequestRecipientId}/data_requests/${dataRequestId}/files/${fileId}`

          if (fileProps.parentFile !== undefined) {
            const parentFileId = new FileIri(fileProps.parentFile).toId()
            fileProps.parentFile = `/v1/data_request_recipients/${dataRequestRecipientId}/data_requests/${dataRequestId}/files/${parentFileId}`
          }
        }
      }

      await this.eventBus.dispatch(DataRequestUpdatedEvent.NAME, new DataRequestUpdatedEvent(
        DataRequest.prototype.fromJSON(message.data as DataRequestProps),
      ))
    }

    if (message.type === Type.DELETE) {
      await this.eventBus.dispatch(DataRequestDeletedEvent.NAME, new DataRequestDeletedEvent(new DataRequestIri(message.data['@id'])))
    }
  }
}
