/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterValueType } from '~/src/Domain/Shared/Filter/FilterCollection'
import type GenericIri from '~/src/Domain/Shared/Identifier/GenericIri'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'

export default class MarkDataPivotStepResultWorkProgramCommand implements CommandInterface {
  public static readonly NAME = 'MARK_DATA_PIVOT_STEP_RESULT_WORK_PROGRAM_COMMAND'

  public constructor(
    public readonly workProgram: WorkProgramIri,
    public readonly slug: Slug,
    public readonly iri: GenericIri,
    public readonly filterValues: {
      field: string
      filterClass: FilterClass
      value: FilterValueType
    }[],
    public readonly rows: string[],
    public readonly columns: string[],
    public readonly values: { field: string, valueType: 'count' | 'sum' }[],
  ) {}
}
