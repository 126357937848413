/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { PivotTableDefinitionProps } from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import type PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import {
  useEncryptStorage,
} from '~/src/UserInterface/Shared/Storage/useEncryptStorage'

const pivotTableStore = defineStore(
  'pivot-table',
  () => {
    const pivotTables = ref<Record<string, PivotTableDefinitionProps[]>>({})

    const updatePivotTable = (phase: PhaseIri, pivotTable: PivotTableDefinition) => {
      if (!(phase.toString() in pivotTables.value)) {
        pivotTables.value[phase.toString()] = []
      }

      const index = pivotTables.value[phase.toString()].findIndex((p) => p.id === pivotTable.id)
      if (index > -1) {
        pivotTables.value[phase.toString()][index] = pivotTable.toJSON()
      } else {
        pivotTables.value[phase.toString()].push(pivotTable.toJSON())
      }
    }

    const resetState = (): void => {
      pivotTables.value = {}
    }

    return {
      pivotTables: pivotTables as Readonly<Ref<Record<string, PivotTableDefinitionProps[]>>>,
      updatePivotTable,
      resetState,
    }
  },
  {
    persist: {
      storage: useEncryptStorage(),
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(pivotTableStore, import.meta.hot))
}

export default pivotTableStore
