/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface HydraViewProps extends JsonValueObjectProps {
  '@id': string
  '@type': string
  'hydra:first'?: string
  'hydra:last'?: string
  'hydra:next'?: string
  'hydra:previous'?: string
}

export default class HydraView implements JsonValueObjectInterface<HydraView, HydraViewProps> {
  public readonly '@id': string
  public readonly '@type': string
  public readonly 'hydra:first'?: string
  public readonly 'hydra:last'?: string
  public readonly 'hydra:next'?: string
  public readonly 'hydra:previous'?: string

  private constructor(
    id: string,
    type: string,
    first: string | undefined,
    last: string | undefined,
    next: string | undefined,
    previous: string | undefined,
  ) {
    this['@id'] = id
    this['@type'] = type
    this['hydra:first'] = first
    this['hydra:last'] = last
    this['hydra:next'] = next
    this['hydra:previous'] = previous
  }

  public fromJSON(values: HydraViewProps): HydraView {
    return new HydraView(
      values['@id'],
      values['@type'],
      values['hydra:first'],
      values['hydra:last'],
      values['hydra:next'],
      values['hydra:previous'],
    )
  }

  public toJSON(): HydraViewProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      'hydra:first': this['hydra:first'],
      'hydra:last': this['hydra:last'],
      'hydra:next': this['hydra:next'],
      'hydra:previous': this['hydra:previous'],
    }
  }
}
