/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import type DataCubeStoreRepositoryInterface from '~/src/Domain/DataCube/DataCubeStoreRepositoryInterface'
import DataCubeUpdatedEvent from '~/src/Domain/DataCube/Event/DataCubeUpdatedEvent'
import type EngagementStoreRepositoryInterface from '~/src/Domain/Engagement/EngagementStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(DataCubeUpdatedEvent.NAME, 'dataCubeUpdatedEventHandler')
export default class DataCubeUpdatedEventHandler extends EventHandlerInterface<DataCubeUpdatedEvent> {
  private readonly dataCubeStoreRepository: DataCubeStoreRepositoryInterface
  private readonly engagementStoreRepository: EngagementStoreRepositoryInterface

  public constructor({ dataCubeStoreRepository, engagementStoreRepository }: Services) {
    super()
    this.dataCubeStoreRepository = dataCubeStoreRepository
    this.engagementStoreRepository = engagementStoreRepository
  }

  public handle(event: DataCubeUpdatedEvent): void {
    if (
      this.engagementStoreRepository.hasCurrentEngagement()
      && event.dataCube['@id'].toString().startsWith(this.engagementStoreRepository.getCurrentEngagement()['@id'].toString())
    ) {
      this.dataCubeStoreRepository.updateDataCube(event.dataCube)
    }
  }
}
