/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import ContextMenu from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import DrillThroughContextMenuItem from '~/src/Domain/Shared/ContextMenu/DrillThroughContextMenuItem'
import { FieldSorterDirection } from '~/src/Domain/Shared/Filter/FieldSorterDirection'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import {
  accountsPayablesGlAccountsFilterField,
  businessUnitFilterField,
  fiscalYearFilterField,
  reportDateFilterField,
  specialRelationsEmployeesFilterField,
  specialRelationsInterCompanyFilterField,
  specialRelationsPensionFundsFilterField,
  specialRelationsTaxAuthoritiesFilterField,
  subsequentYearFilterField,
  subsequentYearFirstDayFilterField,
  subsequentYearFirstPeriodFilterField,
} from '~/src/Domain/WorkProgram/Fixtures/24342109/FilterFields24342109'
import AccountsPayablesGlAccountsParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/AccountsPayablesGlAccountsParameterDefinition24342109'
import BankSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/BankSourcesParameterDefinition24342109'
import BusinessUnitParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/BusinessUnitParameterDefinition24342109'
import FiscalYearParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/FiscalYearParameterDefinition24342109'
import PurchasingSourcesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/PurchasingSourcesParameterDefinition24342109'
import SpecialRelationsEmployeesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsEmployeesParameterDefinition24342109'
import SpecialRelationsInterCompanyParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsInterCompanyParameterDefinition24342109'
import SpecialRelationsPensionFundsParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsPensionFundsParameterDefinition24342109'
import SpecialRelationsTaxAuthoritiesParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SpecialRelationsTaxAuthoritiesParameterDefinition24342109'
import SubsequentYearFirstDayParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SubsequentYearFirstDayParameterDefinition24342109'
import SubsequentYearFirstPeriodParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SubsequentYearFirstPeriodParameterDefinition24342109'
import SubsequentYearParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/SubsequentYearParameterDefinition24342109'
import TrivialAmountParameterDefinition24342109 from '~/src/Domain/WorkProgram/Fixtures/24342109/ParameterDefinition/TrivialAmountParameterDefinition24342109'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import DataTableResultReference from '~/src/Domain/WorkProgram/ResultReference/DataTableResultReference'
import { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import PivotResultReference from '~/src/Domain/WorkProgram/ResultReference/PivotResultReference'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import StepSection from '~/src/Domain/WorkProgram/StepSection'
import StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { usePivotTableDefaultSettings } from '~/src/UserInterface/App/composables/PivotTable/usePivotTableDefaultSettings'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'
import ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'
import WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class AccountsPayables24342109 implements WorkProgramFixtureInterface {
  public create(): () => WorkProgramProjection {
    return () => {
      const { openAccountsPayables } = usePivotTableDefaultSettings()

      const parameters = [
        new ParameterProjection(new FiscalYearParameterDefinition24342109()),
        new ParameterProjection(new SubsequentYearParameterDefinition24342109()),
        new ParameterProjection(new SubsequentYearFirstPeriodParameterDefinition24342109()),
        new ParameterProjection(new SubsequentYearFirstDayParameterDefinition24342109()),
        new ParameterProjection(new BusinessUnitParameterDefinition24342109()),
        new ParameterProjection(new PurchasingSourcesParameterDefinition24342109()),
        new ParameterProjection(new BankSourcesParameterDefinition24342109()),
        new ParameterProjection(new TrivialAmountParameterDefinition24342109()),
        new ParameterProjection(new AccountsPayablesGlAccountsParameterDefinition24342109()),
        new ParameterProjection(new SpecialRelationsEmployeesParameterDefinition24342109()),
        new ParameterProjection(new SpecialRelationsInterCompanyParameterDefinition24342109()),
        new ParameterProjection(new SpecialRelationsPensionFundsParameterDefinition24342109()),
        new ParameterProjection(new SpecialRelationsTaxAuthoritiesParameterDefinition24342109()),
      ]

      return new WorkProgramProjection(
        new Slug('accounts-payables'),
        t('work_programs.24342109.accounts_payables.title'),
        t('work_programs.24342109.accounts_payables.text'),
        new StepSectionCollection([
          new StepSection(
            'intro',
            t('work_programs.24342109.accounts_payables.sections.section_intro.lbl_navigation'),
            [
              new IntroStep(
                new Slug('intro'),
                t('work_programs.24342109.accounts_payables.steps.step_intro.title'),
                t('work_programs.24342109.accounts_payables.steps.step_intro.text'),
              ),
            ],
          ),
          new StepSection(
            'regular',
            t('work_programs.24342109.accounts_payables.sections.section_regular.lbl_navigation'),
            [
              new ResultStep(
                new Slug('gl-ap-reconciliation'),
                t('work_programs.24342109.accounts_payables.steps.step_gl_ap_reconciliation.title'),
                t('work_programs.24342109.accounts_payables.steps.step_gl_ap_reconciliation.text'),
                new PivotResultReference(
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [fiscalYearFilterField(), businessUnitFilterField(), reportDateFilterField()],
                  new PivotTableDefinition(
                    'ap-gl-reconciliation',
                    t(
                      'work_programs.24342109.accounts_payables.steps.step_gl_ap_reconciliation.title',
                    ),
                    [
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'chartOfAccounts.glAccount' },
                    ],
                    [{ key: 'fiscalYear' }, { key: 'reportDate' }, { key: 'currency' }],
                    [{ key: 'amountOutstanding' }],
                    [
                      { key: 'fiscalYear' },
                      { key: 'businessUnit.businessUnitName' },
                      { key: 'reportDate' },
                    ],
                    openAccountsPayables.fieldMappings,
                    openAccountsPayables.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToAccountsPayableOverviewPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'accounts-payable-overview',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToAccountsPayableOverviewPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('open-items'),
                t('work_programs.24342109.accounts_payables.steps.step_open_items.title'),
                t('work_programs.24342109.accounts_payables.steps.step_open_items.text'),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [fiscalYearFilterField(), businessUnitFilterField(), reportDateFilterField()],
                ),
              ),
              new ResultStep(
                new Slug('aging'),
                t('work_programs.24342109.accounts_payables.steps.step_aging.title'),
                t('work_programs.24342109.accounts_payables.steps.step_aging.text'),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_AGING,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [fiscalYearFilterField(), businessUnitFilterField(), reportDateFilterField()],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-employees'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_employees.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_employees.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_SPECIAL_RELATION,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    reportDateFilterField(),
                    specialRelationsEmployeesFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-inter-company'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_inter_company.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_inter_company.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_SPECIAL_RELATION,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    reportDateFilterField(),
                    specialRelationsInterCompanyFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-pension-fund'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_pension_funds.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_pension_funds.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_SPECIAL_RELATION,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    reportDateFilterField(),
                    specialRelationsPensionFundsFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('special-relations-tax-authorities'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_tax_authorities.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_special_relations_tax_authorities.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_SPECIAL_RELATION,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    reportDateFilterField(),
                    specialRelationsTaxAuthoritiesFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('negative-amounts'),
                t('work_programs.24342109.accounts_payables.steps.step_negative_amounts.title'),
                t('work_programs.24342109.accounts_payables.steps.step_negative_amounts.text'),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'debitCreditIndicator',
                      t('general.entity_fields.open_accounts_payable.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, ['D'])],
                      true,
                      true,
                    ),
                    new FilterField(
                      'amountOutstanding',
                      t('general.entity_fields.open_accounts_payable.amount_outstanding'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('cutoff-procedures'),
                t('work_programs.24342109.accounts_payables.steps.step_cutoff_procedures.title'),
                t('work_programs.24342109.accounts_payables.steps.step_cutoff_procedures.text'),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    subsequentYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('general.entity_fields.journal_entry.amount'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    accountsPayablesGlAccountsFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('transactions-near-period-end'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_transactions_near_period_end.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_transactions_near_period_end.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    subsequentYearFirstDayFilterField(),
                    businessUnitFilterField(),
                    accountsPayablesGlAccountsFilterField(),
                    new FilterField(
                      'amount',
                      t('general.entity_fields.journal_entry.amount'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'fraud',
            t('work_programs.24342109.accounts_payables.sections.section_fraud.lbl_navigation'),
            [
              new ResultStep(
                new Slug('debiting-accounts-payable-items-start-new-period'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_debiting_ap_items_start_new_period.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_debiting_ap_items_start_new_period.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    subsequentYearFilterField(),
                    subsequentYearFirstPeriodFilterField(),
                    businessUnitFilterField(),
                    accountsPayablesGlAccountsFilterField(),
                    new FilterField(
                      'debitCreditIndicator',
                      t('general.entity_fields.journal_entry.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, ['D'])],
                      true,
                      true,
                    ),
                    new FilterField(
                      'amount',
                      t('general.entity_fields.journal_entry.amount'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new TrivialAmountParameterDefinition24342109(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('same-creditor-name-different-bank-account'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_same_creditor_name_different_bank_account.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_same_creditor_name_different_bank_account.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_MULTIPLE_BANK_ACCOUNTS_SAME_SUPPLIER_NAME,
                  RepositoryReference.ACCOUNTS_PAYABLE_MULTIPLE_BANK_ACCOUNTS_SAME_SUPPLIER_NAME,
                  [],
                  [
                    {
                      key: 'name',
                      direction: FieldSorterDirection.ASC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('same-creditor-bank-account-different-name'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_same_creditor_bank_account_different_name.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_same_creditor_bank_account_different_name.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE_MULTIPLE_SUPPLIER_NAMES_SAME_BANK_ACCOUNT,
                  RepositoryReference.ACCOUNTS_PAYABLE_MULTIPLE_SUPPLIER_NAMES_SAME_BANK_ACCOUNT,
                  [],
                  [
                    {
                      key: 'bankAccount',
                      direction: FieldSorterDirection.ASC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('transactions-outside-ap-sources'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_transactions_outside_ap_sources.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_transactions_outside_ap_sources.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsPayablesGlAccountsFilterField(),
                    new FilterField(
                      'debitCreditIndicator',
                      t('general.entity_fields.open_accounts_payable.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, ['C'])],
                      true,
                      true,
                    ),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),

                      [
                        new ParameterizedFilterFieldFilter(
                          new PurchasingSourcesParameterDefinition24342109(),
                          FilterClass.NOT_API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.source_code',
                      ),
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('transactions-outside-bank-sources'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_transactions_outside_bank_sources.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_transactions_outside_bank_sources.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    accountsPayablesGlAccountsFilterField(),
                    new FilterField(
                      'debitCreditIndicator',
                      t('general.entity_fields.open_accounts_payable.debit_credit_indicator'),
                      [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, ['D'])],
                      true,
                      true,
                    ),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new BankSourcesParameterDefinition24342109(),
                          FilterClass.NOT_API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.source_code',
                      ),
                    ),
                  ],
                ),
              ),
              // new ResultStep(
              //   new Slug('double-booked-purchase-invoices'),
              //   t(
              //     'work_programs.24342109.accounts_payables.steps.step_double_booked_purchase_invoices.title',
              //   ),
              //   t(
              //     'work_programs.24342109.accounts_payables.steps.step_double_booked_purchase_invoices.text',
              //   ),
              //   new DataTableResultReference(
              //     FieldsResultReference.ACCOUNTS_PAYABLE,
              //     RepositoryResultReference.ACCOUNTS_PAYABLE_REPOSITORY,
              //     [fiscalYearFilterField(), businessUnitFilterField(), reportDateFilterField()],
              //   ),
              // ),
              new ResultStep(
                new Slug('creditors-not-in-master-data'),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_creditors_not_in_master_data.title',
                ),
                t(
                  'work_programs.24342109.accounts_payables.steps.step_creditors_not_in_master_data.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.ACCOUNTS_PAYABLE,
                  RepositoryReference.ACCOUNTS_PAYABLE,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    reportDateFilterField(),
                    new FilterField(
                      'creditorInMasterDataIndicator',
                      t(
                        'general.entity_fields.open_accounts_payable.creditor_in_master_data_indicator',
                      ),
                      [new FilterFieldFilter(FilterClass.EQUALS_FILTER, 'false')],
                      true,
                      true,
                    ),
                  ],
                ),
              ),
            ],
          ),
          // new StepSection(
          //   'specials',
          //   t('work_programs.24342109.accounts_payables.sections.section_specials.lbl_navigation'),
          //   [
          //     new ResultStep(
          //       new Slug('foreign-countries-payments'),
          //       t(
          //         'work_programs.24342109.accounts_payables.steps.step_payments_foreign_countries.title',
          //       ),
          //       t(
          //         'work_programs.24342109.accounts_payables.steps.step_payments_foreign_countries.text',
          //       ),
          //       new DataTableResultReference(
          //         FieldsResultReference.BANK,
          //         RepositoryResultReference.BANK_STATEMENT_ENTRY_DETAIL,
          //         [
          //           new FilterField(
          //             'cpiCountry',
          //             t('general.entity_fields.bank_statement_entry_detail.cpi_country'),
          //             [new FilterFieldFilter(FilterClass.NOT_EQUALS_FILTER, 'NLD')],
          //             true,
          //             true,
          //           ),
          //         ],
          //       ),
          //     ),
          //     new ResultStep(
          //       new Slug('purchasing-volume-eu-procurement-standard'),
          //       t('work_programs.24342109.accounts_payables.steps.step_purchasing_volume.title'),
          //       t('work_programs.24342109.accounts_payables.steps.step_purchasing_volume.text'),
          //       new DataTableResultReference(
          //         FieldsResultReference.JOURNAL_ENTRY,
          //         RepositoryResultReference.JOURNAL_ENTRY,
          //         [
          //           fiscalYearFilterField(),
          //           businessUnitFilterField(),
          //           creditorSourceNameFilterField(),
          //         ],
          //       ),
          //     ),
          //   ],
          // ),
          new StepSection(
            'finalize-export',
            t(
              'work_programs.24342109.accounts_payables.sections.section_finalize_export.lbl_navigation',
            ),
            [
              new FinalStep(
                new Slug('finalize'),
                t('work_programs.24342109.accounts_payables.steps.step_finalize.title'),
                t('work_programs.24342109.accounts_payables.steps.step_finalize.text'),
              ),
            ],
          ),
        ]),
        parameters,
        [AnalysisType.GENERAL_LEDGER, /** AnalysisType.BANK, */ AnalysisType.ACCOUNTS_PAYABLE],
        new ChamberOfCommerceId('24342109'),
      )
    }
  }
}
