/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import FilterNotAppliedException from '~/src/Domain/Shared/Filter/Exception/FilterNotAppliedException'
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterValueType } from '~/src/Domain/Shared/Filter/FilterCollection'
import { FilterState } from '~/src/Domain/Shared/Filter/FilterState'
import type { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import type { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

export default abstract class AbstractFilter<T extends FilterValueType> {
  public readonly initialValue: T | undefined = undefined
  public value: T | undefined = undefined
  private state: FilterState = FilterState.AVAILABLE
  private readOnly = false

  public constructor(
    public readonly operator: Operator,
    public readonly filterType: FilterType,
    public readonly filterClass: FilterClass,
  ) {}

  public hasValue(): boolean {
    return this.value !== undefined
  }

  public isValid(): boolean {
    return this.initialValue !== this.value && this.value !== undefined
  }

  public isActive(): boolean {
    return this.state === FilterState.ACTIVE
  }

  public activate() {
    this.state = FilterState.ACTIVE
  }

  public isApplied(): boolean {
    return this.state === FilterState.APPLIED
  }

  public setReadOnly(): void {
    this.readOnly = true
  }

  public disableReadOnly(): void {
    this.readOnly = false
  }

  public isReadOnly(): boolean {
    return this.readOnly
  }

  public apply(value: T) {
    this.value = value
    this.state = FilterState.APPLIED
  }

  public isAvailable(): boolean {
    return this.state === FilterState.AVAILABLE
  }

  public reset() {
    this.value = this.initialValue
    this.state = FilterState.AVAILABLE
  }

  public getUrlParameters(key: string): string | string[] {
    if (this.value === undefined) {
      throw new FilterNotAppliedException()
    }

    return this.toUrlParameters(key, this.value)
  }

  protected abstract toUrlParameters(key: string, value: T): string | string[]
}
