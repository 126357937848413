/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { Hookable } from 'hookable'
import type CommandBusInterface from '~/src/Application/Shared/MessageBus/CommandBusInterface'
import type CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type LoggerInterface from '~/src/Domain/Shared/Logger/LoggerInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

export default class CommandBus extends Hookable implements CommandBusInterface {
  private handlers: Record<string, CommandHandlerInterface<CommandInterface>> = {}
  private readonly logger: LoggerInterface

  public constructor({ logger }: Services) {
    super()
    this.logger = logger
  }

  public async dispatch<T extends CommandInterface>(
    commandName: string,
    command: T,
  ): Promise<void> {
    this.logger.info(`[${commandName}] Dispatching command`)

    if (!(commandName in this.handlers)) {
      this.logger.info(`[${commandName}] No handler registered for  ${commandName}`)
      return
    }

    await this.handlers[commandName].handle(command)
  }

  public registerHandler<T extends CommandInterface>(
    commandName: string,
    commandHandler: CommandHandlerInterface<T>,
  ): void {
    this.handlers[commandName] = commandHandler
  }
}
