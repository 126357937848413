/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import type { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'

export default abstract class BaseResultReference {
  /**
   * In addition to the field filters that are editable at the work program step, filters can get
   * defined that by default will be passed along to make the backend aware of, for example, the
   * type of analysis filtering it has to do.
   */
  public abstract defaultFilters: FilterField[]
  public abstract repositoryReference: RepositoryReference
}
