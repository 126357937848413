/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { Buffer } from 'node:buffer'
import baseX from 'base-x'
import type UuidEncoderInterface from '~/src/Domain/Shared/Identifier/UuidEncoderInterface'

export default class UuidEncoder implements UuidEncoderInterface {
  private BASE62 = baseX('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
  private UUID_LENGTH = 36
  private UUID_NO_DASH = this.UUID_LENGTH - 4
  private ID_LENGTH = 22

  public encode(input: string): string {
    if (!(input.length === this.UUID_LENGTH || Buffer.isBuffer(input))) {
      throw new TypeError('encode input is not a valid uuid')
    }

    const buffer = Buffer.from(input.replaceAll('-', ''), 'hex')
    const output = this.BASE62.encode(buffer)

    return this.ensureLength(output, this.ID_LENGTH)
  }

  public decode(input: string): string {
    if (input.length === 0) {
      throw new TypeError('decode input is not valid')
    }

    const decoded = this.ensureLength(
      Buffer.from(this.BASE62.decode(input)).toString('hex'),
      this.UUID_NO_DASH,
    )

    const chars = decoded.split('')
    chars.splice(8, 0, '-')
    chars.splice(13, 0, '-')
    chars.splice(18, 0, '-')
    chars.splice(23, 0, '-')

    return chars.join('')
  }

  private trimLeft(string: string, length: number): string {
    let trim = 0
    // prettier-ignore
    while (string[trim] === '0' && (string.length - trim) > length) trim++
    return string.slice(trim)
  }

  private ensureLength(string: string, length: number) {
    if (string.length < length) {
      return string.padStart(length, '0')
    }

    if (string.length > length) {
      return this.trimLeft(string, length)
    }

    return string
  }
}
