/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DataRequestProps } from '~/src/Domain/DataRequest/DataRequest'
import DataRequest from '~/src/Domain/DataRequest/DataRequest'
import type DataRequestApiRepositoryInterface from '~/src/Domain/DataRequest/DataRequestApiRepositoryInterface'
import type DataRequestIri from '~/src/Domain/DataRequest/DataRequestIri'
import type FileIri from '~/src/Domain/DataRequest/File/FileIri'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export default class DataRequestApiRepository implements DataRequestApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async get(dataRequestUrl: string): Promise<DataRequest> {
    return DataRequest.prototype.fromJSON(
      await this.httpClient.get<DataRequestProps>(dataRequestUrl),
    )
  }

  public async create(
    engagement: EngagementIri,
    payload: {
      analysisType: string
      applicationId: string
      businessUnits: { code: string, description: string }[]
      period: { start: string, end: string }
      phaseId: string
      recipient: { firstName: string, lastName: string, email: string, locale: string }
      message: string
    },
  ): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/data_requests`, payload)
  }

  public async delete(dataRequest: DataRequestIri): Promise<void> {
    await this.httpClient.delete(dataRequest.toString())
  }

  public async findAll(engagement: EngagementIri): Promise<DataRequest[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<DataRequestProps>>(
        `${engagement.toString()}/data_requests`,
      ),
      DataRequest.prototype.fromJSON,
    )

    return response['hydra:member']
  }

  public async fulfill(dataRequest: DataRequestIri): Promise<void> {
    await this.httpClient.patch(`${dataRequest.toString()}/fulfill`)
  }

  public async acceptWithWarnings(dataRequest: DataRequestIri): Promise<void> {
    await this.httpClient.patch(`${dataRequest.toString()}/accept-warnings`)
  }

  public async deleteFile(file: FileIri): Promise<void> {
    await this.httpClient.delete(file.toString())
  }

  public async duplicate(dataRequest: DataRequestIri, message: string): Promise<void> {
    await this.httpClient.patch(`${dataRequest.toString()}/duplicate`, {
      message,
    })
  }
}
