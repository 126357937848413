/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Status } from '~/src/Domain/DataRequest/Step/Status/Status'
import type StatusBuilderInterface from '~/src/Domain/DataRequest/Step/Status/StatusBuilderInterface'
import type StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class StatusBuilderFactory {
  public constructor(public readonly statusBuilders: StatusBuilderInterface[]) {}

  public create(step: StepInterface, dataRequest: DataRequestProjection): Status {
    for (const statusBuilder of this.statusBuilders) {
      if (!statusBuilder.supports(step)) {
        continue
      }

      return statusBuilder.build(step, dataRequest)
    }

    throw new Error(`Found no statusBuilder for step type ${step.constructor.name}.`)
  }
}
