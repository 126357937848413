/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { setUser } from '@sentry/nuxt'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import UserNotAuthenticatedException from '~/src/Domain/Identity/Exception/UserNotAuthenticatedException'
import type { UserProps } from '~/src/Domain/Identity/User'
import User from '~/src/Domain/Identity/User'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'

const userStore = defineStore('user', () => {
  const user = ref<UserProps | undefined>(undefined)
  const expiresAt = ref<BetterDateTime | undefined>(undefined)

  const authenticationProcessInitiated = ref<string | undefined>(undefined)

  const getUser = () => {
    if (user.value === undefined) {
      throw new UserNotAuthenticatedException('User is not authenticated.')
    }

    return User.prototype.fromJSON(user.value as UserProps)
  }

  const updateUser = (u: User) => (user.value = u.toJSON())

  const isAuthenticated = computed(() => user.value !== undefined)

  const isAuthenticationProcessInitiated = computed(
    () => authenticationProcessInitiated.value !== undefined,
  )
  const authenticationProcessInitiatedFor = () => authenticationProcessInitiated.value
  const initiateAuthenticationProcess = (email: string) =>
    (authenticationProcessInitiated.value = email)
  const resetAuthenticationProcessInitiation = () =>
    (authenticationProcessInitiated.value = undefined)

  const isExpired = computed(
    () =>
      expiresAt.value !== undefined && new BetterDateTime().getTime() > expiresAt.value.getTime(),
  )

  const markState = (u: User, e: BetterDateTime) => {
    user.value = u.toJSON()
    expiresAt.value = e
    resetAuthenticationProcessInitiation()

    setUser({ email: user.value.email })
  }

  const resetState = (): void => {
    user.value = undefined
    expiresAt.value = undefined
    resetAuthenticationProcessInitiation()

    setUser(null)
  }

  return {
    user: user as Readonly<Ref<UserProps | undefined>>,
    getUser,
    updateUser,
    isAuthenticated,
    isAuthenticationProcessInitiated,
    authenticationProcessInitiatedFor,
    initiateAuthenticationProcess,
    resetAuthenticationProcessInitiation,
    isExpired,
    expiresAt,
    markState,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(userStore, import.meta.hot))
}

export default userStore
