/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ContextMenuItem from '~/src/Domain/Shared/ContextMenu/ContextMenuItem'
import type { ContextMenuItemProps } from '~/src/Domain/Shared/ContextMenu/ContextMenuItem'
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface ContextMenuProps extends JsonValueObjectProps {
  items: ContextMenuItemProps[]
}

export default class ContextMenu
implements JsonValueObjectInterface<ContextMenu, ContextMenuProps> {
  public constructor(public items: ContextMenuItem[]) {}

  public fromJSON(values: ContextMenuProps): ContextMenu {
    return new ContextMenu(values.items.map((i) => ContextMenuItem.prototype.fromJSON(i)))
  }

  public toJSON(): ContextMenuProps {
    return {
      items: this.items.map((i) => i.toJSON()),
    }
  }
}
