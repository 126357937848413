/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import LogicException from '~/src/Domain/Shared/Exception/LogicException'
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterValue, FilterValueType } from '~/src/Domain/Shared/Filter/FilterCollection'
import type ParameterDefinitionInterface from '~/src/Domain/WorkProgram/Fixtures/ParameterDefinitionInterface'
import type ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'

export default class ParameterizedFilterFieldFilter {
  public constructor(
    public readonly parameter: ParameterDefinitionInterface,
    public readonly filterClass: FilterClass,
  ) {}

  public getFilterValue = (
    filterFieldKey: string,
    parameters: ParameterProjection[],
    readOnly: boolean,
  ): FilterValue => {
    const foundParameter = parameters.find((p) =>
      p.parameterDefinition.getName().equalsTo(this.parameter.getName()),
    )
    if (foundParameter === undefined) {
      throw new LogicException(
        `Parameter ${this.parameter.getName().toString()} for field ${filterFieldKey} has not been defined in the work program fixture.`,
      )
    }

    if (foundParameter.filter.value === undefined) {
      throw new LogicException(
        `Parameter ${this.parameter.getName().toString()} for field ${filterFieldKey} has no value defined in the work program fixture.`,
      )
    }

    return {
      key: filterFieldKey,
      filterClass: this.filterClass,
      value: foundParameter.filter.value as FilterValueType,
      readOnly,
    }
  }
}
