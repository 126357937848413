/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import SpecialRelationsInterCompanyParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsInterCompanyParameterDefinition20055194'
import SpecialRelationsPensionFundsParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsPensionFundsParameterDefinition20055194'
import SpecialRelationsRelatedPartiesParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsRelatedPartiesParameterDefinition20055194'
import SpecialRelationsTaxAuthoritiesParameterDefinition20055194 from '~/src/Domain/WorkProgram/Fixtures/20055194/ParameterDefinition/SpecialRelationsTaxAuthoritiesParameterDefinition20055194'
import t from '~/src/Infrastructure/Shared/Translation/t'

/// /////////////////
// Accounts Receivable
/// /////////////////

export const specialRelationsInterCompanyFilterField = (): FilterField => {
  return new FilterField(
    'customer.customerName',
    t('general.entity_fields.customer.customer_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsInterCompanyParameterDefinition20055194(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const specialRelationsTaxAuthoritiesFilterField = (): FilterField => {
  return new FilterField(
    'customer.customerName',
    t('general.entity_fields.customer.customer_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsTaxAuthoritiesParameterDefinition20055194(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const specialRelationsPensionFundsFilterField = (): FilterField => {
  return new FilterField(
    'customer.customerName',
    t('general.entity_fields.customer.customer_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsPensionFundsParameterDefinition20055194(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const specialRelationsRelatedPartiesFilterField = (): FilterField => {
  return new FilterField(
    'customer.customerName',
    t('general.entity_fields.customer.customer_name'),
    [
      new ParameterizedFilterFieldFilter(
        new SpecialRelationsRelatedPartiesParameterDefinition20055194(),
        FilterClass.API_VALUE_FILTER,
      ),
    ],
    true,
    true,
  )
}

export const amountChangePerSubsequentYearIndicatorFilterField = (): FilterField => {
  return new FilterField(
    'amountChangePerSubsequentYearIndicator',
    t('general.entity_fields.open_accounts_receivable.amount_change_per_subsequent_year_indicator'),
    [new FilterFieldFilter(FilterClass.API_VALUE_FILTER, undefined)],
    false,
  )
}
