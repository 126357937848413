/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterValue, FilterValueType } from '~/src/Domain/Shared/Filter/FilterCollection'

export default class FilterFieldFilter {
  public constructor(
    public readonly filterClass: FilterClass,
    public readonly value: FilterValueType,
  ) {}

  public getFilterValue(filterFieldKey: string, readOnly: boolean): FilterValue {
    return {
      key: filterFieldKey,
      filterClass: this.filterClass,
      value: this.value,
      readOnly,
    }
  }
}
