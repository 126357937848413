/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'

export default class UpdateLocaleCommand implements CommandInterface {
  public static readonly NAME = 'UPDATE_LOCALE'

  public constructor(public readonly locale: string) {}
}
