/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { AuditTeamMemberProps } from '~/src/Domain/Engagement/AuditTeamMember'
import AuditTeamMember from '~/src/Domain/Engagement/AuditTeamMember'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import type { ParameterProps } from '~/src/Domain/WorkProgram/Parameter'
import Parameter from '~/src/Domain/WorkProgram/Parameter'
import type { StepProps } from '~/src/Domain/WorkProgram/Step'
import Step from '~/src/Domain/WorkProgram/Step'
import WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'

export interface WorkProgramProps extends DomainModelProps {
  '@id': string
  '@type': string
  phase: string
  slug: string
  description: string | undefined
  startedOn: string
  startedBy: AuditTeamMemberProps
  lastModifiedOn: string
  steps: StepProps[]
  parameters: ParameterProps[]
  finished: boolean
}

export default class WorkProgram implements DomainModelInterface<WorkProgram, WorkProgramProps> {
  public readonly '@id': WorkProgramIri
  public readonly '@type': string

  private constructor(
    id: WorkProgramIri,
    type: string,
    public readonly phase: PhaseIri,
    public readonly slug: Slug,
    public readonly description: string | undefined,
    public readonly startedOn: BetterDateTime,
    public readonly startedBy: AuditTeamMember,
    public readonly lastModifiedOn: BetterDateTime,
    public readonly steps: Step[],
    public readonly parameters: Parameter[],
    public readonly finished: boolean,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'WorkProgram'
  }

  public fromJSON(values: WorkProgramProps): WorkProgram {
    return new WorkProgram(
      new WorkProgramIri(values['@id']),
      values['@type'],
      new PhaseIri(values.phase),
      new Slug(values.slug),
      values.description,
      new BetterDateTime(values.startedOn),
      AuditTeamMember.prototype.fromJSON(values.startedBy),
      new BetterDateTime(values.lastModifiedOn),
      values.steps.map((s) => Step.prototype.fromJSON(s)),
      values.parameters.map((p) => Parameter.prototype.fromJSON(p)),
      values.finished,
    )
  }

  public toJSON(): WorkProgramProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      phase: this.phase.toString(),
      slug: this.slug.toString(),
      description: this.description,
      startedOn: this.startedOn.toString(),
      startedBy: this.startedBy.toJSON(),
      lastModifiedOn: this.lastModifiedOn.toString(),
      steps: this.steps.map((s) => s.toJSON()),
      parameters: this.parameters.map((p) => p.toJSON()),
      finished: this.finished,
    }
  }
}
