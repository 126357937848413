/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ChangeParameterCommand from '~/src/Application/Engagement/Command/ChangeParameterCommand'
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import type EngagementApiRepositoryInterface from '~/src/Domain/Engagement/EngagementApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(ChangeParameterCommand.NAME, 'changeParameterCommandHandler')
export default class ChangeParameterCommandHandler extends CommandHandlerInterface<ChangeParameterCommand> {
  private readonly engagementApiRepository: EngagementApiRepositoryInterface

  public constructor({ engagementApiRepository }: Services) {
    super()
    this.engagementApiRepository = engagementApiRepository
  }

  public async handle(command: ChangeParameterCommand): Promise<void> {
    await this.engagementApiRepository.changeParameter(command.parameter, command.values)
  }
}
