/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import UserIsNotIn2faProcessException from '~/src/Domain/Identity/Exception/UserIsNotIn2faProcessException'
import type User from '~/src/Domain/Identity/User'
import type UserStoreRepositoryInterface from '~/src/Domain/Identity/UserStoreRepositoryInterface'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import pinia from '~/src/Infrastructure/Shared/Store/pinia'
import UserStore from '~/src/UserInterface/Identity/store/UserStore'

export default class UserStoreRepository implements UserStoreRepositoryInterface {
  private readonly store: ReturnType<typeof UserStore>

  public constructor() {
    this.store = UserStore(pinia)
  }

  public getUser(): User {
    return this.store.getUser()
  }

  public updateUser(user: User): void {
    this.store.updateUser(user)
  }

  public isAuthenticated(): boolean {
    return this.store.isAuthenticated
  }

  public initiateAuthenticationProcess(email: string): void {
    this.store.initiateAuthenticationProcess(email)
  }

  public isAuthenticationProcessInitiated(): boolean {
    return this.store.isAuthenticationProcessInitiated
  }

  public authenticationProcessInitiatedFor(): string {
    const email = this.store.authenticationProcessInitiatedFor()
    if (email === undefined) {
      throw new UserIsNotIn2faProcessException('User is not in 2fa process')
    }

    return email
  }

  public isExpired(): boolean {
    return this.store.isExpired
  }

  public markState(user: User, expiresAt: BetterDateTime): void {
    this.store.markState(user, expiresAt)
  }

  public resetState(): void {
    this.store.resetState()
  }
}
