/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventBusInterface from '~/src/Application/Shared/MessageBus/EventBusInterface'
import type { CoaMappingProps } from '~/src/Domain/CoaMapping/CoaMapping'
import CoaMapping from '~/src/Domain/CoaMapping/CoaMapping'
import CoaMappingIri from '~/src/Domain/CoaMapping/CoaMappingIri'
import CoaMappingDeletedEvent from '~/src/Domain/CoaMapping/Event/CoaMappingDeletedEvent'
import CoaMappingUpdatedEvent from '~/src/Domain/CoaMapping/Event/CoaMappingUpdatedEvent'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import { AsPublisher } from '~/src/Infrastructure/Shared/PubSub/AsPublisher'
import type PublisherInterface from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import type { Message } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'
import { Type } from '~/src/Infrastructure/Shared/PubSub/PublisherInterface'

@AsPublisher('coaMappingPublisher')
export default class CoaMappingPublisher implements PublisherInterface {
  private REGEX_PATTERN
    = '^/v1/engagements/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/coa_mappings/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'

  private readonly eventBus: EventBusInterface

  public constructor({ eventBus }: Services) {
    this.eventBus = eventBus
  }

  public async publish(message: Message): Promise<void> {
    if (!('@id' in message.data && message.data['@id'].match(this.REGEX_PATTERN) !== null)) {
      return
    }

    if (message.type === Type.UPDATE) {
      await this.eventBus.dispatch(CoaMappingUpdatedEvent.NAME, new CoaMappingUpdatedEvent(CoaMapping.prototype.fromJSON(message.data as CoaMappingProps)))
    }

    if (message.type === Type.DELETE) {
      await this.eventBus.dispatch(CoaMappingDeletedEvent.NAME, new CoaMappingDeletedEvent(new CoaMappingIri(message.data['@id'])))
    }
  }
}
