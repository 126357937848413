/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  JsonValueObjectInterface,
  JsonValueObjectProps,
} from '~/src/Domain/Shared/JsonValueObjectInterface'

export interface ContextMenuItemProps extends JsonValueObjectProps {
  title: string
  icon: string
  onClick: (...args: any[]) => void | Promise<void>
}

export default class ContextMenuItem
implements JsonValueObjectInterface<ContextMenuItem, ContextMenuItemProps> {
  public constructor(
    public readonly title: string,
    public readonly icon: string,
    public readonly onClick: (...args: any[]) => void | Promise<void>,
  ) {}

  public fromJSON(values: ContextMenuItemProps): ContextMenuItem {
    return new ContextMenuItem(values.title, values.icon, values.onClick)
  }

  public toJSON(): ContextMenuItemProps {
    return {
      title: this.title,
      icon: this.icon,
      onClick: this.onClick,
    }
  }
}
