/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type {
  EngagementExportDetailsProps,
} from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'

export interface CoaMappingExportDetailsProps extends EngagementExportDetailsProps {
  coaMappingId: string
  locale: string
}
export default class CoaMappingExportDetails extends EngagementExportDetailsInterface<
  CoaMappingExportDetails,
  CoaMappingExportDetailsProps
> {
  private constructor(
    public readonly engagementId: string,
    public readonly coaMappingId: string,
    public readonly locale: string,
  ) {
    super()
  }

  public fromJSON(values: CoaMappingExportDetailsProps): CoaMappingExportDetails {
    return new CoaMappingExportDetails(values.engagementId, values.coaMappingId, values.locale)
  }

  public toJSON(): CoaMappingExportDetailsProps {
    return {
      engagementId: this.engagementId,
      coaMappingId: this.coaMappingId,
      locale: this.locale,
    }
  }
}
