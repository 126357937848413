export default {
  admin: () => import("/home/node/app/src/UserInterface/App/layouts/admin.vue"),
  blank: () => import("/home/node/app/src/UserInterface/App/layouts/blank.vue"),
  default: () => import("/home/node/app/src/UserInterface/App/layouts/default.vue"),
  workflow: () => import("/home/node/app/src/UserInterface/App/layouts/workflow.vue"),
  "settings-coa-mapping": () => import("/home/node/app/src/UserInterface/CoaMapping/layouts/settingsCoaMapping.vue"),
  "data-request": () => import("/home/node/app/src/UserInterface/DataRequest/layouts/dataRequest.vue"),
  "data-request-landing": () => import("/home/node/app/src/UserInterface/DataRequest/layouts/dataRequestLanding.vue"),
  "data-request-success": () => import("/home/node/app/src/UserInterface/DataRequest/layouts/dataRequestSuccess.vue"),
  "settings-engagement": () => import("/home/node/app/src/UserInterface/Engagement/layouts/settingsEngagement.vue"),
  identity: () => import("/home/node/app/src/UserInterface/Identity/layouts/identity.vue"),
  "settings-profile": () => import("/home/node/app/src/UserInterface/Identity/layouts/settingsProfile.vue"),
  "settings-organisation": () => import("/home/node/app/src/UserInterface/Organisation/layouts/settingsOrganisation.vue"),
  "work-program-introduction": () => import("/home/node/app/src/UserInterface/WorkProgram/layouts/workProgramIntroduction.vue"),
  workprogram: () => import("/home/node/app/src/UserInterface/WorkProgram/layouts/workprogram.vue")
}