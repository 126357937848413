/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import LanguageUpdatedEvent from '~/src/Application/Shared/Translation/Event/LanguageUpdatedEvent'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import type WorkProgramFixtureFactory from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureFactory'
import type WorkProgramStoreRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramStoreRepositoryInterface'

import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(LanguageUpdatedEvent.NAME, 'workProgramLanguageUpdatedEventHandler')
export default class WorkProgramLanguageUpdatedEventHandler extends EventHandlerInterface<LanguageUpdatedEvent> {
  private readonly organisationStoreRepository: OrganisationStoreRepositoryInterface
  private readonly workProgramFixtureFactory: WorkProgramFixtureFactory
  private readonly workProgramStoreRepository: WorkProgramStoreRepositoryInterface

  public constructor({ organisationStoreRepository, workProgramFixtureFactory, workProgramStoreRepository }: Services) {
    super()
    this.organisationStoreRepository = organisationStoreRepository
    this.workProgramFixtureFactory = workProgramFixtureFactory
    this.workProgramStoreRepository = workProgramStoreRepository
  }

  public handle(_event: LanguageUpdatedEvent): void {
    if (!this.organisationStoreRepository.hasOrganisation()) {
      return
    }

    const fixtures = this.workProgramFixtureFactory.createForOrganisation(
      this.organisationStoreRepository.getOrganisation().chamberOfCommerceId,
    )
    this.workProgramStoreRepository.markWorkProgramFixtures(fixtures)
  }
}
