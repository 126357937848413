/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import ExportPreparedEvent from '~/src/Domain/Export/Event/ExportPreparedEvent'
import type { ExportProps } from '~/src/Domain/Export/Export'
import Export from '~/src/Domain/Export/Export'
import ExportCollection from '~/src/Domain/Export/ExportCollection'
import type ExportIri from '~/src/Domain/Export/ExportIri'
import useService from '~/src/UserInterface/App/composables/Container/useService'

const exportStore = defineStore('export', () => {
  const exports = ref<ExportProps[]>([])

  const exportCollection = computed(
    () => new ExportCollection(exports.value.map((e) => Export.prototype.fromJSON(e))),
  )

  const eventBus = useService('eventBus')
  const updateExport = async (updatedExport: Export) => {
    const index = exports.value.findIndex((e) => e['@id'] === updatedExport['@id'].toString())
    if (index === -1) {
      exports.value.push(updatedExport.toJSON())
    } else {
      const isPrepared = exports.value[index].prepared === false && updatedExport.prepared === true

      exports.value[index] = updatedExport.toJSON()

      if (isPrepared) {
        await eventBus.dispatch(ExportPreparedEvent.NAME, new ExportPreparedEvent(updatedExport))
      }
    }
  }

  const deleteExport = (deletedExport: ExportIri) => {
    const index = exports.value.findIndex((e) => e['@id'] === deletedExport.toString())
    if (index !== -1) {
      exports.value.splice(index, 1)
    }
  }

  const markState = (e: Export[]) => (exports.value = e.map((e) => e.toJSON()))
  const resetState = (): void => {
    exports.value = []
  }

  return {
    exports: exports as Readonly<Ref<ExportProps[]>>,
    exportCollection,
    updateExport,
    deleteExport,
    markState,
    resetState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(exportStore, import.meta.hot))
}

export default exportStore
