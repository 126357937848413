/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import EngagementActivatedEvent from '~/src/Domain/Engagement/Event/EngagementActivatedEvent'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import type WorkProgramFixtureFactory from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureFactory'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type WorkProgramStoreRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(EngagementActivatedEvent.NAME, 'workProgramEngagementActivatedEventHandler')
export default class WorkProgramEngagementActivatedEventHandler extends EventHandlerInterface<EngagementActivatedEvent> {
  private readonly organisationStoreRepository: OrganisationStoreRepositoryInterface
  private readonly workProgramApiRepository: WorkProgramApiRepositoryInterface
  private readonly workProgramFixtureFactory: WorkProgramFixtureFactory
  private readonly workProgramStoreRepository: WorkProgramStoreRepositoryInterface

  public constructor({ organisationStoreRepository, workProgramApiRepository, workProgramFixtureFactory, workProgramStoreRepository }: Services) {
    super()
    this.organisationStoreRepository = organisationStoreRepository
    this.workProgramApiRepository = workProgramApiRepository
    this.workProgramFixtureFactory = workProgramFixtureFactory
    this.workProgramStoreRepository = workProgramStoreRepository
  }

  public async handle(event: EngagementActivatedEvent): Promise<void> {
    this.workProgramStoreRepository.resetState()

    const startedWorkPrograms = await this.workProgramApiRepository.findStarted(event.engagement)
    const fixtures = this.workProgramFixtureFactory.createForOrganisation(
      this.organisationStoreRepository.getOrganisation().chamberOfCommerceId,
    )

    this.workProgramStoreRepository.markState(startedWorkPrograms, fixtures)
  }
}
