/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsCommandHandler } from '~/src/Application/Shared/MessageBus/AsCommandHandler'
import CommandHandlerInterface from '~/src/Application/Shared/MessageBus/CommandHandlerInterface'
import DuplicateWorkProgramCommand from '~/src/Application/WorkProgram/Command/DuplicateWorkProgramCommand'
import type WorkProgramApiRepositoryInterface from '~/src/Domain/WorkProgram/WorkProgramApiRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsCommandHandler(DuplicateWorkProgramCommand.NAME, 'duplicateWorkProgramCommandHandler')
export default class DuplicateWorkProgramCommandHandler extends CommandHandlerInterface<DuplicateWorkProgramCommand> {
  private readonly workProgramApiRepository: WorkProgramApiRepositoryInterface

  public constructor({ workProgramApiRepository }: Services) {
    super()
    this.workProgramApiRepository = workProgramApiRepository
  }

  public async handle(command: DuplicateWorkProgramCommand): Promise<void> {
    await this.workProgramApiRepository.duplicateWorkProgram(command.workProgram)
  }
}
