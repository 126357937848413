/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type CommandInterface from '~/src/Application/Shared/MessageBus/CommandInterface'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'

export class ChangeWorkProgramCommandStep {
  public readonly slug: Slug
  public readonly hidden: boolean
  public readonly hiddenReason: string | undefined

  public constructor(slug: Slug, hidden: boolean, hiddenReason: string | undefined) {
    this.slug = slug
    this.hidden = hidden
    this.hiddenReason = hiddenReason
  }
}

export class ChangeWorkProgramCommandParameter {
  public readonly name: string
  public readonly value: string | string[] | number | number[]

  public constructor(name: string, value: string | string[] | number | number[]) {
    this.name = name
    this.value = value
  }
}

export default class ChangeWorkProgramCommand implements CommandInterface {
  public static readonly NAME = 'CHANGE_WORK_PROGRAM_STEPS_VISIBILITY_COMMAND'

  public readonly workProgram: WorkProgramIri
  public readonly steps: ChangeWorkProgramCommandStep[]
  public readonly parameters: ChangeWorkProgramCommandParameter[]

  public constructor(
    workProgram: WorkProgramIri,
    public readonly description: string | undefined,
    steps: { slug: Slug, hidden: boolean, hiddenReason: string | undefined }[],
    parameters: { name: string, value: string | string[] | number | number[] }[],
  ) {
    this.workProgram = workProgram
    this.steps = steps.map(
      (s) => new ChangeWorkProgramCommandStep(s.slug, s.hidden, s.hiddenReason),
    )
    this.parameters = parameters.map((p) => new ChangeWorkProgramCommandParameter(p.name, p.value))
  }
}
