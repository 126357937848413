/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { destr } from 'destr'

export default function parseJsonString<T>(json: string): T {
  return destr(json, { strict: false })
}
