/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Badge from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Badge'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import Item from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Item'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Image from '~/src/Domain/DataRequest/Fixtures/Content/Image/Image'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import Section from '~/src/Domain/DataRequest/Fixtures/Content/Section/Section'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import AnnualReportFileType from '~/src/Domain/GeneralLedger/Application/Twinfield/FileType/AnnualReportFileType'
import GeneralLedgerDetailsFileType from '~/src/Domain/GeneralLedger/Application/Twinfield/FileType/GeneralLedgerDetailsFileType'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class TwinfieldDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.TWINFIELD === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.TWINFIELD),
      new StepCollection([
        new Step(
          new Slug('twinfield-excel-plugin'),
          t('components.data_request.general_ledger.pages.twinfield_excel_plugin.title'),
          'Voor het onttrekken van data uit Twinfield heeft de leverancier Wolter Kluwers een Excel plug-in gemaakt, genaamd Twinfield Analysis. Middels deze plugin kunnen op een eenvoudige, veilige, efficiente en gestructeerde wijze de benodigde gegevens worden gedownload uit Twinfield. Een bijkomend voordeel van deze extractiemethode is dat de data van meerdere administraties en boekjaren gelijktijdig gedownload kan worden. Voor meer informatie zie de website van de leverancier: https://www.wolterskluwer.com/nl-nl/solutions/twinfield-accounting/functies/twinfield-analysis',
          [
            new Section(
              'Installatie',
              'De Excel plug-in kan via de AppSource store van Microsoft worden geïnstalleerd, zie: https://appsource.microsoft.com/nl-nl/product/office/wa104382004?tab=overview. Let op, je moet mogelijk inloggen op de website van Microsoft met jouw Office 365 account.'
              + 'Om vast te stellen of de plug-in beschikbaar is in Excel open je deze applicatie en maak je een nieuw werkboek aan. Vervolgens navigeer je naar het tabblad “Gegevens”:',
              [
                new Image(
                  '/img/data-request/twinfield/excel_navigate_ribbon_data.png',
                  'Navigeer naar gegevens',
                ),
                new Paragraph([
                  'En zie je rechts in de zogenaamde “ribbon” de optie “Twinfield Analysis”, zie:',
                ]),
                new Image(
                  '/img/data-request/twinfield/excel_navigate_ribbon_twinfield_analysis.png',
                  'Twinfield Analysis',
                ),
              ],
            ),
            new Section(
              'Inloggen',
              'Als je op de optie “Twinfield Analysis” klikt in het Excel menu “Gegevens” opent er een nieuwe zijbalk in Excel en word je de eerste keer gevraagd in te loggen met jouw persoonlijke Twinfield account, zie:',
              [
                new Checklist(
                  [
                    new Item(
                      'Klik op de knop ‘Login’ rechts op de pagina.',
                      new Badge(49, undefined, 44, undefined),
                    ),
                  ],
                  '/img/data-request/twinfield/excel_twinfield_sidebar_login.png',
                  undefined,
                  '550px',
                ),
                new Paragraph([
                  'Er opent zich een pop-up met de mogelijkheid om in te loggen, zie:',
                ]),
                new Checklist(
                  [
                    new Item(
                      'Geef jouw login gegevens op en vul de juiste omgeving in',
                      new Badge(47, undefined, 4, undefined),
                    ),
                    new Item('Klik op ‘Inloggen’', new Badge(59, undefined, 14, undefined)),
                  ],
                  '/img/data-request/twinfield/excel_twinfield_sidebar_login_popup.png',
                  undefined,
                  '550px',
                ),
                new Paragraph(['In de zijbalk wordt de voortgang van het inloggen getoond, zie:']),
                new Image(
                  '/img/data-request/twinfield/excel_twinfield_sidebar_login_progress.png',
                  'Login voortgang',
                  '400px',
                ),
                new Paragraph(['Zodra het inloggen succesvol is wordt het volgende getoond, zie:']),
                new Image(
                  '/img/data-request/twinfield/excel_twinfield_sidebar_login_success_result.png',
                  'Loginc success',
                ),
                new Paragraph(['Je kan nu de volgende stappen in de handleiding volgen.']),
              ],
            ),
          ],
        ),
        new Step(
          new Slug('annual-report'),
          t('components.data_request.general_ledger.pages.annual_report.title'),
          t('components.data_request.general_ledger.pages.annual_report.description'),
          [
            new Alert(
              'Let op: het is van belang het rapport “Annual report” eerst uit Twinfield te exporteren, alvorens de journaalpostdata wordt geëxporteerd. Dit om de kans op eventuele timingsverschillen te verkleinen. Als je meerdere exports moet maken, gebruik een eerder xlsx dan niet opnieuw, maar maak een nieuw xlsx bestand aan. Anders resulteert dit mogelijk in inconsistente resultaten.',
            ),
            new Section(undefined, undefined, [
              new Paragraph(['In het menu selecteer de volgende ‘cube’, zie:']),
              new Checklist(
                [
                  new Item(
                    'Klik op de optie onder ‘Select cube’ om de huidige selectie te wijzigen',
                    new Badge(33, undefined, -4, undefined),
                  ),
                  new Item(
                    'Selecteer de optie ‘Annual Report’',
                    new Badge(73, undefined, -4, undefined),
                  ),
                ],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Hierna wordt de keuze bevestigd, en staat deze onder de optie ‘Select cube’, zie:',
              ]),
              new Checklist(
                [
                  new Item(
                    'Selecteer een, meerdere, of alle bedrijven om mee te nemen in de export',
                    new Badge(22, undefined, -4, undefined),
                  ),
                  new Item(
                    'Hier worden de geselecteerde bedrijven getoond',
                    new Badge(69, undefined, -4, undefined),
                  ),
                  new Item(
                    'Klik op ‘Next’ om de gemaakte keuze te bevestigen',
                    new Badge(94, undefined, 56, undefined),
                  ),
                ],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_select_cube.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Als je het volgende te zien krijgt in de zijbalk, en niet de mogelijkheid krijgt om filters op te geven, ga dan\n'
                + 'even terug naar de vorige pagina:',
              ]),
              new Checklist(
                [new Item('Klik op de optie ‘< back’', new Badge(24, undefined, -4, undefined))],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_go_back.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Nadat je (opnieuw) op ‘Next’ hebt gedrukt wordt het volgende scherm getoond met de mogelijkheid tot het opgeven van filters:',
              ]),
              new Checklist(
                [
                  new Item(
                    'Geef de gewenste periode op. Dit betreft het boekjaar, gevolgd door de periode. Om geheel boekjaar 2023 te downloaden kies je: from=“2023/00”; until= from=“2023/56”. Let op, de aanhalingstekens hoeven niet te worden ingetypt.',
                    new Badge(18, undefined, -4, undefined),
                  ),
                  new Item(
                    'Selecteer de optie ‘Concept & definitief’ bij de optie ‘Status’',
                    new Badge(29, undefined, -4, undefined),
                  ),
                  new Item(
                    'Zorg ervoor dat er geen andere filters staan ingesteld',
                    new Badge(44, undefined, -4, undefined),
                  ),
                  new Item(
                    'Kies de juist optie onder ‘Regime’, bij twijfel kan de optie ‘Fiscaal’ worden gekozen',
                    new Badge(65, undefined, -4, undefined),
                  ),
                  new Item(
                    'Klik op ‘Submit’ om de instellingen te bevestigen en de export te starten',
                    new Badge(77, undefined, 56, undefined),
                  ),
                ],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_cube_criteria.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'De export wordt gestart en de voortgang wordt getoond in de zijbalk:',
              ]),
              new Image(
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_progress.png',
                'Voortgang',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Zodra het exporteren compleet is wordt de volgende melding weergegeven:',
              ]),
              new Image(
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_success.png',
                'Success',
                undefined,
                '350px',
              ),
              new Paragraph(['Sla het Excel bestand op via het standaard menu, zie:']),
              new Image(
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_save.png',
                'Opslaan',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Sla het Excel bestand op met een herkenbare naam, neem in de naamgeving de naam/ code van de administratie(s) op, alsmede het boekjaar van de export.',
              ]),
            ]),
            new FileUploader(new AnnualReportFileType(), [
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
            new Section(undefined, undefined, [
              new Paragraph([
                'Vervolgens kunnen de journaalpostmutaties worden gedownload. Volg hiervoor de stappen in het volgende hoofdstuk.',
              ]),
            ]),
          ],
        ),
        new Step(
          new Slug('general-ledger-details-v2'),
          t('components.data_request.general_ledger.pages.general_ledger_details_v2.title'),
          t('components.data_request.general_ledger.pages.general_ledger_details_v2.description'),
          [
            new Alert(
              'Als je meerdere exports moet maken, gebruik een eerder xlsx dan niet opnieuw, maar maak een nieuw xlsx bestand aan. Anders resulteert dit mogelijk in inconsistente resultaten.',
            ),
            new Section(undefined, undefined, [
              new Paragraph(['In het menu selecteer de volgende ‘cube’, zie:']),
              new Checklist(
                [
                  new Item(
                    'Klik op de optie onder ‘Select cube’ om de huidige selectie te wijzigen',
                    new Badge(38, undefined, -4, undefined),
                  ),
                  new Item(
                    'Selecteer de optie ‘Grootboek (details) (v2)’',
                    new Badge(49, undefined, -4, undefined),
                  ),
                ],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_general_ledger_details_v2.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Hierna wordt de keuze bevestigd, en staat deze onder de optie ‘Select cube’, zie:',
              ]),
              new Checklist(
                [
                  new Item(
                    'Selecteer een, meerdere, of alle bedrijven om mee te nemen in de export',
                    new Badge(22, undefined, -4, undefined),
                  ),
                  new Item(
                    'Hier worden de geselecteerde bedrijven getoond',
                    new Badge(69, undefined, -4, undefined),
                  ),
                  new Item(
                    'Klik op ‘Next’ om de gemaakte keuze te bevestigen',
                    new Badge(93, undefined, 55, undefined),
                  ),
                ],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_general_ledger_details_v2_select_cube.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Nadat je op ‘Next’ hebt gedrukt wordt het volgende scherm getoond met de mogelijkheid tot het opgeven van filters:',
              ]),
              new Checklist(
                [
                  new Item(
                    'Geef de gewenste periode op. Dit betreft het boekjaar, gevolgd door de periode. Om geheel boekjaar 2023 te downloaden kies je: from=“2023/00”; until= from=“2023/56”. Let op, de aanhalingstekens hoeven niet te worden ingetypt.',
                    new Badge(21, undefined, -4, undefined),
                  ),
                  new Item(
                    'Selecteer de optie ‘Concept & definitief’ bij de optie ‘Status’',
                    new Badge(31, undefined, -4, undefined),
                  ),
                  new Item(
                    'Zorg ervoor dat er geen andere filters staan ingesteld',
                    new Badge(57, undefined, -4, undefined),
                  ),
                  new Item(
                    'Kies de juist optie onder ‘Regime’, bij twijfel kan de optie ‘Fiscaal’ worden gekozen',
                    new Badge(87, undefined, -4, undefined),
                  ),
                  new Item(
                    'Klik op ‘Submit’ om de instellingen te bevestigen en de export te starten',
                    new Badge(94, undefined, 58, undefined),
                  ),
                ],
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_general_ledger_details_v2_cube_criteria.png',
                undefined,
                '350px',
              ),
              new Paragraph([
                'De export wordt gestart en de voortgang wordt getoond in de zijbalk:',
              ]),
              new Image(
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_general_ledger_details_v2_progress.png',
                'Voortgang',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Zodra het exporteren compleet is wordt de volgende melding weergegeven in de zijbalk, en wordt de data getoond in het scherm:',
              ]),
              new Image(
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_general_ledger_details_v2_success.png',
                'Resultaat',
              ),
              new Paragraph(['Sla het Excel bestand op via het standaard menu, zie:']),
              new Image(
                '/img/data-request/twinfield/excel_twinfield_sidebar_export_annual_report_save.png',
                'Opslaan',
                undefined,
                '350px',
              ),
              new Paragraph([
                'Sla het Excel bestand op met een herkenbare naam, neem in de naamgeving de naam/ code van de administratie(s) op, alsmede het boekjaar van de export.',
              ]),
            ]),
            new FileUploader(new GeneralLedgerDetailsFileType(), [
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
      ]),
    )
  }
}
