/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useCookie from '~/src/UserInterface/App/composables/useCookie'

export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[I18N] Middleware fired')

  const nuxtApp = useNuxtApp()
  const cookie = useCookie<string>('i18n')
  const locale = cookie.value ?? nuxtApp.$i18n.locale.value

  if (locale !== nuxtApp.$i18n.locale.value) {
    logger.info(`[I18N] Switching app locale from ${nuxtApp.$i18n.locale.value} to ${locale}`)

    await nuxtApp.$i18n.setLocale(locale)
    // eslint-disable-next-line ts/no-unsafe-argument
    await navigateTo(nuxtApp.$localePath(to))
  }
})
