/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import AbstractIri from '~/src/Domain/Shared/Identifier/AbstractIri'

export default class AuditTeamMemberIri extends AbstractIri<AuditTeamMemberIri> {
  public toId(): string {
    return this.iri.toString().split('/').pop()!
  }
}
