/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import type FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import BaseResultReference from '~/src/Domain/WorkProgram/ResultReference/BaseResultReferences'
import type { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'

/**
 * Reference interface that defines how a Pivot component can get rendered.
 *
 * The scope of this reference is for the WorkProgramProjection factory to be able to render a Pivot result.
 */
export default class PivotResultReference extends BaseResultReference {
  public constructor(
    public readonly repositoryReference: RepositoryReference,
    public readonly defaultFilters: FilterField[],
    public readonly pivotTable: PivotTableDefinition,
  ) {
    super()
  }
}
