/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type User from '~/src/Domain/Identity/User'

export default class UserUpdatedEvent implements EventInterface {
  public static readonly NAME = 'USER_UPDATED'

  public constructor(public readonly user: User) {}
}
