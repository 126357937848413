/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type Decimal from '~/src/Domain/Shared/Decimal/Decimal'
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type EqualsFilterValueType =
  | string
  | string[]
  | boolean
  | Decimal
  | Decimal[]
  | BetterDate
  | BetterDate[]
  | BetterDateTime
  | BetterDateTime[]

export default class EqualsFilter extends AbstractFilter<EqualsFilterValueType> {
  public constructor(filterType: FilterType) {
    super(Operator.EQUALS, filterType, FilterClass.EQUALS_FILTER)
  }

  protected toUrlParameters(key: string, value: EqualsFilterValueType): string | string[] {
    if (Array.isArray(value)) {
      return value.map(
        (v: string | boolean | Decimal | BetterDate | BetterDateTime) =>
          `${key}[]=${encodeURIComponent(v.toString())}`,
      )
    }

    return `${key}=${encodeURIComponent(value.toString())}`
  }
}
