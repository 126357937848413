/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { BigNumber } from 'bignumber.js'
import InvalidDecimalException from '~/src/Domain/Shared/Decimal/Exception/InvalidDecimalException'
import type { StringableInterface } from '~/src/Domain/Shared/StringableInterface'

export default class Decimal implements StringableInterface {
  private value: BigNumber

  public constructor(decimal: string | number | BigNumber, base?: number) {
    const decimalValue = new BigNumber(decimal, base)
    if (decimalValue.isNaN()) {
      throw new InvalidDecimalException(`${decimal.toString()} is not a valid decimal.`)
    }

    this.value = decimalValue
  }

  public add(decimal: Decimal, base?: number): Decimal {
    return new Decimal(this.value.plus(decimal.value, base))
  }

  public toNumber(): number {
    return this.value.toNumber()
  }

  public toAbsoluteNumber(): number {
    const number = this.toNumber()
    return number < 0 ? number * -1 : number
  }

  public toFixed(decimalPlaces: number, roundingMode?: BigNumber.RoundingMode): string {
    return this.value.toFixed(decimalPlaces, roundingMode)
  }

  public toString(): string {
    return this.value.toFixed(2)
  }
}
