/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import ChamberOfCommerceId from '~/src/Domain/Organisation/ChamberOfCommerceId'
import ContextMenu from '~/src/Domain/Shared/ContextMenu/ContextMenu'
import DrillThroughContextMenuItem from '~/src/Domain/Shared/ContextMenu/DrillThroughContextMenuItem'
import { FieldSorterDirection } from '~/src/Domain/Shared/Filter/FieldSorterDirection'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'
import FilterField from '~/src/Domain/WorkProgram/FilterField/FilterField'
import FilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/FilterFieldFilter'
import ParameterizedFilterFieldFilter from '~/src/Domain/WorkProgram/FilterField/ParameterizedFilterFieldFilter'
import {
  businessUnitFilterField,
  fiscalYearFilterField,
  headerDescriptionExistsFilterField,
  headerDescriptionUnusualFilterField,
  holidayIndicatorFilterField,
  limitFilterField,
  lineDescriptionExistsFilterField,
  lineDescriptionUnusualFilterField,
  manualSourceNameFilterField,
  outsideWorkingHoursFilterField,
  profitAndLossAccountFilterField,
  workdayWeekendFilterField,
} from '~/src/Domain/WorkProgram/Fixtures/General/FilterFields'
import BookingDelayParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BookingDelayParameterDefinition'
import BusinessUnitParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/BusinessUnitParameterDefinition'
import FiscalYearParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/FiscalYearParameterDefinition'
import ManualBookingSourcesParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ManualBookingSourcesParameterDefinition'
import PerformanceMaterialityThresholdParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/PerformanceMaterialityThresholdParameterDefinition'
import ProfitAndLossAccountsParameterDefinition from '~/src/Domain/WorkProgram/Fixtures/General/ParameterDefinition/ProfitAndLossAccountsParameterDefinition'
import type WorkProgramFixtureInterface from '~/src/Domain/WorkProgram/Fixtures/WorkProgramFixtureInterface'
import DataTableResultReference from '~/src/Domain/WorkProgram/ResultReference/DataTableResultReference'
import { FieldsResultReference } from '~/src/Domain/WorkProgram/ResultReference/FieldsResultReference'
import PivotResultReference from '~/src/Domain/WorkProgram/ResultReference/PivotResultReference'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import StepSection from '~/src/Domain/WorkProgram/StepSection'
import StepSectionCollection from '~/src/Domain/WorkProgram/StepSectionCollection'
import t from '~/src/Infrastructure/Shared/Translation/t'
import { usePivotTableDefaultSettings } from '~/src/UserInterface/App/composables/PivotTable/usePivotTableDefaultSettings'
import { RepositoryReference } from '~/src/UserInterface/App/composables/Repository/repositoryReference'
import ParameterProjection from '~/src/UserInterface/WorkProgram/projection/ParameterProjection'
import WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default class GeneralLedger20055194 implements WorkProgramFixtureInterface {
  public create(): () => WorkProgramProjection {
    const { preparerOverviewDefaultPivotTableSettings } = usePivotTableDefaultSettings()

    return () =>
      new WorkProgramProjection(
        new Slug('general-ledger'),
        t('work_programs.20055194.general_ledger.title'),
        t('work_programs.20055194.general_ledger.text'),
        new StepSectionCollection([
          new StepSection(
            'intro',
            t('work_programs.20055194.general_ledger.sections.section_intro.lbl_navigation'),
            [
              new IntroStep(
                new Slug('intro'),
                t('work_programs.20055194.general_ledger.steps.step_intro.title'),
                t('work_programs.20055194.general_ledger.steps.step_intro.text'),
              ),
            ],
          ),
          new StepSection(
            'impact-pnl',
            t('work_programs.20055194.general_ledger.sections.section_impact_pnl.lbl_navigation'),
            [
              new ResultStep(
                new Slug('journal-entries-impact-pnl'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_impact_pnl.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_impact_pnl.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    profitAndLossAccountFilterField(),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new ManualBookingSourcesParameterDefinition(),
                          FilterClass.NOT_API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.source_code',
                      ),
                    ),
                    limitFilterField(),
                  ],
                  [
                    {
                      key: 'absoluteAmount',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('manual-booking-impact-pnl'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_manualbooking_impact_pnl.title',
                ),
                t('work_programs.20055194.general_ledger.steps.step_manualbooking_impact_pnl.text'),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    profitAndLossAccountFilterField(),
                    new FilterField(
                      'source.sourceName',
                      t('general.entity_fields.source.source_name'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new ManualBookingSourcesParameterDefinition(),
                          FilterClass.API_VALUE_FILTER,
                        ),
                      ],
                      true,
                      false,
                      t(
                        'pages.general_ledger.work_program.management_fraud.descriptions.source_code',
                      ),
                    ),
                    limitFilterField(),
                  ],
                  [
                    {
                      key: 'absoluteAmount',
                      direction: FieldSorterDirection.DESC,
                    },
                    { key: 'amount', direction: FieldSorterDirection.DESC },
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'general',
            t('work_programs.20055194.general_ledger.sections.section_general.lbl_navigation'),
            [
              new ResultStep(
                new Slug('round-unusual-values'),
                t('work_programs.20055194.general_ledger.steps.step_round_unusual_values.title'),
                t('work_programs.20055194.general_ledger.steps.step_round_unusual_values.text'),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                        new FilterFieldFilter(FilterClass.WHOLE_NUMBER_FILTER, true),
                      ],
                      true,
                    ),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-no-description'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_no_description.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_no_description.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    headerDescriptionExistsFilterField(),
                    lineDescriptionExistsFilterField(),
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-unusual-description'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_unusual_description.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_unusual_description.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    headerDescriptionUnusualFilterField(),
                    lineDescriptionUnusualFilterField(),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'timing-journal-entries',
            t(
              'work_programs.20055194.general_ledger.sections.section_timing_journalentries.lbl_navigation',
            ),
            [
              new ResultStep(
                new Slug('significant-booking-delay'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_significant_booking_delay.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_significant_booking_delay.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'daysLag',
                      t('general.filter_fields.general_ledger.days_lag'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new BookingDelayParameterDefinition(),
                          FilterClass.LOWER_THAN_FILTER,
                        ),
                      ],
                      true,
                    ),
                  ],
                  [
                    {
                      key: 'daysLag',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-booked-in-weekends'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_booked_in_weekends.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_booked_in_weekends.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    workdayWeekendFilterField(),
                  ],
                  [
                    {
                      key: 'amount',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-booked-on-bank-holidays'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_booked_on_bankholidays.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_booked_on_bankholidays.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    holidayIndicatorFilterField(),
                  ],
                  [
                    {
                      key: 'amount',
                      direction: FieldSorterDirection.DESC,
                    },
                  ],
                ),
              ),
              new ResultStep(
                new Slug('journal-entries-booked-outside-working-hours'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_booked_outside_workinghours.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_journalentries_booked_outside_workinghours.text',
                ),
                new DataTableResultReference(
                  FieldsResultReference.JOURNAL_ENTRY,
                  RepositoryReference.JOURNAL_ENTRY,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    new FilterField(
                      'amount',
                      t('pages.general_ledger.work_program.management_fraud.form.amount_gte'),
                      [
                        new ParameterizedFilterFieldFilter(
                          new PerformanceMaterialityThresholdParameterDefinition(),
                          FilterClass.GREATER_THAN_ABSOLUTE_FILTER,
                        ),
                      ],
                      true,
                    ),
                    outsideWorkingHoursFilterField(),
                  ],
                ),
              ),
            ],
          ),
          new StepSection(
            'users',
            t('work_programs.20055194.general_ledger.sections.section_users.lbl_navigation'),
            [
              new ResultStep(
                new Slug('user-number-of-booking'),
                t('work_programs.20055194.general_ledger.steps.step_users_number_of_booking.title'),
                t('work_programs.20055194.general_ledger.steps.step_users_number_of_booking.text'),
                new PivotResultReference(
                  RepositoryReference.PREPARER_OVERVIEW,
                  [fiscalYearFilterField(), businessUnitFilterField()],
                  new PivotTableDefinition(
                    'user-number-of-booking',
                    t(
                      'work_programs.20055194.general_ledger.steps.step_users_number_of_booking.title',
                    ),
                    [
                      { key: 'enteredBy.department' },
                      { key: 'enteredBy.businessUserName' },
                      { key: 'source.sourceName' },
                    ],
                    [{ key: 'fiscalYear' }, { key: 'currency' }],
                    [{ key: 'debitAmount' }, { key: 'creditAmount' }, { key: 'jeLineCount' }],
                    [{ key: 'businessUnit.businessUnitName' }],
                    preparerOverviewDefaultPivotTableSettings.fieldMappings,
                    preparerOverviewDefaultPivotTableSettings.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToGeneralLedgerJournalEntriesPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'journal-entry',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToGeneralLedgerJournalEntriesPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
              new ResultStep(
                new Slug('users-number-of-manual-booking'),
                t(
                  'work_programs.20055194.general_ledger.steps.step_users_number_of_manual_booking.title',
                ),
                t(
                  'work_programs.20055194.general_ledger.steps.step_users_number_of_manual_booking.text',
                ),
                new PivotResultReference(
                  RepositoryReference.PREPARER_OVERVIEW,
                  [
                    fiscalYearFilterField(),
                    businessUnitFilterField(),
                    manualSourceNameFilterField(),
                  ],
                  new PivotTableDefinition(
                    'users-number-of-manual-booking',
                    t(
                      'work_programs.20055194.general_ledger.steps.step_users_number_of_manual_booking.title',
                    ),
                    [
                      { key: 'source.sourceName' },
                      { key: 'enteredBy.department' },
                      { key: 'enteredBy.businessUserName' },
                    ],
                    [{ key: 'fiscalYear' }, { key: 'currency' }],
                    [{ key: 'debitAmount' }, { key: 'creditAmount' }, { key: 'jeLineCount' }],
                    [{ key: 'businessUnit.businessUnitName' }],
                    preparerOverviewDefaultPivotTableSettings.fieldMappings,
                    preparerOverviewDefaultPivotTableSettings.formatSettings,
                    false,
                    true,
                    new ContextMenu([
                      new DrillThroughContextMenuItem(
                        async ({
                          filterValues,
                          updateFilterSorterHistory,
                          navigation: { navigateToGeneralLedgerJournalEntriesPage },
                        }): Promise<void> => {
                          updateFilterSorterHistory({
                            id: 'journal-entry',
                            filters: filterValues,
                            sorters: [],
                          })

                          await navigateToGeneralLedgerJournalEntriesPage()
                        },
                      ),
                    ]),
                  ),
                ),
              ),
            ],
          ),

          // Finalize section does not need steps, as it has a specific page
          new StepSection(
            'finalize-export',
            t(
              'work_programs.20055194.general_ledger.sections.section_finalize_export.lbl_navigation',
            ),
            [
              new FinalStep(
                new Slug('finalize'),
                t('work_programs.20055194.general_ledger.steps.step_finalize.title'),
                t('work_programs.20055194.general_ledger.steps.step_finalize.text'),
              ),
            ],
          ),
        ]),
        [
          new ParameterProjection(new BookingDelayParameterDefinition()),
          new ParameterProjection(new BusinessUnitParameterDefinition()),
          new ParameterProjection(new FiscalYearParameterDefinition()),
          new ParameterProjection(new ManualBookingSourcesParameterDefinition()),
          new ParameterProjection(new PerformanceMaterialityThresholdParameterDefinition()),
          new ParameterProjection(new ProfitAndLossAccountsParameterDefinition()),
        ],
        [AnalysisType.GENERAL_LEDGER],
        new ChamberOfCommerceId('20055194'),
      )
  }
}
