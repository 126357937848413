/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AsEventHandler } from '~/src/Application/Shared/MessageBus/AsEventHandler'
import EventHandlerInterface from '~/src/Application/Shared/MessageBus/EventHandlerInterface'
import OrganisationUpdatedEvent from '~/src/Domain/Organisation/Event/OrganisationUpdatedEvent'
import type OrganisationStoreRepositoryInterface from '~/src/Domain/Organisation/OrganisationStoreRepositoryInterface'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'

@AsEventHandler(OrganisationUpdatedEvent.NAME, 'organisationUpdatedEventHandler')
export default class OrganisationUpdatedEventHandler extends EventHandlerInterface<OrganisationUpdatedEvent> {
  private readonly organisationStoreRepository: OrganisationStoreRepositoryInterface

  public constructor({ organisationStoreRepository }: Services) {
    super()

    this.organisationStoreRepository = organisationStoreRepository
  }

  public handle(event: OrganisationUpdatedEvent): void {
    if (
      this.organisationStoreRepository.hasOrganisation()
      && this.organisationStoreRepository.getOrganisation()['@id'].equalsTo(event.organisation['@id'])
    ) {
      this.organisationStoreRepository.updateOrganisation(event.organisation)
    }
  }
}
