/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtPlugin } from '#app'
import { useRoute } from 'vue-router'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import { routeOption } from '~/src/UserInterface/App/middleware/02.auth.global'

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    const route = useRoute()
    const handler = () => {
      const tokenExpiryListener = routeOption(nuxtApp._route, 'auth', 'dataRequest')
        ? useService('dataRequestTokenExpiryListener')
        : useService('tokenExpiryListener')

      if (tokenExpiryListener.isListening()) {
        return
      }

      tokenExpiryListener.tryListen()
    }

    nuxtApp.hook('app:mounted', () => {
      document.addEventListener('visibilitychange', handler, false)
    })

    const _unmount = nuxtApp.vueApp.unmount
    nuxtApp.vueApp.unmount = () => {
      document.removeEventListener('visibilitychange', handler, false)
      const tokenExpiryListener = routeOption(route, 'auth', 'dataRequest')
        ? useService('dataRequestTokenExpiryListener')
        : useService('tokenExpiryListener')
      tokenExpiryListener.unlisten()
      _unmount()
    }
  },
})
