/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import Camt05300102FileType from '~/src/Domain/Bank/Application/Camt053/FileType/Camt05300102FileType'
import Pain001001FileType from '~/src/Domain/Bank/Application/Camt053/FileType/Pain001001FileType'
import RabobankBatchExportDutchFileType from '~/src/Domain/Bank/Application/Rabobank/FileType/RabobankBatchExportDutchFileType'
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class Camt053BankDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.CAMT053 === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.CAMT053),
      new StepCollection([
        new Step(
          new Slug('generic'),
          t('components.data_request.camt_053_001_02.pages.xml_file.title'),
          t('components.data_request.camt_053_001_02.pages.xml_file.description'),
          [new FileUploader(new Camt05300102FileType(), ['application/xml', 'text/xml'])],
        ),
        new Step(
          new Slug('pain-001-001'),
          t('components.data_request.camt_053_001_02.pages.pain_001_001.title'),
          t('components.data_request.camt_053_001_02.pages.pain_001_001.description'),
          [new FileUploader(new Pain001001FileType(), ['application/xml', 'text/xml'])],
        ),
        new Step(
          new Slug('rabobank-batch-flat-file-dutch'),
          t('components.data_request.camt_053_001_02.pages.rabobank_batch_flat_file_dutch.title'),
          t(
            'components.data_request.camt_053_001_02.pages.rabobank_batch_flat_file_dutch.description',
          ),
          [
            new FileUploader(new RabobankBatchExportDutchFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
      ]),
    )
  }
}
