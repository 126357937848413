/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface DataRequestOrganisationProps extends DomainModelProps {
  '@id': string
  '@type': string
  name: string
  logo: string
}

export default class DataRequestOrganisation
implements DomainModelInterface<DataRequestOrganisation, DataRequestOrganisationProps> {
  public readonly '@id': OrganisationIri
  public readonly '@type': string

  private constructor(
    id: OrganisationIri,
    type: string,
    public readonly name: string,
    public readonly logo: string,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getClassName(): string {
    return 'DataRequestOrganisation'
  }

  public fromJSON(values: DataRequestOrganisationProps): DataRequestOrganisation {
    return new DataRequestOrganisation(new OrganisationIri(values['@id']), values['@type'], values.name, values.logo)
  }

  public toJSON(): DataRequestOrganisationProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      name: this.name,
      logo: this.logo,
    }
  }
}
