/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import type BaseResultReference from '~/src/Domain/WorkProgram/ResultReference/BaseResultReferences'
import StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import type DataPivotStepResult from '~/src/Domain/WorkProgram/StepResult/DataPivotStepResult'
import type DataTableStepResult from '~/src/Domain/WorkProgram/StepResult/DataTableStepResult'

export default class ResultStep extends StepInterface {
  public constructor(
    public readonly slug: Slug,
    public readonly title: string,
    public readonly text: string,
    /**
     * Define the fields that will be accessible via the FilterSidebar.
     */
    public readonly resultReference: BaseResultReference,
    /**
     * Certain steps, like intro and finalization, can not be hidden.
     */
    public hidden: boolean = false,
    public hiddenReason: string | undefined = undefined,
    public stepResult: undefined | DataPivotStepResult | DataTableStepResult = undefined,
    public disabled: boolean = false,
  ) {
    super()
  }

  public hide(hiddenReason: string, force: boolean): void {
    this.hidden = true
    this.hiddenReason = hiddenReason

    if (force) {
      this.disabled = true
    }
  }

  public show(): void {
    this.hidden = false
    this.hiddenReason = undefined
    this.disabled = false
  }

  public equals(step: StepInterface): boolean {
    return this.slug.equals(step.slug)
  }
}
