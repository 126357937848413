/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import ApplicationFactory from '~/src/Domain/DataRequest/Application/ApplicationFactory'
import { ApplicationId } from '~/src/Domain/DataRequest/Application/ApplicationId'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import type DataRequestFixtureInterface from '~/src/Domain/DataRequest/Fixtures/DataRequestFixtureInterface'
import Step from '~/src/Domain/DataRequest/Step/Step'
import StepCollection from '~/src/Domain/DataRequest/Step/StepCollection'
import BusinessUnitFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/BusinessUnitFileType'
import BusinessUserFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/BusinessUserFileType'
import CustomerFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/CustomerFileType'
import JournalEntryFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/JournalEntryFileType'
import SourceFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/SourceFileType'
import SupplierFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/SupplierFileType'
import TrialBalanceFileType from '~/src/Domain/GeneralLedger/Application/GenericGeneralLedger/FileType/TrialBalanceFileType'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import t from '~/src/Infrastructure/Shared/Translation/t'
import DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

export default class GenericGeneralLedgerDataRequestFixture implements DataRequestFixtureInterface {
  public supportsApplication(applicationId: ApplicationId): boolean {
    return ApplicationId.GENERIC_GENERAL_LEDGER === applicationId
  }

  public create(dataRequest: DataRequest): DataRequestProjection {
    return new DataRequestProjection(
      dataRequest,
      ApplicationFactory.create(ApplicationId.GENERIC_GENERAL_LEDGER),
      new StepCollection([
        new Step(
          new Slug('journal-entries'),
          t('components.data_request.generic_csv.pages.journal_entries.title'),
          t('components.data_request.generic_csv.pages.journal_entries.description'),
          [
            new FileUploader(new JournalEntryFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('trial-balance'),
          t('components.data_request.generic_csv.pages.trial_balance.title'),
          t('components.data_request.generic_csv.pages.trial_balance.description'),
          [
            new FileUploader(new TrialBalanceFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('business-units'),
          t('components.data_request.generic_csv.pages.business_units.title'),
          t('components.data_request.generic_csv.pages.business_units.description'),
          [
            new FileUploader(new BusinessUnitFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('sources'),
          t('components.data_request.generic_csv.pages.sources.title'),
          t('components.data_request.generic_csv.pages.sources.description'),
          [
            new FileUploader(new SourceFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('business-users'),
          t('components.data_request.generic_csv.pages.business_users.title'),
          t('components.data_request.generic_csv.pages.business_users.description'),
          [
            new FileUploader(new BusinessUserFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('customers'),
          t('components.data_request.generic_csv.pages.customers.title'),
          t('components.data_request.generic_csv.pages.customers.description'),
          [
            new FileUploader(new CustomerFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
        new Step(
          new Slug('suppliers'),
          t('components.data_request.generic_csv.pages.suppliers.title'),
          t('components.data_request.generic_csv.pages.suppliers.description'),
          [
            new FileUploader(new SupplierFileType(), [
              'text/csv',
              'application/csv',
              'text/x-comma-separated-values',
              'text/x-csv',
              'text/plain',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]),
          ],
        ),
      ]),
    )
  }
}
