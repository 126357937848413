/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type EventInterface from '~/src/Application/Shared/MessageBus/EventInterface'
import type WorkProgramIri from '~/src/Domain/WorkProgram/WorkProgramIri'

export default class WorkProgramDeletedEvent implements EventInterface {
  public static readonly NAME = 'WORK_PROGRAM_DELETED'

  public constructor(public readonly workProgram: WorkProgramIri) {}
}
