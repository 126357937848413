/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { DataSourceSettings } from '@syncfusion/ej2-pivotview/src/model/datasourcesettings'
import type { FilterModel } from '@syncfusion/ej2-pivotview/src/model/datasourcesettings-model'
import type PivotTableDefinition from '~/src/Domain/Shared/PivotTable/PivotTableDefinition'

export default class PivotTableToSyncfusionPivotTransformer {
  public static readonly IGNORE_EXACT_FIELDS = [
    'bankAccount.bankAccountName',
    'bankStatementEntry.dateDimension',
    'bankStatementEntry.bankAccount',
    'bankStatementEntry.bankStatement',
    'bankStatement',
    'chartOfAccounts',
    'chartOfAccounts.fiscalYear',
    'chartOfAccounts.businessUnit',
    'chartOfAccounts.glAccountDescription',
    'debtor',
    'creditor',
    'customer',
    'supplier',
  ]

  public static readonly IGNORE_PARTIAL_FIELDS = [
    '@',
    'engagementId',
    'engagement',
    'className',
    '.businessUnit.',
    '.chartOfAccounts.',
    'bankStatementEntry.bankStatement.',
  ]

  public transform(pivotTable: PivotTableDefinition): Partial<DataSourceSettings> {
    return {
      rows: pivotTable.rows.map((r) => ({
        name: r.key,
        caption: pivotTable.getTitleForField(r.key),
      })),
      columns: pivotTable.columns.map((c) => ({
        name: c.key,
        caption: pivotTable.getTitleForField(c.key),
      })),
      values: pivotTable.values.map((v) => ({
        name: v.key,
        caption: pivotTable.getTitleForField(v.key),
      })),
      filters: pivotTable.filters.map((f) => ({
        name: f.key,
        caption: pivotTable.getTitleForField(f.key),
      })),
      filterSettings: [
        ...pivotTable.rows,
        ...pivotTable.columns,
        ...pivotTable.values,
        ...pivotTable.filters,
      ]
        .filter((f) => f.value !== undefined)
        .map((f) => ({ name: f.key, items: f.value })) as FilterModel[],
      fieldMapping: pivotTable.fieldMappings.map((m) => ({
        key: m.key,
        title: m.title,
        type: (m.type.charAt(0).toUpperCase() + m.type.slice(1)) as 'Count' | 'Sum',
        dataType: m.dataType,
      })),
      formatSettings: pivotTable.formatSettings.map((f) => ({
        name: f.key,
        format: f.format,
        type: f.type,
      })),
      showColumnGrandTotals: pivotTable.showColumnGrandTotals,
      showRowGrandTotals: pivotTable.showRowGrandTotals,
    }
  }
}
