/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type Decimal from '~/src/Domain/Shared/Decimal/Decimal'
import AbstractFilter from '~/src/Domain/Shared/Filter/Filter/AbstractFilter'
import { FilterClass } from '~/src/Domain/Shared/Filter/Filter/Factory'
import type { FilterType } from '~/src/Domain/Shared/Filter/FilterType'
import { Operator } from '~/src/Domain/Shared/Filter/Operator/Operator'

type AndEqualsFilterValueType = string[] | Decimal[] | BetterDate[] | BetterDateTime[]

export default class AndEqualsFilter extends AbstractFilter<AndEqualsFilterValueType> {
  public constructor(filterType: FilterType) {
    super(Operator.AND_IN, filterType, FilterClass.AND_EQUALS_FILTER)
  }

  protected toUrlParameters(key: string, value: AndEqualsFilterValueType): string {
    return value
      .map(
        (v: string | boolean | Decimal | BetterDate | BetterDateTime) =>
          `${key}[${this.operator}][]=${encodeURIComponent(v.toString())}`,
      )
      .join('&')
  }
}
