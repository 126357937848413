import revive_payload_client_oAIH3zWesL from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NFrlH2rOgg from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_W947zbSNvD from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nZR8JmOyAJ from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_stPCvC5b33 from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LxMI2KFnyJ from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OtcAHp6Mab from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hjvCkaMotf from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/node/app/.nuxt/components.plugin.mjs";
import prefetch_client_zVdQc9soKn from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__zwgm42rhmfq4kj3orzndavyuza/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_4OAniRrzs0 from "/home/node/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_f9VvYIRqjs from "/home/node/app/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._comifu6s5nlk6xmppcjjjeoq4i/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/node/app/.nuxt/sentry-client-config.mjs";
import switch_locale_path_ssr_BJANLwjFtk from "/home/node/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_aEDZrhq82A from "/home/node/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6Ph1iQzNHk from "/home/node/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/home/node/app/.nuxt/formkitPlugin.mjs";
import useDirectives_eKcmn7xOcc from "/home/node/app/node_modules/.pnpm/@bootstrap-vue-next+nuxt@0.24.23_bootstrap-vue-next@0.24.23_vue@3.5.11_typescript@5.6.3___mag_kzkqwc4scec33gwsabiolkorwu/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.js";
import createBootstrap_2wUPgY4m67 from "/home/node/app/node_modules/.pnpm/@bootstrap-vue-next+nuxt@0.24.23_bootstrap-vue-next@0.24.23_vue@3.5.11_typescript@5.6.3___mag_kzkqwc4scec33gwsabiolkorwu/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.js";
import plugin_BpPV6yo7Iq from "/home/node/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_tuszia5zuc3vqte2xyhjkcxq5y/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _1_container_dkV3BIYYZF from "/home/node/app/src/UserInterface/App/plugins/1.container.ts";
import auth_jruPaxGnrV from "/home/node/app/src/UserInterface/App/plugins/auth.ts";
import i18n_ibdyUyHwKa from "/home/node/app/src/UserInterface/App/plugins/i18n.ts";
import mercure_ukVZiMzPZp from "/home/node/app/src/UserInterface/App/plugins/mercure.ts";
import portal_qbpjBZRiA0 from "/home/node/app/src/UserInterface/App/plugins/portal.ts";
import syncfusion_yS6uqpuBN9 from "/home/node/app/src/UserInterface/App/plugins/syncfusion.ts";
export default [
  revive_payload_client_oAIH3zWesL,
  unhead_NFrlH2rOgg,
  router_W947zbSNvD,
  payload_client_nZR8JmOyAJ,
  navigation_repaint_client_stPCvC5b33,
  check_outdated_build_client_LxMI2KFnyJ,
  chunk_reload_client_OtcAHp6Mab,
  plugin_vue3_hjvCkaMotf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zVdQc9soKn,
  plugin_client_4OAniRrzs0,
  sentry_client_f9VvYIRqjs,
  sentry_client_config_o34nk2sJbg,
  switch_locale_path_ssr_BJANLwjFtk,
  i18n_aEDZrhq82A,
  plugin_6Ph1iQzNHk,
  formkitPlugin_pZqjah0RUG,
  useDirectives_eKcmn7xOcc,
  createBootstrap_2wUPgY4m67,
  plugin_BpPV6yo7Iq,
  _1_container_dkV3BIYYZF,
  auth_jruPaxGnrV,
  i18n_ibdyUyHwKa,
  mercure_ukVZiMzPZp,
  portal_qbpjBZRiA0,
  syncfusion_yS6uqpuBN9
]