/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export type LoggerMessage = string | { toString: () => string }
export type LoggerContext = Record<string, any>

/**
 * Describes a logger instance.
 *
 * The message MUST be a string or object implementing __toString().
 *
 * The message MAY contain placeholders in the form: {foo} where foo
 * will be replaced by the context data in key "foo".
 *
 * The context array can contain arbitrary data, the only assumption that
 * can be made by implementors is that if an Exception instance is given
 * to produce a stack trace, it MUST be in a key named "exception".
 *
 * See https://github.com/php-fig/fig-standards/blob/master/accepted/PSR-3-logger-interface.md
 * for the full interface specification.
 */
export default abstract class LoggerInterface {
  /**
   * System is unusable.
   */
  public abstract emergency(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Action must be taken immediately.
   *
   * Example: Entire website down, database unavailable, etc. This should
   * trigger the SMS alerts and wake you up.
   */
  public abstract alert(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Critical conditions.
   *
   * Example: Application component unavailable, unexpected exception.
   */
  public abstract critical(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Runtime errors that do not require immediate action but should typically
   * be logged and monitored.
   */
  public abstract error(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Exceptional occurrences that are not errors.
   *
   * Example: Use of deprecated APIs, poor use of an API, undesirable things
   * that are not necessarily wrong.
   */
  public abstract warning(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Normal but significant events.
   */
  public abstract notice(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Interesting events.
   *
   * Example: User logs in, SQL logs.
   */
  public abstract info(message: LoggerMessage, context?: LoggerContext): void

  /**
   * Detailed debug information.
   */
  public abstract debug(message: LoggerMessage, context?: LoggerContext): void
}
