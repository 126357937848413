/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DomainException from '~/src/Domain/Shared/Exception/DomainException'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import StepNotFoundException from '~/src/Domain/WorkProgram/Exception/StepNotFoundException'
import type ResultStep from '~/src/Domain/WorkProgram/Step/ResultStep'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import type StepSection from '~/src/Domain/WorkProgram/StepSection'
import { isEqual } from '~/src/UserInterface/App/utils/object'

export default class StepSectionCollection {
  public constructor(public readonly stepSections: StepSection[]) {}

  /**
   * @throws {StepNotFoundException}
   */
  public getStep(stepSlug: Slug): StepInterface {
    const step = this
      .stepSections
      .flatMap((s) => s.getVisibleSteps())
      .find((s) => s.slug.equals(stepSlug))

    if (step !== undefined) {
      return step
    }

    throw new StepNotFoundException(`No step found with slug '${stepSlug.toString()}'.`)
  }

  public getResultStep(stepSlug: Slug): ResultStep | undefined {
    return this
      .stepSections
      .flatMap((s) => s.getResultSteps())
      .find((s) => s.slug.equals(stepSlug))
  }

  public getFirstStep(): StepInterface {
    return this.getFlattenedSteps()[0]
  }

  public getLastStep(): StepInterface {
    return this.getFlattenedSteps().splice(-1)[0]
  }

  public hasPreviousStep(currentStep: StepInterface): boolean {
    return this.getFirstStep().slug !== currentStep.slug
  }

  public hasNextStep(currentStep: StepInterface): boolean {
    return this.getLastStep().slug !== currentStep.slug
  }

  /**
   * @throws {DomainException} when there is no previous step. use `hasPreviousStep` first
   */
  public getPreviousStep(currentStep: StepInterface): StepInterface {
    if (!this.hasPreviousStep(currentStep)) {
      throw new DomainException(
        `Step '${currentStep.slug.toString()}' does not have a previous step.`,
      )
    }

    const currentStepIndex = this.getFlattenedSteps().findIndex((s) => s.equals(currentStep))

    return this.getFlattenedSteps()[currentStepIndex - 1]
  }

  /**
   * @throws {DomainException} when there is no next step. use `hasNextStep` first
   */
  public getNextStep(currentStep: StepInterface): StepInterface {
    if (!this.hasNextStep(currentStep)) {
      throw new DomainException(`Step '${currentStep.slug.toString()}' does not have a next step.`)
    }

    const currentStepIndex = this.getFlattenedSteps().findIndex((s) => s.equals(currentStep))

    return this.getFlattenedSteps()[currentStepIndex + 1]
  }

  public getNumHiddenSteps(): number {
    return this.stepSections.reduce(
      (partialSum, section) => partialSum + section.getHiddenStepCount(),
      0,
    )
  }

  public getNumResultSteps(): number {
    return this.stepSections.reduce(
      (partialSum, section) => partialSum + section.getResultStepCount(),
      0,
    )
  }

  public equalsState(stepSectionCollection: StepSectionCollection): boolean {
    return isEqual(
      this.getFlattenedSteps().map((s) => ({
        slug: s.slug,
        hidden: s.hidden,
        hiddenReason: s.hiddenReason,
      })),
      stepSectionCollection.getFlattenedSteps().map((s) => ({
        slug: s.slug,
        hidden: s.hidden,
        hiddenReason: s.hiddenReason,
      })),
    )
  }

  private getFlattenedSteps(): StepInterface[] {
    return [...this.stepSections].flatMap((s) => s.getVisibleSteps())
  }
}
